
import { defineComponent } from 'vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import { getFiltersData, initFilterOptions } from '@/utils/filters'
import {
  lookupCampaigns,
  lookupOfferCategories,
  lookupAffiliates,
  lookupByField,
  FieldOption,
  lookupAdvertisers,
  lookupManagers,
  lookupGeos,
  lookupOffers,
  lookupTrafficSourceNames,
} from '@/utils/lookups'
import { getDefaultFilters } from '@/utils/request'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'FiltersDialog',
  components: { DatePicker },
  props: ['open', 'close', 'reset', 'currentFilters', 'changeFilters', 'tab'],
  async created() {
    await initFilterOptions(this)
  },
  computed: {
    ...mapState('authModule', ['user']),
    showDialog(): boolean {
      return this.open
    },
    hideForEditedOffers(): boolean {
      return !/^\/offers\/\d+$/.test(this.$route.path)
    },
    hideForNewOffers(): boolean {
      return !this.$route.path.includes('offers/new')
    },
    hideForConversions(): boolean {
      return this.tab !== 'conversions'
    },
    hideForClickLogs(): boolean {
      return this.tab !== 'clicks'
    },
    hideForAffReports(): boolean {
      return this.tab !== 'aff-reports'
    },
    hideForFinances(): boolean {
      return !this.$route.path.includes('finances')
    },
    hideForFinancesReport(): boolean {
      return !this.$route.path.includes('finances-report')
    },
    hideForOffers(): boolean {
      return !this.$route.path.includes('offers')
    },
    hideForAffiliates(): boolean {
      return !this.$route.path.includes('affiliates')
    },
    hideForCampaigns(): boolean {
      return !this.$route.path.includes('campaigns')
    },
    hideForAdvertisers(): boolean {
      return !this.$route.path.includes('advertisers')
    },
    hideForWallets(): boolean {
      return !this.$route.path.includes('wallets')
    },
    hideForPayouts(): boolean {
      return !this.$route.path.includes('payouts')
    },
    hideForStatistics(): boolean {
      return !this.$route.path.includes('statistics')
    },
    hideForLogs(): boolean {
      return !this.$route.path.includes('logs')
    },
  },
  data: function() {
    return {
      filters: { ...this.currentFilters },
      ...getFiltersData(this.currentFilters.name),
    }
  },
  watch: {
    search() {
      this.onChangeFilters('name', this.search)
    },
    currentFilters() {
      this.filters = { ...this.currentFilters }
      this.search = this.currentFilters.name
    },
  },
  methods: {
    addFilters() {
      this.$emit('changeFilters', this.filters)
      this.$emit('close')
    },
    resetFilters() {
      this.search = null
      this.filters = getDefaultFilters()
      this.$emit('changeFilters', this.filters)
    },
    onChangeFilters(field: string, value: string | null) {
      this.clearFilter(field)
      if (field === 'vertical') {
        this.filters.offerCategoryId = null
      }
      this.filters[field] = value
    },
    onChangeDate(date: { startDate: string; endDate: string }) {
      this.filters.dateFrom = date.startDate
      this.filters.dateTo = date.endDate
    },
    async getOptionsByField(
      value: string,
      update: Function,
      field: FieldOption,
    ) {
      await lookupByField(this, value, update, field)
    },
    async getAdvertisers(value: string, update: Function) {
      await lookupAdvertisers(this, value, update)
    },
    async getManagers(value: string, update: Function) {
      await lookupManagers(this, value, update)
    },
    async getCategories(value: string, update: Function) {
      await lookupOfferCategories(this, value, update, [], {
        vertical: this.filters.vertical,
      })
    },
    async getAffiliates(value: string, update: Function) {
      await lookupAffiliates(this, value, update)
    },
    async getCampaigns(value: string, update: Function) {
      await lookupCampaigns(this, value, update)
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update)
    },
    async getOffers(value: string, update: Function) {
      await lookupOffers(this, value, update)
    },
    async getOfferCategories(value: string, update: Function) {
      await lookupOfferCategories(this, value, update)
    },
    async getTrafficSources(value: string, update: Function) {
      await lookupTrafficSourceNames(this, value, update)
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
  },
})


import { defineComponent } from 'vue'
import AffAdvReports from './tabs/AffAdvReports/AffAdvReports.vue'
import AffReports from './tabs/AffReports/AffReports.vue'
import AdvReports from './tabs/AdvReports/AdvReports.vue'

export default defineComponent({
  name: 'FinancesReport',
  components: { AffAdvReports, AffReports, AdvReports },
  data() {
    return {
      tab: this.$route.query.tab || 'aff-adv-reports',
    }
  },
})

import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "q-mx-lg" }
const _hoisted_2 = { class: "row justify-between items-center" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "q-mr-lg" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { key: 4 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { key: 3 }
const _hoisted_14 = { key: 4 }
const _hoisted_15 = { class: "row items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_AddReferalDialog = _resolveComponent("AddReferalDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_table, {
        dense: "",
        rows: _ctx.rows,
        columns: _ctx.columns,
        "row-key": "id",
        separator: "cell",
        pagination: _ctx.pagination,
        "onUpdate:pagination": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pagination) = $event)),
        loading: _ctx.loading,
        onRequest: _ctx.onRequest,
        "binary-state-sort": ""
      }, {
        top: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, [
                _cache[7] || (_cache[7] = _createElementVNode("b", null, "Индивидуальная % ставка реф. программы", -1)),
                _createVNode(_component_q_input, {
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.individualReferralPercent,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.individualReferralPercent) = $event)),
                  type: "number",
                  step: "1",
                  min: "0",
                  max: "100",
                  onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
                }, {
                  append: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("%")
                  ])),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createVNode(_component_q_checkbox, {
                dense: "",
                class: "q-mr-md",
                label: "Вкл/Выкл реф. программу для  данного веба",
                modelValue: _ctx.referralProgramOn,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.referralProgramOn) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("span", null, [
              _createVNode(_component_q_btn, {
                icon: "add",
                class: "q-mr-md",
                color: "primary",
                "no-caps": "",
                label: "Добавить реферала",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showAddReferralDialog = !_ctx.showAddReferralDialog))
              })
            ]),
            _createElementVNode("span", null, [
              _createVNode(_component_q_btn, {
                color: "positive",
                "no-caps": "",
                label: "Сохранить",
                onClick: _ctx.save
              }, null, 8, ["onClick"])
            ])
          ])
        ]),
        "body-cell-email": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_IconWithName, {
                id: props.row.id,
                name: props.row.email,
                size: "20px",
                color: "secondary",
                icon: "account_box",
                entity: "affiliate"
              }, null, 8, ["id", "name"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-approvedBalances": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                (props.row.usdProfitApprove)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createTextVNode(_toDisplayString(`${_ctx.frmt(props.row.usdProfitApprove)} `) + " ", 1),
                      _cache[8] || (_cache[8] = _createElementVNode("small", null, "USD", -1))
                    ]))
                  : _createCommentVNode("", true),
                (props.row.eurProfitApprove)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createTextVNode(_toDisplayString(`${_ctx.frmt(props.row.eurProfitApprove)} `) + " ", 1),
                      _cache[9] || (_cache[9] = _createElementVNode("small", null, "EUR", -1))
                    ]))
                  : _createCommentVNode("", true),
                (props.row.uahProfitApprove)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createTextVNode(_toDisplayString(`${_ctx.frmt(props.row.uahProfitApprove)} `) + " ", 1),
                      _cache[10] || (_cache[10] = _createElementVNode("small", null, "UAH", -1))
                    ]))
                  : _createCommentVNode("", true),
                (props.row.plnProfitApprove)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createTextVNode(_toDisplayString(`${_ctx.frmt(props.row.plnProfitApprove)} `) + " ", 1),
                      _cache[11] || (_cache[11] = _createElementVNode("small", null, "PLN", -1))
                    ]))
                  : _createCommentVNode("", true),
                (
                !props.row.plnProfitApprove &&
                  !props.row.uahProfitApprove &&
                  !props.row.eurProfitApprove &&
                  !props.row.usdProfitApprove
              )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[12] || (_cache[12] = [
                      _createTextVNode(_toDisplayString(`0.00 `) + " "),
                      _createElementVNode("small", null, "USD", -1)
                    ])))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-holdBalances": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                (props.row.usdProfitHold)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createTextVNode(_toDisplayString(`${_ctx.frmt(props.row.usdProfitHold)} `) + " ", 1),
                      _cache[13] || (_cache[13] = _createElementVNode("small", null, "USD", -1))
                    ]))
                  : _createCommentVNode("", true),
                (props.row.eurProfitHold)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createTextVNode(_toDisplayString(`${_ctx.frmt(props.row.eurProfitHold)} `) + " ", 1),
                      _cache[14] || (_cache[14] = _createElementVNode("small", null, "EUR", -1))
                    ]))
                  : _createCommentVNode("", true),
                (props.row.uahProfitHold)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createTextVNode(_toDisplayString(`${_ctx.frmt(props.row.uahProfitHold)} `) + " ", 1),
                      _cache[15] || (_cache[15] = _createElementVNode("small", null, "UAH", -1))
                    ]))
                  : _createCommentVNode("", true),
                (props.row.plnProfitHold)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createTextVNode(_toDisplayString(`${_ctx.frmt(props.row.plnProfitHold)} `) + " ", 1),
                      _cache[16] || (_cache[16] = _createElementVNode("small", null, "PLN", -1))
                    ]))
                  : _createCommentVNode("", true),
                (
                !props.row.plnProfitHold &&
                  !props.row.uahProfitHold &&
                  !props.row.eurProfitHold &&
                  !props.row.usdProfitHold
              )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[17] || (_cache[17] = [
                      _createTextVNode(_toDisplayString(`0.00 `) + " "),
                      _createElementVNode("small", null, "USD", -1)
                    ])))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_q_input, {
                  dense: "",
                  class: "q-mr-md width-180",
                  label: "Ставка",
                  outlined: "",
                  modelValue: props.row.broughtByReferralPercent,
                  "onUpdate:modelValue": ($event: any) => ((props.row.broughtByReferralPercent) = $event),
                  type: "number",
                  step: "1",
                  min: "0",
                  max: "100",
                  onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
                }, {
                  append: _withCtx(() => _cache[18] || (_cache[18] = [
                    _createTextVNode("%")
                  ])),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_q_checkbox, {
                  dense: "",
                  label: "Вкл/Выкл",
                  class: "q-mr-md",
                  hint: "Вкл/Выкл реф. программу за данного реферала",
                  modelValue: props.row.broughtByReferralProgramOn,
                  "onUpdate:modelValue": ($event: any) => ((props.row.broughtByReferralProgramOn) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_q_btn, {
                  dense: "",
                  round: "",
                  flat: "",
                  color: "negative",
                  size: "sm",
                  onClick: _withModifiers(($event: any) => (_ctx.deleteReferral(props.row.id)), ["stop"]),
                  icon: "delete"
                }, null, 8, ["onClick"])
              ])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
    ]),
    _createVNode(_component_AddReferalDialog, {
      open: _ctx.showAddReferralDialog,
      onClose: _ctx.onCloseDialog,
      onRefresh: _ctx.onRequest,
      affiliateId: _ctx.affiliateId
    }, null, 8, ["open", "onClose", "onRefresh", "affiliateId"])
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "row justify-between" }
const _hoisted_3 = { class: "flex row q-mt-lg" }
const _hoisted_4 = { class: "flex column q-mb-md q-mr-md items-center" }
const _hoisted_5 = { class: "flex row items-center" }
const _hoisted_6 = {
  key: 0,
  class: "btn display-inline flex items-center"
}
const _hoisted_7 = { class: "row" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "remove-btn-2" }
const _hoisted_10 = { class: "flex column q-mb-md q-mr-md items-center" }
const _hoisted_11 = { class: "flex row items-center" }
const _hoisted_12 = {
  key: 0,
  class: "btn display-inline flex items-center"
}
const _hoisted_13 = { class: "row" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "remove-btn-2" }
const _hoisted_16 = { class: "flex row q-gutter-x-md q-mb-md mobile-display-block" }
const _hoisted_17 = { class: "flex row items-center q-mb-md" }
const _hoisted_18 = { class: "flex column" }
const _hoisted_19 = { class: "flex row" }
const _hoisted_20 = { class: "flex column" }
const _hoisted_21 = { class: "flex column" }
const _hoisted_22 = { class: "flex column self-center" }
const _hoisted_23 = { class: "q-mt-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_center = _resolveComponent("center")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_vue_feather = _resolveComponent("vue-feather")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-mx-xl q-pl-md q-my-lg" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_btn, {
        "no-caps": "",
        icon: "chevron_left",
        flat: "",
        label: "Все пуши",
        class: "q-mb-lg",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/push-notifications')))
      }),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_center, {
            key: 0,
            class: "q-mt-lg"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_spinner, {
                color: "primary",
                size: "3em",
                thickness: 2
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _cache[6] || (_cache[6] = _createElementVNode("span", null, [
                    _createTextVNode("Preview изображение "),
                    _createElementVNode("sup", null, "?")
                  ], -1)),
                  _createVNode(_component_q_tooltip, {
                    "max-width": "400px",
                    anchor: "bottom middle",
                    self: "top middle",
                    class: "q-tooltip"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Изображение, которое будет размещено справа от текста и отображается в preview уведомления. Размер - квадрат 1:1")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_5, [
                    (!_ctx.images.previewImage)
                      ? (_openBlock(), _createElementBlock("label", _hoisted_6, [
                          _createVNode(_component_vue_feather, {
                            class: "drop-image",
                            type: "image"
                          }),
                          _createElementVNode("input", {
                            type: "file",
                            name: "image",
                            onChange: _cache[1] || (_cache[1] = e => _ctx.onChange(e, 'previewImage'))
                          }, null, 32)
                        ]))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass(["display-inline align-center", { image: true }])
                        }, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("img", {
                              width: "84",
                              height: "84",
                              src: 
                      _ctx.previewImage
                        ? _ctx.previewImage
                        : _ctx.storageUrl + '/' + _ctx.images.previewImage
                    ,
                              alt: "",
                              class: "img"
                            }, null, 8, _hoisted_8),
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_vue_feather, {
                                type: "x",
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeFile('previewImage')))
                              })
                            ])
                          ])
                        ]))
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[8] || (_cache[8] = _createElementVNode("span", null, [
                    _createTextVNode("Фоновое изображение "),
                    _createElementVNode("sup", null, "?")
                  ], -1)),
                  _createVNode(_component_q_tooltip, {
                    "max-width": "400px",
                    anchor: "bottom middle",
                    self: "top middle",
                    class: "q-tooltip"
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Изображение, которое отображается в открытом (расширенном) уведомлении под текстом уведомления. Размер - прямоугольник 2:1")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_11, [
                    (!_ctx.images.backgroundImage)
                      ? (_openBlock(), _createElementBlock("label", _hoisted_12, [
                          _createVNode(_component_vue_feather, {
                            class: "drop-image",
                            type: "image"
                          }),
                          _createElementVNode("input", {
                            type: "file",
                            name: "image",
                            onChange: _cache[3] || (_cache[3] = e => _ctx.onChange(e, 'backgroundImage'))
                          }, null, 32)
                        ]))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass(["display-inline align-center", { image: true }])
                        }, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("img", {
                              width: "184",
                              height: "84",
                              src: 
                      _ctx.backgroundImage
                        ? _ctx.backgroundImage
                        : _ctx.storageUrl + '/' + _ctx.images.backgroundImage
                    ,
                              alt: "",
                              class: "img"
                            }, null, 8, _hoisted_14),
                            _createElementVNode("div", _hoisted_15, [
                              _createVNode(_component_vue_feather, {
                                type: "x",
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.removeFile('backgroundImage')))
                              })
                            ])
                          ])
                        ]))
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_q_input, {
                class: "flex col-sm-12 col-lg-2",
                dense: "",
                disable: "",
                "model-value": "Язык"
              }),
              _createVNode(_component_q_input, {
                class: "flex col-sm-12 col-lg-2",
                dense: "",
                disable: "",
                "model-value": "Заголовок"
              }),
              _createVNode(_component_q_input, {
                class: "flex col-sm-12 col-lg-2",
                dense: "",
                disable: "",
                "model-value": "Описание"
              })
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "flex row q-gutter-x-md mobile-display-block",
                key: index
              }, [
                _createVNode(_component_q_select, {
                  filled: "",
                  dense: "",
                  disable: "",
                  class: "flex col-sm-12 col-lg-2",
                  modelValue: language.id,
                  "onUpdate:modelValue": ($event: any) => ((language.id) = $event),
                  options: _ctx.languages,
                  onFilter: _ctx.getLanguages,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": "",
                  error: !!_ctx.errors?.languageId,
                  "error-message": _ctx.errors?.languageId
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onFilter", "error", "error-message"]),
                _createVNode(_component_q_input, {
                  filled: "",
                  dense: "",
                  class: "flex col-sm-12 col-lg-2",
                  "model-value": _ctx.pushGroup[index]?.title,
                  "onUpdate:modelValue": 
            title =>
              _ctx.updatePush(
                { ..._ctx.pushGroup[index], title, languageId: language.id },
                index,
              )
          ,
                  placeholder: "Get your bonus today!",
                  error: !!_ctx.errors?.notifications?.[index]?.message,
                  "error-message": _ctx.errors?.notifications?.[index]?.message
                }, null, 8, ["model-value", "onUpdate:modelValue", "error", "error-message"]),
                _createVNode(_component_q_input, {
                  filled: "",
                  dense: "",
                  autogrow: "",
                  class: "flex col-sm-12 col-lg-2",
                  placeholder: "Make a deposit of at list 25$ and ...",
                  "model-value": _ctx.pushGroup[index]?.message,
                  "onUpdate:modelValue": 
            message =>
              _ctx.updatePush(
                { ..._ctx.pushGroup[index], message, languageId: language.id },
                index,
              )
          ,
                  error: !!_ctx.errors?.notifications?.[index]?.message,
                  "error-message": _ctx.errors?.notifications?.[index]?.message
                }, null, 8, ["model-value", "onUpdate:modelValue", "error", "error-message"])
              ]))
            }), 128)),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_q_btn, {
                  class: "q-mr-sm",
                  onClick: _ctx.addRule,
                  round: "",
                  color: "secondary",
                  icon: "add"
                }, null, 8, ["onClick"]),
                _cache[9] || (_cache[9] = _createElementVNode("b", null, "Добавить индивидуальные настройки рассылки", -1))
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rules, (rule, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "flex row q-mb-md mobile-display-block",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_18, [
                    _cache[11] || (_cache[11] = _createElementVNode("div", null, [
                      _createTextVNode("Время отправки "),
                      _createElementVNode("sup", null, "?")
                    ], -1)),
                    _createVNode(_component_q_tooltip, {
                      "max-width": "400px",
                      anchor: "bottom left",
                      self: "top middle",
                      class: "q-tooltip"
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("Время отправки по GMT+1, учитывайте часовой пояс для Вашего гео")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_q_input, {
                      filled: "",
                      dense: "",
                      "model-value": rule.time,
                      "onUpdate:modelValue": 
                time => _ctx.updateRules({ ...rule, time }, index)
              ,
                      placeholder: "Например: 14:20, 18:13, 20:20"
                    }, null, 8, ["model-value", "onUpdate:modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex items-center q-ml-lg q-pl-sm" }, "Дни:", -1)),
                      _createVNode(_component_q_field, {
                        class: "max-content",
                        borderless: "",
                        dark: "",
                        dense: "",
                        error: !!_ctx.errors?.days,
                        "error-message": _ctx.errors?.days
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_option_group, {
                            class: "q-px-lg",
                            modelValue: rule.days,
                            "onUpdate:modelValue": ($event: any) => ((rule.days) = $event),
                            options: _ctx.days,
                            color: "primary",
                            type: "checkbox",
                            inline: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                        ]),
                        _: 2
                      }, 1032, ["error", "error-message"])
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex items-center q-ml-lg q-pl-sm" }, "События:", -1)),
                      _createVNode(_component_q_field, {
                        class: "max-content",
                        borderless: "",
                        dark: "",
                        dense: "",
                        error: !!_ctx.errors?.types,
                        "error-message": _ctx.errors?.types
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_option_group, {
                            class: "q-px-lg",
                            modelValue: rule.types,
                            "onUpdate:modelValue": ($event: any) => ((rule.types) = $event),
                            options: _ctx.types,
                            color: "primary",
                            type: "checkbox",
                            inline: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                        ]),
                        _: 2
                      }, 1032, ["error", "error-message"])
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_q_btn, {
                          onClick: ($event: any) => (_ctx.deleteRule(index)),
                          outline: "",
                          round: "",
                          color: "primary",
                          icon: "delete"
                        }, null, 8, ["onClick"])
                      ])
                    ])
                  ])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_q_btn, {
                disable: _ctx.isSaving,
                loading: _ctx.isSaving,
                onClick: _ctx.onSubmit,
                label: "Сохранить",
                type: "submit",
                color: "primary",
                "no-caps": ""
              }, null, 8, ["disable", "loading", "onClick"]),
              _createVNode(_component_q_btn, {
                outline: "",
                label: "Сбросить все",
                type: "reset",
                disable: _ctx.isSaving,
                color: "primary",
                onClick: _ctx.onReset,
                class: "q-ml-sm",
                "no-caps": ""
              }, null, 8, ["disable", "onClick"])
            ])
          ]))
    ]),
    _: 1
  }))
}

import Filters from '@/components/filters/Filters.vue'
import { defineComponent } from 'vue'
import { FiltersType, getDefaultFilters } from '@/utils/request'
import { getData } from '@/utils/getData'
import { FormatMoney, FormatMoneyParse } from 'format-money-js'
import { formatValue } from '@/utils'
import { LocationQueryValue } from 'vue-router'
import { exportToCsv } from '@/utils/export'
import { getColumns } from './getColumns'
import { omit } from 'lodash'
import IconWithName from '@/components/icons/IconWithName.vue'

type RowType = {
  offerAdvertiserId: number
  offerAdvertiserName: number
  usdAdvertiserHoldSum: number
  eurAdvertiserHoldSum: number
  usdAdvertiserRejectedSum: number
  eurAdvertiserRejectedSum: number
  usdAdvertiserApprovedSum: number
  eurAdvertiserApprovedSum: number
}

export default defineComponent({
  name: 'AdvReports',
  components: { IconWithName, Filters },
  data() {
    const filtersL = this.$storage.getStorageSync('financeAdvReportGmbt') || {}
    const queryFilters = this.$route.query
    for (const [filter, value] of Object.entries(queryFilters)) {
      if (Array.isArray(value)) {
        queryFilters[filter] = value.map(v => {
          const num = Number(v)
          return isNaN(num) ? v : ((num as unknown) as LocationQueryValue)
        })
      } else {
        const numberValue = Number(value)
        queryFilters[filter] = isNaN(numberValue)
          ? [value]
          : [(numberValue as unknown) as LocationQueryValue]
      }
    }
    const filters = {
      ...getDefaultFilters(),
      ...filtersL,
      ...queryFilters,
      noAso: (filtersL.noAso || queryFilters.noAso) ?? false,
      noTraffborne:
        (filtersL.noTraffborne || queryFilters.noTraffborne) ?? false,
    }
    const defaultValues = {
      usdAdvertiserHoldSum: 0,
      eurAdvertiserHoldSum: 0,
      usdAdvertiserRejectedSum: 0,
      eurAdvertiserRejectedSum: 0,
      usdAdvertiserApprovedSum: 0,
      eurAdvertiserApprovedSum: 0,
    }
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      loading: false,
      columns: getColumns(),
      rows: [] as RowType[],
      columnKeys: Object.keys(defaultValues),
      filters,
      chartData: [] as RowType[],
      defaultValues,
      moneyFormatter,
    }
  },
  mounted() {
    this.onRequestChartData()
  },
  computed: {
    formattedRows(): Array<{
      [key: string]: string | number | undefined | FormatMoneyParse
    }> {
      return this.rows.map(row => ({
        ...row,
        usdAdvertiserHoldSum: this.formatMoney(row.usdAdvertiserHoldSum),
        eurAdvertiserHoldSum: this.formatMoney(row.eurAdvertiserHoldSum),
        usdAdvertiserRejectedSum: this.formatMoney(
          row.usdAdvertiserRejectedSum,
        ),
        eurAdvertiserRejectedSum: this.formatMoney(
          row.eurAdvertiserRejectedSum,
        ),
        usdAdvertiserApprovedSum: this.formatMoney(
          row.usdAdvertiserApprovedSum,
        ),
        eurAdvertiserApprovedSum: this.formatMoney(
          row.eurAdvertiserApprovedSum,
        ),
      }))
    },
    total(): Record<string, number> {
      return this.rows.reduce(
        (acc, row) => {
          acc.usdAdvertiserHoldSum += row.usdAdvertiserHoldSum || 0
          acc.eurAdvertiserHoldSum += row.eurAdvertiserHoldSum || 0
          acc.usdAdvertiserRejectedSum += row.usdAdvertiserRejectedSum || 0
          acc.eurAdvertiserRejectedSum += row.eurAdvertiserRejectedSum || 0
          acc.usdAdvertiserApprovedSum += row.usdAdvertiserApprovedSum || 0
          acc.eurAdvertiserApprovedSum += row.eurAdvertiserApprovedSum || 0
          return acc
        },
        { ...this.defaultValues },
      )
    },
  },
  watch: {
    async filters() {
      this.$storage.setStorageSync('financeAdvReportGmbt', this.filters)
    },
  },
  methods: {
    async onRequestChartData() {
      const orderBy = { [String(this.filters?.groupBy)]: 'ASC' }
      const filters = omit(this.filters, 'offerIds')
      this.chartData = await getData(
        {
          filters,
          limit: 0,
          offset: 0,
          orderBy,
        },
        '/api/finances',
      )
      const mergedRecords: Record<string, RowType> = {}
      this.chartData.forEach(item => {
        const id = item.offerAdvertiserId
        const advertiserName = item.offerAdvertiserName
        if (!mergedRecords[id]) {
          mergedRecords[id] = {
            offerAdvertiserId: id,
            offerAdvertiserName: advertiserName,
            usdAdvertiserHoldSum: 0,
            eurAdvertiserHoldSum: 0,
            usdAdvertiserRejectedSum: 0,
            eurAdvertiserRejectedSum: 0,
            usdAdvertiserApprovedSum: 0,
            eurAdvertiserApprovedSum: 0,
          }
        }
        mergedRecords[id].usdAdvertiserHoldSum += item.usdAdvertiserHoldSum ?? 0
        mergedRecords[id].eurAdvertiserHoldSum += item.eurAdvertiserHoldSum ?? 0
        mergedRecords[id].usdAdvertiserRejectedSum +=
          item.usdAdvertiserRejectedSum ?? 0
        mergedRecords[id].eurAdvertiserRejectedSum +=
          item.eurAdvertiserRejectedSum ?? 0
        mergedRecords[id].usdAdvertiserApprovedSum +=
          item.usdAdvertiserApprovedSum ?? 0
        mergedRecords[id].eurAdvertiserApprovedSum +=
          item.eurAdvertiserApprovedSum ?? 0
      })
      this.rows = Object.values(mergedRecords)
    },
    formatMoney(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
      )
    },
    resetFilters() {
      this.filters = {
        ...getDefaultFilters(),
        noAso: false,
        noTraffborne: false,
      }
      this.$storage.setStorageSync('financeAdvReportGmbt', {
        ...getDefaultFilters(),
      })
      this.onRequestChartData()
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) return
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters }
      this.onRequestChartData()
    },
    onExport() {
      const totalRow = {
        advertiserName: 'Total',
        usdAdvertiserHoldSum: this.total.usdAdvertiserHoldSum,
        eurAdvertiserHoldSum: this.total.eurAdvertiserHoldSum,
        usdAdvertiserRejectedSum: this.total.usdAdvertiserRejectedSum,
        eurAdvertiserRejectedSum: this.total.eurAdvertiserRejectedSum,
        usdAdvertiserApprovedSum: this.total.usdAdvertiserApprovedSum,
        eurAdvertiserApprovedSum: this.total.eurAdvertiserApprovedSum,
      }
      const exportData = [
        ...this.rows.map(row => ({
          advertiserName: `${row.offerAdvertiserId}_${row.offerAdvertiserName}`,
          usdAdvertiserHoldSum: row.usdAdvertiserHoldSum,
          eurAdvertiserHoldSum: row.eurAdvertiserHoldSum,
          usdAdvertiserRejectedSum: row.usdAdvertiserRejectedSum,
          eurAdvertiserRejectedSum: row.eurAdvertiserRejectedSum,
          usdAdvertiserApprovedSum: row.usdAdvertiserApprovedSum,
          eurAdvertiserApprovedSum: row.eurAdvertiserApprovedSum,
        })),
        totalRow,
      ]
      exportToCsv(exportData, this.columns)
    },
  },
})

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDialog) = $event)),
    onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"min-width":"350px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "text-h6" }, "Переводы лендингов", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                icon: "add",
                outline: "",
                "no-caps": "",
                dense: "",
                label: "Добавить",
                onClick: _ctx.addT
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.translations, (translation, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "flex column q-mb-lg",
              key: index
            }, [
              _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _cache[3] || (_cache[3] = _createElementVNode("b", null, "Язык", -1)),
                    _createVNode(_component_q_select, {
                      dense: "",
                      ref_for: true,
                      ref: `target-languages-${index}`,
                      onBlur: ($event: any) => (_ctx.clearFilter(`target-languages-${index}`)),
                      outlined: "",
                      clearable: "",
                      "model-value": translation.languageId,
                      "onUpdate:modelValue": 
                value =>
                  _ctx.updateField({ ...translation, languageId: value }, index)
              ,
                      options: _ctx.languages,
                      onFilter: _ctx.getLanguages,
                      "option-value": "id",
                      "option-label": "name",
                      "emit-value": "",
                      "map-options": "",
                      "use-input": ""
                    }, null, 8, ["onBlur", "model-value", "onUpdate:modelValue", "options", "onFilter"])
                  ]),
                  _createElementVNode("div", null, [
                    _cache[4] || (_cache[4] = _createElementVNode("b", null, "Название", -1)),
                    _createVNode(_component_q_input, {
                      dense: "",
                      outlined: "",
                      "model-value": translation.name,
                      "onUpdate:modelValue": 
                value =>
                  _ctx.updateField(
                    {
                      ...translation,
                      name: value,
                    },
                    index,
                  )
              
                    }, null, 8, ["model-value", "onUpdate:modelValue"])
                  ])
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128)),
          _createVNode(_component_q_card_actions, {
            align: "center",
            class: "text-primary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                label: "Сохранить",
                onClick: _ctx.onClose
              }, null, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "column col-lg-5 col-sm-12" }
const _hoisted_3 = { class: "row justify-between items-center" }
const _hoisted_4 = { class: "text-h6" }
const _hoisted_5 = { class: "row items-center q-mb-xs" }
const _hoisted_6 = { class: "row items-center q-mb-xs" }
const _hoisted_7 = { class: "row items-center q-mb-xs" }
const _hoisted_8 = { class: "row items-center q-mb-xs" }
const _hoisted_9 = { class: "row items-center q-mb-xs" }
const _hoisted_10 = { class: "row items-center q-mb-xs" }
const _hoisted_11 = { class: "row items-center q-mb-xs" }
const _hoisted_12 = { class: "row items-center q-mb-xs" }
const _hoisted_13 = { class: "row items-center q-mb-xs" }
const _hoisted_14 = { class: "row items-center q-mb-xs" }
const _hoisted_15 = {
  key: 0,
  class: "row items-center q-mb-xs"
}
const _hoisted_16 = { class: "row items-center q-mb-xs" }
const _hoisted_17 = {
  key: 1,
  class: "row items-center q-mb-xs"
}
const _hoisted_18 = {
  key: 2,
  class: "row items-center q-mb-xs"
}
const _hoisted_19 = { class: "column col-lg-7 col-sm-12 q-mt-xl q-pt-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_PayoutLeads = _resolveComponent("PayoutLeads")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-mx-xl q-px-xl" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_btn, {
            "no-caps": "",
            icon: "chevron_left",
            flat: "",
            label: "Все выплаты",
            class: "q-mb-lg q-mt-xl",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (
            _ctx.$route.params.affiliateId
              ? _ctx.$router.push(`/affiliates/${_ctx.$route.params.affiliateId}`)
              : _ctx.$router.push('/payouts')
          ))
          }),
          _createVNode(_component_q_card, { class: "content-with-padding q-pa-md width-max-content" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("b", null, "Выплата #" + _toDisplayString(_ctx.payout.id), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _cache[11] || (_cache[11] = _createElementVNode("b", { class: "width-180" }, "Создано аффилейтом:", -1)),
                _createVNode(_component_IconWithName, {
                  id: _ctx.payout.affiliateId,
                  name: _ctx.payout.affiliateEmail,
                  size: "20px",
                  color: "secondary",
                  icon: "account_box",
                  entity: "affiliate"
                }, null, 8, ["id", "name"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[12] || (_cache[12] = _createElementVNode("b", { class: "width-180" }, "Дата создания:", -1)),
                _createVNode(_component_q_input, {
                  class: "width-350",
                  dense: "",
                  outlined: "",
                  readonly: "",
                  "model-value": _ctx.createdAt
                }, null, 8, ["model-value"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[13] || (_cache[13] = _createElementVNode("b", { class: "width-180" }, "Платежная система:", -1)),
                _createVNode(_component_q_select, {
                  class: "width-350",
                  disable: "",
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.payout.paymentSystem,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payout.paymentSystem) = $event)),
                  options: _ctx.paymentSystems,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[14] || (_cache[14] = _createElementVNode("b", { class: "width-180" }, "Сеть платежной системы:", -1)),
                _createVNode(_component_q_select, {
                  class: "width-350",
                  dense: "",
                  disable: "",
                  outlined: "",
                  modelValue: _ctx.payout.paymentSystemNetwork,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payout.paymentSystemNetwork) = $event)),
                  options: 
                _ctx.payout.paymentSystem === _ctx.paymentSystemsEnum.USDC
                  ? _ctx.paymentSystemNetworks.slice(1)
                  : _ctx.paymentSystemNetworks
              ,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[15] || (_cache[15] = _createElementVNode("b", { class: "width-180" }, "Номер счета:", -1)),
                _createVNode(_component_q_select, {
                  class: "width-350",
                  dense: "",
                  disable: "",
                  outlined: "",
                  modelValue: _ctx.payout.accountId,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payout.accountId) = $event)),
                  options: _ctx.payout.accounts,
                  "option-value": "id",
                  "option-label": "account",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _cache[16] || (_cache[16] = _createElementVNode("b", { class: "width-180" }, "Сумма выплаты:", -1)),
                _createVNode(_component_q_input, {
                  class: "width-350",
                  dense: "",
                  outlined: "",
                  readonly: "",
                  "model-value": _ctx.payout.payoutAmount
                }, null, 8, ["model-value"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _cache[17] || (_cache[17] = _createElementVNode("b", { class: "width-180" }, "Валюта:", -1)),
                _createVNode(_component_q_select, {
                  class: "width-350",
                  outlined: "",
                  disable: "",
                  dense: "",
                  modelValue: _ctx.payout.payoutCurrency,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payout.payoutCurrency) = $event)),
                  options: _ctx.currencies,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _cache[18] || (_cache[18] = _createElementVNode("b", { class: "width-180" }, "Статус:", -1)),
                _createVNode(_component_q_select, {
                  class: "width-350",
                  outlined: "",
                  dense: "",
                  disable: "",
                  modelValue: _ctx.payout.status,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payout.status) = $event)),
                  options: _ctx.payoutStatuses,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _cache[19] || (_cache[19] = _createElementVNode("b", { class: "width-180" }, "Дата редактирования:", -1)),
                _createVNode(_component_q_input, {
                  class: "width-350",
                  dense: "",
                  outlined: "",
                  readonly: "",
                  "model-value": _ctx.updatedAt
                }, null, 8, ["model-value"])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _cache[20] || (_cache[20] = _createElementVNode("b", { class: "width-180" }, "Комментарий (хеш транзакции)", -1)),
                _createVNode(_component_q_input, {
                  class: "width-350",
                  dense: "",
                  readonly: "",
                  outlined: "",
                  modelValue: _ctx.payout.comment,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payout.comment) = $event))
                }, null, 8, ["modelValue"])
              ]),
              (_ctx.payout.status === _ctx.payoutStatusesEnum.Rejected)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _cache[21] || (_cache[21] = _createElementVNode("b", { class: "width-180" }, "Причина реджекта:", -1)),
                    _createVNode(_component_q_input, {
                      class: "width-350",
                      dense: "",
                      readonly: "",
                      outlined: "",
                      modelValue: _ctx.payout.rejectComment,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payout.rejectComment) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_16, [
                _cache[22] || (_cache[22] = _createElementVNode("b", { class: "width-180" }, "Комиссия, %:", -1)),
                _createVNode(_component_q_input, {
                  class: "width-350",
                  dense: "",
                  outlined: "",
                  readonly: "",
                  "model-value": _ctx.payout.feePercent
                }, null, 8, ["model-value"])
              ]),
              (
              _ctx.payout.status === _ctx.payoutStatusesEnum.ReadyForPayment ||
                _ctx.payout.status === _ctx.payoutStatusesEnum.Processing
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _cache[23] || (_cache[23] = _createElementVNode("b", { class: "width-180" }, "Итоговая сумма выплаты:", -1)),
                    _createVNode(_component_q_input, {
                      class: "width-350",
                      dense: "",
                      outlined: "",
                      readonly: "",
                      modelValue: _ctx.payout.paidValueAmount,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.payout.paidValueAmount) = $event)),
                      modelModifiers: { number: true }
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              (
              _ctx.payout.status === _ctx.payoutStatusesEnum.ReadyForPayment ||
                _ctx.payout.status === _ctx.payoutStatusesEnum.Processing
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _cache[24] || (_cache[24] = _createElementVNode("b", { class: "width-180" }, "Итоговая валюта перевода:", -1)),
                    _createVNode(_component_q_select, {
                      class: "width-350",
                      dense: "",
                      outlined: "",
                      readonly: "",
                      modelValue: _ctx.payout.paidValueCurrency,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payout.paidValueCurrency) = $event)),
                      options: _ctx.currencies,
                      "option-value": "id",
                      "option-label": "name",
                      "emit-value": "",
                      "map-options": ""
                    }, null, 8, ["modelValue", "options"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _cache[28] || (_cache[28] = _createElementVNode("h6", { class: "q-mb-md" }, "Смена статусов:", -1)),
          _createVNode(_component_q_card, { class: "width-max-content" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payout.statusChanges, (s, i) => {
                return (_openBlock(), _createBlock(_component_q_card_section, { key: i }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createTextVNode(_toDisplayString(_ctx.frmtDate(s.createdAt)) + " ", 1),
                      _cache[25] || (_cache[25] = _createElementVNode("b", null, "Action change status by:", -1)),
                      _createTextVNode(" " + _toDisplayString(s.emails[0]) + " [ID #" + _toDisplayString(s.adminId) + "] ", 1)
                    ]),
                    _createElementVNode("div", null, [
                      _cache[26] || (_cache[26] = _createTextVNode(" Статус: ")),
                      _createVNode(_component_q_badge, { color: "grey" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.payoutStatusesEnum[s.fromStatus]), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _cache[27] || (_cache[27] = _createTextVNode(" => ")),
                      _createVNode(_component_q_badge, { color: "positive" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.payoutStatusesEnum[s.toStatus]), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_q_btn, {
            "no-caps": "",
            icon: "chevron_left",
            flat: "",
            label: "Все выплаты",
            class: "q-mb-lg q-mt-xl",
            onClick: _cache[10] || (_cache[10] = ($event: any) => (
            _ctx.$route.params.affiliateId
              ? _ctx.$router.push(`/affiliates/${_ctx.$route.params.affiliateId}`)
              : _ctx.$router.push('/payouts')
          ))
          })
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_PayoutLeads, {
            payoutId: _ctx.payout.id
          }, null, 8, ["payoutId"])
        ])
      ])
    ]),
    _: 1
  }))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-mb-lg" }
const _hoisted_2 = { class: "inline-flex items-center gap-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Filters, {
          onExport: _ctx.onExport,
          onReset: _ctx.resetFilters,
          onChange: _ctx.onChangeFilters,
          onChangeAll: _ctx.changeAll,
          "current-filters": _ctx.filters
        }, null, 8, ["onExport", "onReset", "onChange", "onChangeAll", "current-filters"]),
        _createVNode(_component_q_table, {
          dense: "",
          "column-sort-order": "da",
          class: "q-mx-md custom-table",
          rows: _ctx.formattedRows,
          columns: _ctx.columns,
          "row-key": "status",
          separator: "cell",
          loading: _ctx.loading,
          onRequest: _ctx.onRequestChartData,
          "rows-per-page-options": [10, 25, 50, 100, 200, 500, 0]
        }, {
          "body-cell-advertiserName": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("a", _hoisted_2, [
                  _createVNode(_component_IconWithName, {
                    id: props.row.offerAdvertiserId,
                    size: "21px",
                    color: "primary",
                    icon: "people",
                    style: {"cursor":"inherit !important","pointer-events":"none"}
                  }, null, 8, ["id"]),
                  _createTextVNode(_toDisplayString(props.row.offerAdvertiserName), 1)
                ])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "bottom-row": _withCtx(() => [
            _createVNode(_component_q_tr, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_td, { class: "text-left border-top" }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("b", null, "Total:", -1)
                  ])),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnKeys, (col) => {
                  return (_openBlock(), _createBlock(_component_q_td, {
                    key: col,
                    class: "text-right border-top"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("b", null, _toDisplayString(_ctx.formatMoney(_ctx.total[col])), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["rows", "columns", "loading", "onRequest"])
      ])
    ]),
    _: 1
  }))
}
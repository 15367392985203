import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-mx-xl q-my-lg" }, {
    default: _withCtx(() => [
      _createVNode(_component_center, null, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("h6", null, "Тикеты ⚙️", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_q_tabs, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
        "inline-label": "",
        breakpoint: 0,
        align: "justify",
        class: "shadow-2 q-mb-md"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab, {
            "no-caps": "",
            name: _ctx.campaignStatusOptions[0].value,
            label: _ctx.campaignStatusOptions[0].label
          }, null, 8, ["name", "label"]),
          _createVNode(_component_q_tab, {
            "no-caps": "",
            name: _ctx.campaignStatusOptions[1].value,
            label: _ctx.campaignStatusOptions[1].label
          }, null, 8, ["name", "label"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("div", null, [
        _createVNode(_component_q_table, {
          dense: "",
          rows: _ctx.rows,
          columns: _ctx.columns,
          "row-key": "id",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination) = $event)),
          loading: _ctx.loading,
          onRequest: _ctx.onRequest,
          onRowClick: _ctx.onRowClick,
          "binary-state-sort": ""
        }, {
          "body-cell-affiliateEmail": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_IconWithName, {
                  id: props.row.affiliateId,
                  name: _ctx.user?.notFullAccess ? '' : props.row.affiliateEmail,
                  size: "20px",
                  color: "secondary",
                  icon: "account_box",
                  entity: "affiliate"
                }, null, 8, ["id", "name"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-status": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  (props.row.status === _ctx.ticketStatus.New)
                    ? (_openBlock(), _createBlock(_component_q_badge, {
                        key: 0,
                        color: "positive"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTicketStatusName(props.row.status)), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (props.row.status === _ctx.ticketStatus.WaitingForAffiliate)
                    ? (_openBlock(), _createBlock(_component_q_badge, {
                        key: 1,
                        color: "warning"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTicketStatusName(props.row.status)), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (props.row.status === _ctx.ticketStatus.WaitingForAdmin)
                    ? (_openBlock(), _createBlock(_component_q_badge, {
                        key: 2,
                        color: "orange"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTicketStatusName(props.row.status)), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (props.row.status === _ctx.ticketStatus.Resolved)
                    ? (_openBlock(), _createBlock(_component_q_badge, {
                        key: 3,
                        color: "grey"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTicketStatusName(props.row.status)), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-actions": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                (props.row.status !== _ctx.ticketStatus.Resolved)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      "no-caps": "",
                      icon: "done",
                      flat: "",
                      rounded: "",
                      onClick: _withModifiers(($event: any) => (_ctx.closeTicket(props.row.id)), ["stop"])
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "pagination", "loading", "onRequest", "onRowClick"])
      ])
    ]),
    _: 1
  }))
}
import { createApp } from 'vue'
import AdminApp from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import router from './router'
import store from './store'
import quasarOptions from './configs/quasar-options'
import './index.css'
import VueCookies from 'vue-cookies'
import Vue3Storage from 'vue3-storage'
import VueFeather from 'vue-feather'
import i18n from '@/locales/i18n'

createApp(AdminApp)
  .component(VueFeather.name as string, VueFeather)
  .use(VueCookies, { expire: '30d' } as any)
  .use(Quasar, quasarOptions as any)
  .use(router)
  .use(store)
  .use(i18n)
  .use(Vue3Storage, {})
  .mount('#app')


import { defineComponent } from 'vue'
import Filters from '@/components/filters/Filters.vue'
import { getDefaultFilters, getTableProperties } from '@/utils/request'
import { getLeadLimitColumns } from './getColumns'
import { request, Table, TableRequestProps } from '@/utils/request'
import { FiltersType } from '@/utils/request'
import IconWithName from '@/components/icons/IconWithName.vue'
import { mapState } from 'vuex'
import {
  confirmDialog,
  formatValue,
  getLeadLimitStatuses,
  showSuccessSnackBar,
} from '@/utils'
import { LeadLimitStatus } from '@/enums/LeadLimitStatus'
import { LocationQueryValue } from 'vue-router'
import AffiliateCommentDialog from '@/components/dialogs/AffiliateCommentDialog.vue'
import LeadLimitEditPopup from '@/components/popups/LeadLimitEditPopup.vue'
import LeadLimitChangelogPopup from '@/components/popups/LeadLimitChangelogPopup.vue'
import axios from 'axios'
import { FormatMoney } from 'format-money-js'
import { Currency } from '@/enums/Currency'
import { exportToCsv } from '@/utils/export'
export default defineComponent({
  name: 'LeadLimitsList',
  props: ['affiliateId'],
  components: {
    LeadLimitChangelogPopup,
    LeadLimitEditPopup,
    AffiliateCommentDialog,
    IconWithName,
    Filters,
  },
  computed: {
    ...mapState('authModule', ['user']),
  },
  mounted() {
    this.onRequest({ pagination: this.pagination })
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  data: function() {
    const queryFilters = this.$route.query
    for (const [filter, value] of Object.entries(queryFilters)) {
      queryFilters[filter] = (Number(value) as unknown) as LocationQueryValue
    }
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      currencies: Currency,
      moneyFormatter,
      ...getTableProperties('id', 50),
      filters: {
        ...(this.affiliateId ? { affiliateId: this.affiliateId } : {}),
        ...getDefaultFilters(),
        fantomAffiliateOnly: false,
        ...queryFilters,
      },
      columns: getLeadLimitColumns(),
      leadLimitStatuses: getLeadLimitStatuses(),
      showAddCommentDialog: false,
      selectedRow: {},
      leadLimitIsReseting: false,
    }
  },
  methods: {
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      const options = { pagination: this.pagination, ...props }
      await request(options, this as Table, '/api/lead-limits/')
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.pagination.page = 1
      this.filters = { ...getDefaultFilters(), fantomAffiliateOnly: false }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    openCommentDialog(row: any) {
      this.showAddCommentDialog = true
      this.selectedRow = row
    },
    closeCommentDialog() {
      this.showAddCommentDialog = false
      this.selectedRow = {}
    },
    getLeadLimitStatus(status: number) {
      return this.leadLimitStatuses.find((r: any) => r.id === status)?.name
    },
    async resetLeadLimit(id: number) {
      this.leadLimitIsReseting = true
      await axios.post(`/api/lead-limits/reset-by-id/${id}`)
      showSuccessSnackBar(
        `Капа #${id} сброшена в 0 и больше неактивна. Для пролива будет создана новая капа.`,
      )
      this.leadLimitIsReseting = false
      await this.onRequest({ pagination: this.pagination })
    },
    async onDelete(id: number) {
      confirmDialog('Вы действительно хотите удалить эту капу?', async () => {
        await axios.delete('/api/lead-limits?id=' + id)
        this.loading = true
        await this.onRequest({ pagination: this.pagination })
      })
    },
    formatMoney(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
        {
          decimals: 2,
          separator: ' ',
        },
      )
    },
    getLeadLimitBadgeColor(status: number) {
      switch (status) {
        case LeadLimitStatus.InProgress:
          return 'orange'
        case LeadLimitStatus.Finished:
          return 'positive'
        case LeadLimitStatus.NotDefined:
          return 'red'
        case LeadLimitStatus.OverLimited:
          return 'negative'
        default:
          return 'grey'
      }
    },
    onExport() {
      const styleScriptRegex = /<style([\s\S]*?)<\/style>|<script([\s\S]*?)<\/script>/gi
      const tagRegex = /<\/div>/gi
      const listItemRegex = /<\/li>/gi
      const liOpenRegex = /<li>/gi
      const ulCloseRegex = /<\/ul>/gi
      const pCloseRegex = /<\/p>/gi
      const htmlTagRegex = /<[^>]+>/gi
      const rows: {
        comments?: any[]
        [key: string]: any
      }[] = this.rows
      const processedRows = rows.map(row => {
        if (row.comments) {
          row.comments = row.comments.map(comment => {
            return comment.comment
              .replace(styleScriptRegex, '')
              .replace(tagRegex, '\n')
              .replace(listItemRegex, '\n')
              .replace(liOpenRegex, '  *  ')
              .replace(ulCloseRegex, '\n')
              .replace(pCloseRegex, '\n')
              .replace(htmlTagRegex, '')
          })
        }
        return row
      })
      exportToCsv(processedRows, this.columns)
    },
  },
})

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "flex col-sm-2 col-11 justify-end items-center mobile-display-block" }
const _hoisted_3 = { class: "flex row items-center" }
const _hoisted_4 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_RefillBalanceDialog = _resolveComponent("RefillBalanceDialog")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("h6", { class: "col-sm-8 col-11 q-ml-md" }, "Транзакции", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_q_btn, {
              "no-caps": "",
              icon: "add",
              rounded: "",
              label: "Пополнить баланс",
              color: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showRefillBalanceDialog = !_ctx.showRefillBalanceDialog))
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Filters, {
            onReset: _ctx.resetFilters,
            onChange: _ctx.onChangeFilters,
            onChangeAll: _ctx.changeAll,
            "current-filters": _ctx.filters
          }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters"])
        ]),
        (_ctx.showRefillBalanceDialog)
          ? (_openBlock(), _createBlock(_component_RefillBalanceDialog, {
              key: 0,
              open: _ctx.showRefillBalanceDialog,
              onClose: _ctx.onCloseDialog,
              onRefresh: _ctx.onRequest
            }, null, 8, ["open", "onClose", "onRefresh"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_q_table, {
          dense: "",
          rows: _ctx.rows,
          columns: _ctx.columns,
          "row-key": "id",
          separator: "cell",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination) = $event)),
          loading: _ctx.loading,
          onRequest: _ctx.onRequest,
          "binary-state-sort": ""
        }, null, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
      ])
    ]),
    _: 1
  }))
}
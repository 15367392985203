import { Balance, BalanceValueMoney } from '@/store/types'
import { BalanceType } from '@/enums/BalanceType'
import { Currency } from '@/enums/Currency'
import axios from 'axios'
import { formatValue } from '@/utils'

export function getAffiliateBalances(
  affiliateBalances: Balance[],
  isReferralsBalances = false,
) {
  const balances: Balance[] = affiliateBalances
    .filter(
      (b: Balance) =>
        !b.isRentAppsBalance &&
        (isReferralsBalances ? b.isReferralBalance : !b.isReferralBalance),
    )
    .sort((b1: Balance, b2: Balance) =>
      String(b1.value.currency).localeCompare(String(b2.value.currency)),
    )
  const available = balances.filter(
    (b: Balance) => b.type === BalanceType.Available,
  )
  const hold = balances.filter((b: Balance) => b.type === BalanceType.Hold)
  const formattedBalances = []
  for (let i = 0; i < available.length; i++) {
    if (
      isReferralsBalances &&
      !Number(available[i].value.amount) &&
      !Number(hold[i].value.amount)
    ) {
      continue
    }
    formattedBalances.push(
      `${formatValue(available[i].value.amount)} [${formatValue(
        hold[i].value.amount,
      )}] ${Currency[available[i].value.currency]}  ${
        available[i].offer?.name
          ? '(оффер - ' + available[i].offer.name + ')'
          : ''
      } ${
        (available[i] as any).affiliateId
          ? '(ref #' + (hold[i] as any).affiliateId + ')'
          : ''
      }`,
    )
  }
  return formattedBalances.join('\n')
}

export function getAffiliateBalancesApproved(
  affiliateBalances: BalanceValueMoney[],
  referralBalances: any[] = [],
) {
  const formattedAffiliateBalances = affiliateBalances
    .filter(b => b.type === BalanceType.Available)
    .map(b => `${formatValue(b.valueAmount)} ${Currency[b.valueCurrency]}`)

  const formattedReferralBalances = referralBalances
    .filter(b => b.type === BalanceType.Available)
    .map(
      b =>
        `${formatValue(b.valueAmount)} ${Currency[b.valueCurrency]} ${
          b.affiliateId ? '(ref #' + b.affiliateId + ')' : ''
        }`,
    )

  return [...formattedAffiliateBalances, ...formattedReferralBalances].join(
    '<br/>',
  )
}

export function getAffiliateBalancesHold(
  affiliateBalances: BalanceValueMoney[],
  referralBalances: any[] = [],
) {
  const formattedAffiliateBalances = affiliateBalances
    .filter(b => b.type === BalanceType.Hold)
    .map(b => `${formatValue(b.valueAmount)} ${Currency[b.valueCurrency]}`)

  const formattedReferralBalances = referralBalances
    .filter(b => b.type === BalanceType.Hold)
    .map(
      b =>
        `${formatValue(b.valueAmount)} ${Currency[b.valueCurrency]} ${
          b.affiliateId ? '(ref #' + b.affiliateId + ')' : ''
        }`,
    )

  return [...formattedAffiliateBalances, ...formattedReferralBalances].join(
    '<br/>',
  )
}

export async function loginAsAffiliate(id: string) {
  const {
    data: { key },
  } = await axios.post('/api/affiliates/login', { id })
  if (location.hostname === 'localhost') {
    const port = location.host === 'localhost:8080' ? '8081' : '8080'
    window.open(`http://localhost:${port}/login?key=${key}`)
  } else {
    const host = location.hostname.replace('admin.', 'affiliate.')
    window.open(`https://${host}/login?key=${key}`)
  }
}

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "flex row mobile-display-block" }
const _hoisted_3 = { class: "col-sm-12 col-md-2 q-mr-md" }
const _hoisted_4 = { class: "col-sm-12 col-md-3 q-mr-md" }
const _hoisted_5 = { class: "col-sm-12 col-md-3 q-mr-md" }
const _hoisted_6 = { class: "col-sm-12 col-md-2 q-mr-md" }
const _hoisted_7 = { class: "col-sm-12 col-md-1 q-mr-md" }
const _hoisted_8 = { class: "col-sm-12 col-md-1 q-mr-md" }
const _hoisted_9 = { class: "col-sm-12 col-md-1 q-mr-md" }
const _hoisted_10 = { class: "col-sm-12 col-md-1 q-mr-md d-flex flex-column align-items-center" }
const _hoisted_11 = {
  key: 0,
  class: "text-negative text-caption q-mt-xs"
}
const _hoisted_12 = { class: "col-sm-12 col-md-1" }
const _hoisted_13 = { class: "flex row mobile-display-block" }
const _hoisted_14 = { class: "col-sm-12 col-md-3 column" }
const _hoisted_15 = { class: "row items-center" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-5 q-mr-sm" }
const _hoisted_18 = { class: "col-5" }
const _hoisted_19 = { class: "col-sm-12 col-md-3 column" }
const _hoisted_20 = { class: "row items-center" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col-5 q-mr-sm" }
const _hoisted_23 = { class: "col-5" }
const _hoisted_24 = { class: "col-sm-12 col-md-3 column" }
const _hoisted_25 = { class: "row" }
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "col-5 q-mr-sm" }
const _hoisted_28 = { class: "col-5" }
const _hoisted_29 = { class: "col-sm-12 col-md-2 column q-mt-md" }
const _hoisted_30 = { class: "flex row mobile-display-block q-mt-md" }
const _hoisted_31 = { class: "col-sm-12 col-md-2 column q-mr-md" }
const _hoisted_32 = { class: "row" }
const _hoisted_33 = {
  class: "column q-mt-sm",
  style: {"max-width":"200px"}
}
const _hoisted_34 = { class: "col-sm-12 col-md-4 column q-mr-md" }
const _hoisted_35 = { class: "row items-center width-80" }
const _hoisted_36 = { class: "row items-center width-80" }
const _hoisted_37 = { class: "col-sm-12 col-md-4 q-mr-md" }
const _hoisted_38 = { class: "flex column" }
const _hoisted_39 = { class: "row items-center width-80" }
const _hoisted_40 = { class: "row items-center width-80" }
const _hoisted_41 = { class: "col-sm-12 col-md-1" }
const _hoisted_42 = { class: "flex row" }
const _hoisted_43 = ["src", "error", "error-message"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_color = _resolveComponent("q-color")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _component_q_editor = _resolveComponent("q-editor")!
  const _component_EmojiPicker = _resolveComponent("EmojiPicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[33] || (_cache[33] = _createElementVNode("b", null, "Название оффера", -1)),
        _createVNode(_component_q_input, {
          dense: "",
          outlined: "",
          placeholder: "pin-up",
          "model-value": _ctx.offer.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = name => _ctx.update({ name })),
          error: !!_ctx.errors?.name,
          "error-message": _ctx.errors?.name
        }, null, 8, ["model-value", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[34] || (_cache[34] = _createElementVNode("b", null, "Рекламодатель", -1)),
        _createVNode(_component_q_select, {
          dense: "",
          "model-value": _ctx.offer.advertiserId,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = advertiserId => _ctx.update({ advertiserId })),
          outlined: "",
          options: _ctx.advertisers,
          onFilter: _ctx.getAdvertisers,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          "use-input": "",
          error: !!_ctx.errors?.advertiserId,
          "error-message": _ctx.errors?.advertiserId
        }, null, 8, ["model-value", "options", "onFilter", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[35] || (_cache[35] = _createElementVNode("b", null, "Advertiser manager:", -1)),
        _createVNode(_component_q_select, {
          dense: "",
          "model-value": _ctx.offer.advertiserManagerId,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = 
            advertiserManagerId => _ctx.update({ advertiserManagerId })
          ),
          outlined: "",
          options: _ctx.advertiserManagers,
          onFilter: _ctx.getAdvertiserManagers,
          "option-value": "id",
          "option-label": "email",
          "emit-value": "",
          "map-options": "",
          "use-input": "",
          error: !!_ctx.errors?.advertiserManagerId,
          "error-message": _ctx.errors?.advertiserManagerId
        }, null, 8, ["model-value", "options", "onFilter", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[36] || (_cache[36] = _createElementVNode("b", null, "Категория", -1)),
        _createVNode(_component_q_select, {
          dense: "",
          outlined: "",
          multiple: "",
          "use-chips": "",
          ref: "categories",
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearFilter('categories'))),
          "model-value": _ctx.offer.categoryIds,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = categoryIds => _ctx.update({ categoryIds })),
          onFilter: _ctx.getOfferCategories,
          options: _ctx.categories,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          "use-input": "",
          disable: _ctx.offer.vertical == undefined,
          error: !!_ctx.errors?.categoryIds,
          "error-message": _ctx.errors?.categoryIds
        }, null, 8, ["model-value", "onFilter", "options", "disable", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[37] || (_cache[37] = _createElementVNode("b", null, "Статус", -1)),
        _createVNode(_component_q_select, {
          dense: "",
          outlined: "",
          class: "q-mb-md",
          "model-value": _ctx.offer.status,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = status => _ctx.update({ status })),
          options: _ctx.statuses,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          error: !!_ctx.errors?.status,
          "error-message": _ctx.errors?.status
        }, null, 8, ["model-value", "options", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[39] || (_cache[39] = _createElementVNode("b", null, [
          _createTextVNode("Постклик Reg"),
          _createElementVNode("sup", null, "?")
        ], -1)),
        _createVNode(_component_q_tooltip, {
          "max-width": "200px",
          anchor: "bottom middle",
          self: "top middle",
          class: "q-tooltip"
        }, {
          default: _withCtx(() => _cache[38] || (_cache[38] = [
            _createTextVNode(" Опционально. Количество дней с момента регистрации, в течении которых засчитывается депозит. ")
          ])),
          _: 1
        }),
        _createVNode(_component_q_input, {
          dense: "",
          outlined: "",
          type: "number",
          min: "0",
          "model-value": _ctx.offer.postClickReg,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = 
            (value) => _ctx.update({ postClickReg: Number(value) || null })
          ),
          error: !!_ctx.errors?.postClickReg,
          "error-message": _ctx.errors?.postClickReg
        }, null, 8, ["model-value", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[41] || (_cache[41] = _createElementVNode("b", null, [
          _createTextVNode("Постклик Click"),
          _createElementVNode("sup", null, "?")
        ], -1)),
        _createVNode(_component_q_tooltip, {
          "max-width": "200px",
          anchor: "bottom middle",
          self: "top middle",
          class: "q-tooltip"
        }, {
          default: _withCtx(() => _cache[40] || (_cache[40] = [
            _createTextVNode(" Опционально. Количество дней с момента первого события, в течении которых засчитывается депозит. ")
          ])),
          _: 1
        }),
        _createVNode(_component_q_input, {
          dense: "",
          outlined: "",
          type: "number",
          min: "0",
          "model-value": _ctx.offer.postClickEvent,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = 
            (value) => _ctx.update({ postClickEvent: Number(value) || null })
          ),
          error: !!_ctx.errors?.postClickEvent,
          "error-message": _ctx.errors?.postClickEvent
        }, null, 8, ["model-value", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[43] || (_cache[43] = _createElementVNode("b", null, [
          _createTextVNode("Постклик в пределах календарного месяца"),
          _createElementVNode("sup", null, "?")
        ], -1)),
        _createVNode(_component_q_tooltip, {
          "max-width": "200px",
          anchor: "bottom middle",
          self: "top middle",
          class: "q-tooltip"
        }, {
          default: _withCtx(() => _cache[42] || (_cache[42] = [
            _createTextVNode(" Опционально. Постклик в пределах календарного месяца, в течение которого засчитывается депозит."),
            _createElementVNode("br", null, null, -1),
            _createElementVNode("br", null, null, -1),
            _createTextVNode(" Правда — клик 01.06, фтд 07.06 — не постклик"),
            _createElementVNode("br", null, null, -1),
            _createTextVNode(" Ложь — клик 02.06, фтд 15.06 — постклик"),
            _createElementVNode("br", null, null, -1),
            _createTextVNode(" Ложь — клик 30.05, фтд 02.06 — постклик ")
          ])),
          _: 1
        }),
        (_ctx.errors?.postClickMonth)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.errors.postClickMonth), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_q_checkbox, {
          "model-value": _ctx.offer.postClickMonth ?? false,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.offer.postClickMonth = $event))
        }, null, 8, ["model-value"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_q_checkbox, {
          class: "q-mt-md",
          "left-label": "",
          modelValue: _ctx.offer.isSuperOffer,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.offer.isSuperOffer) = $event)),
          label: "Супер оффер"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _cache[61] || (_cache[61] = _createElementVNode("hr", { class: "q-mb-md" }, null, -1)),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _cache[45] || (_cache[45] = _createElementVNode("b", null, [
            _createTextVNode("Фикс. Капа "),
            _createElementVNode("sup", null, "?")
          ], -1)),
          _createVNode(_component_q_tooltip, {
            "max-width": "200px",
            anchor: "bottom middle",
            self: "top middle",
            class: "q-tooltip"
          }, {
            default: _withCtx(() => _cache[44] || (_cache[44] = [
              _createTextVNode(" Опционально. Максимальное количество первых депозитов для данного оффера. При превышении капы депозиты перестают засчитываться вебмастерам. Капа может быть изменена на протяжении периода действия. Доступно для вертикали Гемблинг. ")
            ])),
            _: 1
          }),
          _createVNode(_component_q_checkbox, {
            "left-label": "",
            modelValue: _ctx.leadLimitEnabled,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.leadLimitEnabled) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_q_input, {
              label: "Значение",
              disable: !_ctx.leadLimitEnabled,
              outlined: "",
              dense: "",
              hint: "Максимальное количество первых депозитов за срок действия по офферу",
              type: "number",
              min: "0",
              "model-value": _ctx.offer.defaultLeadLimitPerMonth,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = 
                value =>
                  _ctx.update({
                    defaultLeadLimitPerMonth: Number(value)
                      ? Number(value)
                      : String(value) === '0'
                      ? Number(value)
                      : null,
                  })
              ),
              error: !!_ctx.errors?.defaultLeadLimitPerMonth,
              "error-message": _ctx.errors?.defaultLeadLimitPerMonth
            }, null, 8, ["disable", "model-value", "error", "error-message"])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_q_select, {
              dense: "",
              label: "Период",
              outlined: "",
              disable: !_ctx.leadLimitEnabled,
              "model-value": _ctx.offer.defaultLeadLimitPeriod,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = 
                defaultLeadLimitPeriod => _ctx.update({ defaultLeadLimitPeriod })
              ),
              options: _ctx.leadLimitPeriods,
              "option-value": "id",
              "option-label": "name",
              "emit-value": "",
              "map-options": "",
              error: !!_ctx.errors?.defaultLeadLimitPeriod,
              "error-message": _ctx.errors?.defaultLeadLimitPeriod
            }, null, 8, ["disable", "model-value", "options", "error", "error-message"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _cache[47] || (_cache[47] = _createElementVNode("b", null, [
            _createTextVNode("Информационная Капа "),
            _createElementVNode("sup", null, "?")
          ], -1)),
          _createVNode(_component_q_tooltip, {
            "max-width": "200px",
            anchor: "bottom middle",
            self: "top middle",
            class: "q-tooltip"
          }, {
            default: _withCtx(() => _cache[46] || (_cache[46] = [
              _createTextVNode(" Опционально. Фиксирование договоренностей по капе с вебмастером. ")
            ])),
            _: 1
          }),
          _createVNode(_component_q_checkbox, {
            "left-label": "",
            modelValue: _ctx.informLeadLimitEnabled,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.informLeadLimitEnabled) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_q_input, {
              label: "Значение",
              disable: !_ctx.informLeadLimitEnabled,
              outlined: "",
              dense: "",
              hint: "Число. Фиксирование договоренностей по капе с вебмастером",
              type: "number",
              min: "0",
              "model-value": _ctx.offer.defaultInformLeadLimit,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = 
                value =>
                  _ctx.update({
                    defaultInformLeadLimit: Number(value)
                      ? Number(value)
                      : String(value) === '0'
                      ? Number(value)
                      : null,
                  })
              ),
              error: !!_ctx.errors?.defaultInformLeadLimit,
              "error-message": _ctx.errors?.defaultInformLeadLimit
            }, null, 8, ["disable", "model-value", "error", "error-message"])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_q_select, {
              label: "Период",
              dense: "",
              outlined: "",
              disable: !_ctx.informLeadLimitEnabled,
              "model-value": _ctx.offer.defaultInformLeadLimitPeriod,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = 
                defaultInformLeadLimitPeriod =>
                  _ctx.update({ defaultInformLeadLimitPeriod })
              ),
              options: _ctx.leadLimitPeriods,
              "option-value": "id",
              "option-label": "name",
              "emit-value": "",
              "map-options": "",
              error: !!_ctx.errors?.defaultInformLeadLimitPeriod,
              "error-message": _ctx.errors?.defaultInformLeadLimitPeriod
            }, null, 8, ["disable", "model-value", "options", "error", "error-message"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", _hoisted_25, [
          _cache[49] || (_cache[49] = _createElementVNode("b", null, [
            _createTextVNode("Ревшара "),
            _createElementVNode("sup", null, "?")
          ], -1)),
          _createVNode(_component_q_tooltip, null, {
            default: _withCtx(() => _cache[48] || (_cache[48] = [
              _createTextVNode(" Только для ревшарных офферов ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_q_select, {
              label: "Холд",
              dense: "",
              outlined: "",
              "model-value": _ctx.offer.revShareHold,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = 
                val => _ctx.update({ revShareHold: Number(val) || null })
              ),
              hint: "Количество дней, по истечению которого лиды переходят с Холда в Апрув по модели выплат RevShare",
              options: _ctx.revShareHoldOptions,
              "option-value": "id",
              "option-label": "name",
              "emit-value": "",
              "map-options": "",
              error: !!_ctx.errors?.revShareHold,
              "error-message": _ctx.errors?.revShareHold
            }, null, 8, ["model-value", "options", "error", "error-message"])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createVNode(_component_q_input, {
              label: "Доход, %",
              dense: "",
              outlined: "",
              hint: "% дохода, который мы получаем от рекламодателя по модели выплат RevShare",
              type: "number",
              min: "0",
              "model-value": _ctx.offer.advertiserRevSharePercent,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = 
                value =>
                  _ctx.update({ advertiserRevSharePercent: Number(value) || null })
              ),
              error: !!_ctx.errors?.advertiserRevSharePercent,
              "error-message": _ctx.errors?.advertiserRevSharePercent
            }, null, 8, ["model-value", "error", "error-message"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createVNode(_component_q_checkbox, {
          "left-label": "",
          modelValue: _ctx.offer.disablePostback,
          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.offer.disablePostback) = $event)),
          label: "Отключить прием постбека"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_q_tooltip, null, {
          default: _withCtx(() => _cache[50] || (_cache[50] = [
            _createTextVNode(" Если галочка включена, новые лиды по данному офферу сохраняются у нас в системе со статусом (Отключен постбек). Вебмастерам эти лиды не показываются и выплата за них не начисляется пока они не будут апрувнуты. ")
          ])),
          _: 1
        })
      ])
    ]),
    _cache[62] || (_cache[62] = _createElementVNode("hr", { class: "q-mt-xl q-mb-lg" }, null, -1)),
    _createElementVNode("div", _hoisted_30, [
      _createElementVNode("div", _hoisted_31, [
        _createElementVNode("div", _hoisted_32, [
          _cache[52] || (_cache[52] = _createElementVNode("b", null, "Цвет рамки оффера (опционально)", -1)),
          _createVNode(_component_q_badge, {
            color: "grey-3",
            "text-color": "black",
            class: "q-mb-sm q-ml-sm"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.offer.borderColor || 'Не выбрано'), 1)
            ]),
            _: 1
          }),
          (_ctx.offer.borderColor)
            ? (_openBlock(), _createBlock(_component_q_badge, {
                key: 0,
                onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.update({ borderColor: null }))),
                color: "grey-3",
                "text-color": "red",
                class: "q-mb-sm q-ml-sm cursor-pointer"
              }, {
                default: _withCtx(() => _cache[51] || (_cache[51] = [
                  _createTextVNode(" Удалить ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_q_color, {
            modelValue: _ctx.offer.borderColor,
            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.offer.borderColor) = $event)),
            "no-header": "",
            "no-footer": "",
            "default-view": "palette",
            style: {"width":"100px"}
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _cache[53] || (_cache[53] = _createElementVNode("b", null, "Текст на рамке оффера (опционально)", -1)),
          _createVNode(_component_q_input, {
            dense: "",
            outlined: "",
            placeholder: "+5%",
            "model-value": _ctx.offer.borderLabel,
            "onUpdate:modelValue": _cache[21] || (_cache[21] = borderLabel => _ctx.update({ borderLabel })),
            error: !!_ctx.errors?.borderLabel,
            "error-message": _ctx.errors?.borderLabel
          }, null, 8, ["model-value", "error", "error-message"])
        ])
      ]),
      _createElementVNode("div", _hoisted_34, [
        _cache[56] || (_cache[56] = _createElementVNode("b", null, "Описание оффера", -1)),
        _createVNode(_component_q_editor, {
          ref: "editor",
          "toolbar-bg": "dark",
          "toolbar-text-color": "white",
          class: _normalizeClass([
            'q-mb-md',
            'editor',
            { 'editor-error': !!_ctx.errors?.description },
          ]),
          "model-value": _ctx.offer.description,
          "onUpdate:modelValue": _cache[26] || (_cache[26] = description => _ctx.update({ description })),
          toolbar: [
            ['bold', 'italic'],
            ['underline', 'strike'],
            ['unordered', 'ordered'],
            ['subscript', 'superscript'],
            [
              {
                label: _ctx.$q.lang.editor.formatting,
                icon: _ctx.$q.iconSet.editor.formatting,
                list: 'no-icons',
                options: ['p', 'h3', 'h4', 'h5', 'h6'],
              },
              {
                label: _ctx.$q.lang.editor.fontSize,
                icon: _ctx.$q.iconSet.editor.fontSize,
                fixedLabel: true,
                fixedIcon: true,
                list: 'no-icons',
                options: [
                  'size-1',
                  'size-2',
                  'size-3',
                  'size-4',
                  'size-5',
                  'size-6',
                  'size-7',
                ],
              },
            ],
            [
              {
                label: _ctx.$q.lang.editor.align,
                icon: _ctx.$q.iconSet.editor.align,
                fixedLabel: true,
                options: ['left', 'center', 'right', 'justify'],
              },
              'token',
            ],
            ['undo', 'redo'],
            ['fullscreen', 'removeFormat'],
          ]
        }, {
          token: _withCtx(() => [
            _createVNode(_component_q_btn_dropdown, {
              "menu-anchor": "bottom start",
              "menu-self": "bottom start",
              dense: "",
              "no-caps": "",
              ref: "token",
              "no-wrap": "",
              unelevated: "",
              color: "white",
              "text-color": "primary",
              label: "Цвет",
              size: "sm"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_list, { dense: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item, {
                      tag: "label",
                      clickable: "",
                      onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.color('backColor', _ctx.highlight)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { side: "" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_35, [
                              _createVNode(_component_q_icon, {
                                name: "highlight",
                                class: "q-mr-sm"
                              }),
                              _cache[54] || (_cache[54] = _createTextVNode(" Заливка "))
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_color, {
                              modelValue: _ctx.highlight,
                              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.highlight) = $event)),
                              "default-view": "palette",
                              "no-header": "",
                              "no-footer": "",
                              palette: [
                        '#ffccccaa',
                        '#ffe6ccaa',
                        '#ffffccaa',
                        '#ccffccaa',
                        '#ccffe6aa',
                        '#ccffffaa',
                        '#cce6ffaa',
                        '#ccccffaa',
                        '#e6ccffaa',
                        '#ffccffaa',
                        '#ff0000',
                        '#ff8000',
                        '#ffff00',
                        '#00ff00',
                        '#00ff80',
                        '#00ffff',
                        '#0080ff',
                        '#0000ff',
                        '#8000ff',
                        '#ff00ff',
                        '#000',
                        '#191919',
                        '#333333',
                        '#4c4c4c',
                        '#666666',
                        '#7f7f7f',
                        '#999999',
                        '#b2b2b2',
                        '#cdcdcd',
                        '#ffffff',
                      ],
                              class: "my-picker"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_item, {
                      tag: "label",
                      clickable: "",
                      onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.color('foreColor', _ctx.foreColor)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { side: "" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_36, [
                              _createVNode(_component_q_icon, {
                                name: "format_paint",
                                class: "q-mr-sm"
                              }),
                              _cache[55] || (_cache[55] = _createTextVNode(" Текст "))
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_color, {
                              modelValue: _ctx.foreColor,
                              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.foreColor) = $event)),
                              "no-header": "",
                              "no-footer": "",
                              "default-view": "palette",
                              palette: [
                        '#ffccccaa',
                        '#ffe6ccaa',
                        '#ffffccaa',
                        '#ccffccaa',
                        '#ccffe6aa',
                        '#ccffffaa',
                        '#cce6ffaa',
                        '#ccccffaa',
                        '#e6ccffaa',
                        '#ffccffaa',
                        '#ff0000',
                        '#ff8000',
                        '#ffff00',
                        '#00ff00',
                        '#00ff80',
                        '#00ffff',
                        '#0080ff',
                        '#0000ff',
                        '#8000ff',
                        '#ff00ff',
                        '#000',
                        '#191919',
                        '#333333',
                        '#4c4c4c',
                        '#666666',
                        '#7f7f7f',
                        '#999999',
                        '#b2b2b2',
                        '#cdcdcd',
                        '#ffffff',
                      ],
                              class: "my-picker"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 512)
          ]),
          _: 1
        }, 8, ["class", "model-value", "toolbar"]),
        _withDirectives(_createElementVNode("p", { class: "error-message" }, _toDisplayString(_ctx.errors?.description), 513), [
          [_vShow, !!_ctx.errors?.description]
        ])
      ]),
      _createElementVNode("div", _hoisted_37, [
        _cache[59] || (_cache[59] = _createElementVNode("b", { class: "q-mt-xl" }, "Коммент", -1)),
        _createElementVNode("div", _hoisted_38, [
          _createVNode(_component_q_editor, {
            ref: "editorComments",
            "toolbar-bg": "dark",
            "toolbar-text-color": "white",
            class: _normalizeClass([
              'q-mb-md',
              'editor',
              { 'editor-error': !!_ctx.errors?.comment },
            ]),
            "model-value": _ctx.offer.comment,
            "onUpdate:modelValue": _cache[31] || (_cache[31] = comment => _ctx.update({ comment })),
            toolbar: [
              ['bold', 'italic'],
              ['underline', 'strike'],
              ['unordered', 'ordered'],
              ['subscript', 'superscript'],
              [
                {
                  label: _ctx.$q.lang.editor.formatting,
                  icon: _ctx.$q.iconSet.editor.formatting,
                  list: 'no-icons',
                  options: ['p', 'h3', 'h4', 'h5', 'h6'],
                },
                {
                  label: _ctx.$q.lang.editor.fontSize,
                  icon: _ctx.$q.iconSet.editor.fontSize,
                  fixedLabel: true,
                  fixedIcon: true,
                  list: 'no-icons',
                  options: [
                    'size-1',
                    'size-2',
                    'size-3',
                    'size-4',
                    'size-5',
                    'size-6',
                    'size-7',
                  ],
                },
              ],
              [
                {
                  label: _ctx.$q.lang.editor.align,
                  icon: _ctx.$q.iconSet.editor.align,
                  fixedLabel: true,
                  options: ['left', 'center', 'right', 'justify'],
                },
                'token',
              ],
              ['undo', 'redo'],
              ['fullscreen', 'removeFormat'],
            ]
          }, {
            token: _withCtx(() => [
              _createVNode(_component_q_btn_dropdown, {
                "menu-anchor": "bottom start",
                "menu-self": "bottom start",
                dense: "",
                "no-caps": "",
                ref: "token",
                "no-wrap": "",
                unelevated: "",
                color: "white",
                "text-color": "primary",
                label: "Цвет",
                size: "sm"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_list, { dense: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item, {
                        tag: "label",
                        clickable: "",
                        onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.colorComments('backColor', _ctx.highlight)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_section, { side: "" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_39, [
                                _createVNode(_component_q_icon, {
                                  name: "highlight",
                                  class: "q-mr-sm"
                                }),
                                _cache[57] || (_cache[57] = _createTextVNode(" Заливка "))
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_q_item_section, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_color, {
                                modelValue: _ctx.highlight,
                                "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.highlight) = $event)),
                                "default-view": "palette",
                                "no-header": "",
                                "no-footer": "",
                                palette: [
                          '#ffccccaa',
                          '#ffe6ccaa',
                          '#ffffccaa',
                          '#ccffccaa',
                          '#ccffe6aa',
                          '#ccffffaa',
                          '#cce6ffaa',
                          '#ccccffaa',
                          '#e6ccffaa',
                          '#ffccffaa',
                          '#ff0000',
                          '#ff8000',
                          '#ffff00',
                          '#00ff00',
                          '#00ff80',
                          '#00ffff',
                          '#0080ff',
                          '#0000ff',
                          '#8000ff',
                          '#ff00ff',
                          '#000',
                          '#191919',
                          '#333333',
                          '#4c4c4c',
                          '#666666',
                          '#7f7f7f',
                          '#999999',
                          '#b2b2b2',
                          '#cdcdcd',
                          '#ffffff',
                        ],
                                class: "my-picker"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_item, {
                        tag: "label",
                        clickable: "",
                        onClick: _cache[30] || (_cache[30] = ($event: any) => (_ctx.colorComments('foreColor', _ctx.foreColor)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_section, { side: "" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_40, [
                                _createVNode(_component_q_icon, {
                                  name: "format_paint",
                                  class: "q-mr-sm"
                                }),
                                _cache[58] || (_cache[58] = _createTextVNode(" Текст "))
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_q_item_section, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_color, {
                                modelValue: _ctx.foreColor,
                                "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.foreColor) = $event)),
                                "no-header": "",
                                "no-footer": "",
                                "default-view": "palette",
                                palette: [
                          '#ffccccaa',
                          '#ffe6ccaa',
                          '#ffffccaa',
                          '#ccffccaa',
                          '#ccffe6aa',
                          '#ccffffaa',
                          '#cce6ffaa',
                          '#ccccffaa',
                          '#e6ccffaa',
                          '#ffccffaa',
                          '#ff0000',
                          '#ff8000',
                          '#ffff00',
                          '#00ff00',
                          '#00ff80',
                          '#00ffff',
                          '#0080ff',
                          '#0000ff',
                          '#8000ff',
                          '#ff00ff',
                          '#000',
                          '#191919',
                          '#333333',
                          '#4c4c4c',
                          '#666666',
                          '#7f7f7f',
                          '#999999',
                          '#b2b2b2',
                          '#cdcdcd',
                          '#ffffff',
                        ],
                                class: "my-picker"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512)
            ]),
            _: 1
          }, 8, ["class", "model-value", "toolbar"]),
          _createVNode(_component_EmojiPicker, {
            native: true,
            onSelect: _ctx.onSelectEmoji,
            theme: "dark"
          }, null, 8, ["onSelect"])
        ])
      ]),
      _createElementVNode("div", _hoisted_41, [
        _cache[60] || (_cache[60] = _createElementVNode("b", null, "Изображение оффера", -1)),
        _createElementVNode("div", _hoisted_42, [
          _createElementVNode("img", {
            class: "photo-image q-mb-lg",
            src: `${_ctx.storageUrl + '/' + _ctx.offer.image}`,
            error: !!_ctx.errors?.image,
            "error-message": _ctx.errors?.image
          }, null, 8, _hoisted_43),
          _createElementVNode("input", {
            type: "file",
            onInput: _cache[32] || (_cache[32] = ($event: any) => (_ctx.update({ image: $event.target.files[0] })))
          }, null, 32)
        ]),
        _withDirectives(_createElementVNode("p", { class: "error-message" }, _toDisplayString(_ctx.errors?.image), 513), [
          [_vShow, !!_ctx.errors?.image]
        ])
      ])
    ])
  ]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex row items-center" }
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    class: "wide-dialog",
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDialog) = $event)),
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"max-width":"950px !important"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { class: "row justify-between items-center q-pa-32" }, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "page-title" }, [
                _createElementVNode("h6", null, [
                  _createElementVNode("b", null, "Лиды данной выплаты")
                ])
              ], -1)),
              _createVNode(_component_q_icon, {
                name: "close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
                size: "20px",
                class: "cursor-pointer"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "row justify-between items-center" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_q_table, {
                  "rows-per-page-options": [10, 25, 50, 100, 200, 500],
                  rows: _ctx.rows,
                  columns: _ctx.columns,
                  "row-key": "id",
                  dense: "",
                  separator: "cell",
                  pagination: _ctx.pagination,
                  "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination) = $event)),
                  loading: _ctx.loading,
                  onRequest: _ctx.onRequest,
                  "binary-state-sort": "",
                  "column-sort-order": "da"
                }, {
                  top: _withCtx(() => [
                    _createVNode(_component_q_btn, {
                      "no-caps": "",
                      "icon-right": _ctx.heroOutline24ArrowDownTray,
                      class: "q-btn__main-size text-weight-bold",
                      label: "Export",
                      color: "primary",
                      outline: "",
                      onClick: _ctx.exportCsv
                    }, null, 8, ["icon-right", "onClick"])
                  ]),
                  loading: _withCtx(() => [
                    _createVNode(_component_q_inner_loading, {
                      showing: "",
                      color: "primary"
                    })
                  ]),
                  "body-cell-offerName": _withCtx((props) => [
                    _createVNode(_component_q_td, { props: props }, {
                      default: _withCtx(() => [
                        _createVNode(_component_IconWithName, {
                          id: props.row.offerId,
                          name: props.row.offerName,
                          size: "20px",
                          color: "orange-7",
                          icon: "description",
                          entity: "offer"
                        }, null, 8, ["id", "name"])
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  "body-cell-advertiserName": _withCtx((props) => [
                    _createVNode(_component_q_td, { props: props }, {
                      default: _withCtx(() => [
                        _createVNode(_component_IconWithName, {
                          id: props.row.advertiserId,
                          name: props.row.advertiserName,
                          size: "20px",
                          color: "primary",
                          icon: "people",
                          entity: "advertiser"
                        }, null, 8, ["id", "name"])
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  "body-cell-isoCode": _withCtx((props) => [
                    _createVNode(_component_q_td, { props: props }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createElementVNode("img", {
                            class: "q-mr-sm",
                            height: "10",
                            width: "15",
                            alt: props.row.isoCode,
                            src: 
                      `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.isoCode}.svg`
                    
                          }, null, 8, _hoisted_2),
                          _createTextVNode(" " + _toDisplayString(props.row.isoCode), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  "body-cell-amount": _withCtx((props) => [
                    _createVNode(_component_q_td, { props: props }, {
                      default: _withCtx(() => [
                        (
                    props.row.amount !== null && props.row.amount !== undefined
                  )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.amount)) + " ", 1),
                              _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromRow(props.row.currency)), 1)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString('—')))
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  "body-cell-advertiserAmount": _withCtx((props) => [
                    _createVNode(_component_q_td, { props: props }, {
                      default: _withCtx(() => [
                        (
                    props.row.advertiserAmount !== null &&
                      props.row.advertiserAmount !== undefined
                  )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.advertiserAmount)) + " ", 1),
                              _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromRow(props.row.advertiserCurrency)), 1)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString('—')))
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  _: 1
                }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
              ]),
              _createVNode(_component_q_card_actions, {
                align: "center",
                class: "text-primary q-px-32"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    "no-caps": "",
                    unelevated: "",
                    loading: _ctx.loading,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
                    class: "full-width q-btn__main-size",
                    label: "OK",
                    color: "primary"
                  }, null, 8, ["loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
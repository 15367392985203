import { getOptions } from '@/utils'
import { Advertiser, Affiliate } from '@/store/types'
import axios from 'axios'

export type FieldOption =
  | 'advertisers'
  | 'offers'
  | 'promos'
  | 'markers'
  | 'apps'
  | 'languages'

export async function lookupByField(
  ref: any,
  value: string,
  update: Function,
  field: FieldOption,
) {
  const options = await getOptions(ref[field], value, '/api/' + field)
  if (field === 'apps') {
    update(() => {
      ref[field] = options.map((a: any) => ({
        id: a.id,
        name: `#${a.id} 🎰 ${a.name}`,
      }))
    })
    return
  }
  update(() => {
    ref[field] = options
  })
}

export async function lookupMobileApps(
  ref: any,
  value: string,
  update: Function,
  ids: number[] = [],
) {
  const apps = await getOptions(ref.apps as [], value, '/api/apps/', ids)
  update(() => {
    ref.apps = apps.map((a: any) => ({
      id: a.id,
      name: `#${a.id} 🎰 ${a.name}`,
    }))
  })
}

export async function lookupOfferMarkers(
  ref: any,
  value: string,
  update: Function,
) {
  const options: any = await getOptions(
    ref.offerMarkers,
    value,
    '/api/' + 'offer-markers',
  )
  options.unshift({
    id: 0,
    name: 'None',
  })
  update(() => {
    ref.offerMarkers = options
  })
}

export async function lookupMarkers(ref: any, value: string, update: Function) {
  const options: any = await getOptions(ref.markers, value, '/api/' + 'markers')
  options.unshift({
    id: 0,
    name: 'None',
  })
  update(() => {
    ref.markers = options
  })
}

export async function lookupAffiliates(
  ref: any,
  value: string,
  update: Function,
  extraParams = {},
) {
  const affiliates = await getOptions(
    ref.affiliates,
    value,
    '/api/affiliates/list',
    [],
    extraParams,
  )
  const affiliatesOptions = affiliates.map((affiliate: Affiliate) => ({
    ...affiliate,
    email:
      JSON.parse(localStorage.getItem('user') as any)?.showStatisticsOnly ||
      JSON.parse(localStorage.getItem('user') as any)?.notFullAccess
        ? ''
        : `#${affiliate.id} 👤 ` + affiliate.credentials.email,
  }))
  update(() => {
    ref.affiliates = affiliatesOptions as never[]
  })
}

export async function lookupManagers(
  ref: any,
  value: string,
  update: Function,
  withNone = false,
) {
  const admins = await getOptions(ref.admins, value, '/api/admins/managers')
  const adminsOptions = admins.map((admin: any) => ({
    ...admin,
    email: JSON.parse(localStorage.getItem('user') as any)?.showStatisticsOnly
      ? ''
      : admin.credentials.email,
  }))
  if (withNone) {
    adminsOptions.unshift({
      id: 0,
      email: 'None',
    })
  }
  update(() => {
    ref.admins = adminsOptions as never[]
  })
}

export async function lookupAdvertiserManagers(
  ref: any,
  value: string,
  update: Function,
  withNone = false,
) {
  const admins = await getOptions(
    ref.advertiserManagers,
    value,
    '/api/admins/advertiser-managers',
  )
  const adminsOptions = admins.map((admin: any) => ({
    ...admin,
    email: JSON.parse(localStorage.getItem('user') as any)?.showStatisticsOnly
      ? ''
      : admin.credentials.email,
  }))
  if (withNone) {
    adminsOptions.unshift({
      id: 0,
      email: 'None',
    })
  }
  update(() => {
    ref.advertiserManagers = adminsOptions as never[]
  })
}

export async function lookupOffers(
  ref: any,
  value: string,
  update: Function,
  ids: number[] = [],
  extraParams: any = {},
  isList = false,
) {
  const offers = await getOptions(
    ref.offers,
    value,
    '/api/offers/' + (ids.length || isList ? 'list' : ''),
    ids,
    extraParams,
  )
  const formattedOffers = offers.map((offer: any) => ({
    ...offer,
    name: `#${offer.id} ⭐️ ` + offer.name,
  }))
  update(() => {
    ref.offers = formattedOffers
  })
}

export async function lookupGeos(
  ref: any,
  value: string,
  update: Function,
  ids: number[] = [],
  notIncludeIds: number[] = [],
  customExtraParams: any = {},
) {
  const extraParams: any =
    ref.$route.path.match(/offers\/./g) ||
    ref.$route.path.match(/mobile-apps\/./g)
      ? { ...customExtraParams }
      : { byRewards: true, ...customExtraParams }
  if (ref.$route.path === '/offers') {
    extraParams.isOffersPage = true
  }
  const geos = await getOptions(
    ref.geos as [],
    value,
    '/api/geos',
    ids,
    extraParams,
    notIncludeIds,
  )
  update(() => {
    ref.geos = geos
  })
}

export async function lookupRedirectDomains(
  ref: any,
  value: string,
  update: Function,
  ids: number[] = [],
  extraParams: any = {},
) {
  const redirectDomains = await getOptions(
    ref.redirectDomains as [],
    value,
    '/api/redirect-domains',
    ids,
    extraParams,
  )
  update(() => {
    ref.redirectDomains = redirectDomains
  })
}

export async function lookupLanguages(
  ref: any,
  value: string,
  update: Function,
  ids: number[] = [],
) {
  const languages = await getOptions(
    ref.languages as [],
    value,
    '/api/languages',
    ids,
  )
  update(() => {
    ref.languages = languages
  })
}

export async function lookupCampaigns(
  ref: any,
  value: string,
  update: Function,
) {
  const campaigns = await getOptions(
    ref.campaigns as [],
    value,
    '/api/campaigns',
  )
  update(() => {
    ref.campaigns = campaigns.map((c: any) => ({
      id: c.id,
      name: `#${c.id} 🔀 ${c.name}`,
    }))
  })
}

export async function lookupPresets(ref: any, value: string, update: Function) {
  const apiPath = ref.$route.path.includes('offers')
    ? '/api/presets/offer'
    : '/api/presets'
  const presets = await getOptions(ref.presets as [], value, apiPath)
  update(() => {
    ref.presets = presets
  })
}

export async function lookupAdvertisers(
  ref: any,
  value: string,
  update: Function,
) {
  const advertisers = await getOptions(
    ref.advertisers as [],
    value,
    '/api/advertisers',
    ref.offer?.advertiserId ? [ref.offer.advertiserId as number] : [],
  )
  update(() => {
    ref.advertisers = advertisers.map((a: Advertiser) => ({
      id: a.id,
      name: a.name,
    }))
  })
}

export async function lookupOfferCategories(
  ref: any,
  value: string,
  update: Function,
  ids: number[] = [],
  extraParams: any = {},
) {
  const categories = await getOptions(
    ref.categories as [],
    value,
    '/api/offer-categories',
    ids,
    extraParams,
  )
  update(() => {
    ref.categories = categories
  })
}

export async function lookupTrafficSources(
  ref: any,
  value: string,
  update: Function,
) {
  const params: { offerId: number; search?: string } = {
    offerId: ref.campaign.offerId,
  }
  if (value) {
    params.search = value
  }

  const trafficSources = (await axios.get('/api/traffic-sources', { params }))
    .data

  update(() => {
    ref.trafficSources = trafficSources
  })
}

export async function lookupTrafficSourcesByOffer(
  ref: any,
  value: string,
  update: Function,
) {
  const params: { offerId: number; search?: string } = {
    offerId: ref.campaign.offerId,
  }
  if (value) {
    params.search = value
  }

  const trafficSources = (
    await axios.get('/api/traffic-sources/by-offer', { params })
  ).data

  update(() => {
    ref.trafficSources = trafficSources
  })
}

export async function lookupTrafficSourceNames(
  ref: any,
  value: string,
  update: Function,
) {
  const params: any = {}
  if (value) {
    params.search = value
  }

  const trafficSources = (
    await axios.get('/api/traffic-sources/list', { params })
  ).data

  update(() => {
    ref.trafficSourceNames = trafficSources
  })
}

export async function lookupTrafficSourcesForApps(
  ref: any,
  value: string,
  update: Function,
) {
  const params: { search?: string } = {}
  if (value) {
    params.search = value
  }

  const trafficSources = (await axios.get('/api/traffic-sources', { params }))
    .data

  update(() => {
    ref.trafficSources = trafficSources
  })
}

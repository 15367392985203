import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_editor = _resolveComponent("q-editor")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_color = _resolveComponent("q-color")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showDialog) = $event)),
    onKeyup: _cache[8] || (_cache[8] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_q_spinner, {
            key: 0,
            color: "primary",
            size: "3em",
            thickness: 2
          }))
        : (_openBlock(), _createBlock(_component_q_card, {
            key: 1,
            style: {"min-width":"350px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { class: "flex justify-between" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.offerTag.name ? 'Редактировать маркер' : 'Добавить маркет'), 1),
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_btn, {
                      "no-caps": "",
                      icon: "close",
                      flat: "",
                      rounded: "",
                      onClick: _ctx.onClose
                    }, null, 8, ["onClick"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_section, { class: "q-pb-none" }, {
                default: _withCtx(() => [
                  _cache[9] || (_cache[9] = _createElementVNode("b", null, "Выбрать существующий маркет (опционально):", -1)),
                  _createVNode(_component_q_select, {
                    disable: Boolean(_ctx.offerTag.name),
                    dense: "",
                    autofocus: "",
                    clearable: "",
                    modelValue: _ctx.existedMarkerId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.existedMarkerId) = $event)),
                    outlined: "",
                    options: _ctx.offerMarkers,
                    onFilter: _cache[1] || (_cache[1] = 
            (value, updateFn) => _ctx.getOptionsByField(value, updateFn)
          ),
                    "option-value": "id",
                    "option-label": "name",
                    "emit-value": "",
                    "map-options": "",
                    "use-input": ""
                  }, null, 8, ["disable", "modelValue", "options"])
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_section, { class: "q-py-none" }, {
                default: _withCtx(() => [
                  _cache[10] || (_cache[10] = _createElementVNode("b", null, "Название", -1)),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    disable: Number.isInteger(_ctx.existedMarkerId),
                    modelValue: _ctx.offerTag.name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.offerTag.name) = $event)),
                    placeholder: "TOP UA",
                    onKeyup: _withKeys(_ctx.onClose, ["esc"]),
                    error: !!_ctx.errors?.name,
                    "error-message": _ctx.errors?.name
                  }, null, 8, ["disable", "modelValue", "onKeyup", "error", "error-message"])
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_section, { class: "q-py-none" }, {
                default: _withCtx(() => [
                  _cache[11] || (_cache[11] = _createElementVNode("b", null, "Причина добавления / описание", -1)),
                  _createVNode(_component_q_editor, {
                    class: _normalizeClass(['q-mb-md', 'editor', { 'editor-error': !!_ctx.errors?.reason }]),
                    placeholder: "текст...",
                    onKeyup: _withKeys(_ctx.onClose, ["esc"]),
                    modelValue: _ctx.offerTag.reason,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.offerTag.reason) = $event)),
                    toolbar: [
            ['bold', 'italic', 'underline'],
            ['unordered', 'ordered'],
            [
              {
                label: _ctx.$q.lang.editor.formatting,
                icon: _ctx.$q.iconSet.editor.formatting,
                list: 'no-icons',
                options: ['p', 'h3', 'h4', 'h5', 'h6'],
              },
            ],
          ]
                  }, null, 8, ["class", "onKeyup", "modelValue", "toolbar"]),
                  _withDirectives(_createElementVNode("p", { class: "error-message" }, _toDisplayString(_ctx.errors?.reason), 513), [
                    [_vShow, !!_ctx.errors?.reason]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_section, { class: "q-py-none" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _cache[13] || (_cache[13] = _createElementVNode("b", null, "Цвет фона", -1)),
                    _createVNode(_component_q_badge, {
                      color: "grey-3",
                      "text-color": "black",
                      class: "q-mb-sm q-ml-sm"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.offerTag.color || 'Не выбрано'), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.offerTag.color)
                      ? (_openBlock(), _createBlock(_component_q_badge, {
                          key: 0,
                          modelValue: _ctx.offerTag.color,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.offerTag.color) = $event)),
                          color: "grey-3",
                          "text-color": "black",
                          class: "q-mb-sm q-ml-sm cursor-pointer",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.offerTag.color = null))
                        }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode(" Удалить ")
                          ])),
                          _: 1
                        }, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_q_color, {
                      disable: Number.isInteger(_ctx.existedMarkerId),
                      modelValue: _ctx.offerTag.color,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.offerTag.color) = $event)),
                      "no-header": "",
                      "no-footer": "",
                      "default-view": "palette",
                      style: {"max-width":"250px"}
                    }, null, 8, ["disable", "modelValue"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_actions, {
                align: "right",
                class: "text-primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    flat: "",
                    label: "Отменить",
                    onClick: _ctx.onClose,
                    "no-caps": ""
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_q_btn, {
                    flat: "",
                    label: "Сохранить",
                    onClick: _ctx.addOfferTag,
                    "no-caps": "",
                    loading: _ctx.isSaving
                  }, null, 8, ["onClick", "loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "col-sm-12 col-lg-2" }
const _hoisted_3 = { class: "col-sm-12 col-lg-3" }
const _hoisted_4 = { class: "col-sm-12 col-lg-4" }
const _hoisted_5 = { class: "col-sm-12 col-lg-1 self-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_editor = _resolveComponent("q-editor")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.translations, (row, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "flex row q-gutter-x-md q-mb-lg mobile-display-block"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("b", null, "Язык", -1)),
          _createVNode(_component_q_select, {
            dense: "",
            ref_for: true,
            ref: `target-languages-${index}`,
            onBlur: ($event: any) => (_ctx.clearFilter(`target-languages-${index}`)),
            outlined: "",
            clearable: "",
            "model-value": row.languageId,
            "onUpdate:modelValue": 
            value => _ctx.updateField({ ...row, languageId: value }, index)
          ,
            options: _ctx.languages,
            onFilter: _ctx.getLanguages,
            "option-value": "id",
            "option-label": "name",
            "emit-value": "",
            "map-options": "",
            "use-input": ""
          }, null, 8, ["onBlur", "model-value", "onUpdate:modelValue", "options", "onFilter"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("b", null, "Название оффера", -1)),
          _createVNode(_component_q_input, {
            dense: "",
            outlined: "",
            "model-value": row.name,
            "onUpdate:modelValue": 
            value =>
              _ctx.updateField(
                {
                  ...row,
                  name: value,
                },
                index,
              )
          ,
            error: !!_ctx.errors?.translations?.[index]?.name,
            "error-message": _ctx.errors?.translations?.[index]?.name
          }, null, 8, ["model-value", "onUpdate:modelValue", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("b", null, "Описание оффера", -1)),
          _createVNode(_component_q_editor, {
            class: _normalizeClass([
            'q-mb-md',
            'editor',
            { 'editor-error': !!_ctx.errors?.description },
          ]),
            "model-value": row.description || null,
            "onUpdate:modelValue": 
            value =>
              _ctx.updateField(
                {
                  ...row,
                  description: value,
                },
                index,
              )
          ,
            toolbar: [
            ['bold', 'italic', 'underline'],
            ['unordered', 'ordered'],
            [
              {
                label: _ctx.$q.lang.editor.formatting,
                icon: _ctx.$q.iconSet.editor.formatting,
                list: 'no-icons',
                options: ['p', 'h3', 'h4', 'h5', 'h6'],
              },
            ],
          ]
          }, null, 8, ["class", "model-value", "onUpdate:modelValue", "toolbar"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_q_btn, {
            onClick: ($event: any) => (_ctx.deleteReward(index)),
            outline: "",
            round: "",
            color: "primary",
            icon: "delete"
          }, null, 8, ["onClick"])
        ])
      ]))
    }), 128)),
    _createVNode(_component_q_btn, {
      onClick: _ctx.addReward,
      round: "",
      color: "secondary",
      icon: "add"
    }, null, 8, ["onClick"])
  ]))
}
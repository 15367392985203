import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "custom-table",
  style: {"border":"1px solid #ccc"}
}
const _hoisted_2 = {
  class: "row flex",
  style: {"min-width":"350px"}
}
const _hoisted_3 = { style: {"min-width":"60px"} }
const _hoisted_4 = {
  class: "custom-table",
  style: {"border":"1px solid #ccc"}
}
const _hoisted_5 = {
  class: "row flex",
  style: {"min-width":"250px"}
}
const _hoisted_6 = {
  class: "custom-table",
  style: {"border":"1px solid #ccc"}
}
const _hoisted_7 = {
  class: "row flex",
  style: {"min-width":"100px"}
}
const _hoisted_8 = ["alt", "src"]
const _hoisted_9 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_tabs, {
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
      "inline-label": "",
      breakpoint: 0,
      align: "justify",
      "no-caps": "",
      class: "shadow-2 q-mb-md q-ml-lg",
      style: {"max-width":"600px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_tab, {
          name: "intersections",
          label: "Аффилейты"
        }),
        _createVNode(_component_q_tab, {
          name: "consumers",
          label: "Консьюмеры"
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_q_tab_panels, {
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tab) = $event)),
      animated: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_tab_panel, { name: "intersections" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_table, {
              rows: _ctx.rows,
              dense: "",
              "column-sort-order": "da",
              columns: _ctx.columns,
              "row-key": "id",
              separator: "cell",
              pagination: _ctx.pagination,
              "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination) = $event)),
              loading: _ctx.loading,
              onRequest: _ctx.onRequest,
              "binary-state-sort": ""
            }, {
              "body-cell-affiliateId": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_IconWithName, {
                      id: props.row.affiliateId,
                      name: 
                _ctx.user?.notFullAccess ? '' : _ctx.affiliates[props.row.affiliateId]
              ,
                      size: "20px",
                      color: "secondary",
                      icon: "account_box",
                      entity: "affiliate"
                    }, null, 8, ["id", "name"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-intersections": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("table", _hoisted_1, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.intersections, (row, ind) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          class: "flex row items-center justify-start",
                          key: ind
                        }, [
                          _createElementVNode("td", _hoisted_2, [
                            _createVNode(_component_IconWithName, {
                              id: row.affiliateId,
                              name: 
                      _ctx.user?.notFullAccess ? '' : _ctx.affiliates[row.affiliateId]
                    ,
                              size: "20px",
                              color: "secondary",
                              icon: "account_box",
                              entity: "affiliate"
                            }, null, 8, ["id", "name"])
                          ]),
                          _createElementVNode("td", _hoisted_3, _toDisplayString(`${row.consumersCount}`), 1),
                          _createElementVNode("td", null, _toDisplayString(`${_ctx.frmtDate(row.lastIntersectionDate)}`), 1)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-offerIds": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("table", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.offerIntersections, (row, ind) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          class: "flex row items-center justify-start",
                          key: ind
                        }, [
                          _createElementVNode("td", _hoisted_5, [
                            _createVNode(_component_IconWithName, {
                              id: row.offerId,
                              name: _ctx.offers[row.offerId],
                              size: "20px",
                              color: "orange-7",
                              icon: "description",
                              entity: "offer"
                            }, null, 8, ["id", "name"])
                          ]),
                          _createElementVNode("td", null, _toDisplayString(`${row.consumersCount}`), 1)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-geoIds": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("table", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.geoIntersections, (row, ind) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          class: "flex row items-center justify-start",
                          key: ind,
                          style: {"width":"max-content"}
                        }, [
                          _createElementVNode("td", _hoisted_7, [
                            _createElementVNode("img", {
                              class: "q-mr-sm",
                              height: "10",
                              width: "15",
                              alt: _ctx.geos[row.geoId],
                              src: 
                      `http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                        _ctx.geos[row.geoId]
                      }.svg`
                    
                            }, null, 8, _hoisted_8),
                            _createTextVNode(" " + _toDisplayString(_ctx.geos[row.geoId] || '??') + " ", 1),
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.geosNames[row.geoId] || 'Неизвестно'), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _createElementVNode("td", null, _toDisplayString(`${row.consumersCount}`), 1)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 1
            }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
          ]),
          _: 1
        }),
        _createVNode(_component_q_tab_panel, { name: "consumers" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_table, {
              rows: _ctx.rows,
              dense: "",
              "column-sort-order": "da",
              columns: _ctx.columns,
              "row-key": "id",
              separator: "cell",
              pagination: _ctx.pagination,
              "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pagination) = $event)),
              loading: _ctx.loading,
              onRequest: _ctx.onRequest,
              "binary-state-sort": ""
            }, {
              "body-cell-affiliateIds": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.affiliateIds, (affiliateId, ind) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "flex row items-center",
                        key: ind
                      }, [
                        _createVNode(_component_IconWithName, {
                          id: affiliateId,
                          name: _ctx.user?.notFullAccess ? '' : _ctx.affiliates[affiliateId],
                          size: "20px",
                          color: "secondary",
                          icon: "account_box",
                          entity: "affiliate"
                        }, null, 8, ["id", "name"])
                      ]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-offerIds": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.offerIds, (offerId, ind) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "flex row items-center",
                        key: ind
                      }, [
                        _createVNode(_component_IconWithName, {
                          id: offerId,
                          name: _ctx.offers[offerId],
                          size: "20px",
                          color: "orange-7",
                          icon: "description",
                          entity: "offer"
                        }, null, 8, ["id", "name"])
                      ]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-geoIds": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.geoIds, (geoId, ind) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "flex row items-center",
                        key: ind,
                        style: {"width":"max-content"}
                      }, [
                        _createElementVNode("img", {
                          class: "q-mr-sm",
                          height: "10",
                          width: "15",
                          alt: _ctx.geos[geoId],
                          src: 
                  `http://purecatamphetamine.github.io/country-flag-icons/3x2/${_ctx.geos[geoId]}.svg`
                
                        }, null, 8, _hoisted_9),
                        _createTextVNode(" " + _toDisplayString(_ctx.geos[geoId] || '??') + " ", 1),
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.geosNames[geoId] || 'Неизвестно'), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-advertiserIds": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.advertiserIds, (advertiserId, ind) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "flex row items-center",
                        key: ind
                      }, [
                        _createVNode(_component_IconWithName, {
                          id: advertiserId,
                          name: _ctx.advertisers[advertiserId],
                          size: "21px",
                          color: "primary",
                          icon: "people",
                          entity: "advertiser"
                        }, null, 8, ["id", "name"])
                      ]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-clickIds": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getClickIds(props.row.clickIds, true), (clickId, ind) => {
                      return (_openBlock(), _createElementBlock("div", { key: ind }, _toDisplayString(clickId), 1))
                    }), 128)),
                    (_ctx.getClickIds(props.row.clickIds).length > 3)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          onClick: _withModifiers(($event: any) => (true), ["stop"]),
                          size: "sm",
                          flat: "",
                          "no-caps": "",
                          push: "",
                          label: "Еще..."
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_popup_proxy, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_banner, null, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getClickIds(props.row.clickIds), (clickId, key) => {
                                      return (_openBlock(), _createElementBlock("div", { key: key }, [
                                        _createElementVNode("div", null, _toDisplayString(clickId), 1)
                                      ]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 1
            }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify-between items-center mobile-display-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_PushGroupsList = _resolveComponent("PushGroupsList")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_PushSettings = _resolveComponent("PushSettings")!
  const _component_PushAnalytics = _resolveComponent("PushAnalytics")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-mx-lg q-my-xl" }, {
    default: _withCtx(() => [
      _createVNode(_component_center, null, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("h6", null, "Пуши", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_tabs, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
          "inline-label": "",
          "no-caps": "",
          breakpoint: 0,
          align: "justify",
          class: "shadow-2 q-ml-xl q-mb-md"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tab, {
              name: "push-groups",
              label: "Наши пуши"
            }),
            _createVNode(_component_q_tab, {
              name: "push-settings",
              label: "Найстроки рассылки"
            }),
            _createVNode(_component_q_tab, {
              name: "push-analytics",
              label: "Аналитика"
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createVNode(_component_q_tab_panels, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
        animated: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab_panel, { name: "push-groups" }, {
            default: _withCtx(() => [
              _createVNode(_component_PushGroupsList)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "push-settings" }, {
            default: _withCtx(() => [
              _createVNode(_component_PushSettings)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "push-analytics" }, {
            default: _withCtx(() => [
              _createVNode(_component_PushAnalytics)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = { class: "q-mb-lg" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl q-mr-lg" }, {
    default: _withCtx(() => [
      _createVNode(_component_center, null, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("h6", null, "Рекламодатели", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          class: "link-text",
          to: "/advertisers/new"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_btn, {
              "no-caps": "",
              class: "q-ml-md",
              label: "Добавить рекламодателя",
              color: "primary"
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Filters, {
          onReset: _ctx.resetFilters,
          onChange: _ctx.onChangeFilters,
          onChangeAll: _ctx.changeAll,
          "current-filters": _ctx.filters
        }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters"]),
        _createVNode(_component_q_table, {
          dense: "",
          rows: _ctx.rows,
          columns: _ctx.columns,
          "row-key": "id",
          separator: "cell",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
          loading: _ctx.loading,
          onRequest: _ctx.onRequest,
          onRowClick: _ctx.onRowClick,
          "binary-state-sort": ""
        }, {
          "body-cell-id": _withCtx((props) => [
            _createVNode(_component_q_td, {
              props: props,
              class: "cursor-pointer"
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  href: '/advertisers/' + props.row.id
                }, [
                  _createVNode(_component_IconWithName, {
                    id: props.row.id,
                    size: "21px",
                    color: "primary",
                    icon: "people",
                    entity: "advertiser"
                  }, null, 8, ["id"])
                ], 8, _hoisted_3)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-offersCount": _withCtx((props) => [
            _createVNode(_component_q_td, {
              props: props,
              onClick: _withModifiers(($event: any) => (_ctx.$router.push('/offers?advertiserId=' + props.row.id)), ["stop"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, { to: "" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.offersCount), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["props", "onClick"])
          ]),
          "body-cell-actions": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  dense: "",
                  round: "",
                  flat: "",
                  color: "primary",
                  size: "sm",
                  onClick: _withModifiers(($event: any) => (_ctx.deleteRow(props)), ["stop"]),
                  icon: "delete"
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "pagination", "loading", "onRequest", "onRowClick"])
      ])
    ]),
    _: 1
  }))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center q-mb-lg" }
const _hoisted_2 = { class: "q-mb-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl" }, {
    default: _withCtx(() => [
      _createVNode(_component_center, null, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("h6", null, "Админы", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          class: "link-text",
          to: "/admins/new"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_btn, {
              "no-caps": "",
              class: "q-ml-md",
              label: "Добавить админа",
              color: "primary"
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_table, {
          dense: "",
          rows: _ctx.rows,
          columns: _ctx.columns,
          "row-key": "id",
          separator: "cell",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
          loading: _ctx.loading,
          onRequest: _ctx.onRequest,
          onRowClick: _ctx.onRowClick,
          "binary-state-sort": ""
        }, {
          "body-cell-externalId": _withCtx((props) => [
            _createVNode(_component_q_td, {
              props: props,
              onClick: _withModifiers(($event: any) => (_ctx.copyKey(props.row.externalId)), ["stop"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.row.externalId), 1)
              ]),
              _: 2
            }, 1032, ["props", "onClick"])
          ]),
          "body-cell-isTdsAdmin": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.row.isTdsAdmin ? 'Админ TDS' : 'Админ ПП'), 1)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-logout": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  dense: "",
                  round: "",
                  flat: "",
                  color: "primary",
                  size: "sm",
                  onClick: _withModifiers(($event: any) => (_ctx.deleteAdminSession(props)), ["stop"]),
                  icon: "logout"
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-delete": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  dense: "",
                  round: "",
                  flat: "",
                  color: "primary",
                  size: "sm",
                  onClick: _withModifiers(($event: any) => (_ctx.deleteRow(props)), ["stop"]),
                  icon: "delete"
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "pagination", "loading", "onRequest", "onRowClick"])
      ])
    ]),
    _: 1
  }))
}
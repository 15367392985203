
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import {
  getDefaultFilters,
  getPagination,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import getColumns from './config/getColumns'
import axios from 'axios'
import Filters from '@/components/filters/Filters.vue'
import { getTypesForRewards } from '@/utils/rewards'
import { LocationQueryValue } from 'vue-router'
import { Row } from '@/utils/request'
import { FiltersType } from '@/utils/request'
import {
  confirmDialog,
  formatDate,
  showErrorMessageSnackBar,
  showSuccessSnackBar,
} from '@/utils'
import { OfferStatus } from '@/enums/OfferStatus'
import { Vertical } from '@/enums/Vertical'
import { Currency } from '@/enums/Currency'
import IconWithName from '@/components/icons/IconWithName.vue'
import { OfferData, TrafficSource } from '@/store/types'
import { RewardType } from '@/enums/RewardType'
import RewardsPopup from '@/components/popups/RewardsPopup.vue'
import { lookupAdvertiserManagers } from '@/utils/lookups'
import AddOfferMarkerDialog from '@/components/dialogs/AddOfferMarkerDialog.vue'

export default defineComponent({
  name: 'OffersList',
  components: { RewardsPopup, IconWithName, Filters, AddOfferMarkerDialog },
  async created() {
    const admins = (await axios.get('/api/admins/advertiser-managers')).data
    this.advertiserManagers = admins.map((admin: any) => ({
      ...admin,
      email: `#${admin.id} ` + admin.credentials.email,
    }))
    await this.onRequest({ pagination: this.pagination })
  },
  computed: {
    ...mapState('offersModule', ['offer', 'errors']),
    ...mapState('authModule', ['user']),
    uploadingOperations(): number {
      return this.offer.uploadingOperations || 0
    },
    complexFilters(): any {
      return { ...this.filters, pagination: this.pagination }
    },
  },
  data() {
    const queryFilters = this.$route.query
    for (const [filter, value] of Object.entries(queryFilters)) {
      queryFilters[filter] = ((Number(value) ||
        Boolean(value)) as unknown) as LocationQueryValue
    }
    const filtersL = this.$storage.getStorageSync('offersFiltersGmbt') || {}
    const paginationL =
      this.$storage.getStorageSync('offersPaginationGmbt') || {}
    const filters = {
      ...getDefaultFilters(),
      ...filtersL,
      ...queryFilters,
    }
    const pagination = {
      ...getPagination('id', 50),
      visibleColumns: getColumns().map(c => c.name),
      ...paginationL,
    }
    return {
      verticals: Vertical,
      currency: Currency,
      statuses: OfferStatus,
      rewardTypes: RewardType,
      offers: [],
      ...getTableProperties('id', 30),
      pagination,
      filters,
      preset: {},
      columns: getColumns(),
      affiliates: [],
      advertiserManagers: [],
      newAdvertiserManagerId: null,
      currentRowId: null,
      selectedRowId: null,
      selectedMarkerId: null,
      showAddTagsDialog: false,
      popupModel: null,
    }
  },
  watch: {
    async filters() {
      this.$storage.setStorageSync('offersFiltersGmbt', this.filters)
      this.$storage.removeStorageSync('offersPaginationGmbt')
      this.pagination.page = 1
      if ((this.filters as any).presetId) {
        this.pagination = {
          ...this.pagination,
          ...(this.filters as any).pagination,
        }
      }
      await this.onRequest({ pagination: this.pagination })
    },
    pagination: {
      handler() {
        this.$storage.setStorageSync('offersPaginationGmbt', this.pagination)
      },
      deep: true,
    },
  },
  methods: {
    onRowClick(row: Row) {
      this.$router.push('/offers/' + row.id)
    },
    async deleteRow(props: TableRequestProps) {
      confirmDialog('Вы действительно хотите удалить оффер?', async () => {
        this.loading = true
        await axios.delete('/api/offers', { params: { id: props.row?.id } })
        await this.onRequest({ pagination: this.pagination })
      })
    },
    getRewardTypes(row: any) {
      return getTypesForRewards([
        ...(row.commonRewards || []),
        ...(row.affiliateRewards || []),
      ])
    },
    getTsNameById(offer: OfferData, trafficSourceId: number) {
      return offer.trafficSources.find(
        (t: TrafficSource) => t.id === trafficSourceId,
      )?.name
    },
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/offers/all')
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.filters = getDefaultFilters()
    },
    resetColumns() {
      this.pagination.visibleColumns = getColumns().map(c => c.name)
    },
    async getManagers(value: string, update: Function) {
      await lookupAdvertiserManagers(this, value, update)
    },
    onChangeManagers(advertiserManagerId: any, row: any) {
      this.currentRowId = row.id
      if (!advertiserManagerId) {
        this.newAdvertiserManagerId = null
      } else {
        this.newAdvertiserManagerId = advertiserManagerId
      }
      row.advertiserManagerId = advertiserManagerId
    },
    saveManagers() {
      this.clearFilter('managers')
      axios
        .post('/api/offers/managers', {
          advertiserManagerId: this.newAdvertiserManagerId,
          offerId: this.currentRowId,
        })
        .then(() => {
          showSuccessSnackBar('Изменения сохранены')
        })
        .catch(() => {
          showErrorMessageSnackBar('Произошла ошибка :(')
        })
    },
    getAdminEmail(id: number) {
      return (this.advertiserManagers.find((ad: any) => ad.id === id) as any)
        ?.credentials?.email
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
    addMarker(offerId: number, markerId?: number) {
      this.selectedRowId = offerId as any
      this.selectedMarkerId = markerId as any
      this.showAddTagsDialog = !this.showAddTagsDialog
    },
    onCloseDialog() {
      this.showAddTagsDialog = false
      this.selectedMarkerId = null
    },
    frmtDate(date: Date) {
      return formatDate(date)
    },
    async deleteMarker(offerId: number, markerId: number, name: string) {
      confirmDialog(
        `Вы действительно хотите удалить маркер ${name}? с оффера ID:${offerId}?`,
        async () => {
          await axios.post(`/api/offer-markers/delete-from-offer`, {
            id: markerId,
            offerId,
          })
          await this.onRequest({ pagination: this.pagination })
        },
      )
    },
    async onRefresh() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
})

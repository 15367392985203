import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_vue_feather = _resolveComponent("vue-feather")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"])),
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showDialog) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"min-width":"450px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { class: "row justify-between" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, " Attribution links for " + _toDisplayString(_ctx.source === 'tt' ? 'TikTok' : 'Unity Ads'), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  icon: "close",
                  flat: "",
                  rounded: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "row items-center",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copy('click_url')))
              }, [
                _cache[7] || (_cache[7] = _createElementVNode("b", null, "Click attribution link:", -1)),
                _createVNode(_component_vue_feather, {
                  class: "q-ml-md cursor-pointer",
                  type: "copy"
                }),
                _createVNode(_component_q_tooltip, null, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Копировать")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(_component_q_input, {
                for: "click_url",
                "model-value": _ctx.getClickLink(),
                filled: "",
                readonly: "",
                type: "textarea"
              }, null, 8, ["model-value"]),
              _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("div", {
                class: "row items-center",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.copy('impression_url')))
              }, [
                _cache[9] || (_cache[9] = _createElementVNode("b", null, "Impression attribution link:", -1)),
                _createVNode(_component_vue_feather, {
                  class: "q-ml-md cursor-pointer",
                  type: "copy"
                }),
                _createVNode(_component_q_tooltip, null, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("Копировать")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(_component_q_input, {
                for: "impression_url",
                "model-value": _ctx.getImpressionLink(),
                filled: "",
                readonly: "",
                type: "textarea"
              }, null, 8, ["model-value"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                flat: "",
                label: "OK",
                color: "primary",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../../public/images/gambeat_logo_white_full.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_spinner_pie = _resolveComponent("q-spinner-pie")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_page_container = _resolveComponent("q-page-container")!

  return (_openBlock(), _createBlock(_component_q_page_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_q_page, { class: "q-layout-container column justify-center items-center bg-dark" }, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("img", {
            width: "150",
            height: "30",
            src: _imports_0,
            class: "cursor-pointer"
          }, null, -1)),
          (!_ctx.userExists)
            ? (_openBlock(), _createBlock(_component_q_form, {
                key: 0,
                onSubmit: _ctx.submit,
                class: "login-form column q-mt-lg"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_input, {
                    class: "login-field",
                    outlined: "",
                    color: "white",
                    "input-class": "text-white",
                    "label-color": "white",
                    "bg-color": "black",
                    label: "E-mail",
                    modelValue: _ctx.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                    type: "email",
                    error: _ctx.error
                  }, null, 8, ["modelValue", "error"]),
                  _createVNode(_component_q_input, {
                    outlined: "",
                    "input-class": "text-white",
                    color: "white",
                    "label-color": "white",
                    "bg-color": "black",
                    class: "q-mt-xs login-field",
                    label: "Password",
                    modelValue: _ctx.password,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                    type: "password",
                    error: _ctx.error,
                    "error-message": "Неверный e-mail или пароль"
                  }, null, 8, ["modelValue", "error"]),
                  _createVNode(_component_q_btn, {
                    class: "col self-end",
                    label: "Login",
                    "no-caps": "",
                    type: "submit",
                    color: "positive",
                    loading: _ctx.loading
                  }, null, 8, ["loading"])
                ]),
                _: 1
              }, 8, ["onSubmit"]))
            : (_openBlock(), _createBlock(_component_q_spinner_pie, {
                key: 1,
                class: "q-mt-xl",
                style: {"color":"#ffd000 !important"},
                size: "xl"
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "row items-center",
  style: {"width":"100%"}
}
const _hoisted_2 = {
  class: "row justify-start items-center",
  style: {"flex":"1"}
}
const _hoisted_3 = { class: "q-mr-md" }
const _hoisted_4 = {
  key: 0,
  class: "q-mr-lg"
}
const _hoisted_5 = { class: "row items-center no-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_PayoutEditDialog = _resolveComponent("PayoutEditDialog")!
  const _component_PayoutLeadsDialog = _resolveComponent("PayoutLeadsDialog")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl q-mr-lg" }, {
    default: _withCtx(() => [
      (!_ctx.affiliateId)
        ? (_openBlock(), _createBlock(_component_center, { key: 0 }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("h6", null, "Выплаты", -1)
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_Filters, {
        onReset: _ctx.resetFilters,
        onChange: _ctx.onChangeFilters,
        onChangeAll: _ctx.changeAll,
        "current-filters": _ctx.filters,
        onExport: _ctx.onExport,
        tab: _ctx.affiliateId ? 'payouts' : undefined
      }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters", "onExport", "tab"]),
      _createVNode(_component_q_table, {
        rows: _ctx.rows,
        dense: "",
        columns: _ctx.columns,
        "row-key": "id",
        separator: "cell",
        pagination: _ctx.pagination,
        "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
        loading: _ctx.loading,
        onRequest: _ctx.onRequest,
        onRowClick: _ctx.infoPayout,
        "binary-state-sort": "",
        "column-sort-order": "da"
      }, {
        top: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", null, [
                _cache[5] || (_cache[5] = _createElementVNode("b", { class: "q-mr-sm" }, "TOTAL: ", -1)),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.totalUsdSum)) + "$ ", 1),
                (_ctx.rows[0]?.totalEurSum)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.totalEurSum)) + "€ ", 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        "body-cell-affiliateEmail": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_IconWithName, {
                id: props.row.affiliateId,
                name: props.row.affiliateEmail,
                size: "20px",
                color: "secondary",
                icon: "account_box",
                entity: "affiliate"
              }, null, 8, ["id", "name"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-payoutAmount": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(Number(props.row.payoutAmount)
              ?.toFixed(2)
              ?.replace('.', ',')), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-status": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.row.status === _ctx.payoutStatuses.Done)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 0,
                    color: "positive"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.payoutStatuses[props.row.status]), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (props.row.status === _ctx.payoutStatuses.Rejected)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 1,
                    color: "red"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.payoutStatuses[props.row.status]), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (props.row.status === _ctx.payoutStatuses.InReview)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 2,
                    color: "orange"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.payoutStatuses[props.row.status]), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (props.row.status === _ctx.payoutStatuses.Processing)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 3,
                    color: "primary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.payoutStatuses[props.row.status]), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (props.row.status === _ctx.payoutStatuses.ReadyForPayment)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 4,
                    color: "teal"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.payoutStatuses[props.row.status]), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_q_btn, {
                  disable: props.row.status === _ctx.payoutStatuses.Rejected,
                  round: "",
                  flat: "",
                  size: "sm",
                  color: "primary",
                  onClick: _withModifiers(($event: any) => (_ctx.editPayout(props.row)), ["stop"]),
                  icon: "edit"
                }, null, 8, ["disable", "onClick"]),
                _createVNode(_component_q_btn, {
                  round: "",
                  flat: "",
                  size: "sm",
                  color: "primary",
                  onClick: _withModifiers(($event: any) => (_ctx.infoPayout(null, props.row)), ["stop"]),
                  icon: "info"
                }, null, 8, ["onClick"]),
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  label: "Конверсии",
                  flat: "",
                  size: "xs",
                  color: "primary",
                  onClick: _withModifiers(($event: any) => (_ctx.showPayoutLeads(props.row)), ["stop"]),
                  icon: "description"
                }, null, 8, ["onClick"])
              ])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-paidValueAmount": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(Number(props.row.paidValueAmount)
              ?.toFixed(2)
              ?.replace('.', ',')), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-paidValueCurrency": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.currencies[props.row.paidValueCurrency] || 'USD'), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-comment": _withCtx((props) => [
          _createVNode(_component_q_td, {
            props: props,
            onClick: _withModifiers(($event: any) => (_ctx.editPayout(props.row)), ["stop"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.comment || '-'), 1)
            ]),
            _: 2
          }, 1032, ["props", "onClick"])
        ]),
        "body-cell-rejectComment": _withCtx((props) => [
          _createVNode(_component_q_td, {
            props: props,
            onClick: _withModifiers(($event: any) => (_ctx.copy(props.row.rejectComment)), ["stop"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.rejectComment || '-'), 1)
            ]),
            _: 2
          }, 1032, ["props", "onClick"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "pagination", "loading", "onRequest", "onRowClick"]),
      (_ctx.showPayoutEditDialog)
        ? (_openBlock(), _createBlock(_component_PayoutEditDialog, {
            key: 1,
            open: _ctx.showPayoutEditDialog,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPayoutEditDialog = false)),
            onRefresh: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onRequest({ pagination: _ctx.pagination }))),
            currentPayout: _ctx.currentPayout
          }, null, 8, ["open", "currentPayout"]))
        : _createCommentVNode("", true),
      (_ctx.showPayoutLeadsDialog)
        ? (_openBlock(), _createBlock(_component_PayoutLeadsDialog, {
            key: 2,
            currentPayout: _ctx.currentPayout,
            open: _ctx.showPayoutLeadsDialog,
            onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPayoutLeadsDialog = false))
          }, null, 8, ["currentPayout", "open"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
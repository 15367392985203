
import { defineComponent } from 'vue'
import {
  confirmDialog,
  enumToArray,
  payoutCurrencies as currencies,
  showErrorMessageSnackBar,
  showInternalErrorSnackBar,
  showSuccessSnackBar,
} from '@/utils'
import { PaymentSystem } from '@/enums/PaymentSystem'
import { PayoutStatus } from '@/enums/PayoutStatus'
import axios from 'axios'
import { PaymentSystemNetwork } from '@/enums/PaymentSystemNetwork'
import IconWithName from '@/components/icons/IconWithName.vue'

export default defineComponent({
  name: 'PayoutEditDialog',
  components: { IconWithName },
  props: ['open', 'close', 'refresh', 'currentPayout'],
  async created() {
    this.admin = (await axios.get('/api/auth/profile')).data as any
  },
  computed: {
    showDialog(): boolean {
      return this.open
    },
    isAffiliateManager(): boolean {
      return this.admin.isAffiliateManager === true
    },
    isFinanceManager(): boolean {
      return this.admin.isFinanceManager === true
    },
    isSuperAdmin(): boolean {
      return this.admin.isSuperAdmin === true
    },
  },
  watch: {
    currentPayout() {
      return {
        ...this.currentPayout,
        paidValueCurrency:
          this.currentPayout.paidValueCurrency === undefined ||
          this.currentPayout.paidValueCurrency === null
            ? this.currentPayout.payoutCurrency
            : this.currentPayout.paidValueCurrency,
        paidValueAmount:
          this.currentPayout.paidValueAmount === undefined ||
          this.currentPayout.paidValueAmount === null
            ? this.currentPayout.payoutAmount
            : this.currentPayout.paidValueAmount,
      }
    },
  },
  data: function() {
    const admin: any = null
    return {
      admin,
      loading: false,
      accounts: [],
      payout: {
        comment: null,
        ...this.currentPayout,
        paidValueCurrency:
          this.currentPayout.paidValueCurrency === undefined ||
          this.currentPayout.paidValueCurrency === null
            ? this.currentPayout.payoutCurrency
            : this.currentPayout.paidValueCurrency,
        paidValueAmount:
          this.currentPayout.paidValueAmount === undefined ||
          this.currentPayout.paidValueAmount === null
            ? this.currentPayout.payoutAmount
            : this.currentPayout.paidValueAmount,
      },
      errors: {},
      currencies,
      payoutStatusesEnum: PayoutStatus,
      paymentSystemNetworks: enumToArray(PaymentSystemNetwork),
      payoutStatuses: enumToArray(PayoutStatus),
      paymentSystems: enumToArray(PaymentSystem).filter(
        e => (e.id as any) !== PaymentSystem.QIWI,
      ),
      paymentSystemsEnum: PaymentSystem,
    }
  },
  methods: {
    async hideAlert(payout: any) {
      confirmDialog(
        `Больше не показывать это уведомление для данной выплаты?`,
        async () => {
          await axios.put(`/api/payouts/hide-alert`, payout)
          this.payout.showAlertInPayouts = false
        },
      )
    },
    onChangeAmount(value: number) {
      this.payout.payoutAmount = Number(value)
      this.onChangePayoutFee(this.payout.feePercent)
    },
    onChangePayoutFee(fee: number) {
      if (!fee || isNaN(Number(fee))) {
        this.payout.paidValueAmount = this.payout.payoutAmount
        this.payout.feePercent = null
        return
      }
      this.payout.feePercent = Number(fee)
      this.payout.paidValueAmount =
        (Number(this.payout.payoutAmount) * (100 - Number(fee))) / 100
    },
    save() {
      if (
        this.currentPayout.status === PayoutStatus.InReview &&
        ![
          PayoutStatus.InReview,
          PayoutStatus.Rejected,
          PayoutStatus.ReadyForPayment,
        ].includes(this.payout.status)
      ) {
        showErrorMessageSnackBar(
          'Недопустимая смена статуса. Возможные смены статуса: Rejected, ReadyForPayment',
        )
        return
      }
      if (
        this.currentPayout.status === PayoutStatus.ReadyForPayment &&
        ![
          PayoutStatus.ReadyForPayment,
          PayoutStatus.Rejected,
          PayoutStatus.Processing,
        ].includes(this.payout.status)
      ) {
        showErrorMessageSnackBar(
          'Недопустимая смена статуса. Возможные смены статуса: Rejected, Processing',
        )
        return
      }
      if (
        this.currentPayout.status === PayoutStatus.Processing &&
        ![
          PayoutStatus.Processing,
          PayoutStatus.Done,
          PayoutStatus.Rejected,
        ].includes(this.payout.status)
      ) {
        showErrorMessageSnackBar(
          'Недопустимая смена статуса. Возможные смены статуса: Done, Rejected',
        )
        return
      }
      if (
        this.payout.status === PayoutStatus.Rejected &&
        !this.payout.rejectComment
      ) {
        showErrorMessageSnackBar(
          'При реджекте выплаты нужно указать причину реджекта.',
        )
        return
      }
      if (!this.isSuperAdmin) {
        if (
          this.currentPayout.status === PayoutStatus.InReview &&
          !this.isAffiliateManager
        ) {
          showErrorMessageSnackBar(
            'Только афф менеджеры и супер админы могут редактировать выплату с текущим статусом',
          )
          return
        }
        if (
          this.payout.status === PayoutStatus.Processing &&
          !this.isFinanceManager
        ) {
          showErrorMessageSnackBar(
            'Только фин. менеджеры и супер админы могут редактировать выплату с текущим статусом',
          )
          return
        }
        if (
          this.payout.status === PayoutStatus.Done &&
          !this.isFinanceManager
        ) {
          showErrorMessageSnackBar(
            'Только фин. менеджеры и супер админы могут редактировать выплату с текущим статусом',
          )
          return
        }
        if (this.payout.status === PayoutStatus.Rejected) {
          confirmDialog(
            'Вы уверены что хотите реджектнуть выплату? Это действие нельзя будет отменить.',
            () => {
              this.sendRequest()
            },
          )
          return
        }
        if (this.payout.status === PayoutStatus.Done) {
          confirmDialog(
            'Вы уверены что хотите перевести выплату в статус Done? Это действие нельзя будет отменить.',
            () => {
              this.sendRequest()
            },
          )
          return
        }
      }
      this.sendRequest()
    },
    sendRequest() {
      this.loading = true
      axios
        .put(`/api/payouts/${this.payout.id}/change`, this.payout)
        .then(() => {
          this.errors = {}
          this.$emit('refresh')
          this.onClose(true)
          showSuccessSnackBar('Выплата успешно обновлена.')
        })
        .catch(err => {
          showInternalErrorSnackBar(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onClose(noRefresh = false) {
      if (noRefresh) {
        this.$emit('close')
        return
      }
      if (
        this.payout.showAlertInPayouts !==
          this.currentPayout.showAlertInPayouts &&
        this.payout.showAlertInPayouts === false
      ) {
        this.$emit('refresh')
        this.$emit('close')
        return
      }
      this.$emit('close')
    },
  },
})

import { ColumnType } from '@/utils'

export function getColumns(): ColumnType[] {
  return [
    {
      name: 'advertiserName',
      label: 'Biz Dev / Status',
      align: 'left',
      field: 'advertiserName',
    },
    {
      name: 'usdAdvertiserHoldSum',
      label: 'Hold, $',
      align: 'right',
      field: 'usdAdvertiserHoldSum',
    },
    {
      name: 'eurAdvertiserHoldSum',
      label: 'Hold, €',
      align: 'right',
      field: 'eurAdvertiserHoldSum',
    },
    {
      name: 'usdAdvertiserRejectedSum',
      label: 'Rejected, $',
      align: 'right',
      field: 'usdAdvertiserRejectedSum',
    },
    {
      name: 'eurAdvertiserRejectedSum',
      label: 'Rejected, €',
      align: 'right',
      field: 'eurAdvertiserRejectedSum',
    },
    {
      name: 'usdAdvertiserApprovedSum',
      label: 'Approved, $',
      align: 'right',
      field: 'usdAdvertiserApprovedSum',
    },
    {
      name: 'eurAdvertiserApprovedSum',
      label: 'Approved, €',
      align: 'right',
      field: 'eurAdvertiserApprovedSum',
    },
  ]
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "q-ml-sm text-grey-8" }
const _hoisted_2 = { class: "q-ml-sm text-grey-8" }
const _hoisted_3 = { class: "q-ml-sm text-grey-8" }
const _hoisted_4 = { class: "q-ml-sm text-grey-8" }
const _hoisted_5 = {
  class: "row",
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_6 = {
  key: 0,
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_7 = ["alt", "src"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_12 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_13 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_14 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_15 = {
  class: "row",
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_16 = {
  key: 0,
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_17 = ["alt", "src"]
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { key: 2 }
const _hoisted_20 = { key: 3 }
const _hoisted_21 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_22 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_23 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_24 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_25 = {
  class: "row justify-end",
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_26 = {
  key: 0,
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_27 = ["alt", "src"]
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { key: 2 }
const _hoisted_30 = { key: 3 }
const _hoisted_31 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_32 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_33 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_34 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_35 = { class: "q-ml-sm text-grey-8" }
const _hoisted_36 = { class: "q-ml-sm text-grey-8" }
const _hoisted_37 = { class: "q-ml-sm text-grey-8" }
const _hoisted_38 = { class: "q-ml-sm text-grey-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.filters.hideChart)
      ? (_openBlock(), _createBlock(_component_LineChart, {
          key: 0,
          rows: _ctx.chartData,
          groupBy: _ctx.filters.groupBy,
          allColumns: _ctx.columns,
          columns: _ctx.pagination.selectedColumns,
          loading: _ctx.chartLoading,
          groupByOptions: _ctx.getGroupByArray()
        }, null, 8, ["rows", "groupBy", "allColumns", "columns", "loading", "groupByOptions"]))
      : _createCommentVNode("", true),
    _createVNode(_component_q_table, {
      dense: "",
      "column-sort-order": "da",
      class: "q-mx-md",
      rows: _ctx.rows,
      columns: _ctx.columns,
      "row-key": "id",
      separator: "cell",
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.pagination) = $event)),
      "visible-columns": _ctx.visibleColumns,
      loading: _ctx.loading,
      onRequest: _ctx.onRequest,
      "binary-state-sort": ""
    }, _createSlots({
      top: _withCtx(() => [
        _createVNode(_component_q_select, {
          modelValue: _ctx.pagination.visibleColumns,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination.visibleColumns) = $event)),
          multiple: "",
          outlined: "",
          dense: "",
          "options-dense": "",
          "display-value": "Колонки",
          "emit-value": "",
          "map-options": "",
          options: _ctx.columns,
          "option-value": "name",
          "option-label": "label",
          "options-cover": "",
          style: {"min-width":"150px"}
        }, null, 8, ["modelValue", "options"]),
        _createVNode(_component_q_btn, {
          "no-caps": "",
          class: "q-ml-md",
          icon: "autorenew",
          color: "red",
          label: "Сбросить",
          size: "13px",
          onClick: _ctx.resetColumns,
          outline: ""
        }, null, 8, ["onClick"]),
        _createVNode(_component_q_btn, {
          class: "q-ml-sm",
          unelevated: "",
          "no-caps": "",
          color: "primary",
          icon: "person",
          label: 'Рефоводы: ' + (_ctx.rows?.length ? _ctx.rows[0].total : 0)
        }, null, 8, ["label"]),
        _createVNode(_component_q_btn, {
          class: "q-ml-sm",
          color: "grey",
          unelevated: "",
          "no-caps": "",
          icon: "hail",
          label: 'Рефералы: ' + (_ctx.rows?.length ? _ctx.rows[0].referralsCount : 0)
        }, null, 8, ["label"]),
        _createVNode(_component_q_btn, {
          class: "q-ml-sm",
          color: "positive",
          unelevated: "",
          "no-caps": "",
          icon: "arrow_drop_up",
          label: 
            'Активные рефералы: ' +
              (_ctx.rows?.length ? _ctx.rows[0].activeReferralsCount : 0)
          
        }, null, 8, ["label"])
      ]),
      header: _withCtx((props) => [
        _createVNode(_component_q_tr, null, {
          default: _withCtx(() => [
            (_ctx.pagination.visibleColumns.includes('groupByField'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: "groupByField",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getGroupedField('name')), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('registrations'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
              'left-border': true,
            }),
                  key: "registrations",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.registrations,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.registrations) = $event)),
                      val: "xs",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeSelected('registrations')))
                    }, null, 8, ["modelValue"]),
                    _cache[22] || (_cache[22] = _createTextVNode("Регистрации"))
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('conversions'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
              'left-border': true,
            }),
                  key: "conversions",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.conversions,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.conversions) = $event)),
                      val: "xs",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeSelected('conversions')))
                    }, null, 8, ["modelValue"]),
                    _cache[23] || (_cache[23] = _createTextVNode("FTD"))
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('revenue'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
              'left-border': true,
            }),
                  key: "revenue",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.revenue,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.revenue) = $event)),
                      val: "xs",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeSelected('revenue')))
                    }, null, 8, ["modelValue"]),
                    _cache[26] || (_cache[26] = _createTextVNode("Profit ")),
                    _createElementVNode("sup", null, [
                      _cache[25] || (_cache[25] = _createTextVNode("?")),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[24] || (_cache[24] = [
                          _createTextVNode("Сумма в базовой валюте аккаунта по курсу на 1 число месяца")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('usdRevenue'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
              'left-border': true,
            }),
                  key: "usdRevenue",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.usdRevenue,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.usdRevenue) = $event)),
                      val: "xs",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.changeSelected('usdRevenue')))
                    }, null, 8, ["modelValue"]),
                    _cache[29] || (_cache[29] = _createTextVNode("Profit USD ")),
                    _createElementVNode("sup", null, [
                      _cache[28] || (_cache[28] = _createTextVNode("?")),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[27] || (_cache[27] = [
                          _createTextVNode("Сумма в оригинальной валюте оффера")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('eurRevenue'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
              'left-border': true,
            }),
                  key: "eurRevenue",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.eurRevenue,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.eurRevenue) = $event)),
                      val: "xs",
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.changeSelected('eurRevenue')))
                    }, null, 8, ["modelValue"]),
                    _cache[32] || (_cache[32] = _createTextVNode("Profit EUR ")),
                    _createElementVNode("sup", null, [
                      _cache[31] || (_cache[31] = _createTextVNode("?")),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[30] || (_cache[30] = [
                          _createTextVNode("Сумма в оригинальной валюте оффера")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('uahRevenue'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
              'left-border': true,
            }),
                  key: "uahRevenue",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.uahRevenue,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.uahRevenue) = $event)),
                      val: "xs",
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.changeSelected('uahRevenue')))
                    }, null, 8, ["modelValue"]),
                    _cache[35] || (_cache[35] = _createTextVNode("Profit UAH ")),
                    _createElementVNode("sup", null, [
                      _cache[34] || (_cache[34] = _createTextVNode("?")),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[33] || (_cache[33] = [
                          _createTextVNode("Сумма в оригинальной валюте оффера")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('revenueHold'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
              'left-border': true,
            }),
                  key: "revenueHold",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.revenueHold,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.revenueHold) = $event)),
                      val: "xs",
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.changeSelected('revenueHold')))
                    }, null, 8, ["modelValue"]),
                    _cache[38] || (_cache[38] = _createTextVNode("Profit HOLD ")),
                    _createElementVNode("sup", null, [
                      _cache[37] || (_cache[37] = _createTextVNode("?")),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[36] || (_cache[36] = [
                          _createTextVNode("Сумма в базовой валюте аккаунта по курсу на 1 число месяца")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('usdRevenueHold'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
              'left-border': true,
            }),
                  key: "usdRevenueHold",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.usdRevenueHold,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.usdRevenueHold) = $event)),
                      val: "xs",
                      onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.changeSelected('usdRevenueHold')))
                    }, null, 8, ["modelValue"]),
                    _cache[41] || (_cache[41] = _createTextVNode("Profit USD HOLD ")),
                    _createElementVNode("sup", null, [
                      _cache[40] || (_cache[40] = _createTextVNode("?")),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[39] || (_cache[39] = [
                          _createTextVNode("Сумма в оригинальной валюте оффера")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('eurRevenueHold'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
              'left-border': true,
            }),
                  key: "eurRevenueHold",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.eurRevenueHold,
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.eurRevenueHold) = $event)),
                      val: "xs",
                      onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.changeSelected('eurRevenueHold')))
                    }, null, 8, ["modelValue"]),
                    _cache[44] || (_cache[44] = _createTextVNode("Profit EUR HOLD ")),
                    _createElementVNode("sup", null, [
                      _cache[43] || (_cache[43] = _createTextVNode("?")),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[42] || (_cache[42] = [
                          _createTextVNode("Сумма в оригинальной валюте оффера")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('uahRevenueHold'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
              'left-border': true,
            }),
                  key: "uahRevenueHold",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.uahRevenueHold,
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.uahRevenueHold) = $event)),
                      val: "xs",
                      onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.changeSelected('uahRevenueHold')))
                    }, null, 8, ["modelValue"]),
                    _cache[47] || (_cache[47] = _createTextVNode("Profit UAH HOLD ")),
                    _createElementVNode("sup", null, [
                      _cache[46] || (_cache[46] = _createTextVNode("?")),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[45] || (_cache[45] = [
                          _createTextVNode("Сумма в оригинальной валюте оффера")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_q_tr, null, {
          default: _withCtx(() => [
            (_ctx.pagination.visibleColumns.includes('groupByField'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 0,
                  class: "text-left border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => _cache[48] || (_cache[48] = [
                    _createElementVNode("b", null, "TOTAL:", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('registrations'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 1,
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("b", null, _toDisplayString(_ctx.total('registrations', 'sum')), 1)
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('conversions'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 2,
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("b", null, _toDisplayString(_ctx.total('conversions', 'sum')), 1)
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('revenue'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 3,
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("b", null, [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('revenue', 'sum'))), 1),
                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('usdRevenue'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 4,
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("b", null, [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdRevenue', 'sum'))), 1),
                        _cache[49] || (_cache[49] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('eurRevenue'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 5,
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("b", null, [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurRevenue', 'sum'))), 1),
                        _cache[50] || (_cache[50] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('uahRevenue'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 6,
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("b", null, [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahRevenue', 'sum'))), 1),
                        _cache[51] || (_cache[51] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('revenueHold'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 7,
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_1, [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('revenueHold', 'sum'))) + " ", 1),
                        _createElementVNode("small", null, [
                          _createTextVNode(_toDisplayString(_ctx.getCurrencyFromCookie()), 1),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[52] || (_cache[52] = [
                              _createTextVNode("Холд")
                            ])),
                            _: 1
                          })
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('usdRevenueHold'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 8,
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_2, [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdRevenueHold', 'sum'))) + " ", 1),
                        _createElementVNode("small", null, [
                          _cache[54] || (_cache[54] = _createTextVNode(_toDisplayString(' USD'))),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[53] || (_cache[53] = [
                              _createTextVNode("Холд")
                            ])),
                            _: 1
                          })
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('eurRevenueHold'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 9,
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_3, [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurRevenueHold', 'sum'))) + " ", 1),
                        _createElementVNode("small", null, [
                          _cache[56] || (_cache[56] = _createTextVNode(_toDisplayString(' EUR'))),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[55] || (_cache[55] = [
                              _createTextVNode("Холд")
                            ])),
                            _: 1
                          })
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('uahRevenueHold'))
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 10,
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_4, [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahRevenueHold', 'sum'))) + " ", 1),
                        _createElementVNode("small", null, [
                          _cache[58] || (_cache[58] = _createTextVNode(_toDisplayString(' UAH'))),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[57] || (_cache[57] = [
                              _createTextVNode("Холд")
                            ])),
                            _: 1
                          })
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      body: _withCtx((props) => [
        _createVNode(_component_q_tr, {
          props: props,
          style: _normalizeStyle(
            this.expandedIds.includes(props.row.expandedId)
              ? 'background: #def3b4'
              : ''
          )
        }, {
          default: _withCtx(() => [
            (_ctx.pagination.visibleColumns.includes('groupByField'))
              ? (_openBlock(), _createBlock(_component_q_td, {
                  props: props,
                  key: "groupByField"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_5, [
                      (_ctx.filters.groupBy === 'geoId')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                            _createElementVNode("img", {
                              style: {"user-select":"none","display":"initial"},
                              class: "q-mr-xs",
                              height: "10",
                              width: "15",
                              alt: _ctx.geos[props.row.geoId],
                              src: 
                    `http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                      _ctx.geos[props.row.geoId]
                    }.svg`
                  
                            }, null, 8, _hoisted_7),
                            _createTextVNode(" " + _toDisplayString(_ctx.geos[props.row.geoId] || '??') + " ", 1),
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.geos[props.row.geoId] || 'Неизвестно'), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.filters.groupBy === 'date')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.formatDateLikeChart(_ctx.getGroupedField('field', props.row))), 1))
                        : _createCommentVNode("", true),
                      (_ctx.filters.groupBy === 'week')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.getISOWeekName(_ctx.getGroupedField('field', props.row))), 1))
                        : _createCommentVNode("", true),
                      (_ctx.filters.groupBy === 'month')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.getISOMonthName(_ctx.getGroupedField('field', props.row))), 1))
                        : _createCommentVNode("", true),
                      (_ctx.filters.groupBy === 'campaignId')
                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                            key: 4,
                            id: props.row.campaignId,
                            name: props.row.campaignName || 'Неизвестно',
                            size: "18px",
                            color: "secondary",
                            icon: "share",
                            entity: "campaign"
                          }, null, 8, ["id", "name"]))
                        : _createCommentVNode("", true),
                      (_ctx.filters.groupBy === 'landingId')
                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                            key: 5,
                            id: props.row.landingId,
                            name: props.row.landingName || 'Неизвестно',
                            size: "18px",
                            color: "primary",
                            icon: "airline_stops",
                            entity: "landing"
                          }, null, 8, ["id", "name"]))
                        : _createCommentVNode("", true),
                      (_ctx.filters.groupBy === 'advertiserId')
                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                            key: 6,
                            id: props.row.advertiserId,
                            name: props.row.advertiserName || 'Неизвестно',
                            size: "20px",
                            color: "primary",
                            icon: "people",
                            entity: "advertiser"
                          }, null, 8, ["id", "name"]))
                        : _createCommentVNode("", true),
                      (_ctx.filters.groupBy === 'offerId')
                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                            key: 7,
                            id: props.row.offerId,
                            name: _ctx.offers[props.row.offerId] || 'Неизвестно',
                            size: "18px",
                            color: "orange-7",
                            icon: "description",
                            entity: "offer"
                          }, null, 8, ["id", "name"]))
                        : _createCommentVNode("", true),
                      (_ctx.filters.groupBy === 'managerId')
                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                            key: 8,
                            id: props.row.managerId,
                            name: props.row.managerEmail || 'Неизвестно',
                            size: "20px",
                            color: "primary",
                            icon: "manage_accounts",
                            entity: "manager"
                          }, null, 8, ["id", "name"]))
                        : _createCommentVNode("", true),
                      (_ctx.filters.groupBy === 'affiliateId')
                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                            key: 9,
                            id: props.row.affiliateId,
                            name: _ctx.affiliates[props.row.affiliateId] || '',
                            size: "20px",
                            color: "secondary",
                            icon: "account_box",
                            entity: "affiliate"
                          }, null, 8, ["id", "name"]))
                        : _createCommentVNode("", true),
                      (_ctx.filters.groupBy === 'id')
                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                            key: 10,
                            id: props.row.id,
                            name: _ctx.affiliates[props.row.id] || '',
                            size: "20px",
                            color: "secondary",
                            icon: "account_box",
                            entity: "affiliate"
                          }, null, 8, ["id", "name"]))
                        : _createCommentVNode("", true),
                      (_ctx.filters.groupBy2)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 11,
                            class: "q-ml-sm",
                            size: "sm",
                            dense: "",
                            flat: "",
                            onClick: ($event: any) => (_ctx.onExpandRow(props.row)),
                            icon: 
                  this.expandedIds.includes(props.row.expandedId)
                    ? 'arrow_drop_up'
                    : 'arrow_drop_down'
                
                          }, null, 8, ["onClick", "icon"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('registrations'))
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: "registrations",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(props.row.registrations), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('conversions'))
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: "conversions",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(props.row.conversions), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('revenue'))
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: "revenue",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.revenue)), 1),
                    _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('usdRevenue'))
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: "usdRevenue",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.usdRevenue)), 1),
                    _cache[59] || (_cache[59] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('eurRevenue'))
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: "eurRevenue",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.eurRevenue)), 1),
                    _cache[60] || (_cache[60] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('uahRevenue'))
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: "uahRevenue",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.uahRevenue)), 1),
                    _cache[61] || (_cache[61] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('revenueHold'))
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: "revenueHold",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_11, [
                      _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.revenueHold)) + " ", 1),
                      _createElementVNode("small", null, [
                        _createTextVNode(_toDisplayString(_ctx.getCurrencyFromCookie()) + " ", 1),
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => _cache[62] || (_cache[62] = [
                            _createTextVNode("Холд")
                          ])),
                          _: 1
                        })
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('usdRevenueHold'))
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: "usdRevenueHold",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_12, [
                      _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.usdRevenueHold)) + " ", 1),
                      _createElementVNode("small", null, [
                        _cache[64] || (_cache[64] = _createTextVNode(_toDisplayString(' USD') + " ")),
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => _cache[63] || (_cache[63] = [
                            _createTextVNode("Холд")
                          ])),
                          _: 1
                        })
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('eurRevenueHold'))
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: "eurRevenueHold",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_13, [
                      _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.eurRevenueHold)) + " ", 1),
                      _createElementVNode("small", null, [
                        _cache[66] || (_cache[66] = _createTextVNode(_toDisplayString(' EUR') + " ")),
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => _cache[65] || (_cache[65] = [
                            _createTextVNode("Холд")
                          ])),
                          _: 1
                        })
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.pagination.visibleColumns.includes('uahRevenueHold'))
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: "uahRevenueHold",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_14, [
                      _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.uahRevenueHold)) + " ", 1),
                      _createElementVNode("small", null, [
                        _cache[68] || (_cache[68] = _createTextVNode(_toDisplayString(' UAH') + " ")),
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => _cache[67] || (_cache[67] = [
                            _createTextVNode("Холд")
                          ])),
                          _: 1
                        })
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["props", "style"]),
        (this.expandedIds.includes(props.row.expandedId))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.children, (child, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  _createVNode(_component_q_tr, {
                    style: {"background":"aliceblue"},
                    props: props
                  }, {
                    default: _withCtx(() => [
                      (_ctx.pagination.visibleColumns.includes('groupByField'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            props: props,
                            key: "groupByField"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_15, [
                                (_ctx.filters.groupBy2 === 'geoId')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                                      _createElementVNode("img", {
                                        style: {"user-select":"none","display":"initial"},
                                        class: "q-mr-xs",
                                        height: "10",
                                        width: "15",
                                        alt: _ctx.geos[props.row.children?.[index].geoId],
                                        src: 
                        `http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                          _ctx.geos[props.row.children?.[index].geoId]
                        }.svg`
                      
                                      }, null, 8, _hoisted_17),
                                      _createTextVNode(" " + _toDisplayString(_ctx.geos[props.row.children?.[index].geoId] || '??') + " ", 1),
                                      _createVNode(_component_q_tooltip, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(props.row.children?.[index].geoName || 'Неизвестно'), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.filters.groupBy2 === 'date')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.formatDateLikeChart(
                        _ctx.getGroupedField2('field', props.row.children?.[index]),
                      )), 1))
                                  : _createCommentVNode("", true),
                                (_ctx.filters.groupBy2 === 'week')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.getISOWeekName(
                        _ctx.getGroupedField2('field', props.row.children?.[index]),
                      )), 1))
                                  : _createCommentVNode("", true),
                                (_ctx.filters.groupBy2 === 'month')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.getISOMonthName(
                        _ctx.getGroupedField2('field', props.row.children?.[index]),
                      )), 1))
                                  : _createCommentVNode("", true),
                                (_ctx.filters.groupBy2 === 'campaignId')
                                  ? (_openBlock(), _createBlock(_component_IconWithName, {
                                      key: 4,
                                      id: props.row.children?.[index].campaignId,
                                      name: 
                      props.row.children?.[index].campaignName || 'Неизвестно'
                    ,
                                      size: "18px",
                                      color: "secondary",
                                      icon: "share",
                                      entity: "campaign"
                                    }, null, 8, ["id", "name"]))
                                  : _createCommentVNode("", true),
                                (_ctx.filters.groupBy2 === 'landingId')
                                  ? (_openBlock(), _createBlock(_component_IconWithName, {
                                      key: 5,
                                      id: props.row.children?.[index].landingId,
                                      name: 
                      props.row.children?.[index].landingName || 'Неизвестно'
                    ,
                                      size: "18px",
                                      color: "primary",
                                      icon: "airline_stops",
                                      entity: "landing"
                                    }, null, 8, ["id", "name"]))
                                  : _createCommentVNode("", true),
                                (_ctx.filters.groupBy2 === 'advertiserId')
                                  ? (_openBlock(), _createBlock(_component_IconWithName, {
                                      key: 6,
                                      id: props.row.children?.[index].advertiserId,
                                      name: 
                      props.row.children?.[index].advertiserName || 'Неизвестно'
                    ,
                                      size: "20px",
                                      color: "primary",
                                      icon: "people",
                                      entity: "advertiser"
                                    }, null, 8, ["id", "name"]))
                                  : _createCommentVNode("", true),
                                (_ctx.filters.groupBy2 === 'offerId')
                                  ? (_openBlock(), _createBlock(_component_IconWithName, {
                                      key: 7,
                                      id: props.row.children?.[index].offerId,
                                      name: 
                      _ctx.offers[props.row.children?.[index].offerId] ||
                        'Неизвестно'
                    ,
                                      size: "18px",
                                      color: "orange-7",
                                      icon: "description",
                                      entity: "offer"
                                    }, null, 8, ["id", "name"]))
                                  : _createCommentVNode("", true),
                                (_ctx.filters.groupBy2 === 'managerId')
                                  ? (_openBlock(), _createBlock(_component_IconWithName, {
                                      key: 8,
                                      id: props.row.children?.[index].managerId,
                                      name: 
                      props.row.children?.[index].managerEmail || 'Неизвестно'
                    ,
                                      size: "20px",
                                      color: "primary",
                                      icon: "manage_accounts",
                                      entity: "manager"
                                    }, null, 8, ["id", "name"]))
                                  : _createCommentVNode("", true),
                                (_ctx.filters.groupBy2 === 'affiliateId')
                                  ? (_openBlock(), _createBlock(_component_IconWithName, {
                                      key: 9,
                                      id: props.row.children?.[index].affiliateId,
                                      name: 
                      _ctx.affiliates[props.row.children?.[index].affiliateId] || ''
                    ,
                                      size: "20px",
                                      color: "secondary",
                                      icon: "account_box",
                                      entity: "affiliate",
                                      prevText: "Ref. "
                                    }, null, 8, ["id", "name"]))
                                  : _createCommentVNode("", true),
                                (_ctx.filters.groupBy3)
                                  ? (_openBlock(), _createBlock(_component_q_btn, {
                                      key: 10,
                                      class: "q-ml-sm",
                                      size: "sm",
                                      dense: "",
                                      flat: "",
                                      onClick: ($event: any) => (_ctx.onExpandRow(props.row.children?.[index])),
                                      icon: 
                      this.expandedIds.includes(
                        props.row.children?.[index].expandedId,
                      )
                        ? 'arrow_drop_up'
                        : 'arrow_drop_down'
                    
                                    }, null, 8, ["onClick", "icon"]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["props"]))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('registrations'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: "registrations",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].registrations), 1)
                            ]),
                            _: 2
                          }, 1032, ["props"]))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('conversions'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: "conversions",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].conversions), 1)
                            ]),
                            _: 2
                          }, 1032, ["props"]))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('revenue'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: "revenue",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].revenue)), 1),
                              _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                            ]),
                            _: 2
                          }, 1032, ["props"]))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('usdRevenue'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: "usdRevenue",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].usdRevenue)), 1),
                              _cache[69] || (_cache[69] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                            ]),
                            _: 2
                          }, 1032, ["props"]))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('eurRevenue'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: "eurRevenue",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].eurRevenue)), 1),
                              _cache[70] || (_cache[70] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                            ]),
                            _: 2
                          }, 1032, ["props"]))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('uahRevenue'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: "uahRevenue",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].uahRevenue)), 1),
                              _cache[71] || (_cache[71] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                            ]),
                            _: 2
                          }, 1032, ["props"]))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('revenueHold'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: "revenueHold",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_21, [
                                _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].revenueHold)) + " ", 1),
                                _createElementVNode("small", null, [
                                  _createTextVNode(_toDisplayString(_ctx.getCurrencyFromCookie()) + " ", 1),
                                  _createVNode(_component_q_tooltip, null, {
                                    default: _withCtx(() => _cache[72] || (_cache[72] = [
                                      _createTextVNode("Холд")
                                    ])),
                                    _: 1
                                  })
                                ])
                              ])
                            ]),
                            _: 2
                          }, 1032, ["props"]))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('usdRevenueHold'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: "usdRevenueHold",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_22, [
                                _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].usdRevenueHold)) + " ", 1),
                                _createElementVNode("small", null, [
                                  _cache[74] || (_cache[74] = _createTextVNode(_toDisplayString(' USD') + " ")),
                                  _createVNode(_component_q_tooltip, null, {
                                    default: _withCtx(() => _cache[73] || (_cache[73] = [
                                      _createTextVNode("Холд")
                                    ])),
                                    _: 1
                                  })
                                ])
                              ])
                            ]),
                            _: 2
                          }, 1032, ["props"]))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('eurRevenueHold'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: "eurRevenueHold",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_23, [
                                _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].eurRevenueHold)) + " ", 1),
                                _createElementVNode("small", null, [
                                  _cache[76] || (_cache[76] = _createTextVNode(_toDisplayString(' EUR') + " ")),
                                  _createVNode(_component_q_tooltip, null, {
                                    default: _withCtx(() => _cache[75] || (_cache[75] = [
                                      _createTextVNode("Холд")
                                    ])),
                                    _: 1
                                  })
                                ])
                              ])
                            ]),
                            _: 2
                          }, 1032, ["props"]))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('uahRevenueHold'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: "uahRevenueHold",
                            props: props
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_24, [
                                _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].uahRevenueHold)) + " ", 1),
                                _createElementVNode("small", null, [
                                  _cache[78] || (_cache[78] = _createTextVNode(_toDisplayString(' UAH') + " ")),
                                  _createVNode(_component_q_tooltip, null, {
                                    default: _withCtx(() => _cache[77] || (_cache[77] = [
                                      _createTextVNode("Холд")
                                    ])),
                                    _: 1
                                  })
                                ])
                              ])
                            ]),
                            _: 2
                          }, 1032, ["props"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["props"]),
                  (this.expandedIds.includes(child.expandedId))
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(child.children, (child2, index2) => {
                        return (_openBlock(), _createBlock(_component_q_tr, {
                          style: {"background":"#ffffde"},
                          props: props,
                          key: index2
                        }, {
                          default: _withCtx(() => [
                            (_ctx.pagination.visibleColumns.includes('groupByField'))
                              ? (_openBlock(), _createBlock(_component_q_td, {
                                  props: props,
                                  key: "groupByField",
                                  class: "text-right"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_25, [
                                      (_ctx.filters.groupBy3 === 'geoId')
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_26, [
                                            _createElementVNode("img", {
                                              style: {"user-select":"none","display":"initial"},
                                              class: "q-mr-xs",
                                              height: "10",
                                              width: "15",
                                              alt: 
                          _ctx.geos[
                            props.row.children?.[index].children?.[index2].geoId
                          ]
                        ,
                                              src: 
                          `http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                            _ctx.geos[
                              props.row.children?.[index].children?.[index2]
                                .geoId
                            ]
                          }.svg`
                        
                                            }, null, 8, _hoisted_27),
                                            _createTextVNode(" " + _toDisplayString(_ctx.geos[
                          props.row.children?.[index].children?.[index2].geoId
                        ] || '??') + " ", 1),
                                            _createVNode(_component_q_tooltip, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.geos[
                          props.row.children?.[index].children?.[index2].geoId
                        ] || 'Неизвестно'), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]))
                                        : _createCommentVNode("", true),
                                      (_ctx.filters.groupBy3 === 'date')
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_ctx.formatDateLikeChart(
                          _ctx.getGroupedField3(
                            'field',
                            props.row.children?.[index].children?.[index2],
                          ),
                        )), 1))
                                        : _createCommentVNode("", true),
                                      (_ctx.filters.groupBy3 === 'week')
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_ctx.getISOWeekName(
                          _ctx.getGroupedField3(
                            'field',
                            props.row.children?.[index].children?.[index2],
                          ),
                        )), 1))
                                        : _createCommentVNode("", true),
                                      (_ctx.filters.groupBy3 === 'month')
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_ctx.getISOMonthName(
                          _ctx.getGroupedField3(
                            'field',
                            props.row.children?.[index].children?.[index2],
                          ),
                        )), 1))
                                        : _createCommentVNode("", true),
                                      (_ctx.filters.groupBy3 === 'campaignId')
                                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                                            key: 4,
                                            id: 
                        props.row.children?.[index].children?.[index2]
                          .campaignId
                      ,
                                            name: 
                        props.row.children?.[index].children?.[index2]
                          .campaignName || 'Неизвестно'
                      ,
                                            size: "18px",
                                            color: "secondary",
                                            icon: "share",
                                            entity: "campaign"
                                          }, null, 8, ["id", "name"]))
                                        : _createCommentVNode("", true),
                                      (_ctx.filters.groupBy3 === 'landingId')
                                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                                            key: 5,
                                            id: 
                        props.row.children?.[index].children?.[index2].landingId
                      ,
                                            name: 
                        props.row.children?.[index].children?.[index2]
                          .landingName || 'Неизвестно'
                      ,
                                            size: "18px",
                                            color: "primary",
                                            icon: "airline_stops",
                                            entity: "landing"
                                          }, null, 8, ["id", "name"]))
                                        : _createCommentVNode("", true),
                                      (_ctx.filters.groupBy3 === 'advertiserId')
                                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                                            key: 6,
                                            id: 
                        props.row.children?.[index].children?.[index2]
                          .advertiserId
                      ,
                                            name: 
                        props.row.children?.[index].children?.[index2]
                          .advertiserName || 'Неизвестно'
                      ,
                                            size: "20px",
                                            color: "primary",
                                            icon: "people",
                                            entity: "advertiser"
                                          }, null, 8, ["id", "name"]))
                                        : _createCommentVNode("", true),
                                      (_ctx.filters.groupBy3 === 'offerId')
                                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                                            key: 7,
                                            id: 
                        props.row.children?.[index].children?.[index2].offerId
                      ,
                                            name: 
                        _ctx.offers[
                          props.row.children?.[index].children?.[index2].offerId
                        ] || 'Неизвестно'
                      ,
                                            size: "18px",
                                            color: "orange-7",
                                            icon: "description",
                                            entity: "offer"
                                          }, null, 8, ["id", "name"]))
                                        : _createCommentVNode("", true),
                                      (_ctx.filters.groupBy3 === 'managerId')
                                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                                            key: 8,
                                            id: 
                        props.row.children?.[index].children?.[index2].managerId
                      ,
                                            name: 
                        props.row.children?.[index].children?.[index2]
                          .managerEmail || 'Неизвестно'
                      ,
                                            size: "20px",
                                            color: "primary",
                                            icon: "manage_accounts",
                                            entity: "manager"
                                          }, null, 8, ["id", "name"]))
                                        : _createCommentVNode("", true),
                                      (_ctx.filters.groupBy3 === 'affiliateId')
                                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                                            key: 9,
                                            id: 
                        props.row.children?.[index].children?.[index2]
                          .affiliateId
                      ,
                                            name: 
                        _ctx.affiliates[
                          props.row.children?.[index].children?.[index2]
                            .affiliateId
                        ] || ''
                      ,
                                            size: "20px",
                                            color: "secondary",
                                            icon: "account_box",
                                            entity: "affiliate"
                                          }, null, 8, ["id", "name"]))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["props"]))
                              : _createCommentVNode("", true),
                            (_ctx.pagination.visibleColumns.includes('registrations'))
                              ? (_openBlock(), _createBlock(_component_q_td, {
                                  key: "registrations",
                                  props: props
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2]
                        .registrations), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["props"]))
                              : _createCommentVNode("", true),
                            (_ctx.pagination.visibleColumns.includes('conversions'))
                              ? (_openBlock(), _createBlock(_component_q_td, {
                                  key: "conversions",
                                  props: props
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2].conversions), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["props"]))
                              : _createCommentVNode("", true),
                            (_ctx.pagination.visibleColumns.includes('revenue'))
                              ? (_openBlock(), _createBlock(_component_q_td, {
                                  key: "revenue",
                                  props: props
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.formatMoney(
                      props.row.children?.[index].children?.[index2].revenue,
                    )), 1),
                                    _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["props"]))
                              : _createCommentVNode("", true),
                            (_ctx.pagination.visibleColumns.includes('usdRevenue'))
                              ? (_openBlock(), _createBlock(_component_q_td, {
                                  key: "usdRevenue",
                                  props: props
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.formatMoney(
                      props.row.children?.[index].children?.[index2].usdRevenue,
                    )), 1),
                                    _cache[79] || (_cache[79] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                                  ]),
                                  _: 2
                                }, 1032, ["props"]))
                              : _createCommentVNode("", true),
                            (_ctx.pagination.visibleColumns.includes('eurRevenue'))
                              ? (_openBlock(), _createBlock(_component_q_td, {
                                  key: "eurRevenue",
                                  props: props
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.formatMoney(
                      props.row.children?.[index].children?.[index2].eurRevenue,
                    )), 1),
                                    _cache[80] || (_cache[80] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                                  ]),
                                  _: 2
                                }, 1032, ["props"]))
                              : _createCommentVNode("", true),
                            (_ctx.pagination.visibleColumns.includes('uahRevenue'))
                              ? (_openBlock(), _createBlock(_component_q_td, {
                                  key: "uahRevenue",
                                  props: props
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.formatMoney(
                      props.row.children?.[index].children?.[index2].uahRevenue,
                    )), 1),
                                    _cache[81] || (_cache[81] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                                  ]),
                                  _: 2
                                }, 1032, ["props"]))
                              : _createCommentVNode("", true),
                            (_ctx.pagination.visibleColumns.includes('revenueHold'))
                              ? (_openBlock(), _createBlock(_component_q_td, {
                                  key: "revenueHold",
                                  props: props
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_31, [
                                      _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .revenueHold,
                      )) + " ", 1),
                                      _createElementVNode("small", null, [
                                        _createTextVNode(_toDisplayString(_ctx.getCurrencyFromCookie()) + " ", 1),
                                        _createVNode(_component_q_tooltip, null, {
                                          default: _withCtx(() => _cache[82] || (_cache[82] = [
                                            _createTextVNode("Холд")
                                          ])),
                                          _: 1
                                        })
                                      ])
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["props"]))
                              : _createCommentVNode("", true),
                            (_ctx.pagination.visibleColumns.includes('usdRevenueHold'))
                              ? (_openBlock(), _createBlock(_component_q_td, {
                                  key: "usdRevenueHold",
                                  props: props
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_32, [
                                      _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .usdRevenueHold,
                      )) + " ", 1),
                                      _createElementVNode("small", null, [
                                        _cache[84] || (_cache[84] = _createTextVNode(_toDisplayString(' USD') + " ")),
                                        _createVNode(_component_q_tooltip, null, {
                                          default: _withCtx(() => _cache[83] || (_cache[83] = [
                                            _createTextVNode("Холд")
                                          ])),
                                          _: 1
                                        })
                                      ])
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["props"]))
                              : _createCommentVNode("", true),
                            (_ctx.pagination.visibleColumns.includes('eurRevenueHold'))
                              ? (_openBlock(), _createBlock(_component_q_td, {
                                  key: "eurRevenueHold",
                                  props: props
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_33, [
                                      _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .eurRevenueHold,
                      )) + " ", 1),
                                      _createElementVNode("small", null, [
                                        _cache[86] || (_cache[86] = _createTextVNode(_toDisplayString(' EUR') + " ")),
                                        _createVNode(_component_q_tooltip, null, {
                                          default: _withCtx(() => _cache[85] || (_cache[85] = [
                                            _createTextVNode("Холд")
                                          ])),
                                          _: 1
                                        })
                                      ])
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["props"]))
                              : _createCommentVNode("", true),
                            (_ctx.pagination.visibleColumns.includes('uahRevenueHold'))
                              ? (_openBlock(), _createBlock(_component_q_td, {
                                  key: "uahRevenueHold",
                                  props: props
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_34, [
                                      _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .uahRevenueHold,
                      )) + " ", 1),
                                      _createElementVNode("small", null, [
                                        _cache[88] || (_cache[88] = _createTextVNode(_toDisplayString(' UAH') + " ")),
                                        _createVNode(_component_q_tooltip, null, {
                                          default: _withCtx(() => _cache[87] || (_cache[87] = [
                                            _createTextVNode("Холд")
                                          ])),
                                          _: 1
                                        })
                                      ])
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["props"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["props"]))
                      }), 128))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128)),
              _createVNode(_component_q_tr, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_td, { colspan: "100%" }, {
                    default: _withCtx(() => _cache[89] || (_cache[89] = [
                      _createElementVNode("hr", null, null, -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["props"])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.rows?.length)
        ? {
            name: "bottom-row",
            fn: _withCtx(() => [
              _createVNode(_component_q_tr, null, {
                default: _withCtx(() => [
                  (_ctx.pagination.visibleColumns.includes('groupByField'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 0,
                        class: "text-left border-top",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => _cache[90] || (_cache[90] = [
                          _createElementVNode("b", null, "TOTAL:", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.pagination.visibleColumns.includes('registrations'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 1,
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('registrations', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.pagination.visibleColumns.includes('conversions'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 2,
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('conversions', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.pagination.visibleColumns.includes('revenue'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 3,
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('revenue', 'sum'))), 1),
                              _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                            ])
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.pagination.visibleColumns.includes('usdRevenue'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 4,
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdRevenue', 'sum'))), 1),
                              _cache[91] || (_cache[91] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                            ])
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.pagination.visibleColumns.includes('eurRevenue'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 5,
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurRevenue', 'sum'))), 1),
                              _cache[92] || (_cache[92] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                            ])
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.pagination.visibleColumns.includes('uahRevenue'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 6,
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahRevenue', 'sum'))), 1),
                              _cache[93] || (_cache[93] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                            ])
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.pagination.visibleColumns.includes('revenueHold'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 7,
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", _hoisted_35, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('revenueHold', 'sum'))) + " ", 1),
                              _createElementVNode("small", null, [
                                _createTextVNode(_toDisplayString(_ctx.getCurrencyFromCookie()), 1),
                                _createVNode(_component_q_tooltip, null, {
                                  default: _withCtx(() => _cache[94] || (_cache[94] = [
                                    _createTextVNode("Холд")
                                  ])),
                                  _: 1
                                })
                              ])
                            ])
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.pagination.visibleColumns.includes('usdRevenueHold'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 8,
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", _hoisted_36, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdRevenueHold', 'sum'))) + " ", 1),
                              _createElementVNode("small", null, [
                                _cache[96] || (_cache[96] = _createTextVNode(_toDisplayString(' USD'))),
                                _createVNode(_component_q_tooltip, null, {
                                  default: _withCtx(() => _cache[95] || (_cache[95] = [
                                    _createTextVNode("Холд")
                                  ])),
                                  _: 1
                                })
                              ])
                            ])
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.pagination.visibleColumns.includes('eurRevenueHold'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 9,
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", _hoisted_37, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurRevenueHold', 'sum'))) + " ", 1),
                              _createElementVNode("small", null, [
                                _cache[98] || (_cache[98] = _createTextVNode(_toDisplayString(' EUR'))),
                                _createVNode(_component_q_tooltip, null, {
                                  default: _withCtx(() => _cache[97] || (_cache[97] = [
                                    _createTextVNode("Холд")
                                  ])),
                                  _: 1
                                })
                              ])
                            ])
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.pagination.visibleColumns.includes('uahRevenueHold'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 10,
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", _hoisted_38, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahRevenueHold', 'sum'))) + " ", 1),
                              _createElementVNode("small", null, [
                                _cache[100] || (_cache[100] = _createTextVNode(_toDisplayString(' UAH'))),
                                _createVNode(_component_q_tooltip, null, {
                                  default: _withCtx(() => _cache[99] || (_cache[99] = [
                                    _createTextVNode("Холд")
                                  ])),
                                  _: 1
                                })
                              ])
                            ])
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["rows", "columns", "pagination", "visible-columns", "loading", "onRequest"])
  ]))
}
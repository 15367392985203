import { formatDate, isDefined } from '@/utils'
import {
  // extractNumericValueFromFormattedString,
  getAffiliateBalancesApproved,
  getAffiliateBalancesHold,
} from '@/utils/affiliate'
import { Row } from '@/utils/request'
import { formatValue } from '@/utils'
import { BalanceType } from '@/enums/BalanceType'
import { Currency } from '@/enums/Currency'
import { BalanceValueMoney } from '@/store/types'

export function getColumnsForExport() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'center', sortable: true },
    {
      name: 'email',
      label: 'email',
      field: 'email',
      align: 'center',
      sortable: true,
    },
    {
      name: 'telegram',
      label: 'telegram',
      field: 'telegram',
      align: 'center',
      sortable: true,
    },
    {
      name: 'skype',
      label: 'skype',
      field: 'skype',
      align: 'center',
      sortable: true,
    },
    {
      name: 'comments',
      label: 'comments',
      field: 'comments',
      align: 'center',
      sortable: true,
    },
  ]
}

export function getColumns() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'center', sortable: true },
    { name: 'contacts', label: 'Email', field: 'contacts', align: 'left' },
    {
      name: 'createdAt',
      label: 'Дата регистрации',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'lastLoginDate',
      label: 'Дата последнего входа',
      field: 'lastLoginDate',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'clicks',
      label: 'Clicks',
      field: 'clicks',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
    },
    {
      name: 'clicksDifference',
      label: 'Δ Clicks',
      field: 'clicksDifference',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
    },
    {
      name: 'registrationCount',
      label: 'Regs',
      field: 'registrationCount',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
    },
    {
      name: 'regsDifference',
      label: 'Δ Regs',
      field: 'regsDifference',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
    },
    {
      name: 'firstDepositCount',
      label: 'FTD',
      field: 'firstDepositCount',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
    },
    {
      name: 'ftdDifference',
      label: 'Δ FTD',
      field: 'ftdDifference',
      align: 'right',
      sortable: true,
      classes: 'monospaced',
    },
    { name: 'markers', label: 'Маркеры', field: 'markers', align: 'left' },
    {
      name: 'managers',
      label: 'Менеджер',
      field: 'managers',
      align: 'left',
    },
    {
      name: 'referrals',
      label: 'Рефералы',
      field: 'referrals',
      align: 'right',
      classes: 'monospaced',
    },
    {
      name: 'campaignsCount',
      label: 'Кампании',
      field: 'campaignsCount',
      align: 'right',
      classes: 'monospaced',
    },
    {
      name: 'balancesApprove',
      label: 'Баланс Апрув',
      field: 'balancesApprove',
      classes: 'monospaced',
      align: 'right',
      sortable: true,
      format: (row: BalanceValueMoney[]) =>
        getAffiliateBalancesApproved(row || []),
    },
    {
      name: 'balancesHold',
      label: 'Баланс Холд',
      field: 'balancesHold',
      classes: 'monospaced',
      align: 'right',
      sortable: true,
      format: (row: BalanceValueMoney[]) => getAffiliateBalancesHold(row || []),
    },
    {
      name: 'mobileAppsBalances',
      label: 'Баланс по прилам',
      field: 'mobileAppsBalances',
      align: 'right',
      classes: 'monospaced',
      format: (val: Row[]) =>
        formatValue(val?.length ? val[0].valueAmount || 0.0 : 0.0) + ' usd',
    },
    {
      name: 'mobileAppsCount',
      label: 'Доступные приложения',
      field: 'mobileAppsCount',
      align: 'right',
      classes: 'monospaced',
    },
    {
      name: 'rules',
      label: 'Универсальный нейминг',
      field: 'rules',
      align: 'left',
    },
    {
      name: 'installValueAmount',
      label: 'Цена за инсталл',
      field: 'installValueAmount',
      align: 'right',
      classes: 'monospaced',
      format: (val: Row) => (val ? val + ' usd' : '—'),
    },
  ]
}

export function getReferralsColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата регистрации',
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'email',
      label: 'Аффилейт',
      field: 'email',
      align: 'center',
    },
    {
      name: 'ip',
      label: 'IP',
      field: 'ip',
      align: 'center',
      format: (val: any) => isDefined(val || null),
    },
    {
      name: 'approvedBalances',
      label: 'Профит Аппрув',
      field: 'approvedBalances',
      align: 'right',
    },
    {
      name: 'holdBalances',
      label: 'Профит Холд',
      field: 'holdBalances',
      align: 'right',
    },
    { name: 'actions', label: '', field: 'actions', align: 'center' },
  ]
}

export function getLoginLogsColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата входа',
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'ip',
      label: 'IP',
      field: 'ip',
      align: 'center',
    },
    {
      name: 'geo',
      label: 'Гео',
      field: 'geo',
      align: 'left',
    },
    {
      name: 'os',
      label: 'Операционная система',
      field: 'os',
      align: 'left',
      format: (val: any) => isDefined(val?.trim() || null),
    },
    {
      name: 'device',
      label: 'Устройство',
      field: 'device',
      align: 'left',
      format: (val: any) => isDefined(val?.trim() || null),
    },
    {
      name: 'browser',
      label: 'Браузер',
      field: 'browser',
      align: 'left',
      format: (val: any) => isDefined(val?.trim() || null),
    },
  ]
}

export function getManagerChangelogColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата изменения',
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'prevManagers',
      label: 'Предыдущий менеджер',
      field: 'prevManagers',
      align: 'center',
    },
    {
      name: 'managers',
      label: 'Новый менеджер',
      field: 'managers',
      align: 'left',
    },
    {
      name: 'createdBy',
      label: 'Кем изменено',
      field: 'createdBy',
      align: 'left',
    },
  ]
}

export function getBalanceChangelogColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата изменения',
      field: 'createdAt',
      align: 'center',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'balanceId',
      label: 'ID баланса',
      field: 'balanceId',
      align: 'center',
    },
    {
      name: 'type',
      label: 'Тип баланса',
      field: 'type',
      align: 'center',
      format: (val: number) => BalanceType[val],
    },
    {
      name: 'prevAmount',
      label: 'Предыдущее значение',
      field: 'prevAmount',
      align: 'right',
    },
    {
      name: 'prevCurrency',
      label: 'Валюта',
      field: 'prevCurrency',
      align: 'right',
      format: (val: number) => Currency[val],
    },
    {
      name: 'amount',
      label: 'Новое значение',
      field: 'amount',
      align: 'right',
    },
    {
      name: 'currency',
      label: 'Валюта',
      field: 'currency',
      align: 'right',
      format: (val: number) => Currency[val],
    },
    {
      name: 'createdBy',
      label: 'Кем изменено',
      field: 'createdBy',
      align: 'left',
    },
  ]
}

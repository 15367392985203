import { ColumnType } from '@/utils'

export function getColumns(): ColumnType[] {
  return [
    {
      name: 'affiliateEmail',
      label: 'Aff / Status',
      align: 'left',
      field: 'affiliateEmail',
    },
    {
      name: 'usdHoldSum',
      label: 'Hold, $',
      align: 'right',
      field: 'usdHoldSum',
    },
    {
      name: 'eurHoldSum',
      label: 'Hold, €',
      align: 'right',
      field: 'eurHoldSum',
    },
    {
      name: 'usdRejectedSum',
      label: 'Rejected, $',
      align: 'right',
      field: 'usdRejectedSum',
    },
    {
      name: 'eurRejectedSum',
      label: 'Rejected, €',
      align: 'right',
      field: 'eurRejectedSum',
    },
    {
      name: 'usdApprovedSum',
      label: 'Approved, $',
      align: 'right',
      field: 'usdApprovedSum',
    },
    {
      name: 'eurApprovedSum',
      label: 'Approved, €',
      align: 'right',
      field: 'eurApprovedSum',
    },
    {
      name: 'paySum',
      label: 'Paid',
      align: 'right',
      field: 'paySum',
    },
  ]
}

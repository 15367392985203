
import { defineComponent } from 'vue'
import Filters from '@/components/filters/AppsFilters.vue'
import { getDefaultFilters, getTableProperties } from '@/utils/request'
import { getBlackListLogColumns } from './columns'
import { request, Table, TableRequestProps } from '@/utils/request'
import { exportToCsv } from '@/utils/export'
import { FiltersType } from '@/utils/request'
import { showErrorMessageSnackBar } from '@/utils'
export default defineComponent({
  name: 'BlackListLogs',
  components: { Filters },
  mounted() {
    this.onRequest({ pagination: this.pagination })
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  data: function() {
    return {
      ...getTableProperties('createdAt', 50),
      filters: {
        ...getDefaultFilters(),
        showBlockGeos: false,
        appMarket: null,
      } as any,
      columns: getBlackListLogColumns(),
    }
  },
  methods: {
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      if (
        this.filters.mobileAppIds?.length > 1 ||
        this.filters.geoIds?.length > 1
      ) {
        showErrorMessageSnackBar(
          'Выбор нескольких приложений/гео не поддерживается. Выберите одно приложение/гео',
        )
        return
      }
      const options = { pagination: this.pagination, ...props }
      await request(options, this as Table, '/api/logs/cloak-installs')
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.pagination.page = 1
      this.filters = getDefaultFilters()
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    onExport() {
      exportToCsv(this.rows, this.columns)
    },
  },
})

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row q-mt-md" }
const _hoisted_2 = {
  key: 0,
  class: "col-12"
}
const _hoisted_3 = {
  key: 1,
  class: "col-12"
}
const _hoisted_4 = { class: "row items-center justify-between" }
const _hoisted_5 = {
  key: 2,
  class: "col-12"
}
const _hoisted_6 = { class: "row items-center justify-between" }
const _hoisted_7 = {
  key: 3,
  class: "col-12"
}
const _hoisted_8 = { class: "row items-center justify-between" }
const _hoisted_9 = {
  key: 4,
  class: "col-12"
}
const _hoisted_10 = { class: "row items-center justify-between" }
const _hoisted_11 = {
  key: 5,
  class: "col-12"
}
const _hoisted_12 = {
  key: 6,
  class: "col-12"
}
const _hoisted_13 = { class: "row items-center justify-between" }
const _hoisted_14 = {
  key: 7,
  class: "col-12"
}
const _hoisted_15 = { class: "row items-center justify-between" }
const _hoisted_16 = {
  key: 8,
  class: "col-12"
}
const _hoisted_17 = { class: "row items-center justify-between" }
const _hoisted_18 = {
  key: 9,
  class: "col-12"
}
const _hoisted_19 = { class: "row items-center justify-between" }
const _hoisted_20 = {
  key: 10,
  class: "col-12"
}
const _hoisted_21 = { class: "row items-center justify-between" }
const _hoisted_22 = {
  key: 11,
  class: "col-12"
}
const _hoisted_23 = { class: "row items-center justify-between" }
const _hoisted_24 = {
  key: 12,
  class: "col-12"
}
const _hoisted_25 = { class: "row items-center justify-between" }
const _hoisted_26 = {
  key: 13,
  class: "col-12"
}
const _hoisted_27 = { class: "row items-center justify-between" }
const _hoisted_28 = {
  key: 14,
  class: "col-12"
}
const _hoisted_29 = { class: "row items-center justify-between" }
const _hoisted_30 = {
  key: 15,
  class: "col-12"
}
const _hoisted_31 = { class: "row items-center justify-between" }
const _hoisted_32 = {
  key: 16,
  class: "col-12"
}
const _hoisted_33 = { class: "row items-center justify-between" }
const _hoisted_34 = {
  key: 17,
  class: "col-12"
}
const _hoisted_35 = {
  key: 18,
  class: "col-2"
}
const _hoisted_36 = { class: "row items-center justify-between" }
const _hoisted_37 = {
  key: 19,
  class: "col-12"
}
const _hoisted_38 = { class: "row items-center justify-between" }
const _hoisted_39 = {
  key: 20,
  class: "col-12"
}
const _hoisted_40 = { class: "row items-center justify-between" }
const _hoisted_41 = {
  key: 21,
  class: "col-12"
}
const _hoisted_42 = { class: "row items-center justify-between" }
const _hoisted_43 = {
  key: 22,
  class: "col-2"
}
const _hoisted_44 = { class: "row items-center justify-between" }
const _hoisted_45 = {
  key: 23,
  class: "col-12"
}
const _hoisted_46 = {
  key: 24,
  class: "col-12"
}
const _hoisted_47 = {
  key: 0,
  class: "self-center q-mt-md"
}
const _hoisted_48 = {
  key: 1,
  class: "self-center q-mt-md"
}
const _hoisted_49 = {
  key: 2,
  class: "self-center"
}
const _hoisted_50 = {
  key: 3,
  class: "self-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[76] || (_cache[76] = ($event: any) => ((_ctx.showDialog) = $event)),
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: "card q-pa-none",
        style: {"min-width":"350px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { className: "q-card__section q-card__section--vert q-px-lg row justify-between items-center" }, {
            default: _withCtx(() => [
              _cache[77] || (_cache[77] = _createElementVNode("div", { className: "text-h6" }, "Фильтры", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  icon: "close",
                  flat: "",
                  rounded: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { className: "q-pt-none q-form q-px-lg" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (
              _ctx.hideForWallets &&
                _ctx.hideForAffiliates &&
                _ctx.hideForStatistics &&
                _ctx.hideForAdvertisers &&
                _ctx.hideForOffers &&
                _ctx.hideForPayouts &&
                _ctx.hideForFinances
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _cache[78] || (_cache[78] = _createElementVNode("b", null, "Поиск:", -1)),
                      _createVNode(_component_q_input, {
                        outlined: "",
                        debounce: "500",
                        class: "col-12 q-mr-lg",
                        modelValue: _ctx.search,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event))
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                (
              !_ctx.hideForAffReports ||
                (_ctx.hideForWallets &&
                  _ctx.hideForFinancesReport &&
                  _ctx.hideForEditedOffers &&
                  _ctx.hideForNewOffers &&
                  _ctx.hideForAffiliates &&
                  _ctx.hideForPayouts &&
                  _ctx.hideForAdvertisers &&
                  _ctx.hideForStatistics &&
                  _ctx.hideForOffers)
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _cache[79] || (_cache[79] = _createElementVNode("b", null, "Оффер:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeOfferId,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.includeOfferId) = $event)),
                          "toggle-color": _ctx.filters.includeOfferId ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.offerId,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filters.offerId) = $event)),
                        onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onChangeFilters('offerId', _ctx.filters.offerId))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.offers,
                        onFilter: _ctx.getOffers,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForWallets &&
                _ctx.hideForFinancesReport &&
                _ctx.hideForEditedOffers &&
                _ctx.hideForNewOffers &&
                (!_ctx.hideForStatistics || !_ctx.hideForOffers)
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _cache[80] || (_cache[80] = _createElementVNode("b", null, "Оффер:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeOfferIds,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filters.includeOfferIds) = $event)),
                          "toggle-color": _ctx.filters.includeOfferIds ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.offerIds,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filters.offerIds) = $event)),
                        onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onChangeFilters('offerIds', _ctx.filters.offerIds))),
                        ref: "offerIds",
                        outlined: "",
                        multiple: "",
                        "use-chips": "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.offers,
                        onFilter: _ctx.getOffers,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (
              !_ctx.hideForEditedOffers ||
                !_ctx.hideForNewOffers ||
                (_ctx.hideForWallets &&
                  _ctx.hideForFinancesReport &&
                  _ctx.hideForOffers &&
                  _ctx.hideForAdvertisers &&
                  _ctx.hideForStatistics)
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _cache[81] || (_cache[81] = _createElementVNode("b", null, "Источник трафа:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeTrafficSourceId,
                          "onUpdate:modelValue": [
                            _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filters.includeTrafficSourceId) = $event)),
                            _cache[9] || (_cache[9] = ($event: any) => (
                  _ctx.onChangeFilters(
                    'includeTrafficSourceId',
                    _ctx.filters.includeTrafficSourceId ?? '',
                  )
                ))
                          ],
                          "toggle-color": 
                  _ctx.filters.includeTrafficSourceId ? 'positive' : 'red'
                ,
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        dense: "",
                        modelValue: _ctx.filters.trafficSourceNames,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.filters.trafficSourceNames) = $event)),
                        multiple: "",
                        onBlur: _cache[11] || (_cache[11] = ($event: any) => (
                _ctx.onChangeFilters(
                  'trafficSourceNames',
                  _ctx.filters.trafficSourceNames,
                )
              )),
                        onFilter: _cache[12] || (_cache[12] = (value, updateFn) => _ctx.getTrafficSources(value, updateFn)),
                        outlined: "",
                        "use-chips": "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.trafficSourceNames
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForWallets &&
                _ctx.hideForStatistics &&
                _ctx.hideForConversions &&
                _ctx.hideForPayouts &&
                _ctx.hideForAffiliates &&
                _ctx.hideForOffers &&
                _ctx.hideForAdvertisers &&
                _ctx.hideForFinances
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _cache[82] || (_cache[82] = _createElementVNode("b", null, "Поток:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeCampaignId,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.filters.includeCampaignId) = $event)),
                          "toggle-color": _ctx.filters.includeCampaignId ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.campaignId,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.filters.campaignId) = $event)),
                        onBlur: _cache[15] || (_cache[15] = ($event: any) => (_ctx.onChangeFilters('campaignId', _ctx.filters.campaignId))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.campaigns,
                        onFilter: _ctx.getCampaigns,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForWallets &&
                _ctx.hideForStatistics &&
                _ctx.hideForConversions &&
                _ctx.hideForPayouts &&
                _ctx.hideForAffiliates &&
                _ctx.hideForOffers &&
                _ctx.hideForAdvertisers &&
                _ctx.hideForCampaigns &&
                _ctx.hideForClickLogs &&
                _ctx.hideForFinances
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _cache[83] || (_cache[83] = _createElementVNode("b", null, "Событие:", -1)),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.eventType,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.filters.eventType) = $event)),
                        onBlur: _cache[17] || (_cache[17] = ($event: any) => (_ctx.onChangeFilters('eventType', _ctx.filters.eventType))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.postbackEventTypes,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              !_ctx.hideForEditedOffers ||
                !_ctx.hideForNewOffers ||
                (_ctx.hideForWallets &&
                  _ctx.hideForFinancesReport &&
                  _ctx.hideForOffers &&
                  _ctx.hideForAdvertisers &&
                  _ctx.hideForStatistics)
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _cache[84] || (_cache[84] = _createElementVNode("b", null, "Аффилейт:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeAffiliateId,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.filters.includeAffiliateId) = $event)),
                          "toggle-color": _ctx.filters.includeAffiliateId ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.affiliateId,
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.filters.affiliateId) = $event)),
                        onFilter: _ctx.getAffiliates,
                        onBlur: _cache[20] || (_cache[20] = ($event: any) => (_ctx.onChangeFilters('affiliateId', _ctx.filters.affiliateId))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.affiliates,
                        "option-value": "id",
                        "option-label": "email",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "onFilter", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.hideForStatistics || !_ctx.hideForFinances)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _cache[85] || (_cache[85] = _createElementVNode("b", null, "Аффилейт:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeAffiliateIds,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.filters.includeAffiliateIds) = $event)),
                          "toggle-color": _ctx.filters.includeAffiliateIds ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.affiliateIds,
                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.filters.affiliateIds) = $event)),
                        onFilter: _ctx.getAffiliates,
                        onBlur: _cache[23] || (_cache[23] = ($event: any) => (_ctx.onChangeFilters('affiliateIds', _ctx.filters.affiliateIds))),
                        ref: "affiliateIds",
                        outlined: "",
                        multiple: "",
                        "use-chips": "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.affiliates,
                        "option-value": "id",
                        "option-label": "email",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "onFilter", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              ((!_ctx.hideForStatistics && _ctx.tab !== 'referral-stats') ||
                (!_ctx.hideForCampaigns && _ctx.tab !== 'limits') ||
                !_ctx.hideForLogs) &&
                !_ctx.user?.isLimitedAccess
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _cache[86] || (_cache[86] = _createElementVNode("b", null, "Фантом. Афф:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          "model-value": _ctx.filters.includeFantomAffiliateIds,
                          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (
                  _ctx.onChangeFilters(
                    'includeFantomAffiliateIds',
                    _ctx.filters.includeFantomAffiliateIds ?? '',
                  )
                )),
                          "toggle-color": 
                  _ctx.filters.includeFantomAffiliateIds ? 'positive' : 'red'
                ,
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["model-value", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        dense: "",
                        modelValue: _ctx.filters.fantomAffiliateIds,
                        "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.filters.fantomAffiliateIds) = $event)),
                        onFilter: _ctx.getAffiliates,
                        onBlur: _cache[26] || (_cache[26] = ($event: any) => (
                _ctx.onChangeFilters(
                  'fantomAffiliateIds',
                  _ctx.filters.fantomAffiliateIds,
                )
              )),
                        outlined: "",
                        ref: "fantomAffiliateIds",
                        multiple: "",
                        "use-chips": "",
                        class: "col-4 q-mr-lg",
                        options: _ctx.affiliates,
                        "option-value": "id",
                        "option-label": "email",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, {
                        option: _withCtx((scope) => [
                          _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_IconWithName, {
                                    id: scope.opt.id,
                                    name: scope.opt.email,
                                    size: "20px",
                                    color: "secondary",
                                    icon: "account_box",
                                    entity: "affiliate"
                                  }, null, 8, ["id", "name"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1040)), [
                            [_directive_close_popup]
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "onFilter", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForEditedOffers &&
                _ctx.hideForNewOffers &&
                _ctx.hideForAffiliates &&
                _ctx.hideForPayouts &&
                _ctx.hideForLogs &&
                _ctx.hideForCampaigns &&
                _ctx.hideForFinances &&
                _ctx.hideForStatistics &&
                _ctx.hideForOffers
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _cache[87] || (_cache[87] = _createElementVNode("b", null, "Рекламодатель:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeAdvertiserId,
                          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.filters.includeAdvertiserId) = $event)),
                          "toggle-color": _ctx.filters.includeAdvertiserId ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.advertiserId,
                        "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.filters.advertiserId) = $event)),
                        onBlur: _cache[29] || (_cache[29] = ($event: any) => (_ctx.onChangeFilters('advertiserId', _ctx.filters.advertiserId))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.advertisers,
                        onFilter: _ctx.getAdvertisers,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForEditedOffers &&
                _ctx.hideForNewOffers &&
                (!_ctx.hideForFinancesReport || !_ctx.hideForStatistics || !_ctx.hideForOffers)
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _cache[88] || (_cache[88] = _createElementVNode("b", null, "Рекламодатель:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeAdvertiserIds,
                          "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.filters.includeAdvertiserIds) = $event)),
                          "toggle-color": 
                  _ctx.filters.includeAdvertiserIds ? 'positive' : 'red'
                ,
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.advertiserIds,
                        "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.filters.advertiserIds) = $event)),
                        onBlur: _cache[32] || (_cache[32] = ($event: any) => (_ctx.onChangeFilters('advertiserIds', _ctx.filters.advertiserIds))),
                        ref: "advertiserIds",
                        outlined: "",
                        multiple: "",
                        "use-chips": "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.advertisers,
                        onFilter: _ctx.getAdvertisers,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForWallets &&
                _ctx.hideForPayouts &&
                _ctx.hideForLogs &&
                _ctx.hideForAdvertisers &&
                _ctx.hideForStatistics &&
                _ctx.hideForOffers
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _cache[89] || (_cache[89] = _createElementVNode("b", null, "ГЕО:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeGeoId,
                          "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.filters.includeGeoId) = $event)),
                          "toggle-color": _ctx.filters.includeGeoId ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.geoId,
                        "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.filters.geoId) = $event)),
                        onBlur: _cache[35] || (_cache[35] = ($event: any) => (_ctx.onChangeFilters('geoId', _ctx.filters.geoId))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.geos,
                        onFilter: _ctx.getGeos,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.hideForStatistics || !_ctx.hideForOffers)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _cache[90] || (_cache[90] = _createElementVNode("b", null, "ГЕО:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeGeoIds,
                          "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.filters.includeGeoIds) = $event)),
                          "toggle-color": _ctx.filters.includeGeoIds ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.geoIds,
                        "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.filters.geoIds) = $event)),
                        onBlur: _cache[38] || (_cache[38] = ($event: any) => (_ctx.onChangeFilters('geoIds', _ctx.filters.geoIds))),
                        ref: "geoIds",
                        outlined: "",
                        multiple: "",
                        "use-chips": "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.geos,
                        onFilter: _ctx.getGeos,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForWallets &&
                _ctx.hideForPayouts &&
                _ctx.hideForLogs &&
                _ctx.hideForOffers &&
                _ctx.hideForAdvertisers &&
                _ctx.hideForCampaigns
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _cache[91] || (_cache[91] = _createElementVNode("b", null, "Афф. менеджер:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeManagerId,
                          "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.filters.includeManagerId) = $event)),
                          "toggle-color": _ctx.filters.includeManagerId ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        disable: true,
                        modelValue: _ctx.filters.managerId,
                        "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.filters.managerId) = $event)),
                        onBlur: _cache[41] || (_cache[41] = ($event: any) => (_ctx.onChangeFilters('managerId', _ctx.filters.managerId))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.admins,
                        onFilter: _ctx.getManagers,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForWallets &&
                _ctx.hideForEditedOffers &&
                _ctx.hideForNewOffers &&
                !_ctx.hideForConversions
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                      _createElementVNode("div", _hoisted_29, [
                        _cache[92] || (_cache[92] = _createElementVNode("b", null, "Статус:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeStatus,
                          "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.filters.includeStatus) = $event)),
                          "toggle-color": _ctx.filters.includeStatus ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        dense: "",
                        modelValue: _ctx.filters.status,
                        "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.filters.status) = $event)),
                        onBlur: _cache[44] || (_cache[44] = ($event: any) => (_ctx.onChangeFilters('status', _ctx.filters.status))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.statuses,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForWallets &&
                _ctx.hideForEditedOffers &&
                _ctx.hideForNewOffers &&
                _ctx.hideForStatistics &&
                _ctx.hideForPayouts &&
                _ctx.hideForLogs &&
                _ctx.hideForOffers &&
                _ctx.hideForAdvertisers &&
                _ctx.hideForAffiliates &&
                _ctx.hideForFinances
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, [
                        _cache[93] || (_cache[93] = _createElementVNode("b", null, "Статус:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeStatus,
                          "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.filters.includeStatus) = $event)),
                          "toggle-color": _ctx.filters.includeStatus ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.status,
                        "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.filters.status) = $event)),
                        onBlur: _cache[47] || (_cache[47] = ($event: any) => (_ctx.onChangeFilters('status', _ctx.filters.status))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.campaignStatuses,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForWallets &&
                _ctx.hideForEditedOffers &&
                _ctx.hideForNewOffers &&
                _ctx.hideForStatistics &&
                _ctx.hideForPayouts &&
                _ctx.hideForLogs &&
                _ctx.hideForAffiliates &&
                _ctx.hideForAdvertisers &&
                _ctx.hideForCampaigns &&
                _ctx.hideForFinances
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                      _createElementVNode("div", _hoisted_33, [
                        _cache[94] || (_cache[94] = _createElementVNode("b", null, "Статус:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeStatus,
                          "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.filters.includeStatus) = $event)),
                          "toggle-color": _ctx.filters.includeStatus ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.status,
                        "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.filters.status) = $event)),
                        onBlur: _cache[50] || (_cache[50] = ($event: any) => (_ctx.onChangeFilters('status', _ctx.filters.status))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.offerStatuses,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                false
                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                      _cache[95] || (_cache[95] = _createElementVNode("b", null, "Вертикаль:", -1)),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.vertical,
                        "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.filters.vertical) = $event)),
                        onBlur: _cache[52] || (_cache[52] = ($event: any) => (_ctx.onChangeFilters('vertical', _ctx.filters.vertical))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.verticals,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForWallets &&
                _ctx.hideForEditedOffers &&
                _ctx.hideForNewOffers &&
                !_ctx.hideForOffers
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                      _createElementVNode("div", _hoisted_36, [
                        _cache[96] || (_cache[96] = _createElementVNode("b", null, "Категория:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeOfferCategoryId,
                          "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => ((_ctx.filters.includeOfferCategoryId) = $event)),
                          "toggle-color": 
                  _ctx.filters.includeOfferCategoryId ? 'positive' : 'red'
                ,
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.offerCategoryId,
                        "onUpdate:modelValue": _cache[54] || (_cache[54] = ($event: any) => ((_ctx.filters.offerCategoryId) = $event)),
                        onBlur: _cache[55] || (_cache[55] = ($event: any) => (
                _ctx.onChangeFilters('offerCategoryId', _ctx.filters.offerCategoryId)
              )),
                        outlined: "",
                        class: "col-4 q-mr-lg",
                        options: _ctx.categories,
                        onFilter: _ctx.getOfferCategories,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForWallets &&
                _ctx.hideForAffiliates &&
                _ctx.hideForPayouts &&
                _ctx.hideForLogs &&
                _ctx.hideForOffers &&
                _ctx.hideForAdvertisers &&
                _ctx.hideForCampaigns &&
                _ctx.hideForFinances
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                      _createElementVNode("div", _hoisted_38, [
                        _cache[97] || (_cache[97] = _createElementVNode("b", null, "Тип конверсии:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeRewardType,
                          "onUpdate:modelValue": _cache[56] || (_cache[56] = ($event: any) => ((_ctx.filters.includeRewardType) = $event)),
                          "toggle-color": _ctx.filters.includeRewardType ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.rewardType,
                        "onUpdate:modelValue": _cache[57] || (_cache[57] = ($event: any) => ((_ctx.filters.rewardType) = $event)),
                        onBlur: _cache[58] || (_cache[58] = ($event: any) => (_ctx.onChangeFilters('rewardType', _ctx.filters.rewardType))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.rewardTypes,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForWallets &&
                _ctx.hideForAffiliates &&
                _ctx.hideForPayouts &&
                _ctx.hideForLogs &&
                _ctx.hideForOffers &&
                _ctx.hideForAdvertisers &&
                _ctx.hideForCampaigns &&
                _ctx.hideForFinances &&
                !_ctx.user.showStatisticsOnly
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                      _createElementVNode("div", _hoisted_40, [
                        _cache[98] || (_cache[98] = _createElementVNode("b", null, "Лендинг:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeLandingId,
                          "onUpdate:modelValue": _cache[59] || (_cache[59] = ($event: any) => ((_ctx.filters.includeLandingId) = $event)),
                          "toggle-color": _ctx.filters.includeLandingId ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.landingId,
                        "onUpdate:modelValue": _cache[60] || (_cache[60] = ($event: any) => ((_ctx.filters.landingId) = $event)),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.promos,
                        onBlur: _cache[61] || (_cache[61] = ($event: any) => (_ctx.onChangeFilters('landingId', _ctx.filters.landingId))),
                        onFilter: _cache[62] || (_cache[62] = 
                (value, updateFn) =>
                  _ctx.getOptionsByField(value, updateFn, 'promos')
              ),
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForWallets &&
                _ctx.hideForEditedOffers &&
                _ctx.hideForNewOffers &&
                _ctx.hideForAffiliates &&
                _ctx.hideForPayouts &&
                _ctx.hideForLogs &&
                _ctx.hideForOffers &&
                _ctx.hideForAdvertisers &&
                _ctx.hideForCampaigns &&
                _ctx.hideForFinances
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                      _createElementVNode("div", _hoisted_42, [
                        _cache[99] || (_cache[99] = _createElementVNode("b", null, "Категория:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeCategoryId,
                          "onUpdate:modelValue": _cache[63] || (_cache[63] = ($event: any) => ((_ctx.filters.includeCategoryId) = $event)),
                          "toggle-color": _ctx.filters.includeCategoryId ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.categoryId,
                        "onUpdate:modelValue": _cache[64] || (_cache[64] = ($event: any) => ((_ctx.filters.categoryId) = $event)),
                        outlined: "",
                        disable: _ctx.filters.vertical === undefined,
                        class: "col-12 q-mr-lg",
                        options: _ctx.categories,
                        onBlur: _cache[65] || (_cache[65] = ($event: any) => (_ctx.onChangeFilters('categoryId', _ctx.filters.categoryId))),
                        onFilter: _ctx.getCategories,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "disable", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hideForFinancesReport && !_ctx.hideForAffiliates)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                      _createElementVNode("div", _hoisted_44, [
                        _cache[100] || (_cache[100] = _createElementVNode("b", null, "Маркер:", -1)),
                        _createVNode(_component_q_btn_toggle, {
                          class: "q-ml-sm filter-btn-toggle",
                          unelevated: "",
                          size: "xs",
                          modelValue: _ctx.filters.includeMarkerId,
                          "onUpdate:modelValue": _cache[66] || (_cache[66] = ($event: any) => ((_ctx.filters.includeMarkerId) = $event)),
                          "toggle-color": _ctx.filters.includeMarkerId ? 'positive' : 'red',
                          options: [
                  { label: 'IS', value: true },
                  { label: 'IS NOT', value: false },
                ]
                        }, null, 8, ["modelValue", "toggle-color"])
                      ]),
                      _createVNode(_component_q_select, {
                        dense: "",
                        modelValue: _ctx.filters.markerId,
                        "onUpdate:modelValue": _cache[67] || (_cache[67] = ($event: any) => ((_ctx.filters.markerId) = $event)),
                        onBlur: _cache[68] || (_cache[68] = ($event: any) => (_ctx.onChangeFilters('markers', _ctx.filters.markerId))),
                        outlined: "",
                        class: "col-4 q-mr-lg",
                        options: _ctx.markers,
                        onFilter: _cache[69] || (_cache[69] = 
                (value, updateFn) =>
                  _ctx.getOptionsByField(value, updateFn, 'markers')
              ),
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForWallets &&
                _ctx.hideForFinancesReport &&
                _ctx.hideForConversions &&
                _ctx.hideForAffiliates &&
                _ctx.hideForPayouts &&
                _ctx.hideForLogs &&
                _ctx.hideForOffers &&
                _ctx.hideForAdvertisers &&
                _ctx.hideForCampaigns
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                      _cache[101] || (_cache[101] = _createElementVNode("b", null, "Группировать по:", -1)),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.groupBy,
                        "onUpdate:modelValue": _cache[70] || (_cache[70] = ($event: any) => ((_ctx.filters.groupBy) = $event)),
                        outlined: "",
                        onBlur: _cache[71] || (_cache[71] = ($event: any) => (_ctx.onChangeFilters('groupBy', _ctx.filters.groupBy))),
                        class: "col-12 q-mr-lg",
                        options: _ctx.groupByOptions,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hideForWallets && _ctx.hideForOffers)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                      _cache[102] || (_cache[102] = _createElementVNode("b", null, "Дата:", -1)),
                      _createVNode(_component_DatePicker, {
                        onChangeDate: _ctx.onChangeDate,
                        from: _ctx.filters.dateFrom,
                        to: _ctx.filters.dateTo,
                        id: "1"
                      }, null, 8, ["onChangeDate", "from", "to"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (!_ctx.hideForConversions)
                ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                    _createVNode(_component_q_checkbox, {
                      label: "Подробнее",
                      "model-value": _ctx.filters.isDetailing,
                      onClick: _cache[72] || (_cache[72] = ($event: any) => (_ctx.onChangeFilters('isDetailing', _ctx.filters.isDetailing ?? '')))
                    }, null, 8, ["model-value"])
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.hideForCampaigns && _ctx.tab !== 'limits' && !_ctx.user?.isLimitedAccess)
                ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                    _createVNode(_component_q_checkbox, {
                      label: "Фантом",
                      "model-value": _ctx.filters.fantomAffiliateOnly,
                      onClick: _cache[73] || (_cache[73] = ($event: any) => (
              _ctx.onChangeFilters(
                'fantomAffiliateOnly',
                _ctx.filters.fantomAffiliateOnly ?? '',
              )
            ))
                    }, null, 8, ["model-value"])
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.hideForStatistics && _ctx.hideForConversions)
                ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                    _createVNode(_component_q_toggle, {
                      modelValue: _ctx.filters.factualStats,
                      "onUpdate:modelValue": _cache[74] || (_cache[74] = ($event: any) => ((_ctx.filters.factualStats) = $event)),
                      color: "positive",
                      label: "Фактическая статистика"
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.hideForStatistics && _ctx.hideForConversions)
                ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                    _createVNode(_component_q_toggle, {
                      modelValue: _ctx.filters.hideChart,
                      "onUpdate:modelValue": _cache[75] || (_cache[75] = ($event: any) => ((_ctx.filters.hideChart) = $event)),
                      color: "dark",
                      label: "Скрыть график"
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, {
            align: "center",
            class: "flex column text-primary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                disable: _ctx.loading,
                loading: _ctx.loading,
                "no-caps": "",
                rounded: "",
                color: "primary",
                label: "Добавить",
                onClick: _ctx.addFilters
              }, null, 8, ["disable", "loading", "onClick"]),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                class: "reset-btn q-mt-md",
                label: "Скинуть",
                onClick: _ctx.resetFilters
              }, null, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row mobile-display-block q-mx-md" }
const _hoisted_2 = { class: "col-sm-10 col-lg-3 q-mr-md q-mx-md" }
const _hoisted_3 = { class: "col-sm-10 col-lg-3 q-mr-md q-mx-md" }
const _hoisted_4 = { class: "col-sm-10 col-lg-2 q-mr-md q-mx-md" }
const _hoisted_5 = { class: "self-center" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "row justify-center" }
const _hoisted_9 = { class: "self-center q-mr-md" }
const _hoisted_10 = { class: "self-center" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_page = _resolveComponent("q-page")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl" }, {
    default: _withCtx(() => [
      _createVNode(_component_center, null, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createElementVNode("h6", null, "Доступы к офферам", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[8] || (_cache[8] = _createElementVNode("b", null, "Оффер", -1)),
          _createVNode(_component_q_select, {
            dense: "",
            modelValue: _ctx.access.offerId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.access.offerId) = $event)),
            outlined: "",
            class: "col-5",
            options: _ctx.offers,
            onFilter: _ctx.getOffers,
            "option-value": "id",
            "option-label": "name",
            "emit-value": "",
            "map-options": "",
            "use-input": "",
            error: !!_ctx.errors?.offerId,
            "error-message": _ctx.errors?.offerId
          }, {
            option: _withCtx((scope) => [
              _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_IconWithName, {
                        id: scope.opt.id,
                        name: scope.opt.name,
                        size: "20px",
                        color: "orange-7",
                        icon: "description",
                        entity: "offer"
                      }, null, 8, ["id", "name"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1040)), [
                [_directive_close_popup]
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "options", "onFilter", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[9] || (_cache[9] = _createElementVNode("b", null, "Аффилейт:", -1)),
          _createVNode(_component_q_select, {
            dense: "",
            modelValue: _ctx.access.affiliateId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.access.affiliateId) = $event)),
            onFilter: _ctx.getAffiliates,
            outlined: "",
            "use-input": "",
            options: _ctx.affiliates,
            "option-value": "id",
            "option-label": "email",
            "emit-value": "",
            "map-options": "",
            error: !!_ctx.errors?.affiliateId,
            "error-message": _ctx.errors?.affiliateId
          }, {
            option: _withCtx((scope) => [
              _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_IconWithName, {
                        id: scope.opt.id,
                        name: scope.opt.email,
                        size: "20px",
                        color: "secondary",
                        icon: "account_box",
                        entity: "affiliate"
                      }, null, 8, ["id", "name"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1040)), [
                [_directive_close_popup]
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "onFilter", "options", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[10] || (_cache[10] = _createElementVNode("b", null, "Статус", -1)),
          _createVNode(_component_q_select, {
            dense: "",
            modelValue: _ctx.access.status,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.access.status) = $event)),
            disable: !_ctx.access.offerId || !_ctx.access.affiliateId,
            outlined: "",
            options: _ctx.statuses,
            "option-value": "id",
            "option-label": "name",
            "emit-value": "",
            "map-options": "",
            error: !!_ctx.errors?.status,
            "error-message": _ctx.errors?.status
          }, null, 8, ["modelValue", "disable", "options", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_q_btn, {
            "no-caps": "",
            label: "Сохранить",
            color: "primary",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeStatus(_ctx.access)))
          })
        ])
      ]),
      _cache[11] || (_cache[11] = _createElementVNode("h6", { class: "q-mx-md" }, "Запросы на доступ", -1)),
      _createVNode(_component_q_btn_toggle, {
        modelValue: _ctx.showLogs,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showLogs) = $event)),
        class: "q-mb-md my-custom-toggle q-mx-md",
        "no-caps": "",
        rounded: "",
        unelevated: "",
        "toggle-color": "primary",
        color: "white",
        "text-color": "primary",
        options: [
        { label: 'Активные запросы', value: false },
        { label: 'История запросов', value: true },
      ]
      }, null, 8, ["modelValue"]),
      (!_ctx.showLogs)
        ? (_openBlock(), _createBlock(_component_q_table, {
            key: 0,
            class: "q-mx-md",
            rows: _ctx.rows,
            columns: _ctx.columns,
            "row-key": "id",
            separator: "cell",
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pagination) = $event)),
            loading: _ctx.loading,
            onRequest: _ctx.onRequest,
            "binary-state-sort": ""
          }, {
            "body-cell-affiliateEmail": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconWithName, {
                    id: props.row.affiliateId,
                    name: props.row.affiliateEmail,
                    size: "20px",
                    color: "secondary",
                    icon: "account_box",
                    entity: "affiliate"
                  }, null, 8, ["id", "name"])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-offerName": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconWithName, {
                    id: props.row.offerId,
                    name: props.row.offerName,
                    size: "20px",
                    color: "orange-7",
                    icon: "description",
                    entity: "offer"
                  }, null, 8, ["id", "name"])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-managers": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (props.row.managers?.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.managers, (m, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            _createVNode(_component_IconWithName, {
                              name: m.emails?.[0] || '—',
                              size: "20px",
                              color: "primary",
                              icon: "manage_accounts",
                              entity: "manager"
                            }, null, 8, ["name"])
                          ]))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, "—"))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-actions": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_q_btn, {
                        "no-caps": "",
                        label: "Дать доступ",
                        color: "primary",
                        onClick: ($event: any) => (_ctx.changeStatus({ ...props.row, status: _ctx.status.Opened }))
                      }, null, 8, ["onClick"])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_q_btn, {
                        "no-caps": "",
                        outline: "",
                        color: "primary",
                        label: "Отклонить",
                        onClick: ($event: any) => (_ctx.changeStatus({ ...props.row, status: _ctx.status.Closed }))
                      }, null, 8, ["onClick"])
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 1
          }, 8, ["rows", "columns", "pagination", "loading", "onRequest"]))
        : (_openBlock(), _createBlock(_component_q_table, {
            key: 1,
            class: "q-mx-md",
            rows: _ctx.rows,
            columns: _ctx.columns,
            "row-key": "id",
            separator: "cell",
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.pagination) = $event)),
            loading: _ctx.loading,
            onRequest: _ctx.onRequestLogs,
            "binary-state-sort": ""
          }, {
            "body-cell-affiliateEmail": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconWithName, {
                    id: props.row.affiliateId,
                    name: _ctx.user?.notFullAccess ? '' : props.row.affiliateEmail,
                    size: "20px",
                    color: "secondary",
                    icon: "account_box",
                    entity: "affiliate"
                  }, null, 8, ["id", "name"])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-offerName": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconWithName, {
                    id: props.row.offerId,
                    name: props.row.offerName,
                    size: "20px",
                    color: "orange-7",
                    icon: "description",
                    entity: "offer"
                  }, null, 8, ["id", "name"])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-managerEmail": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (props.row.managerId)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createVNode(_component_q_td, { props: props }, {
                          default: _withCtx(() => [
                            _createVNode(_component_IconWithName, {
                              id: props.row.managerId,
                              name: props.row.managerEmail,
                              size: "20px",
                              color: "primary",
                              icon: "manage_accounts",
                              entity: "manager"
                            }, null, 8, ["id", "name"])
                          ]),
                          _: 2
                        }, 1032, ["props"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_12, "—"))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-status": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (props.row.status === _ctx.status.Opened)
                    ? (_openBlock(), _createBlock(_component_q_badge, {
                        key: 0,
                        color: "positive"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.status[props.row.status]), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (props.row.status === _ctx.status.Rejected)
                    ? (_openBlock(), _createBlock(_component_q_badge, {
                        key: 1,
                        color: "red"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.status[props.row.status]), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (props.row.status === _ctx.status.Closed)
                    ? (_openBlock(), _createBlock(_component_q_badge, {
                        key: 2,
                        color: "orange"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.status[props.row.status]), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-updatedByEmail": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconWithName, {
                    id: props.row.updatedById,
                    name: props.row.updatedByEmail,
                    size: "20px",
                    color: "primary",
                    icon: "manage_accounts",
                    entity: "manager"
                  }, null, 8, ["id", "name"])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 1
          }, 8, ["rows", "columns", "pagination", "loading", "onRequest"]))
    ]),
    _: 1
  }))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showDialog) = $event)),
    onKeyup: _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"min-width":"350px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createElementVNode("div", { class: "text-h6" }, "Редактирование конверсии", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pb-none" }, {
            default: _withCtx(() => [
              _cache[11] || (_cache[11] = _createElementVNode("b", null, "ID конверсий", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.changedLeads, (row, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  _createVNode(_component_q_input, {
                    readonly: "",
                    "model-value": row.id
                  }, null, 8, ["model-value"])
                ]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pb-none" }, {
            default: _withCtx(() => [
              _cache[12] || (_cache[12] = _createElementVNode("b", null, "Статус", -1)),
              _createVNode(_component_q_select, {
                disable: _ctx.isSaving,
                outlined: "",
                dense: "",
                class: "wide-select",
                modelValue: _ctx.lead.status,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.lead.status) = $event)),
                options: _ctx.statuses,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "error-message": _ctx.errors?.message,
                error: !!_ctx.errors?.message
              }, null, 8, ["disable", "modelValue", "options", "error-message", "error"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-py-none" }, {
            default: _withCtx(() => [
              _cache[13] || (_cache[13] = _createElementVNode("b", null, "Тип конверсии", -1)),
              _createVNode(_component_q_select, {
                disable: _ctx.isSaving,
                dense: "",
                modelValue: _ctx.lead.type,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lead.type) = $event)),
                outlined: "",
                class: "wide-select",
                options: _ctx.rewardTypes,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": ""
              }, null, 8, ["disable", "modelValue", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pb-none" }, {
            default: _withCtx(() => [
              _cache[14] || (_cache[14] = _createElementVNode("b", null, "ГЕО", -1)),
              _createVNode(_component_q_select, {
                disable: _ctx.isSaving,
                dense: "",
                modelValue: _ctx.lead.geoId,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.lead.geoId) = $event)),
                outlined: "",
                clearable: "",
                options: _ctx.geos,
                onFilter: _ctx.getGeos,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["disable", "modelValue", "options", "onFilter"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pb-none" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _cache[15] || (_cache[15] = _createElementVNode("b", null, "Выплата веба", -1)),
                _createVNode(_component_q_input, {
                  disable: _ctx.isSaving,
                  dense: "",
                  outlined: "",
                  type: "number",
                  min: "0",
                  "model-value": _ctx.lead.amount,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = 
              value =>
                _ctx.updateField({
                  ..._ctx.lead,
                  amount: Number(value) || 0,
                })
            )
                }, null, 8, ["disable", "model-value"])
              ]),
              _createElementVNode("div", null, [
                _cache[16] || (_cache[16] = _createElementVNode("b", null, "Валюта", -1)),
                _createVNode(_component_q_select, {
                  disable: _ctx.isSaving,
                  outlined: "",
                  dense: "",
                  "model-value": _ctx.lead.currency,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = 
              currency =>
                _ctx.updateField({
                  ..._ctx.lead,
                  currency,
                })
            ),
                  options: _ctx.currencies,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["disable", "model-value", "options"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pb-none" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _cache[17] || (_cache[17] = _createElementVNode("b", null, "Выплата от рекла", -1)),
                _createVNode(_component_q_input, {
                  disable: _ctx.isSaving,
                  dense: "",
                  outlined: "",
                  type: "number",
                  min: "0",
                  "model-value": _ctx.lead.advertiserAmount,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = 
              value =>
                _ctx.updateField({
                  ..._ctx.lead,
                  advertiserAmount: Number(value) || 0,
                })
            )
                }, null, 8, ["disable", "model-value"])
              ]),
              _createElementVNode("div", null, [
                _cache[18] || (_cache[18] = _createElementVNode("b", null, "Валюта", -1)),
                _createVNode(_component_q_select, {
                  disable: _ctx.isSaving,
                  outlined: "",
                  dense: "",
                  "model-value": _ctx.lead.advertiserCurrency,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = 
              advertiserCurrency =>
                _ctx.updateField({
                  ..._ctx.lead,
                  advertiserCurrency,
                })
            ),
                  options: _ctx.currencies,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["disable", "model-value", "options"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [
              _createElementVNode("div", null, " ❗️ Балансы аффилейта обновляются АВТОМАТИЧЕСКИ, но после сохранения ОБЯЗАТЕЛЬНО проверьте балансы ❗️ ", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_q_card_actions, {
            align: "right",
            class: "text-primary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                flat: "",
                label: "Отменить",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('close'))),
                disable: _ctx.isSaving
              }, null, 8, ["disable"]),
              _createVNode(_component_q_btn, {
                flat: "",
                label: "Сохранить",
                onClick: _ctx.save,
                loading: _ctx.isSaving,
                disable: _ctx.isSaving
              }, null, 8, ["onClick", "loading", "disable"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "q-mb-lg" }
const _hoisted_2 = { class: "q-ml-sm text-grey-8" }
const _hoisted_3 = { class: "q-ml-sm text-grey-8" }
const _hoisted_4 = { class: "q-ml-sm text-grey-8" }
const _hoisted_5 = { class: "q-ml-sm text-grey-8" }
const _hoisted_6 = { class: "q-ml-sm text-grey-8" }
const _hoisted_7 = {
  class: "row",
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_8 = {
  key: 0,
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_9 = ["alt", "src"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { key: 3 }
const _hoisted_13 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_14 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_15 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_16 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  key: 1,
  class: "q-ml-sm text-grey-8"
}
const _hoisted_19 = { key: 2 }
const _hoisted_20 = {
  class: "row",
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_21 = {
  key: 0,
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_22 = ["alt", "src"]
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { key: 2 }
const _hoisted_25 = { key: 3 }
const _hoisted_26 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_27 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_28 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_29 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_30 = { key: 0 }
const _hoisted_31 = {
  key: 1,
  class: "q-ml-sm text-grey-8"
}
const _hoisted_32 = { key: 2 }
const _hoisted_33 = {
  class: "row justify-end",
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_34 = {
  key: 0,
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_35 = ["alt", "src"]
const _hoisted_36 = { key: 1 }
const _hoisted_37 = { key: 2 }
const _hoisted_38 = { key: 3 }
const _hoisted_39 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_40 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_41 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_42 = {
  class: "q-ml-sm text-grey-8",
  style: {"width":"150px"}
}
const _hoisted_43 = { key: 0 }
const _hoisted_44 = {
  key: 1,
  class: "q-ml-sm text-grey-8"
}
const _hoisted_45 = { key: 2 }
const _hoisted_46 = { class: "q-ml-sm text-grey-8" }
const _hoisted_47 = { class: "q-ml-sm text-grey-8" }
const _hoisted_48 = { class: "q-ml-sm text-grey-8" }
const _hoisted_49 = { class: "q-ml-sm text-grey-8" }
const _hoisted_50 = { class: "q-ml-sm text-grey-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl" }, {
    default: _withCtx(() => [
      _createVNode(_component_center, null, {
        default: _withCtx(() => _cache[58] || (_cache[58] = [
          _createElementVNode("h6", null, "FIN TOTAL", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Filters, {
          onExport: _ctx.onExport,
          onReset: _ctx.resetFilters,
          onChange: _ctx.onChangeFilters,
          onChangeAll: _ctx.changeAll,
          "current-filters": _ctx.complexFilters
        }, null, 8, ["onExport", "onReset", "onChange", "onChangeAll", "current-filters"]),
        (!_ctx.filters.hideChart)
          ? (_openBlock(), _createBlock(_component_LineChart, {
              key: 0,
              rows: _ctx.chartData,
              groupBy: _ctx.filters.groupBy,
              allColumns: _ctx.columns,
              columns: _ctx.pagination.selectedColumns,
              loading: _ctx.chartLoading,
              groupByOptions: _ctx.getGroupByArray()
            }, null, 8, ["rows", "groupBy", "allColumns", "columns", "loading", "groupByOptions"]))
          : _createCommentVNode("", true),
        _createVNode(_component_q_table, {
          dense: "",
          "column-sort-order": "da",
          class: "q-mx-md",
          rows: _ctx.rows,
          columns: _ctx.columns,
          "row-key": "id",
          separator: "cell",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[57] || (_cache[57] = ($event: any) => ((_ctx.pagination) = $event)),
          "visible-columns": _ctx.pagination.visibleColumns,
          loading: _ctx.loading,
          onRequest: _ctx.onRequest,
          onRowClick: _ctx.onRowClick,
          "binary-state-sort": ""
        }, _createSlots({
          top: _withCtx(() => [
            _createVNode(_component_q_select, {
              modelValue: _ctx.pagination.visibleColumns,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination.visibleColumns) = $event)),
              multiple: "",
              outlined: "",
              dense: "",
              "options-dense": "",
              "display-value": "Колонки",
              "emit-value": "",
              "map-options": "",
              options: _ctx.columns,
              "option-value": "name",
              "option-label": "label",
              "options-cover": "",
              style: {"min-width":"150px"}
            }, null, 8, ["modelValue", "options"]),
            _createVNode(_component_q_btn, {
              "no-caps": "",
              class: "q-ml-md",
              icon: "autorenew",
              color: "red",
              label: "Сбросить",
              size: "13px",
              onClick: _ctx.resetColumns,
              outline: ""
            }, null, 8, ["onClick"])
          ]),
          header: _withCtx((props) => [
            _createVNode(_component_q_tr, null, {
              default: _withCtx(() => [
                (_ctx.pagination.visibleColumns.includes('groupByField'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: "groupByField",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getGroupedField('name')), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('registrations'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "registrations",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.registrations,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.registrations) = $event)),
                          val: "xs",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeSelected('registrations')))
                        }, null, 8, ["modelValue"]),
                        _cache[59] || (_cache[59] = _createTextVNode("Регистрации"))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('conversions'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "conversions",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.conversions,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.conversions) = $event)),
                          val: "xs",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeSelected('conversions')))
                        }, null, 8, ["modelValue"]),
                        _cache[60] || (_cache[60] = _createTextVNode("Conv Count"))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('paySum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "paySum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.paySum,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.paySum) = $event)),
                          val: "xs",
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeSelected('paySum')))
                        }, null, 8, ["modelValue"]),
                        _cache[63] || (_cache[63] = _createTextVNode("PAY SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[62] || (_cache[62] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[61] || (_cache[61] = [
                              _createTextVNode("Сумма в базовой валюте аккаунта по курсу на 1 число месяца")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdPaySum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "usdPaySum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.usdPaySum,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.usdPaySum) = $event)),
                          val: "xs",
                          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.changeSelected('usdPaySum')))
                        }, null, 8, ["modelValue"]),
                        _cache[66] || (_cache[66] = _createTextVNode("Approve USD SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[65] || (_cache[65] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[64] || (_cache[64] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurPaySum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "eurPaySum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.eurPaySum,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.eurPaySum) = $event)),
                          val: "xs",
                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.changeSelected('eurPaySum')))
                        }, null, 8, ["modelValue"]),
                        _cache[69] || (_cache[69] = _createTextVNode("Approve EUR SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[68] || (_cache[68] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[67] || (_cache[67] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahPaySum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "uahPaySum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.uahPaySum,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.uahPaySum) = $event)),
                          val: "xs",
                          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.changeSelected('uahPaySum')))
                        }, null, 8, ["modelValue"]),
                        _cache[72] || (_cache[72] = _createTextVNode("Approve UAH SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[71] || (_cache[71] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[70] || (_cache[70] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('partners'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "partners",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.partners,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.partners) = $event)),
                          val: "xs",
                          onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.changeSelected('partners')))
                        }, null, 8, ["modelValue"]),
                        _createTextVNode(_toDisplayString(_ctx.filters.groupBy === 'date' ? 'DAU' : 'WAU'), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('approvedSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "approvedSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.approvedSum,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.approvedSum) = $event)),
                          val: "xs",
                          onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.changeSelected('approvedSum')))
                        }, null, 8, ["modelValue"]),
                        _cache[75] || (_cache[75] = _createTextVNode("Approve SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[74] || (_cache[74] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[73] || (_cache[73] = [
                              _createTextVNode("Сумма в базовой валюте аккаунта по курсу на 1 число месяца")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdApprovedSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "usdApprovedSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.usdApprovedSum,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.usdApprovedSum) = $event)),
                          val: "xs",
                          onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.changeSelected('usdApprovedSum')))
                        }, null, 8, ["modelValue"]),
                        _cache[78] || (_cache[78] = _createTextVNode("Approve USD SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[77] || (_cache[77] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[76] || (_cache[76] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurApprovedSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "eurApprovedSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.eurApprovedSum,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.eurApprovedSum) = $event)),
                          val: "xs",
                          onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.changeSelected('eurApprovedSum')))
                        }, null, 8, ["modelValue"]),
                        _cache[81] || (_cache[81] = _createTextVNode("Approve EUR SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[80] || (_cache[80] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[79] || (_cache[79] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahApprovedSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "uahApprovedSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.uahApprovedSum,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.uahApprovedSum) = $event)),
                          val: "xs",
                          onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.changeSelected('uahApprovedSum')))
                        }, null, 8, ["modelValue"]),
                        _cache[84] || (_cache[84] = _createTextVNode("Approve UAH SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[83] || (_cache[83] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[82] || (_cache[82] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('holdSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "holdSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.holdSum,
                          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.holdSum) = $event)),
                          val: "xs",
                          onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.changeSelected('holdSum')))
                        }, null, 8, ["modelValue"]),
                        _cache[87] || (_cache[87] = _createTextVNode("Hold SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[86] || (_cache[86] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[85] || (_cache[85] = [
                              _createTextVNode("Сумма в базовой валюте аккаунта по курсу на 1 число месяца")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdHoldSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "usdHoldSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.usdHoldSum,
                          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.usdHoldSum) = $event)),
                          val: "xs",
                          onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.changeSelected('usdHoldSum')))
                        }, null, 8, ["modelValue"]),
                        _cache[90] || (_cache[90] = _createTextVNode("Hold USD SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[89] || (_cache[89] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[88] || (_cache[88] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurHoldSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "eurHoldSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.eurHoldSum,
                          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.eurHoldSum) = $event)),
                          val: "xs",
                          onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.changeSelected('eurHoldSum')))
                        }, null, 8, ["modelValue"]),
                        _cache[93] || (_cache[93] = _createTextVNode("Hold EUR SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[92] || (_cache[92] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[91] || (_cache[91] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahHoldSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "uahHoldSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.uahHoldSum,
                          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.uahHoldSum) = $event)),
                          val: "xs",
                          onClick: _cache[30] || (_cache[30] = ($event: any) => (_ctx.changeSelected('uahHoldSum')))
                        }, null, 8, ["modelValue"]),
                        _cache[96] || (_cache[96] = _createTextVNode("Hold UAH SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[95] || (_cache[95] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[94] || (_cache[94] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('rejectedSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "rejectedSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.rejectedSum,
                          "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.rejectedSum) = $event)),
                          val: "xs",
                          onClick: _cache[32] || (_cache[32] = ($event: any) => (_ctx.changeSelected('rejectedSum')))
                        }, null, 8, ["modelValue"]),
                        _cache[99] || (_cache[99] = _createTextVNode("Reject SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[98] || (_cache[98] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[97] || (_cache[97] = [
                              _createTextVNode("Сумма в базовой валюте аккаунта по курсу на 1 число месяца")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('revenue'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "revenue",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.revenue,
                          "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.revenue) = $event)),
                          val: "xs",
                          onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.changeSelected('revenue')))
                        }, null, 8, ["modelValue"]),
                        _cache[102] || (_cache[102] = _createTextVNode("Profit SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[101] || (_cache[101] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[100] || (_cache[100] = [
                              _createTextVNode("Сумма в базовой валюте аккаунта по курсу на 1 число месяца")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdRevenue'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "usdRevenue",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.usdRevenue,
                          "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.usdRevenue) = $event)),
                          val: "xs",
                          onClick: _cache[36] || (_cache[36] = ($event: any) => (_ctx.changeSelected('usdRevenue')))
                        }, null, 8, ["modelValue"]),
                        _cache[105] || (_cache[105] = _createTextVNode("Profit USD SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[104] || (_cache[104] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[103] || (_cache[103] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurRevenue'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "eurRevenue",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.eurRevenue,
                          "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.eurRevenue) = $event)),
                          val: "xs",
                          onClick: _cache[38] || (_cache[38] = ($event: any) => (_ctx.changeSelected('eurRevenue')))
                        }, null, 8, ["modelValue"]),
                        _cache[108] || (_cache[108] = _createTextVNode("Profit EUR SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[107] || (_cache[107] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[106] || (_cache[106] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahRevenue'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "uahRevenue",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.uahRevenue,
                          "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.uahRevenue) = $event)),
                          val: "xs",
                          onClick: _cache[40] || (_cache[40] = ($event: any) => (_ctx.changeSelected('uahRevenue')))
                        }, null, 8, ["modelValue"]),
                        _cache[111] || (_cache[111] = _createTextVNode("Profit UAH SUM ")),
                        _createElementVNode("sup", null, [
                          _cache[110] || (_cache[110] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[109] || (_cache[109] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('revenueHold'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "revenueHold",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.revenueHold,
                          "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.revenueHold) = $event)),
                          val: "xs",
                          onClick: _cache[42] || (_cache[42] = ($event: any) => (_ctx.changeSelected('revenueHold')))
                        }, null, 8, ["modelValue"]),
                        _cache[114] || (_cache[114] = _createTextVNode("Profit SUM HOLD ")),
                        _createElementVNode("sup", null, [
                          _cache[113] || (_cache[113] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[112] || (_cache[112] = [
                              _createTextVNode("Сумма в базовой валюте аккаунта по курсу на 1 число месяца")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdRevenueHold'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "usdRevenueHold",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.usdRevenueHold,
                          "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.usdRevenueHold) = $event)),
                          val: "xs",
                          onClick: _cache[44] || (_cache[44] = ($event: any) => (_ctx.changeSelected('usdRevenueHold')))
                        }, null, 8, ["modelValue"]),
                        _cache[117] || (_cache[117] = _createTextVNode("Profit USD SUM HOLD ")),
                        _createElementVNode("sup", null, [
                          _cache[116] || (_cache[116] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[115] || (_cache[115] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurRevenueHold'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "eurRevenueHold",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.eurRevenueHold,
                          "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.eurRevenueHold) = $event)),
                          val: "xs",
                          onClick: _cache[46] || (_cache[46] = ($event: any) => (_ctx.changeSelected('eurRevenueHold')))
                        }, null, 8, ["modelValue"]),
                        _cache[120] || (_cache[120] = _createTextVNode("Profit EUR SUM HOLD ")),
                        _createElementVNode("sup", null, [
                          _cache[119] || (_cache[119] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[118] || (_cache[118] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahRevenueHold'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "uahRevenueHold",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.uahRevenueHold,
                          "onUpdate:modelValue": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.uahRevenueHold) = $event)),
                          val: "xs",
                          onClick: _cache[48] || (_cache[48] = ($event: any) => (_ctx.changeSelected('uahRevenueHold')))
                        }, null, 8, ["modelValue"]),
                        _cache[123] || (_cache[123] = _createTextVNode("Profit UAH SUM HOLD ")),
                        _createElementVNode("sup", null, [
                          _cache[122] || (_cache[122] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[121] || (_cache[121] = [
                              _createTextVNode("Сумма в оригинальной валюте оффера")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('revenuePercent'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "revenuePercent",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.revenuePercent,
                          "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.revenuePercent) = $event)),
                          val: "xs",
                          onClick: _cache[50] || (_cache[50] = ($event: any) => (_ctx.changeSelected('revenuePercent')))
                        }, null, 8, ["modelValue"]),
                        _cache[124] || (_cache[124] = _createTextVNode("Profit, %"))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('advHold'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "advHold",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.advHold,
                          "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.advHold) = $event)),
                          val: "xs",
                          onClick: _cache[52] || (_cache[52] = ($event: any) => (_ctx.changeSelected('advHold')))
                        }, null, 8, ["modelValue"]),
                        _cache[127] || (_cache[127] = _createTextVNode("Adv Hold ")),
                        _createElementVNode("sup", null, [
                          _cache[126] || (_cache[126] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[125] || (_cache[125] = [
                              _createTextVNode("Сумма в базовой валюте аккаунта по курсу на 1 число месяца")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('advApprove'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "advApprove",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.advApprove,
                          "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => ((_ctx.advApprove) = $event)),
                          val: "xs",
                          onClick: _cache[54] || (_cache[54] = ($event: any) => (_ctx.changeSelected('advApprove')))
                        }, null, 8, ["modelValue"]),
                        _cache[130] || (_cache[130] = _createTextVNode("Adv Approve ")),
                        _createElementVNode("sup", null, [
                          _cache[129] || (_cache[129] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[128] || (_cache[128] = [
                              _createTextVNode("Сумма в базовой валюте аккаунта по курсу на 1 число месяца")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('revenueTotal'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                      key: "revenueTotal",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          size: "xs",
                          modelValue: _ctx.revenueTotal,
                          "onUpdate:modelValue": _cache[55] || (_cache[55] = ($event: any) => ((_ctx.revenueTotal) = $event)),
                          val: "xs",
                          onClick: _cache[56] || (_cache[56] = ($event: any) => (_ctx.changeSelected('revenueTotal')))
                        }, null, 8, ["modelValue"]),
                        _cache[133] || (_cache[133] = _createTextVNode("Revenue Total ")),
                        _createElementVNode("sup", null, [
                          _cache[132] || (_cache[132] = _createTextVNode("?")),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => _cache[131] || (_cache[131] = [
                              _createTextVNode("Сумма в базовой валюте аккаунта по курсу на 1 число месяца")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_tr, null, {
              default: _withCtx(() => [
                (_ctx.pagination.visibleColumns.includes('groupByField'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 0,
                      class: "text-left border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => _cache[134] || (_cache[134] = [
                        _createElementVNode("b", null, "TOTAL:", -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('registrations'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 1,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, _toDisplayString(_ctx.total('registrations', 'sum')), 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('conversions'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 2,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, _toDisplayString(_ctx.total('conversions', 'sum')), 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('paySum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 3,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('paySum', 'sum'))), 1),
                            _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdPaySum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 4,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdPaySum', 'sum'))), 1),
                            _cache[135] || (_cache[135] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurPaySum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 5,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurPaySum', 'sum'))), 1),
                            _cache[136] || (_cache[136] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahPaySum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 6,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahPaySum', 'sum'))), 1),
                            _cache[137] || (_cache[137] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                _ctx.pagination.visibleColumns.includes('partners') &&
                  ['date', 'week', 'month'].includes(_ctx.filters.groupBy)
              )
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 7,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, _toDisplayString(_ctx.total('partners', 'avg')), 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('approvedSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 8,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('approvedSum', 'sum'))), 1),
                            _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdApprovedSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 9,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdApprovedSum', 'sum'))), 1),
                            _cache[138] || (_cache[138] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurApprovedSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 10,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurApprovedSum', 'sum'))), 1),
                            _cache[139] || (_cache[139] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahApprovedSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 11,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahApprovedSum', 'sum'))), 1),
                            _cache[140] || (_cache[140] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('holdSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 12,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('holdSum', 'sum'))), 1),
                            _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdHoldSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 13,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdHoldSum', 'sum'))), 1),
                            _cache[141] || (_cache[141] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurHoldSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 14,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurHoldSum', 'sum'))), 1),
                            _cache[142] || (_cache[142] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahHoldSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 15,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahHoldSum', 'sum'))), 1),
                            _cache[143] || (_cache[143] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('rejectedSum'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 16,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('rejectedSum', 'sum'))), 1),
                            _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('revenue'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 17,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('revenue', 'sum'))), 1),
                            _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdRevenue'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 18,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdRevenue', 'sum'))), 1),
                            _cache[144] || (_cache[144] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurRevenue'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 19,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurRevenue', 'sum'))), 1),
                            _cache[145] || (_cache[145] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahRevenue'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 20,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahRevenue', 'sum'))), 1),
                            _cache[146] || (_cache[146] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('revenueHold'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 21,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", _hoisted_2, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('revenueHold', 'sum'))) + " ", 1),
                            _createElementVNode("small", null, [
                              _createTextVNode(_toDisplayString(_ctx.getCurrencyFromCookie()), 1),
                              _createVNode(_component_q_tooltip, null, {
                                default: _withCtx(() => _cache[147] || (_cache[147] = [
                                  _createTextVNode("Холд")
                                ])),
                                _: 1
                              })
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdRevenueHold'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 22,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", _hoisted_3, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdRevenueHold', 'sum'))) + " ", 1),
                            _createElementVNode("small", null, [
                              _cache[149] || (_cache[149] = _createTextVNode(_toDisplayString(' USD'))),
                              _createVNode(_component_q_tooltip, null, {
                                default: _withCtx(() => _cache[148] || (_cache[148] = [
                                  _createTextVNode("Холд")
                                ])),
                                _: 1
                              })
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurRevenueHold'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 23,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", _hoisted_4, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurRevenueHold', 'sum'))) + " ", 1),
                            _createElementVNode("small", null, [
                              _cache[151] || (_cache[151] = _createTextVNode(_toDisplayString(' EUR'))),
                              _createVNode(_component_q_tooltip, null, {
                                default: _withCtx(() => _cache[150] || (_cache[150] = [
                                  _createTextVNode("Холд")
                                ])),
                                _: 1
                              })
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahRevenueHold'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 24,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", _hoisted_5, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahRevenueHold', 'sum'))) + " ", 1),
                            _createElementVNode("small", null, [
                              _cache[153] || (_cache[153] = _createTextVNode(_toDisplayString(' UAH'))),
                              _createVNode(_component_q_tooltip, null, {
                                default: _withCtx(() => _cache[152] || (_cache[152] = [
                                  _createTextVNode("Холд")
                                ])),
                                _: 1
                              })
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('revenuePercent'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 25,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", null, [
                            _createElementVNode("b", null, _toDisplayString((
                        (Number(_ctx.total('revenue', 'sum')) /
                          (Number(_ctx.total('approvedSum', 'sum')) +
                            Number(_ctx.total('revenue', 'sum')))) *
                          100 || 0
                      ).toFixed(2)) + " %", 1)
                          ]),
                          _createElementVNode("span", _hoisted_6, [
                            _createElementVNode("small", null, [
                              _createTextVNode(" (" + _toDisplayString((
                        (Number(_ctx.total('revenueHold', 'sum')) /
                          (Number(_ctx.total('holdSum', 'sum')) +
                            Number(_ctx.total('revenueHold', 'sum')))) *
                          100 || 0
                      ).toFixed(2)) + " %)", 1),
                              _createVNode(_component_q_tooltip, null, {
                                default: _withCtx(() => _cache[154] || (_cache[154] = [
                                  _createTextVNode("Холд")
                                ])),
                                _: 1
                              })
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('advHold'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 26,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('advHold', 'sum'))), 1),
                            _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('advApprove'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 27,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('advApprove', 'sum'))), 1),
                            _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('revenueTotal'))
                  ? (_openBlock(), _createBlock(_component_q_th, {
                      key: 28,
                      class: "text-right border-bottom monospaced",
                      colspan: "1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('revenueTotal', 'sum'))), 1),
                            _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          body: _withCtx((props) => [
            _createVNode(_component_q_tr, {
              props: props,
              style: _normalizeStyle(
              this.expandedIds.includes(props.row.id)
                ? 'background: #def3b4'
                : ''
            )
            }, {
              default: _withCtx(() => [
                (_ctx.pagination.visibleColumns.includes('groupByField'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      props: props,
                      key: "groupByField"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_7, [
                          (_ctx.filters.groupBy === 'geoId')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                                _createElementVNode("img", {
                                  style: {"user-select":"none","display":"initial"},
                                  class: "q-mr-xs",
                                  height: "10",
                                  width: "15",
                                  alt: props.row.geoCode,
                                  src: 
                      `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.geoCode}.svg`
                    
                                }, null, 8, _hoisted_9),
                                _createTextVNode(" " + _toDisplayString(props.row.geoCode || '??') + " ", 1),
                                _createVNode(_component_q_tooltip, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(props.row.geoName || 'Неизвестно'), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.filters.groupBy === 'date')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.formatDateLikeChart(_ctx.getGroupedField('field', props.row))), 1))
                            : _createCommentVNode("", true),
                          (_ctx.filters.groupBy === 'week')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.getISOWeekName(_ctx.getGroupedField('field', props.row))), 1))
                            : _createCommentVNode("", true),
                          (_ctx.filters.groupBy === 'month')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.getISOMonthName(_ctx.getGroupedField('field', props.row))), 1))
                            : _createCommentVNode("", true),
                          (_ctx.filters.groupBy === 'campaignId')
                            ? (_openBlock(), _createBlock(_component_IconWithName, {
                                key: 4,
                                id: props.row.campaignId,
                                name: props.row.campaignName || 'Неизвестно',
                                size: "18px",
                                color: "secondary",
                                icon: "share",
                                entity: "campaign"
                              }, null, 8, ["id", "name"]))
                            : _createCommentVNode("", true),
                          (_ctx.filters.groupBy === 'landingId')
                            ? (_openBlock(), _createBlock(_component_IconWithName, {
                                key: 5,
                                id: props.row.landingId,
                                name: props.row.landingName || 'Неизвестно',
                                size: "18px",
                                color: "primary",
                                icon: "airline_stops",
                                entity: "landing"
                              }, null, 8, ["id", "name"]))
                            : _createCommentVNode("", true),
                          (_ctx.filters.groupBy === 'advertiserId')
                            ? (_openBlock(), _createBlock(_component_IconWithName, {
                                key: 6,
                                id: props.row.advertiserId,
                                name: props.row.advertiserName || 'Неизвестно',
                                size: "20px",
                                color: "primary",
                                icon: "people",
                                entity: "advertiser"
                              }, null, 8, ["id", "name"]))
                            : _createCommentVNode("", true),
                          (_ctx.filters.groupBy === 'offerId')
                            ? (_openBlock(), _createBlock(_component_IconWithName, {
                                key: 7,
                                id: props.row.offerId,
                                name: props.row.offerName || 'Неизвестно',
                                size: "18px",
                                color: "orange-7",
                                icon: "description",
                                entity: "offer"
                              }, null, 8, ["id", "name"]))
                            : _createCommentVNode("", true),
                          (_ctx.filters.groupBy === 'managerId')
                            ? (_openBlock(), _createBlock(_component_IconWithName, {
                                key: 8,
                                id: props.row.managerId,
                                name: props.row.managerEmail || 'Неизвестно',
                                size: "20px",
                                color: "primary",
                                icon: "manage_accounts",
                                entity: "manager"
                              }, null, 8, ["id", "name"]))
                            : _createCommentVNode("", true),
                          (_ctx.filters.groupBy === 'affiliateId')
                            ? (_openBlock(), _createBlock(_component_IconWithName, {
                                key: 9,
                                id: props.row.affiliateId,
                                name: props.row.affiliateEmail || '',
                                size: "20px",
                                color: "secondary",
                                icon: "account_box",
                                entity: "affiliate"
                              }, null, 8, ["id", "name"]))
                            : _createCommentVNode("", true),
                          (_ctx.filters.groupBy2)
                            ? (_openBlock(), _createBlock(_component_q_btn, {
                                key: 10,
                                class: "q-ml-sm",
                                size: "sm",
                                dense: "",
                                flat: "",
                                onClick: ($event: any) => (_ctx.onExpandRow(props.row)),
                                icon: 
                    this.expandedIds.includes(props.row.id)
                      ? 'arrow_drop_up'
                      : 'arrow_drop_down'
                  
                              }, null, 8, ["onClick", "icon"]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('registrations'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "registrations",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, _toDisplayString(props.row.registrations), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('conversions'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "conversions",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, _toDisplayString(props.row.conversions), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('paySum'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "paySum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.paySum)), 1),
                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdPaySum'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "usdPaySum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.usdPaySum)), 1),
                        _cache[155] || (_cache[155] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurPaySum'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "eurPaySum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.eurPaySum)), 1),
                        _cache[156] || (_cache[156] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahPaySum'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "uahPaySum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.uahPaySum)), 1),
                        _cache[157] || (_cache[157] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (
                (_ctx.filters.groupBy === 'date' ||
                  _ctx.filters.groupBy === 'week' ||
                  _ctx.filters.groupBy === 'month') &&
                  _ctx.pagination.visibleColumns.includes('partners')
              )
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "partners",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, _toDisplayString(props.row.partners), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('approvedSum'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "approvedSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.approvedSum)), 1),
                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdApprovedSum'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "usdApprovedSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.usdApprovedSum)), 1),
                        _cache[158] || (_cache[158] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurApprovedSum'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "eurApprovedSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.eurApprovedSum)), 1),
                        _cache[159] || (_cache[159] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahApprovedSum'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "uahApprovedSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.uahApprovedSum)), 1),
                        _cache[160] || (_cache[160] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('holdSum'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "holdSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.holdSum)), 1),
                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdHoldSum'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "usdHoldSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.usdHoldSum)), 1),
                        _cache[161] || (_cache[161] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurHoldSum'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "eurHoldSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.eurHoldSum)), 1),
                        _cache[162] || (_cache[162] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahHoldSum'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "uahHoldSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.uahHoldSum)), 1),
                        _cache[163] || (_cache[163] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('rejectedSum'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "rejectedSum",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.rejectedSum)), 1),
                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('revenue'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "revenue",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.revenue)), 1),
                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdRevenue'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "usdRevenue",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.usdRevenue)), 1),
                        _cache[164] || (_cache[164] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurRevenue'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "eurRevenue",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.eurRevenue)), 1),
                        _cache[165] || (_cache[165] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahRevenue'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "uahRevenue",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.uahRevenue)), 1),
                        _cache[166] || (_cache[166] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('revenueHold'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "revenueHold",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_13, [
                          _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.revenueHold)) + " ", 1),
                          _createElementVNode("small", null, [
                            _createTextVNode(_toDisplayString(_ctx.getCurrencyFromCookie()) + " ", 1),
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => _cache[167] || (_cache[167] = [
                                _createTextVNode("Холд")
                              ])),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('usdRevenueHold'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "usdRevenueHold",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_14, [
                          _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.usdRevenueHold)) + " ", 1),
                          _createElementVNode("small", null, [
                            _cache[169] || (_cache[169] = _createTextVNode(_toDisplayString(' USD') + " ")),
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => _cache[168] || (_cache[168] = [
                                _createTextVNode("Холд")
                              ])),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('eurRevenueHold'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "eurRevenueHold",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_15, [
                          _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.eurRevenueHold)) + " ", 1),
                          _createElementVNode("small", null, [
                            _cache[171] || (_cache[171] = _createTextVNode(_toDisplayString(' EUR') + " ")),
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => _cache[170] || (_cache[170] = [
                                _createTextVNode("Холд")
                              ])),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('uahRevenueHold'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "uahRevenueHold",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_16, [
                          _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.uahRevenueHold)) + " ", 1),
                          _createElementVNode("small", null, [
                            _cache[173] || (_cache[173] = _createTextVNode(_toDisplayString(' UAH') + " ")),
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => _cache[172] || (_cache[172] = [
                                _createTextVNode("Холд")
                              ])),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('revenuePercent'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "revenuePercent",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        (props.row.revenuePercent)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.format(props.row.revenuePercent)), 1))
                          : _createCommentVNode("", true),
                        (props.row.revenuePercentHold)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                              _createElementVNode("small", null, [
                                _createTextVNode("(" + _toDisplayString(_ctx.format(props.row.revenuePercentHold)) + ")", 1),
                                _createVNode(_component_q_tooltip, null, {
                                  default: _withCtx(() => _cache[174] || (_cache[174] = [
                                    _createTextVNode("Холд")
                                  ])),
                                  _: 1
                                })
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (
                  !props.row.revenuePercent && !props.row.revenuePercentHold
                )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_19, " — "))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('advHold'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "advHold",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.advHold)), 1),
                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('advApprove'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "advApprove",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.advApprove)), 1),
                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true),
                (_ctx.pagination.visibleColumns.includes('revenueTotal'))
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "revenueTotal",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.revenueTotal)), 1),
                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["props", "style"]),
            (this.expandedIds.includes(props.row.id))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.children, (child, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      _createVNode(_component_q_tr, {
                        style: {"background":"aliceblue"},
                        props: props
                      }, {
                        default: _withCtx(() => [
                          (_ctx.pagination.visibleColumns.includes('groupByField'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                props: props,
                                key: "groupByField"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_20, [
                                    (_ctx.filters.groupBy2 === 'geoId')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_21, [
                                          _createElementVNode("img", {
                                            style: {"user-select":"none","display":"initial"},
                                            class: "q-mr-xs",
                                            height: "10",
                                            width: "15",
                                            alt: props.row.children?.[index].geoName,
                                            src: 
                          `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.children?.[index].geoCode}.svg`
                        
                                          }, null, 8, _hoisted_22),
                                          _createTextVNode(" " + _toDisplayString(props.row.children?.[index].geoCode || '??') + " ", 1),
                                          _createVNode(_component_q_tooltip, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(props.row.children?.[index].geoName || 'Неизвестно'), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.filters.groupBy2 === 'date')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.formatDateLikeChart(
                          _ctx.getGroupedField2(
                            'field',
                            props.row.children?.[index],
                          ),
                        )), 1))
                                      : _createCommentVNode("", true),
                                    (_ctx.filters.groupBy2 === 'week')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.getISOWeekName(
                          _ctx.getGroupedField2(
                            'field',
                            props.row.children?.[index],
                          ),
                        )), 1))
                                      : _createCommentVNode("", true),
                                    (_ctx.filters.groupBy2 === 'month')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_ctx.getISOMonthName(
                          _ctx.getGroupedField2(
                            'field',
                            props.row.children?.[index],
                          ),
                        )), 1))
                                      : _createCommentVNode("", true),
                                    (_ctx.filters.groupBy2 === 'campaignId')
                                      ? (_openBlock(), _createBlock(_component_IconWithName, {
                                          key: 4,
                                          id: props.row.children?.[index].campaignId,
                                          name: 
                        props.row.children?.[index].campaignName || 'Неизвестно'
                      ,
                                          size: "18px",
                                          color: "secondary",
                                          icon: "share",
                                          entity: "campaign"
                                        }, null, 8, ["id", "name"]))
                                      : _createCommentVNode("", true),
                                    (_ctx.filters.groupBy2 === 'landingId')
                                      ? (_openBlock(), _createBlock(_component_IconWithName, {
                                          key: 5,
                                          id: props.row.children?.[index].landingId,
                                          name: 
                        props.row.children?.[index].landingName || 'Неизвестно'
                      ,
                                          size: "18px",
                                          color: "primary",
                                          icon: "airline_stops",
                                          entity: "landing"
                                        }, null, 8, ["id", "name"]))
                                      : _createCommentVNode("", true),
                                    (_ctx.filters.groupBy2 === 'advertiserId')
                                      ? (_openBlock(), _createBlock(_component_IconWithName, {
                                          key: 6,
                                          id: props.row.children?.[index].advertiserId,
                                          name: 
                        props.row.children?.[index].advertiserName ||
                          'Неизвестно'
                      ,
                                          size: "20px",
                                          color: "primary",
                                          icon: "people",
                                          entity: "advertiser"
                                        }, null, 8, ["id", "name"]))
                                      : _createCommentVNode("", true),
                                    (_ctx.filters.groupBy2 === 'offerId')
                                      ? (_openBlock(), _createBlock(_component_IconWithName, {
                                          key: 7,
                                          id: props.row.children?.[index].offerId,
                                          name: 
                        props.row.children?.[index].offerName || 'Неизвестно'
                      ,
                                          size: "18px",
                                          color: "orange-7",
                                          icon: "description",
                                          entity: "offer"
                                        }, null, 8, ["id", "name"]))
                                      : _createCommentVNode("", true),
                                    (_ctx.filters.groupBy2 === 'managerId')
                                      ? (_openBlock(), _createBlock(_component_IconWithName, {
                                          key: 8,
                                          id: props.row.children?.[index].managerId,
                                          name: 
                        props.row.children?.[index].managerEmail || 'Неизвестно'
                      ,
                                          size: "20px",
                                          color: "primary",
                                          icon: "manage_accounts",
                                          entity: "manager"
                                        }, null, 8, ["id", "name"]))
                                      : _createCommentVNode("", true),
                                    (_ctx.filters.groupBy2 === 'affiliateId')
                                      ? (_openBlock(), _createBlock(_component_IconWithName, {
                                          key: 9,
                                          id: props.row.children?.[index].affiliateId,
                                          name: props.row.children?.[index].affiliateEmail || '',
                                          size: "20px",
                                          color: "secondary",
                                          icon: "account_box",
                                          entity: "affiliate"
                                        }, null, 8, ["id", "name"]))
                                      : _createCommentVNode("", true),
                                    (_ctx.filters.groupBy3)
                                      ? (_openBlock(), _createBlock(_component_q_btn, {
                                          key: 10,
                                          class: "q-ml-sm",
                                          size: "sm",
                                          dense: "",
                                          flat: "",
                                          onClick: ($event: any) => (_ctx.onExpandRow(props.row.children?.[index])),
                                          icon: 
                        this.expandedIds.includes(
                          props.row.children?.[index].id,
                        )
                          ? 'arrow_drop_up'
                          : 'arrow_drop_down'
                      
                                        }, null, 8, ["onClick", "icon"]))
                                      : _createCommentVNode("", true)
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('registrations'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "registrations",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].registrations), 1)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('conversions'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "conversions",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].conversions), 1)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('paySum'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "paySum",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].paySum)), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('usdPaySum'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "usdPaySum",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].usdPaySum)), 1),
                                  _cache[175] || (_cache[175] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('eurPaySum'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "eurPaySum",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].eurPaySum)), 1),
                                  _cache[176] || (_cache[176] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('uahPaySum'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "uahPaySum",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].uahPaySum)), 1),
                                  _cache[177] || (_cache[177] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (
                    (_ctx.filters.groupBy === 'date' ||
                      _ctx.filters.groupBy === 'week' ||
                      _ctx.filters.groupBy === 'month') &&
                      _ctx.pagination.visibleColumns.includes('partners')
                  )
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "partners",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].partners), 1)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('approvedSum'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "approvedSum",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].approvedSum)), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('usdApprovedSum'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "usdApprovedSum",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].usdApprovedSum)), 1),
                                  _cache[178] || (_cache[178] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('eurApprovedSum'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "eurApprovedSum",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].eurApprovedSum)), 1),
                                  _cache[179] || (_cache[179] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('uahApprovedSum'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "uahApprovedSum",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].uahApprovedSum)), 1),
                                  _cache[180] || (_cache[180] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('holdSum'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "holdSum",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].holdSum)), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('usdHoldSum'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "usdHoldSum",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].usdHoldSum)), 1),
                                  _cache[181] || (_cache[181] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('eurHoldSum'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "eurHoldSum",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].eurHoldSum)), 1),
                                  _cache[182] || (_cache[182] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('uahHoldSum'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "uahHoldSum",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].uahHoldSum)), 1),
                                  _cache[183] || (_cache[183] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('rejectedSum'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "rejectedSum",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].rejectedSum)), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('revenue'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "revenue",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].revenue)), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('usdRevenue'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "usdRevenue",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].usdRevenue)), 1),
                                  _cache[184] || (_cache[184] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('eurRevenue'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "eurRevenue",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].eurRevenue)), 1),
                                  _cache[185] || (_cache[185] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('uahRevenue'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "uahRevenue",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].uahRevenue)), 1),
                                  _cache[186] || (_cache[186] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('revenueHold'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "revenueHold",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_26, [
                                    _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].revenueHold)) + " ", 1),
                                    _createElementVNode("small", null, [
                                      _createTextVNode(_toDisplayString(_ctx.getCurrencyFromCookie()) + " ", 1),
                                      _createVNode(_component_q_tooltip, null, {
                                        default: _withCtx(() => _cache[187] || (_cache[187] = [
                                          _createTextVNode("Холд")
                                        ])),
                                        _: 1
                                      })
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('usdRevenueHold'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "usdRevenueHold",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_27, [
                                    _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].usdRevenueHold)) + " ", 1),
                                    _createElementVNode("small", null, [
                                      _cache[189] || (_cache[189] = _createTextVNode(_toDisplayString(' USD') + " ")),
                                      _createVNode(_component_q_tooltip, null, {
                                        default: _withCtx(() => _cache[188] || (_cache[188] = [
                                          _createTextVNode("Холд")
                                        ])),
                                        _: 1
                                      })
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('eurRevenueHold'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "eurRevenueHold",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_28, [
                                    _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].eurRevenueHold)) + " ", 1),
                                    _createElementVNode("small", null, [
                                      _cache[191] || (_cache[191] = _createTextVNode(_toDisplayString(' EUR') + " ")),
                                      _createVNode(_component_q_tooltip, null, {
                                        default: _withCtx(() => _cache[190] || (_cache[190] = [
                                          _createTextVNode("Холд")
                                        ])),
                                        _: 1
                                      })
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('uahRevenueHold'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "uahRevenueHold",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_29, [
                                    _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].uahRevenueHold)) + " ", 1),
                                    _createElementVNode("small", null, [
                                      _cache[193] || (_cache[193] = _createTextVNode(_toDisplayString(' UAH') + " ")),
                                      _createVNode(_component_q_tooltip, null, {
                                        default: _withCtx(() => _cache[192] || (_cache[192] = [
                                          _createTextVNode("Холд")
                                        ])),
                                        _: 1
                                      })
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('revenuePercent'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "revenuePercent",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  (props.row.children?.[index].revenuePercent)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_ctx.format(props.row.children?.[index].revenuePercent)), 1))
                                    : _createCommentVNode("", true),
                                  (props.row.children?.[index].revenuePercentHold)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_31, [
                                        _createElementVNode("small", null, [
                                          _createTextVNode("(" + _toDisplayString(_ctx.format(props.row.children?.[index].revenuePercentHold)) + ")", 1),
                                          _createVNode(_component_q_tooltip, null, {
                                            default: _withCtx(() => _cache[194] || (_cache[194] = [
                                              _createTextVNode("Холд")
                                            ])),
                                            _: 1
                                          })
                                        ])
                                      ]))
                                    : _createCommentVNode("", true),
                                  (
                      !props.row.children?.[index].revenuePercent &&
                        !props.row.children?.[index].revenuePercentHold
                    )
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_32, " — "))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('advHold'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "advHold",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].advHold)), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('advApprove'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "advApprove",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].advApprove)), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true),
                          (_ctx.pagination.visibleColumns.includes('revenueTotal'))
                            ? (_openBlock(), _createBlock(_component_q_td, {
                                key: "revenueTotal",
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.children?.[index].revenueTotal)), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      (this.expandedIds.includes(child.id))
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(child.children, (child2, index2) => {
                            return (_openBlock(), _createBlock(_component_q_tr, {
                              style: {"background":"#ffffde"},
                              props: props,
                              key: index2
                            }, {
                              default: _withCtx(() => [
                                (_ctx.pagination.visibleColumns.includes('groupByField'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      props: props,
                                      key: "groupByField",
                                      class: "text-right"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_33, [
                                          (_ctx.filters.groupBy3 === 'geoId')
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_34, [
                                                _createElementVNode("img", {
                                                  style: {"user-select":"none","display":"initial"},
                                                  class: "q-mr-xs",
                                                  height: "10",
                                                  width: "15",
                                                  alt: 
                            props.row.children?.[index].children?.[index2]
                              .geoCode
                          ,
                                                  src: 
                            `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.children?.[index].children?.[index2].geoCode}.svg`
                          
                                                }, null, 8, _hoisted_35),
                                                _createTextVNode(" " + _toDisplayString(props.row.children?.[index].children?.[index2]
                            .geoCode || '??') + " ", 1),
                                                _createVNode(_component_q_tooltip, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(props.row.children?.[index].children?.[index2]
                            .geoName || 'Неизвестно'), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]))
                                            : _createCommentVNode("", true),
                                          (_ctx.filters.groupBy3 === 'date')
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(_ctx.formatDateLikeChart(
                            _ctx.getGroupedField3(
                              'field',
                              props.row.children?.[index].children?.[index2],
                            ),
                          )), 1))
                                            : _createCommentVNode("", true),
                                          (_ctx.filters.groupBy3 === 'week')
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(_ctx.getISOWeekName(
                            _ctx.getGroupedField3(
                              'field',
                              props.row.children?.[index].children?.[index2],
                            ),
                          )), 1))
                                            : _createCommentVNode("", true),
                                          (_ctx.filters.groupBy3 === 'month')
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(_ctx.getISOMonthName(
                            _ctx.getGroupedField3(
                              'field',
                              props.row.children?.[index].children?.[index2],
                            ),
                          )), 1))
                                            : _createCommentVNode("", true),
                                          (_ctx.filters.groupBy3 === 'campaignId')
                                            ? (_openBlock(), _createBlock(_component_IconWithName, {
                                                key: 4,
                                                id: 
                          props.row.children?.[index].children?.[index2]
                            .campaignId
                        ,
                                                name: 
                          props.row.children?.[index].children?.[index2]
                            .campaignName || 'Неизвестно'
                        ,
                                                size: "18px",
                                                color: "secondary",
                                                icon: "share",
                                                entity: "campaign"
                                              }, null, 8, ["id", "name"]))
                                            : _createCommentVNode("", true),
                                          (_ctx.filters.groupBy3 === 'landingId')
                                            ? (_openBlock(), _createBlock(_component_IconWithName, {
                                                key: 5,
                                                id: 
                          props.row.children?.[index].children?.[index2]
                            .landingId
                        ,
                                                name: 
                          props.row.children?.[index].children?.[index2]
                            .landingName || 'Неизвестно'
                        ,
                                                size: "18px",
                                                color: "primary",
                                                icon: "airline_stops",
                                                entity: "landing"
                                              }, null, 8, ["id", "name"]))
                                            : _createCommentVNode("", true),
                                          (_ctx.filters.groupBy3 === 'advertiserId')
                                            ? (_openBlock(), _createBlock(_component_IconWithName, {
                                                key: 6,
                                                id: 
                          props.row.children?.[index].children?.[index2]
                            .advertiserId
                        ,
                                                name: 
                          props.row.children?.[index].children?.[index2]
                            .advertiserName || 'Неизвестно'
                        ,
                                                size: "20px",
                                                color: "primary",
                                                icon: "people",
                                                entity: "advertiser"
                                              }, null, 8, ["id", "name"]))
                                            : _createCommentVNode("", true),
                                          (_ctx.filters.groupBy3 === 'offerId')
                                            ? (_openBlock(), _createBlock(_component_IconWithName, {
                                                key: 7,
                                                id: 
                          props.row.children?.[index].children?.[index2].offerId
                        ,
                                                name: 
                          props.row.children?.[index].children?.[index2]
                            .offerName || 'Неизвестно'
                        ,
                                                size: "18px",
                                                color: "orange-7",
                                                icon: "description",
                                                entity: "offer"
                                              }, null, 8, ["id", "name"]))
                                            : _createCommentVNode("", true),
                                          (_ctx.filters.groupBy3 === 'managerId')
                                            ? (_openBlock(), _createBlock(_component_IconWithName, {
                                                key: 8,
                                                id: 
                          props.row.children?.[index].children?.[index2]
                            .managerId
                        ,
                                                name: 
                          props.row.children?.[index].children?.[index2]
                            .managerEmail || 'Неизвестно'
                        ,
                                                size: "20px",
                                                color: "primary",
                                                icon: "manage_accounts",
                                                entity: "manager"
                                              }, null, 8, ["id", "name"]))
                                            : _createCommentVNode("", true),
                                          (_ctx.filters.groupBy3 === 'affiliateId')
                                            ? (_openBlock(), _createBlock(_component_IconWithName, {
                                                key: 9,
                                                id: 
                          props.row.children?.[index].children?.[index2]
                            .affiliateId
                        ,
                                                name: 
                          props.row.children?.[index].children?.[index2]
                            .affiliateEmail || ''
                        ,
                                                size: "20px",
                                                color: "secondary",
                                                icon: "account_box",
                                                entity: "affiliate"
                                              }, null, 8, ["id", "name"]))
                                            : _createCommentVNode("", true)
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('registrations'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "registrations",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2]
                          .registrations), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('conversions'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "conversions",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2]
                          .conversions), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('paySum'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "paySum",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2].paySum,
                      )), 1),
                                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('usdPaySum'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "usdPaySum",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .usdPaySum,
                      )), 1),
                                        _cache[195] || (_cache[195] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('eurPaySum'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "eurPaySum",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .eurPaySum,
                      )), 1),
                                        _cache[196] || (_cache[196] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('uahPaySum'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "uahPaySum",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .uahPaySum,
                      )), 1),
                                        _cache[197] || (_cache[197] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (
                      (_ctx.filters.groupBy === 'date' ||
                        _ctx.filters.groupBy === 'week' ||
                        _ctx.filters.groupBy === 'month') &&
                        _ctx.pagination.visibleColumns.includes('partners')
                    )
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "partners",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2].partners), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('approvedSum'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "approvedSum",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .approvedSum,
                      )), 1),
                                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('usdApprovedSum'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "usdApprovedSum",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .usdApprovedSum,
                      )), 1),
                                        _cache[198] || (_cache[198] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('eurApprovedSum'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "eurApprovedSum",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .eurApprovedSum,
                      )), 1),
                                        _cache[199] || (_cache[199] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('uahApprovedSum'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "uahApprovedSum",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .uahApprovedSum,
                      )), 1),
                                        _cache[200] || (_cache[200] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('holdSum'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "holdSum",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2].holdSum,
                      )), 1),
                                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('usdHoldSum'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "usdHoldSum",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .usdHoldSum,
                      )), 1),
                                        _cache[201] || (_cache[201] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('eurHoldSum'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "eurHoldSum",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .eurHoldSum,
                      )), 1),
                                        _cache[202] || (_cache[202] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('uahHoldSum'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "uahHoldSum",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .uahHoldSum,
                      )), 1),
                                        _cache[203] || (_cache[203] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('rejectedSum'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "rejectedSum",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .rejectedSum,
                      )), 1),
                                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('revenue'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "revenue",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2].revenue,
                      )), 1),
                                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('usdRevenue'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "usdRevenue",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .usdRevenue,
                      )), 1),
                                        _cache[204] || (_cache[204] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('eurRevenue'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "eurRevenue",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .eurRevenue,
                      )), 1),
                                        _cache[205] || (_cache[205] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('uahRevenue'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "uahRevenue",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .uahRevenue,
                      )), 1),
                                        _cache[206] || (_cache[206] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('revenueHold'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "revenueHold",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_39, [
                                          _createTextVNode(_toDisplayString(_ctx.formatMoney(
                          props.row.children?.[index].children?.[index2]
                            .revenueHold,
                        )) + " ", 1),
                                          _createElementVNode("small", null, [
                                            _createTextVNode(_toDisplayString(_ctx.getCurrencyFromCookie()) + " ", 1),
                                            _createVNode(_component_q_tooltip, null, {
                                              default: _withCtx(() => _cache[207] || (_cache[207] = [
                                                _createTextVNode("Холд")
                                              ])),
                                              _: 1
                                            })
                                          ])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('usdRevenueHold'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "usdRevenueHold",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_40, [
                                          _createTextVNode(_toDisplayString(_ctx.formatMoney(
                          props.row.children?.[index].children?.[index2]
                            .usdRevenueHold,
                        )) + " ", 1),
                                          _createElementVNode("small", null, [
                                            _cache[209] || (_cache[209] = _createTextVNode(_toDisplayString(' USD') + " ")),
                                            _createVNode(_component_q_tooltip, null, {
                                              default: _withCtx(() => _cache[208] || (_cache[208] = [
                                                _createTextVNode("Холд")
                                              ])),
                                              _: 1
                                            })
                                          ])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('eurRevenueHold'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "eurRevenueHold",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_41, [
                                          _createTextVNode(_toDisplayString(_ctx.formatMoney(
                          props.row.children?.[index].children?.[index2]
                            .eurRevenueHold,
                        )) + " ", 1),
                                          _createElementVNode("small", null, [
                                            _cache[211] || (_cache[211] = _createTextVNode(_toDisplayString(' EUR') + " ")),
                                            _createVNode(_component_q_tooltip, null, {
                                              default: _withCtx(() => _cache[210] || (_cache[210] = [
                                                _createTextVNode("Холд")
                                              ])),
                                              _: 1
                                            })
                                          ])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('uahRevenueHold'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "uahRevenueHold",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_42, [
                                          _createTextVNode(_toDisplayString(_ctx.formatMoney(
                          props.row.children?.[index].children?.[index2]
                            .uahRevenueHold,
                        )) + " ", 1),
                                          _createElementVNode("small", null, [
                                            _cache[213] || (_cache[213] = _createTextVNode(_toDisplayString(' UAH') + " ")),
                                            _createVNode(_component_q_tooltip, null, {
                                              default: _withCtx(() => _cache[212] || (_cache[212] = [
                                                _createTextVNode("Холд")
                                              ])),
                                              _: 1
                                            })
                                          ])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('revenuePercent'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "revenuePercent",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        (
                        props.row.children?.[index].children?.[index2]
                          .revenuePercent
                      )
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_43, _toDisplayString(_ctx.format(
                          props.row.children?.[index].children?.[index2]
                            .revenuePercent,
                        )), 1))
                                          : _createCommentVNode("", true),
                                        (
                        props.row.children?.[index].children?.[index2]
                          .revenuePercentHold
                      )
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_44, [
                                              _createElementVNode("small", null, [
                                                _createTextVNode("(" + _toDisplayString(_ctx.format(
                            props.row.children?.[index].children?.[index2]
                              .revenuePercentHold,
                          )) + ")", 1),
                                                _createVNode(_component_q_tooltip, null, {
                                                  default: _withCtx(() => _cache[214] || (_cache[214] = [
                                                    _createTextVNode("Холд")
                                                  ])),
                                                  _: 1
                                                })
                                              ])
                                            ]))
                                          : _createCommentVNode("", true),
                                        (
                        !props.row.children?.[index].children?.[index2]
                          .revenuePercent &&
                          !props.row.children?.[index].children?.[index2]
                            .revenuePercentHold
                      )
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_45, " — "))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('advHold'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "advHold",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2].advHold,
                      )), 1),
                                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('advApprove'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "advApprove",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .advApprove,
                      )), 1),
                                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true),
                                (_ctx.pagination.visibleColumns.includes('revenueTotal'))
                                  ? (_openBlock(), _createBlock(_component_q_td, {
                                      key: "revenueTotal",
                                      props: props
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatMoney(
                        props.row.children?.[index].children?.[index2]
                          .revenueTotal,
                      )), 1),
                                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["props"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["props"]))
                          }), 128))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128)),
                  _createVNode(_component_q_tr, { props: props }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_td, { colspan: "100%" }, {
                        default: _withCtx(() => _cache[215] || (_cache[215] = [
                          _createElementVNode("hr", null, null, -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, [
          (_ctx.rows?.length)
            ? {
                name: "bottom-row",
                fn: _withCtx(() => [
                  _createVNode(_component_q_tr, null, {
                    default: _withCtx(() => [
                      (_ctx.pagination.visibleColumns.includes('groupByField'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 0,
                            class: "text-left border-top",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => _cache[216] || (_cache[216] = [
                              _createElementVNode("b", null, "TOTAL:", -1)
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('registrations'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 1,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, _toDisplayString(_ctx.total('registrations', 'sum')), 1)
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('conversions'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 2,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, _toDisplayString(_ctx.total('conversions', 'sum')), 1)
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('paySum'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 3,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('paySum', 'sum'))) + " ", 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('usdPaySum'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 4,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdPaySum', 'sum'))) + " ", 1),
                                  _cache[217] || (_cache[217] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('eurPaySum'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 5,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurPaySum', 'sum'))) + " ", 1),
                                  _cache[218] || (_cache[218] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('uahPaySum'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 6,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahPaySum', 'sum'))) + " ", 1),
                                  _cache[219] || (_cache[219] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (
                ['date', 'week', 'month'].includes(_ctx.filters.groupBy) &&
                  _ctx.pagination.visibleColumns.includes('partners')
              )
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 7,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, _toDisplayString(_ctx.total('partners', 'avg')), 1)
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('approvedSum'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 8,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('approvedSum', 'sum'))) + " ", 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('usdApprovedSum'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 9,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdApprovedSum', 'sum'))) + " ", 1),
                                  _cache[220] || (_cache[220] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('eurApprovedSum'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 10,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurApprovedSum', 'sum'))) + " ", 1),
                                  _cache[221] || (_cache[221] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('uahApprovedSum'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 11,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahApprovedSum', 'sum'))) + " ", 1),
                                  _cache[222] || (_cache[222] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('holdSum'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 12,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('holdSum', 'sum'))), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('usdHoldSum'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 13,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdHoldSum', 'sum'))), 1),
                                  _cache[223] || (_cache[223] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('eurHoldSum'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 14,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurHoldSum', 'sum'))), 1),
                                  _cache[224] || (_cache[224] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('uahHoldSum'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 15,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahHoldSum', 'sum'))), 1),
                                  _cache[225] || (_cache[225] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('rejectedSum'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 16,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('rejectedSum', 'sum'))), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('revenue'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 17,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('revenue', 'sum'))), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('usdRevenue'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 18,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdRevenue', 'sum'))), 1),
                                  _cache[226] || (_cache[226] = _createElementVNode("small", null, _toDisplayString(' USD'), -1))
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('eurRevenue'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 19,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurRevenue', 'sum'))), 1),
                                  _cache[227] || (_cache[227] = _createElementVNode("small", null, _toDisplayString(' EUR'), -1))
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('uahRevenue'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 20,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahRevenue', 'sum'))), 1),
                                  _cache[228] || (_cache[228] = _createElementVNode("small", null, _toDisplayString(' UAH'), -1))
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('revenueHold'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 21,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("span", _hoisted_46, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('revenueHold', 'sum'))) + " ", 1),
                                  _createElementVNode("small", null, [
                                    _createTextVNode(_toDisplayString(_ctx.getCurrencyFromCookie()), 1),
                                    _createVNode(_component_q_tooltip, null, {
                                      default: _withCtx(() => _cache[229] || (_cache[229] = [
                                        _createTextVNode("Холд")
                                      ])),
                                      _: 1
                                    })
                                  ])
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('usdRevenueHold'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 22,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("span", _hoisted_47, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('usdRevenueHold', 'sum'))) + " ", 1),
                                  _createElementVNode("small", null, [
                                    _cache[231] || (_cache[231] = _createTextVNode(_toDisplayString(' USD'))),
                                    _createVNode(_component_q_tooltip, null, {
                                      default: _withCtx(() => _cache[230] || (_cache[230] = [
                                        _createTextVNode("Холд")
                                      ])),
                                      _: 1
                                    })
                                  ])
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('eurRevenueHold'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 23,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("span", _hoisted_48, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('eurRevenueHold', 'sum'))) + " ", 1),
                                  _createElementVNode("small", null, [
                                    _cache[233] || (_cache[233] = _createTextVNode(_toDisplayString(' EUR'))),
                                    _createVNode(_component_q_tooltip, null, {
                                      default: _withCtx(() => _cache[232] || (_cache[232] = [
                                        _createTextVNode("Холд")
                                      ])),
                                      _: 1
                                    })
                                  ])
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('uahRevenueHold'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 24,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("span", _hoisted_49, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('uahRevenueHold', 'sum'))) + " ", 1),
                                  _createElementVNode("small", null, [
                                    _cache[235] || (_cache[235] = _createTextVNode(_toDisplayString(' UAH'))),
                                    _createVNode(_component_q_tooltip, null, {
                                      default: _withCtx(() => _cache[234] || (_cache[234] = [
                                        _createTextVNode("Холд")
                                      ])),
                                      _: 1
                                    })
                                  ])
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('revenuePercent'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 25,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("span", null, [
                                  _createElementVNode("b", null, _toDisplayString((
                        (Number(_ctx.total('revenue', 'sum')) /
                          (Number(_ctx.total('approvedSum', 'sum')) +
                            Number(_ctx.total('revenue', 'sum')))) *
                          100 || 0
                      ).toFixed(2)) + " %", 1)
                                ]),
                                _createElementVNode("span", _hoisted_50, [
                                  _createElementVNode("small", null, [
                                    _createTextVNode(" (" + _toDisplayString((
                        (Number(_ctx.total('revenueHold', 'sum')) /
                          (Number(_ctx.total('holdSum', 'sum')) +
                            Number(_ctx.total('revenueHold', 'sum')))) *
                          100 || 0
                      ).toFixed(2)) + " %)", 1),
                                    _createVNode(_component_q_tooltip, null, {
                                      default: _withCtx(() => _cache[236] || (_cache[236] = [
                                        _createTextVNode("Холд")
                                      ])),
                                      _: 1
                                    })
                                  ])
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('advHold'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 26,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('advHold', 'sum'))), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('advApprove'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 27,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('advApprove', 'sum'))), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.pagination.visibleColumns.includes('revenueTotal'))
                        ? (_openBlock(), _createBlock(_component_q_td, {
                            key: 28,
                            class: "text-right border-top monospaced",
                            colspan: "1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", null, [
                                  _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('revenueTotal', 'sum'))), 1),
                                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                                ])
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["rows", "columns", "pagination", "visible-columns", "loading", "onRequest", "onRowClick"])
      ])
    ]),
    _: 1
  }))
}
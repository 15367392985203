import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "col-sm-12 col-lg-2" }
const _hoisted_3 = { class: "col-sm-12 col-lg-4" }
const _hoisted_4 = { class: "col-sm-12 col-lg-1 self-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.affiliateRewards, (reward, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "flex row q-gutter-x-md q-mb-xl mobile-display-block",
        key: index
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("b", null, "Аффилейт", -1)),
          _createVNode(_component_q_select, {
            ref_for: true,
            ref: `affiliates-${index}`,
            onBlur: ($event: any) => (_ctx.clearFilter(`affiliates-${index}`)),
            outlined: "",
            "model-value": reward.affiliateIds?.[0],
            "onUpdate:modelValue": 
            affiliateId =>
              _ctx.updateField({ ...reward, affiliateIds: [affiliateId] }, index)
          ,
            onFilter: _ctx.getAffiliates,
            options: _ctx.affiliates,
            "option-value": "id",
            "option-label": "email",
            "emit-value": "",
            "map-options": "",
            "use-input": "",
            "error-message": _ctx.errors?.affiliateRewards?.[index]?.affiliateIds,
            error: !!_ctx.errors?.affiliateRewards?.[index]?.affiliateIds
          }, {
            option: _withCtx((scope) => [
              _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps({ ref_for: true }, scope.itemProps, _toHandlers(scope.itemEvents)), {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_IconWithName, {
                        id: scope.opt.id,
                        name: scope.opt.email,
                        size: "20px",
                        color: "secondary",
                        icon: "account_box",
                        entity: "affiliate"
                      }, null, 8, ["id", "name"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1040)), [
                [_directive_close_popup]
              ])
            ]),
            _: 2
          }, 1032, ["onBlur", "model-value", "onUpdate:modelValue", "onFilter", "options", "error-message", "error"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("b", null, "Доступные Гео (Органика)", -1)),
          _createVNode(_component_q_select, {
            outlined: "",
            multiple: "",
            "use-chips": "",
            ref_for: true,
            ref: `reward-geos-${index}`,
            onBlur: ($event: any) => (_ctx.clearFilter(`reward-geos-${index}`)),
            "model-value": reward.geoIds,
            "onUpdate:modelValue": 
            geoIds => _ctx.updateField({ ...reward, geoIds }, index)
          ,
            onFilter: (val, upd) => _ctx.getGeos(val, upd, reward),
            options: _ctx.geos,
            "option-value": "id",
            "option-label": "name",
            "emit-value": "",
            "map-options": "",
            "use-input": "",
            "error-message": _ctx.errors?.affiliateRewards?.[index]?.geoIds,
            error: !!_ctx.errors?.affiliateRewards?.[index]?.geoIds
          }, null, 8, ["onBlur", "model-value", "onUpdate:modelValue", "onFilter", "options", "error-message", "error"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_btn, {
            onClick: ($event: any) => (_ctx.deleteRule(index)),
            outline: "",
            round: "",
            color: "primary",
            icon: "delete"
          }, null, 8, ["onClick"])
        ])
      ]))
    }), 128)),
    _createVNode(_component_q_btn, {
      onClick: _ctx.addRule,
      round: "",
      color: "secondary",
      icon: "add"
    }, null, 8, ["onClick"])
  ]))
}
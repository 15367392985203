<template>
  <div class="q-pa-md q-pr-xl">
    <q-table
      flat
      bordered
      separator="cell"
      :rows="rewards"
      :rows-per-page-options="[0, 10, 25, 50, 100, 200, 500, 1000]"
      :columns="filteredColumns"
      row-key="id"
    >
      <template v-slot:body-cell-affiliateIds="props">
        <q-td :props="props">
          <q-select
            dense
            outlined
            multiple
            use-chips
            @filter="getAffiliates"
            v-model="props.row.affiliateIds"
            @update:model-value="() => updateField(props.row, props.rowIndex)"
            :options="affiliates"
            option-value="id"
            option-label="email"
            emit-value
            map-options
            use-input
          />
        </q-td>
      </template>
      <template v-slot:body-cell-geoIds="props">
        <q-td :props="props">
          <q-select
            dense
            outlined
            multiple
            use-chips
            v-model="props.row.geoIds"
            @update:model-value="() => updateField(props.row, props.rowIndex)"
            @filter="getGeos"
            :options="geos"
            option-value="id"
            option-label="name"
            emit-value
            map-options
            use-input
          >
            <template #option="scope">
              <q-item
                v-close-popup
                v-bind="scope.itemProps"
                v-on="scope.itemEvents"
              >
                <img
                  style="user-select: none; display: initial;"
                  class="q-mr-sm"
                  height="10"
                  width="15"
                  :src="
                    `http://purecatamphetamine.github.io/country-flag-icons/3x2/${scope.opt.isoCode}.svg`
                  "
                />
                <q-item-section>
                  <q-item-label v-html="scope.opt.name"></q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </q-td>
      </template>
      <template v-slot:body-cell-value="props">
        <q-td :props="props">
          <q-input
            dense
            outlined
            type="number"
            step=".01"
            min="0"
            :model-value="props.row.value.amount ?? null"
            @update:model-value="
              value =>
                (props.row.value.amount = value === '' ? null : Number(value))
            "
          />
        </q-td>
      </template>
      <template v-slot:body-cell-currency="props">
        <q-td :props="props">
          <q-select
            dense
            outlined
            v-model="props.row.value.currency"
            @blur="updateField(props.row, props.rowIndex)"
            :options="currencies"
            option-value="id"
            option-label="name"
            emit-value
            map-options
          />
        </q-td>
      </template>
      <template v-slot:body-cell-advertiserValueAmount="props">
        <q-td :props="props">
          <q-input
            dense
            outlined
            type="number"
            step=".01"
            min="0"
            :model-value="props.row.advertiserValueAmount ?? null"
            @update:model-value="
              value =>
                (props.row.advertiserValueAmount =
                  value === '' ? null : Number(value))
            "
          />
        </q-td>
      </template>
      <template v-slot:header-cell-advertiserValueCurrency="props">
        <q-th :props="props">
          <div class="flex items-center">
            <span
              >{{ props.col.label }}
              <q-icon name="help_outline" size="16px">
                <q-tooltip
                  max-width="150px"
                  anchor="bottom middle"
                  self="top middle"
                  class="q-tooltip"
                >
                  Опционально. Валюта рекламодателя. Если целевого гео нету в
                  Таргетинге, то нужно обязательно указать валюту от
                  рекламодателя.
                </q-tooltip>
              </q-icon>
            </span>
          </div>
        </q-th>
      </template>
      <template v-slot:body-cell-advertiserValueCurrency="props">
        <q-td :props="props">
          <q-select
            dense
            outlined
            v-model="props.row.advertiserValueCurrency"
            @blur="updateField(props.row, props.rowIndex)"
            :options="currencies"
            option-value="id"
            option-label="name"
            emit-value
            map-options
          />
        </q-td>
      </template>
      <template v-slot:body-cell-type="props">
        <q-td :props="props">
          <q-select
            dense
            outlined
            v-model="props.row.type"
            @blur="updateField(props.row, props.rowIndex)"
            :options="rewardTypes"
            option-value="id"
            option-label="name"
            emit-value
            map-options
            :color="
              errors?.affiliateRewards?.[props.rowIndex]?.type
                ? 'negative'
                : 'primary'
            "
          >
            <template
              v-slot:append
              v-if="errors?.affiliateRewards?.[props.rowIndex]?.type"
            >
              <q-icon name="error" color="negative" />
            </template>
            <q-tooltip
              v-if="errors?.affiliateRewards?.[props.rowIndex]?.type"
              class="bg-negative text-white"
            >
              {{ 'Conv. type should not be empty.' }}
            </q-tooltip>
          </q-select>
        </q-td>
      </template>
      <template v-slot:header-cell-trafficSource="props">
        <q-th :props="props">
          <div class="flex items-center">
            <span
              >{{ props.col.label }}
              <q-icon name="help_outline" size="16px">
                <q-tooltip
                  max-width="150px"
                  anchor="bottom middle"
                  self="top middle"
                  class="q-tooltip"
                >
                  Опционально. Если выбран источник трафика, то выплата за
                  конверсию расчитывается исходя из выбранного источника трафика
                  при создании потока. Если для выбранного в потоке источника
                  трафика нету выплаты - начисляется дефолтная.
                </q-tooltip>
              </q-icon>
            </span>
          </div>
        </q-th>
      </template>
      <template v-slot:body-cell-trafficSource="props">
        <q-td :props="props">
          <q-select
            dense
            outlined
            v-model="props.row.trafficSource"
            :options="trafficSources"
            option-label="name"
          />
        </q-td>
      </template>
      <template v-slot:header-cell-percent="props">
        <q-th :props="props">
          <div class="flex items-center">
            <span
              >{{ props.col.label }}
              <q-icon name="help_outline" size="16px">
                <q-tooltip
                  max-width="150px"
                  anchor="bottom middle"
                  self="top middle"
                  class="q-tooltip"
                >
                  Ставка по офферу для веба (%) по RevShare
                </q-tooltip>
              </q-icon>
            </span>
          </div>
        </q-th>
      </template>
      <template v-slot:body-cell-percent="props">
        <q-td :props="props">
          <q-input
            dense
            outlined
            type="number"
            min="0"
            v-model.number="props.row.percent"
            :model-value="props.row.percent ?? null"
            @update:model-value="
              value => (props.row.percent = value === '' ? null : Number(value))
            "
          />
        </q-td>
      </template>
      <template v-slot:body-cell-holdInDays="props">
        <q-td :props="props">
          <q-input
            dense
            outlined
            type="number"
            min="0"
            :model-value="props.row.holdInDays ?? null"
            @update:model-value="
              value =>
                (props.row.holdInDays = value === '' ? null : Number(value))
            "
          />
        </q-td>
      </template>
      <template v-slot:header-cell-informLeadLimit="props">
        <q-th :props="props">
          <div class="flex items-center">
            <span
              >{{ props.col.label }}
              <q-icon name="help_outline" size="16px">
                <q-tooltip
                  max-width="150px"
                  anchor="bottom middle"
                  self="top middle"
                  class="q-tooltip"
                >
                  Опционально. Фиксирование договоренностей по капе с
                  вебмастером.
                </q-tooltip>
              </q-icon>
            </span>
          </div>
        </q-th>
      </template>
      <template v-slot:body-cell-informLeadLimit="props">
        <q-td :props="props">
          <q-input
            dense
            outlined
            type="number"
            min="0"
            :model-value="props.row.informLeadLimit ?? null"
            @update:model-value="
              value =>
                (props.row.informLeadLimit =
                  value === '' ? null : Number(value))
            "
          />
        </q-td>
      </template>
      <template v-slot:body-cell-informLeadLimitPeriod="props">
        <q-td :props="props">
          <q-select
            dense
            outlined
            v-model="props.row.informLeadLimitPeriod"
            @blur="updateField(props.row, props.rowIndex)"
            :options="leadLimitPeriods"
            option-value="id"
            option-label="name"
            emit-value
            map-options
          />
        </q-td>
      </template>
      <template v-slot:header-cell-leadLimitPerMonth="props">
        <q-th :props="props">
          <div class="flex items-center">
            <span
              >{{ props.col.label }}
              <q-icon name="help_outline" size="16px">
                <q-tooltip
                  max-width="150px"
                  anchor="bottom middle"
                  self="top middle"
                  class="q-tooltip"
                >
                  Опционально. Максимальное количество первых депозитов, которое
                  засчитывается вебмастеру. При превышении капы депозиты
                  перестают засчитываться вебмастеру. Капа может быть изменена
                  на протяжении периода действия. Доступно для вертикали
                  Гемблинг.
                </q-tooltip>
              </q-icon>
            </span>
          </div>
        </q-th>
      </template>
      <template v-slot:body-cell-leadLimitPerMonth="props">
        <q-td :props="props">
          <q-input
            outlined
            dense
            type="number"
            min="0"
            :model-value="props.row.leadLimitPerMonth ?? null"
            @update:model-value="
              value =>
                (props.row.leadLimitPerMonth =
                  value === '' ? null : Number(value))
            "
          />
        </q-td>
      </template>
      <template v-slot:body-cell-leadLimitPeriod="props">
        <q-td :props="props">
          <q-select
            dense
            outlined
            v-model="props.row.leadLimitPeriod"
            @blur="updateField(props.row, props.rowIndex)"
            :options="leadLimitPeriods"
            option-value="id"
            option-label="name"
            emit-value
            map-options
          />
        </q-td>
      </template>
      <template v-slot:header-cell-postClickReg="props">
        <q-th :props="props">
          <div class="flex items-center">
            <span
              >{{ props.col.label }}
              <q-icon name="help_outline" size="16px">
                <q-tooltip
                  max-width="150px"
                  anchor="bottom middle"
                  self="top middle"
                  class="q-tooltip"
                >
                  Опционально. Количество дней с момента регистрации, в течении
                  которых засчитывается депозит.
                </q-tooltip>
              </q-icon>
            </span>
          </div>
        </q-th>
      </template>
      <template v-slot:body-cell-postClickReg="props">
        <q-td :props="props">
          <q-input
            dense
            outlined
            hide-bottom-space
            type="number"
            min="0"
            :model-value="props.row.postClickReg ?? null"
            @update:model-value="
              value =>
                (props.row.postClickReg = value === '' ? null : Number(value))
            "
            :color="
              errors?.affiliateRewards?.[props.rowIndex]?.postClickReg
                ? 'negative'
                : 'primary'
            "
          >
            <template
              v-slot:append
              v-if="errors?.affiliateRewards?.[props.rowIndex]?.postClickReg"
            >
              <q-icon name="error" color="negative" />
            </template>
            <q-tooltip
              v-if="errors?.affiliateRewards?.[props.rowIndex]?.postClickReg"
              class="bg-negative text-white"
            >
              {{ errors.affiliateRewards[props.rowIndex].postClickReg }}
            </q-tooltip>
          </q-input>
        </q-td>
      </template>
      <template v-slot:header-cell-postClickEvent="props">
        <q-th :props="props">
          <div class="flex items-center">
            <span
              >{{ props.col.label }}
              <q-icon name="help_outline" size="16px">
                <q-tooltip
                  max-width="150px"
                  anchor="bottom middle"
                  self="top middle"
                  class="q-tooltip"
                >
                  Опционально. Количество дней с момента первого события, в
                  течении которых засчитывается депозит.
                </q-tooltip>
              </q-icon>
            </span>
          </div>
        </q-th>
      </template>
      <template v-slot:body-cell-postClickEvent="props">
        <q-td :props="props">
          <q-input
            dense
            outlined
            hide-bottom-space
            type="number"
            min="0"
            :model-value="props.row.postClickEvent ?? null"
            @update:model-value="
              value =>
                (props.row.postClickEvent = value === '' ? null : Number(value))
            "
            :color="
              errors?.affiliateRewards?.[props.rowIndex]?.postClickEvent
                ? 'negative'
                : 'primary'
            "
          >
            <template
              v-slot:append
              v-if="errors?.affiliateRewards?.[props.rowIndex]?.postClickEvent"
            >
              <q-icon name="error" color="negative" />
            </template>
            <q-tooltip
              v-if="errors?.affiliateRewards?.[props.rowIndex]?.postClickEvent"
              class="bg-negative text-white"
            >
              {{ errors.affiliateRewards[props.rowIndex].postClickEvent }}
            </q-tooltip>
          </q-input>
        </q-td>
      </template>
      <template v-slot:header-cell-postClickMonth="props">
        <q-th :props="props">
          <div class="flex items-center">
            <span
              >{{ props.col.label }}
              <q-icon name="help_outline" size="16px">
                <q-tooltip
                  max-width="150px"
                  anchor="bottom middle"
                  self="top middle"
                  class="q-tooltip"
                >
                  Опционально. Постклик в пределах календарного месяца, в
                  течение которого засчитывается депозит.<br /><br />
                  Правда — клик 01.06, фтд 07.06 — не постклик<br />
                  Ложь — клик 02.06, фтд 15.06 — постклик<br />
                  Ложь — клик 30.05, фтд 02.06 — постклик
                </q-tooltip>
              </q-icon>
            </span>
          </div>
        </q-th>
      </template>
      <template v-slot:body-cell-postClickMonth="props">
        <q-td :props="props">
          <div class="q-gutter-sm">
            <q-checkbox
              hide-bottom-space
              v-model="props.row.postClickMonth"
              :model-value="props.row.postClickMonth ?? false"
              @update:model-value="() => updateField(props.row, props.rowIndex)"
              :color="
                !!errors?.affiliateRewards?.[props.rowIndex]?.postClickMonth
                  ? 'negative'
                  : 'primary'
              "
            >
              <q-tooltip
                v-if="
                  errors?.affiliateRewards?.[props.rowIndex]?.postClickMonth
                "
                class="bg-negative text-white"
              >
                {{ errors.affiliateRewards[props.rowIndex].postClickMonth }}
              </q-tooltip>
            </q-checkbox>
          </div>
        </q-td>
      </template>

      <template v-slot:header-cell-disablePostback="props">
        <q-th :props="props">
          <div class="flex items-center">
            <span
              >{{ props.col.label }}
              <q-icon name="help_outline" size="16px">
                <q-tooltip
                  max-width="150px"
                  anchor="bottom middle"
                  self="top middle"
                  class="q-tooltip"
                >
                  Если галочка включена, новые лиды по данному офферу
                  сохраняются у нас в системе со статусом (Отключен постбек).
                  Вебмастерам эти лиды не показываются и выплата за них не
                  начисляется пока они не будут апрувнуты.
                </q-tooltip>
              </q-icon>
            </span>
          </div>
        </q-th>
      </template>
      <template v-slot:body-cell-disablePostback="props">
        <q-td :props="props">
          <q-checkbox
            v-model="props.row.disablePostback"
            :model-value="props.row.disablePostback ?? false"
            @update:model-value="() => updateField(props.row, props.rowIndex)"
          />
        </q-td>
      </template>
      <template v-slot:body-cell-actions="props">
        <q-td :props="props">
          <q-btn
            @click="$emit('delete-reward', props.rowIndex)"
            outline
            round
            color="negative"
            icon="delete"
            size="sm"
          />
        </q-td>
      </template>
      <template v-slot:pagination="props">
        <div class="row items-center justify-between q-pa-sm full-width">
          <q-pagination
            v-model="props.pagination.page"
            :max="props.pagesNumber"
            max-pages="5"
            boundary-numbers
            color="secondary"
            size="sm"
          />
          <q-btn
            @click="$emit('add-reward')"
            round
            color="secondary"
            icon="add"
            size="sm"
            class="q-ml-sm"
          />
        </div>
      </template>
      <template v-slot:no-data>
        <div class="row items-center justify-end q-pa-sm full-width">
          <span>No data available</span>
          <q-btn
            @click="$emit('add-reward')"
            round
            color="secondary"
            icon="add"
            size="sm"
            class="q-ml-sm"
          />
        </div>
      </template>
    </q-table>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { RewardType } from '@/enums/RewardType'
import {lookupAffiliates} from "@/utils/lookups";
import axios from "axios";

export default defineComponent({
  name: 'AffiliatesTable',
  props: {
    rewards: Array,
    geos: Array,
    currencies: Array,
    trafficSources: Array,
    errors: Object,
    getGeos: Function,
    rewardTypes: Array,
    offer: Object,
    leadLimitPeriods: Object,
    columns: Array,
  },
  async created() {
    const affiliates = (await axios.get('/api/affiliates/list')).data
    this.affiliates = affiliates.map((affiliate) => ({
      ...affiliate,
      email: 'ID:' + affiliate.id + ' — ' + affiliate.credentials.email,
    }))
  },
  data() {
    return {
      affiliates: [],
    }
  },
  computed: {
    filteredColumns() {
      const columnConditions = {
        percent: row => row.type === RewardType.RevShare,
        holdInDays: row => row.type === RewardType.RevShare,
      }
      return this.columns.filter(col => {
        return columnConditions[col.name]
          ? this.rewards.some(columnConditions[col.name])
          : true
      })
    },
  },
  methods: {
    updateField(updatedReward, index) {
      this.$emit('update-reward', { updatedReward, index })
    },
    async getAffiliates(value, update, extraParams = {}) {
      await lookupAffiliates(this, value, update, extraParams)
    },
  },
})
</script>

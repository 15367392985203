import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "row q-mb-md" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "row items-center justify-between" }
const _hoisted_4 = { class: "col-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_PayoutEditDialog = _resolveComponent("PayoutEditDialog")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl q-mr-lg" }, {
    default: _withCtx(() => [
      (!_ctx.affiliateId)
        ? (_openBlock(), _createBlock(_component_center, { key: 0 }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("h6", null, "Выплаты", -1)
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[7] || (_cache[7] = _createElementVNode("b", null, "Статус выплаты:", -1)),
            _createVNode(_component_q_btn_toggle, {
              class: "q-ml-sm filter-btn-toggle",
              unelevated: "",
              size: "xs",
              "model-value": _ctx.filters.includeStatus,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (
              _ctx.onChangeFilters('includeStatus', !_ctx.filters.includeStatus)
            )),
              "toggle-color": _ctx.filters.includeStatus ? 'positive' : 'red',
              options: [
              { label: 'IS', value: true },
              { label: 'IS NOT', value: false },
            ]
            }, null, 8, ["model-value", "toggle-color"])
          ]),
          _createVNode(_component_q_select, {
            dense: "",
            modelValue: _ctx.filters.status,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.status) = $event)),
            onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChangeFilters('status', _ctx.filters.status))),
            ref: "status",
            outlined: "",
            "use-chips": "",
            multiple: "",
            class: "col-4 q-mr-lg",
            options: _ctx.payoutStatusesEnum,
            "option-value": "id",
            "option-label": "name",
            "emit-value": "",
            "map-options": ""
          }, null, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[8] || (_cache[8] = _createElementVNode("b", null, "Дата:", -1)),
          _createVNode(_component_DatePicker, {
            id: "1",
            onChangeDate: _ctx.onChangeDate,
            from: _ctx.filters.dateFrom,
            to: _ctx.filters.dateTo
          }, null, 8, ["onChangeDate", "from", "to"])
        ])
      ]),
      _createVNode(_component_q_table, {
        rows: _ctx.rows,
        dense: "",
        columns: _ctx.columns,
        "row-key": "id",
        separator: "cell",
        pagination: _ctx.pagination,
        "onUpdate:pagination": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pagination) = $event)),
        loading: _ctx.loading,
        onRequest: _ctx.onRequest,
        onRowClick: _ctx.infoPayout,
        "binary-state-sort": "",
        "column-sort-order": "da"
      }, {
        "body-cell-affiliateEmail": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_IconWithName, {
                id: props.row.affiliateId,
                name: props.row.affiliateEmail,
                size: "20px",
                color: "secondary",
                icon: "account_box",
                entity: "affiliate"
              }, null, 8, ["id", "name"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-status": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.row.status === _ctx.payoutStatuses.Done)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 0,
                    color: "positive"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.payoutStatuses[props.row.status]), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (props.row.status === _ctx.payoutStatuses.Rejected)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 1,
                    color: "red"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.payoutStatuses[props.row.status]), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (props.row.status === _ctx.payoutStatuses.InReview)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 2,
                    color: "orange"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.payoutStatuses[props.row.status]), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (props.row.status === _ctx.payoutStatuses.Processing)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 3,
                    color: "primary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.payoutStatuses[props.row.status]), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (props.row.status === _ctx.payoutStatuses.ReadyForPayment)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 4,
                    color: "teal"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.payoutStatuses[props.row.status]), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                disable: props.row.status === _ctx.payoutStatuses.Rejected,
                round: "",
                flat: "",
                size: "sm",
                color: "primary",
                onClick: _withModifiers(($event: any) => (_ctx.editPayout(props.row)), ["stop"]),
                icon: "edit"
              }, null, 8, ["disable", "onClick"]),
              _createVNode(_component_q_btn, {
                round: "",
                flat: "",
                size: "sm",
                color: "primary",
                onClick: _withModifiers(($event: any) => (_ctx.infoPayout(null, props.row)), ["stop"]),
                icon: "info"
              }, null, 8, ["onClick"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-paidValueAmount": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(Number(props.row.paidValueAmount)
              ?.toFixed(2)
              ?.replace('.', ',')), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-paidValueCurrency": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.currencies[props.row.paidValueCurrency] || 'USD'), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-comment": _withCtx((props) => [
          _createVNode(_component_q_td, {
            props: props,
            onClick: _withModifiers(($event: any) => (_ctx.editPayout(props.row)), ["stop"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.comment || '-'), 1)
            ]),
            _: 2
          }, 1032, ["props", "onClick"])
        ]),
        "body-cell-rejectComment": _withCtx((props) => [
          _createVNode(_component_q_td, {
            props: props,
            onClick: _withModifiers(($event: any) => (_ctx.copy(props.row.rejectComment)), ["stop"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.rejectComment || '-'), 1)
            ]),
            _: 2
          }, 1032, ["props", "onClick"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "pagination", "loading", "onRequest", "onRowClick"]),
      _createVNode(_component_PayoutEditDialog, {
        open: _ctx.showPayoutEditDialog,
        onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showPayoutEditDialog = false)),
        onRefresh: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onRequest({ pagination: _ctx.pagination }))),
        currentPayout: _ctx.currentPayout
      }, null, 8, ["open", "currentPayout"])
    ]),
    _: 1
  }))
}
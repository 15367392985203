import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "col-2 q-mx-lg q-my-md"
}
const _hoisted_2 = { class: "col-2 q-mx-lg q-my-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_center = _resolveComponent("center")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_link, {
      to: "/offers/access",
      class: "row text-primary items-center q-mx-lg"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_btn, {
          "no-caps": "",
          round: "",
          class: "q-mr-md",
          color: "secondary",
          icon: "add"
        }),
        _cache[0] || (_cache[0] = _createElementVNode("b", null, "Добавить оффер", -1))
      ]),
      _: 1
    }),
    (_ctx.availableOffers.length)
      ? (_openBlock(), _createBlock(_component_center, { key: 0 }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("h6", null, "Доступные офферы", -1)
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.availableOffers.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Название оффера "))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableOffers, (offer, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col",
        key: index
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_input, {
            outlined: "",
            readonly: "",
            modelValue: offer.name,
            "onUpdate:modelValue": ($event: any) => ((offer.name) = $event)
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ])
      ]))
    }), 128))
  ], 64))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = { class: "q-mb-lg" }
const _hoisted_3 = { class: "flex flex-col gap-2" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl q-mr-lg" }, {
    default: _withCtx(() => [
      _createVNode(_component_center, null, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("h6", null, "Наши кошельки", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          class: "link-text",
          to: "/our-wallets/new"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_btn, {
              "no-caps": "",
              class: "q-ml-md",
              label: "Добавить кошелек",
              color: "primary"
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Filters, {
          onReset: _ctx.resetFilters,
          onChange: _ctx.onChangeFilters,
          onChangeAll: _ctx.changeAll,
          "current-filters": _ctx.filters
        }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters"]),
        _createVNode(_component_q_table, {
          dense: "",
          rows: _ctx.rows,
          columns: _ctx.columns,
          "row-key": "id",
          separator: "cell",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
          loading: _ctx.loading,
          onRequest: _ctx.onRequest,
          "binary-state-sort": "",
          "rows-per-page-options": [10, 25, 50, 100, 200, 500, 0]
        }, {
          "body-cell-partners": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.partners, (partner) => {
                    return (_openBlock(), _createElementBlock("a", {
                      key: partner.id,
                      class: "flex items-center gap-1"
                    }, [
                      (partner.id)
                        ? (_openBlock(), _createBlock(_component_IconWithName, {
                            key: 0,
                            id: partner.id,
                            size: "21px",
                            color: "primary",
                            icon: "people",
                            entity: "partner",
                            class: "cursor-inherit"
                          }, null, 8, ["id"]))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(partner.name), 1)
                    ]))
                  }), 128))
                ])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-paymentSystem": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.paymentSystemsEnum[props.row.paymentSystem]) + " ", 1),
                (
                props.row.paymentSystem === _ctx.paymentSystemsEnum.USDT ||
                  props.row.paymentSystem === _ctx.paymentSystemsEnum.USDC
              )
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, " (" + _toDisplayString(_ctx.paymentSystemsNetworkEnum[props.row.paymentSystemNetwork]) + ") ", 1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-actions": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  dense: "",
                  round: "",
                  flat: "",
                  color: "primary",
                  size: "sm",
                  onClick: _withModifiers(() => _ctx.$router.push('/our-wallets/' + props.row?.id), ["stop"]),
                  icon: "edit"
                }, null, 8, ["onClick"]),
                _createVNode(_component_q_btn, {
                  dense: "",
                  round: "",
                  flat: "",
                  color: "negative",
                  size: "sm",
                  onClick: _withModifiers(($event: any) => (_ctx.deleteRow(props)), ["stop"]),
                  icon: "delete"
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
      ])
    ]),
    _: 1
  }))
}
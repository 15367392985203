import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "q-mb-md" }
const _hoisted_2 = { class: "row items-center" }
const _hoisted_3 = {
  key: 0,
  class: "flex row items-center",
  style: {"width":"max-content"}
}
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "row items-center" }
const _hoisted_7 = {
  class: "flex row items-center",
  style: {"width":"max-content"}
}
const _hoisted_8 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_center, { class: "q-ma-lg" }, {
      default: _withCtx(() => [
        _createElementVNode("b", null, "Оффер #" + _toDisplayString(_ctx.offerId) + " (" + _toDisplayString(_ctx.offerName) + ")", 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_table, {
        dense: "",
        rows: _ctx.rows.affiliateRewards,
        columns: _ctx.affiliateRewardsColumns,
        separator: "cell",
        pagination: _ctx.affRewardsPagination,
        "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.affRewardsPagination) = $event)),
        loading: _ctx.loading,
        onRequest: _ctx.onRequest,
        "binary-state-sort": "",
        "column-sort-order": "da",
        "rows-per-page-options": [10, 100, 500]
      }, {
        top: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _cache[4] || (_cache[4] = _createElementVNode("b", { class: "q-mr-xs" }, "Индивидуальные ставки", -1)),
            _createVNode(_component_q_btn, {
              size: "xs",
              flat: "",
              icon: "edit",
              color: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editRewards('affiliates')))
            })
          ])
        ]),
        "body-cell-id": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_IconWithName, {
                id: props.row.id,
                name: _ctx.user?.notFullAccess ? '' : props.row.email,
                size: "20px",
                color: "secondary",
                icon: "account_box",
                entity: "affiliate"
              }, null, 8, ["id", "name"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-geoCode": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.row.geoCode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("img", {
                      class: "q-mr-sm",
                      height: "10",
                      width: "15",
                      alt: props.row.geoCode,
                      src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.geoCode}.svg`
              
                    }, null, 8, _hoisted_4),
                    _createTextVNode(" " + _toDisplayString(props.row.geoCode), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, "-"))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_q_table, {
        dense: "",
        rows: _ctx.rows.commonRewards,
        columns: _ctx.commonRewardsColumns,
        separator: "cell",
        pagination: _ctx.commonRewardsPagination,
        "onUpdate:pagination": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.commonRewardsPagination) = $event)),
        loading: _ctx.loading,
        onRequest: _ctx.onRequest,
        "binary-state-sort": "",
        "column-sort-order": "da",
        "rows-per-page-options": [10, 100, 500]
      }, {
        top: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("b", { class: "q-mr-xs" }, "Общие ставки", -1)),
            _createVNode(_component_q_btn, {
              size: "xs",
              flat: "",
              icon: "edit",
              color: "primary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editRewards('targeting')))
            })
          ])
        ]),
        "body-cell-geoCode": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  class: "q-mr-sm",
                  height: "10",
                  width: "15",
                  alt: props.row.geoCode,
                  src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.geoCode}.svg`
              
                }, null, 8, _hoisted_8),
                _createTextVNode(" " + _toDisplayString(props.row.geoCode), 1)
              ])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
    ])
  ], 64))
}
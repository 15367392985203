
import Filters from '@/components/filters/Filters.vue'
import { defineComponent } from 'vue'
import { getColumns } from './getColumns'
import { FiltersType, getDefaultFilters } from '@/utils/request'
import { getData } from '@/utils/getData'
import { FormatMoney, FormatMoneyParse } from 'format-money-js'
import { formatValue } from '@/utils'
import { LocationQueryValue } from 'vue-router'
import { exportToCsv } from '@/utils/export'
import IconWithName from '@/components/icons/IconWithName.vue'

type RowType = {
  offerAffiliateId: number
  offerAffiliateEmail: number
  usdHoldSum: number
  eurHoldSum: number
  usdRejectedSum: number
  eurRejectedSum: number
  usdApprovedSum: number
  eurApprovedSum: number
  paySum: number
}

export default defineComponent({
  name: 'AffReports',
  components: { IconWithName, Filters },
  data() {
    const filtersL = this.$storage.getStorageSync('financeAffReportGmbt') || {}
    const queryFilters = this.$route.query
    for (const [filter, value] of Object.entries(queryFilters)) {
      if (Array.isArray(value)) {
        queryFilters[filter] = value.map(v => {
          const num = Number(v)
          return isNaN(num) ? v : ((num as unknown) as LocationQueryValue)
        })
      } else {
        const numberValue = Number(value)
        queryFilters[filter] = isNaN(numberValue)
          ? [value]
          : [(numberValue as unknown) as LocationQueryValue]
      }
    }
    const filters = {
      ...getDefaultFilters(),
      ...filtersL,
      ...queryFilters,
      noAso: (filtersL.noAso || queryFilters.noAso) ?? false,
      noTraffborne:
        (filtersL.noTraffborne || queryFilters.noTraffborne) ?? false,
    }
    const defaultValues = {
      usdHoldSum: 0,
      eurHoldSum: 0,
      usdRejectedSum: 0,
      eurRejectedSum: 0,
      usdApprovedSum: 0,
      eurApprovedSum: 0,
      paySum: 0,
    }
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      loading: false,
      columns: getColumns(),
      rows: [] as RowType[],
      columnKeys: Object.keys(defaultValues),
      filters,
      chartData: [] as RowType[],
      defaultValues,
      moneyFormatter,
    }
  },
  mounted() {
    this.onRequestChartData()
  },
  computed: {
    formattedRows(): Array<{
      [key: string]: string | number | undefined | FormatMoneyParse
    }> {
      return this.rows.map(row => ({
        ...row,
        usdHoldSum: this.formatMoney(row.usdHoldSum),
        eurHoldSum: this.formatMoney(row.eurHoldSum),
        usdRejectedSum: this.formatMoney(row.usdRejectedSum),
        eurRejectedSum: this.formatMoney(row.eurRejectedSum),
        usdApprovedSum: this.formatMoney(row.usdApprovedSum),
        eurApprovedSum: this.formatMoney(row.eurApprovedSum),
        paySum: this.formatMoney(row.paySum),
      }))
    },
    total(): Record<string, number> {
      return this.rows.reduce(
        (acc, row) => {
          acc.usdHoldSum += row.usdHoldSum || 0
          acc.eurHoldSum += row.eurHoldSum || 0
          acc.usdRejectedSum += row.usdRejectedSum || 0
          acc.eurRejectedSum += row.eurRejectedSum || 0
          acc.usdApprovedSum += row.usdApprovedSum || 0
          acc.eurApprovedSum += row.eurApprovedSum || 0
          acc.paySum += row.paySum || 0
          return acc
        },
        { ...this.defaultValues },
      )
    },
  },
  watch: {
    async filters() {
      this.$storage.setStorageSync('financeAffReportGmbt', this.filters)
    },
  },
  methods: {
    async onRequestChartData() {
      const orderBy = { [String(this.filters?.groupBy)]: 'ASC' }
      this.chartData = await getData(
        {
          filters: this.filters || {},
          limit: 0,
          offset: 0,
          orderBy,
        },
        '/api/finances',
      )
      const mergedRecords: Record<string, RowType> = {}
      this.chartData.forEach(item => {
        const id = item.offerAffiliateId
        const affiliateEmail = item.offerAffiliateEmail
        if (!mergedRecords[id]) {
          mergedRecords[id] = {
            offerAffiliateId: id,
            offerAffiliateEmail: affiliateEmail,
            usdHoldSum: 0,
            eurHoldSum: 0,
            usdRejectedSum: 0,
            eurRejectedSum: 0,
            usdApprovedSum: 0,
            eurApprovedSum: 0,
            paySum: 0,
          }
        }
        mergedRecords[id].usdHoldSum += item.usdHoldSum ?? 0
        mergedRecords[id].eurHoldSum += item.eurHoldSum ?? 0
        mergedRecords[id].usdRejectedSum += item.usdRejectedSum ?? 0
        mergedRecords[id].eurRejectedSum += item.eurRejectedSum ?? 0
        mergedRecords[id].usdApprovedSum += item.usdApprovedSum ?? 0
        mergedRecords[id].eurApprovedSum += item.eurApprovedSum ?? 0
        mergedRecords[id].paySum += item.paySum ?? 0
      })

      this.rows = Object.values(mergedRecords).filter(
        (row: Record<string, number>) =>
          !this.columnKeys.every(key => row[key] === 0),
      )
    },
    formatMoney(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
      )
    },
    resetFilters() {
      this.filters = {
        ...getDefaultFilters(),
        noAso: false,
        noTraffborne: false,
      }
      this.$storage.setStorageSync('financeAffReportGmbt', {
        ...getDefaultFilters(),
      })
      this.onRequestChartData()
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) return
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters }
      this.onRequestChartData()
    },
    onExport() {
      const totalRow = {
        affiliateEmail: 'Total',
        usdHoldSum: this.total.usdHoldSum,
        eurHoldSum: this.total.eurHoldSum,
        usdRejectedSum: this.total.usdRejectedSum,
        eurRejectedSum: this.total.eurRejectedSum,
        usdApprovedSum: this.total.usdApprovedSum,
        eurApprovedSum: this.total.eurApprovedSum,
        paySum: this.total.paySum,
      }
      const exportData = [
        ...this.rows.map(row => ({
          affiliateEmail: `${row.offerAffiliateId}_${row.offerAffiliateEmail}`,
          usdHoldSum: row.usdHoldSum,
          eurHoldSum: row.eurHoldSum,
          usdRejectedSum: row.usdRejectedSum,
          eurRejectedSum: row.eurRejectedSum,
          usdApprovedSum: row.usdApprovedSum,
          eurApprovedSum: row.eurApprovedSum,
          paySum: row.paySum,
        })),
        totalRow,
      ]
      exportToCsv(exportData, this.columns)
    },
  },
})

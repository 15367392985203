import { OfferState } from '@/store/types'
import { Vertical } from '@/enums/Vertical'

function getDefaultData(oldState?: OfferState): OfferState {
  return {
    offer: {
      name: null,
      advertiserId: null,
      advertiserManagerId: null,
      vertical: Vertical.Gambling,
      categoryIds: [],
      defaultHoldInDays: null,
      defaultLeadLimitPerMonth: null,
      defaultLeadLimitPeriod: null,
      defaultInformLeadLimit: null,
      defaultInformLeadLimitPeriod: null,
      advertiserRevSharePercent: null,
      revShareHold: null,
      description: null,
      comment: null,
      status: null,
      image: null,
      commonRewards: [],
      affiliateRewards: [],
      advertiserLink: null,
      postbackLink: null,
      redirectDomainId: 15, // tracker.gambeat.io
      trafficSources: [],
      promos: [],
      translations: [],
      uploadingOperations: oldState?.offer.uploadingOperations || 0,
      isSuperOffer: false,
      borderLabel: null,
      borderColor: null,
      disablePostback: false,
      postClickReg: null,
      postClickEvent: null,
      postClickMonth: false,
    },
    errors: {},
  }
}

export default getDefaultData

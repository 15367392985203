import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "col-sm-12 q-ml-xs col-md-4" }
const _hoisted_3 = { class: "col-sm-12 q-ml-xs col-md-1" }
const _hoisted_4 = { class: "col-sm-12 q-ml-xs col-md-1" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "col-sm-12 q-ml-xs col-md-1" }
const _hoisted_7 = { class: "col-sm-12 q-ml-xs col-md-1" }
const _hoisted_8 = { class: "col-sm-12 q-ml-xs col-md-1" }
const _hoisted_9 = { class: "col-sm-12 q-ml-xs col-md-1 column q-mt-lg" }
const _hoisted_10 = { class: "col-sm-12 q-ml-xs col-md-1 column width-70 q-mt-md" }
const _hoisted_11 = {
  class: "q-mt-md q-ml-xs",
  style: {"width":"36px !important"}
}
const _hoisted_12 = {
  class: "q-mt-md",
  style: {"width":"36px !important"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_PromoTranslationsDialog = _resolveComponent("PromoTranslationsDialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promos, (promo, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "flex row q-gutter-x-md mobile-display-block",
          key: index
        }, [
          _createElementVNode("div", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("b", null, "Ссылка", -1)),
            _createVNode(_component_q_input, {
              dense: "",
              outlined: "",
              "model-value": promo.url,
              "onUpdate:modelValue": url => _ctx.updateField({ ...promo, url }, index),
              "error-message": _ctx.errors?.promos?.[index]?.url,
              error: !!_ctx.errors?.promos?.[index]?.url
            }, null, 8, ["model-value", "onUpdate:modelValue", "error-message", "error"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _cache[2] || (_cache[2] = _createElementVNode("b", null, "Название", -1)),
            _createVNode(_component_q_input, {
              dense: "",
              outlined: "",
              "model-value": promo.name,
              "onUpdate:modelValue": name => _ctx.updateField({ ...promo, name }, index),
              "error-message": _ctx.errors?.promos?.[index]?.name,
              error: !!_ctx.errors?.promos?.[index]?.name
            }, null, 8, ["model-value", "onUpdate:modelValue", "error-message", "error"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[3] || (_cache[3] = _createElementVNode("b", null, "Гео", -1)),
            _createVNode(_component_q_select, {
              dense: "",
              outlined: "",
              multiple: "",
              "use-chips": "",
              ref_for: true,
              ref: `promo-geos-${index}`,
              onBlur: ($event: any) => (_ctx.clearFilter(`promo-geos-${index}`)),
              "model-value": promo.geoIds,
              "onUpdate:modelValue": 
            geoIds => _ctx.updateField({ ...promo, geoIds }, index)
          ,
              onFilter: _ctx.getGeos,
              options: _ctx.geos,
              "option-value": "id",
              "option-label": "name",
              "emit-value": "",
              "map-options": "",
              "use-input": "",
              "error-message": _ctx.errors?.promos?.[index]?.geoIds,
              error: !!_ctx.errors?.promos?.[index]?.geoIds
            }, {
              option: _withCtx((scope) => [
                _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps({ ref_for: true }, scope.itemProps, _toHandlers(scope.itemEvents)), {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      style: {"user-select":"none","display":"initial"},
                      class: "q-mr-sm",
                      height: "10",
                      width: "15",
                      src: 
                  `http://purecatamphetamine.github.io/country-flag-icons/3x2/${scope.opt.isoCode}.svg`
                
                    }, null, 8, _hoisted_5),
                    _createVNode(_component_q_item_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_label, {
                          innerHTML: scope.opt.name
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1040)), [
                  [_directive_close_popup]
                ])
              ]),
              _: 2
            }, 1032, ["onBlur", "model-value", "onUpdate:modelValue", "onFilter", "options", "error-message", "error"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("b", null, [
              _createTextVNode("Аффилейт"),
              _createElementVNode("sup", null, "?")
            ], -1)),
            _createVNode(_component_q_tooltip, {
              "max-width": "200px",
              anchor: "bottom middle",
              self: "top middle",
              class: "q-tooltip"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Опционально. При выборе аффилейта текущее промо будет приватным и станет доступно только выбранному аффилейту и не будет отображаться другим аффилейтам. ")
              ])),
              _: 1
            }),
            _createVNode(_component_q_select, {
              dense: "",
              ref_for: true,
              ref: `affiliates-${index}`,
              onBlur: ($event: any) => (_ctx.clearFilter(`affiliates-${index}`)),
              outlined: "",
              multiple: "",
              "use-chips": "",
              "model-value": promo.affiliateIds,
              "onUpdate:modelValue": 
            affiliateIds => _ctx.updateField({ ...promo, affiliateIds }, index)
          ,
              onFilter: _ctx.getAffiliates,
              options: _ctx.affiliates,
              "option-value": "id",
              "option-label": "email",
              "emit-value": "",
              "map-options": "",
              "use-input": "",
              "error-message": _ctx.errors?.promos?.[index]?.affiliateIds,
              error: !!_ctx.errors?.promos?.[index]?.affiliateIds
            }, {
              option: _withCtx((scope) => [
                _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps({ ref_for: true }, scope.itemProps, _toHandlers(scope.itemEvents)), {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_IconWithName, {
                          id: scope.opt.id,
                          name: scope.opt.email,
                          size: "20px",
                          color: "secondary",
                          icon: "account_box",
                          entity: "affiliate"
                        }, null, 8, ["id", "name"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1040)), [
                  [_directive_close_popup]
                ])
              ]),
              _: 2
            }, 1032, ["onBlur", "model-value", "onUpdate:modelValue", "onFilter", "options", "error-message", "error"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[7] || (_cache[7] = _createElementVNode("b", null, [
              _createTextVNode("Источники"),
              _createElementVNode("sup", null, "?")
            ], -1)),
            _createVNode(_component_q_tooltip, {
              "max-width": "200px",
              anchor: "bottom middle",
              self: "top middle",
              class: "q-tooltip"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Опционально. Если выбран источник трафика, то данное промо будет доступно при создании потока только на этот источник. Если поле пустое, то промо доступно под все источники трафика, указанные во вкладке Источники на этапе создания оффера. ")
              ])),
              _: 1
            }),
            _createVNode(_component_q_select, {
              dense: "",
              ref_for: true,
              ref: `trafficSources-${index}`,
              onBlur: ($event: any) => (_ctx.clearFilter(`trafficSources-${index}`)),
              outlined: "",
              multiple: "",
              "use-chips": "",
              "model-value": (promo.trafficSources || []).map(s => s.name),
              "onUpdate:modelValue": name => _ctx.updateTrafficSource(promo, name, index),
              onFilter: _ctx.getTrafficSources,
              options: _ctx.trafficSources,
              "use-input": "",
              "error-message": _ctx.errors?.promos?.[index]?.trafficSourceIds,
              error: !!_ctx.errors?.promos?.[index]?.trafficSourceIds
            }, null, 8, ["onBlur", "model-value", "onUpdate:modelValue", "onFilter", "options", "error-message", "error"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[9] || (_cache[9] = _createElementVNode("b", null, [
              _createTextVNode("Link params"),
              _createElementVNode("sup", null, "?")
            ], -1)),
            _createVNode(_component_q_tooltip, {
              "max-width": "200px",
              anchor: "bottom middle",
              self: "top middle",
              class: "q-tooltip"
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Опционально. Уникальные дополнительные параметры в системе рекламодателя которые можно передавать при переходе на промо. ")
              ])),
              _: 1
            }),
            _createVNode(_component_q_input, {
              dense: "",
              outlined: "",
              "model-value": promo.urlExtraParams,
              "onUpdate:modelValue": 
            urlExtraParams => _ctx.updateField({ ...promo, urlExtraParams }, index)
          ,
              "error-message": _ctx.errors?.promos?.[index]?.urlExtraParams,
              error: !!_ctx.errors?.promos?.[index]?.urlExtraParams
            }, null, 8, ["model-value", "onUpdate:modelValue", "error-message", "error"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_q_tooltip, {
              "max-width": "200px",
              anchor: "bottom middle",
              self: "top middle",
              class: "q-tooltip"
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode(" Опционально. Активируйте, если хотите отображать данный Лендинг на странице предпросмотра в карточке оффера в кабинете веба. ")
              ])),
              _: 1
            }),
            _createVNode(_component_q_checkbox, {
              label: "Показывать в оффере ?",
              class: "col-md-1 col-2",
              "model-value": 
            promo.showForOffers !== undefined ? promo.showForOffers : true
          ,
              "onUpdate:modelValue": 
            showForOffers => _ctx.updateField({ ...promo, showForOffers }, index)
          
            }, null, 8, ["model-value", "onUpdate:modelValue"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_q_btn, {
              size: "sm",
              dense: "",
              "no-caps": "",
              label: "Переводы",
              onClick: ($event: any) => (_ctx.changeTranslations(index)),
              outline: "",
              color: "primary",
              icon: "edit"
            }, null, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_q_btn, {
              size: "sm",
              onClick: ($event: any) => (_ctx.deletePromo(index)),
              outline: "",
              round: "",
              color: "negative",
              icon: "delete"
            }, null, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_q_btn, {
              size: "sm",
              round: "",
              outline: "",
              color: "positive",
              onClick: ($event: any) => (_ctx.copy(index)),
              icon: "file_copy"
            }, null, 8, ["onClick"]),
            _createVNode(_component_q_tooltip, null, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("Копировать")
              ])),
              _: 1
            })
          ])
        ]))
      }), 128)),
      _createVNode(_component_q_btn, {
        onClick: _ctx.addPromo,
        round: "",
        color: "secondary",
        icon: "add",
        size: "sm"
      }, null, 8, ["onClick"])
    ]),
    _createVNode(_component_PromoTranslationsDialog, {
      open: _ctx.showTranslationsDialog,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTranslationsDialog = false)),
      defaultOptions: _ctx.defaultOptions,
      promoIndex: _ctx.promoIndex
    }, null, 8, ["open", "defaultOptions", "promoIndex"])
  ], 64))
}
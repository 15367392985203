import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "flex justify-between q-mb-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_GeneralInfo = _resolveComponent("GeneralInfo")!
  const _component_Promos = _resolveComponent("Promos")!
  const _component_Postbacks = _resolveComponent("Postbacks")!
  const _component_RedirectDomain = _resolveComponent("RedirectDomain")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-layout-container" }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_q_spinner, {
            key: 0,
            color: "primary",
            size: "3em",
            thickness: 2
          }))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_form, {
              onSubmit: _ctx.onSubmit,
              onReset: _ctx.onReset,
              class: "q-gutter-md q-mx-xl"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_GeneralInfo, {
                  onSelect: _ctx.selectOffer,
                  defaultOptions: _ctx.defaultOptions
                }, null, 8, ["onSelect", "defaultOptions"]),
                (_ctx.campaign.offerId)
                  ? (_openBlock(), _createBlock(_component_Promos, {
                      key: 0,
                      defaultOptions: _ctx.defaultOptions
                    }, null, 8, ["defaultOptions"]))
                  : _createCommentVNode("", true),
                _cache[0] || (_cache[0] = _createElementVNode("h6", null, "Настройки Postback:", -1)),
                _createVNode(_component_Postbacks),
                _createVNode(_component_RedirectDomain, { defaultOptions: _ctx.defaultOptions }, null, 8, ["defaultOptions"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_q_btn, {
                    label: "Отменить",
                    type: "reset",
                    color: "primary",
                    flat: "",
                    class: "q-ml-sm"
                  }),
                  _createVNode(_component_q_btn, {
                    flat: "",
                    label: "Сохранить",
                    "no-caps": "",
                    type: "submit",
                    color: "positive",
                    icon: "save"
                  })
                ])
              ]),
              _: 1
            }, 8, ["onSubmit", "onReset"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
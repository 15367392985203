import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "col-sm-12 col-lg-2" }
const _hoisted_3 = { class: "col-sm-12 col-lg-2" }
const _hoisted_4 = { class: "col-sm-12 col-lg-2" }
const _hoisted_5 = { class: "col-sm-12 col-lg-4" }
const _hoisted_6 = { class: "col-sm-12 col-lg-2" }
const _hoisted_7 = { class: "col-sm-12 col-lg-1 self-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rules, (rule, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "flex row q-gutter-x-md q-mb-xl mobile-display-block",
        key: index
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("b", null, "Аффилейт", -1)),
          _createVNode(_component_q_select, {
            ref_for: true,
            ref: `affiliates-${index}`,
            onBlur: ($event: any) => (_ctx.clearFilter(`affiliates-${index}`, rule)),
            outlined: "",
            "model-value": rule.affiliateId,
            "onUpdate:modelValue": 
            affiliateId => _ctx.updateField({ ...rule, affiliateId }, index)
          ,
            onFilter: _ctx.getAffiliates,
            options: _ctx.affiliates,
            "option-value": "id",
            "option-label": "email",
            "emit-value": "",
            "map-options": "",
            "use-input": "",
            "error-message": _ctx.errors?.rules?.[index]?.affiliateId,
            error: !!_ctx.errors?.rules?.[index]?.affiliateId
          }, {
            option: _withCtx((scope) => [
              _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps({ ref_for: true }, scope.itemProps, _toHandlers(scope.itemEvents)), {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_IconWithName, {
                        id: scope.opt.id,
                        name: scope.opt.email,
                        size: "20px",
                        color: "secondary",
                        icon: "account_box",
                        entity: "affiliate"
                      }, null, 8, ["id", "name"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1040)), [
                [_directive_close_popup]
              ])
            ]),
            _: 2
          }, 1032, ["onBlur", "model-value", "onUpdate:modelValue", "onFilter", "options", "error-message", "error"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("b", null, "Гео (Органика)", -1)),
          _createVNode(_component_q_select, {
            outlined: "",
            multiple: "",
            "use-chips": "",
            ref_for: true,
            ref: `rule-geos-${index}`,
            onBlur: ($event: any) => (_ctx.clearFilter(`rule-geos-${index}`)),
            "model-value": rule.geoIds,
            disable: !rule.affiliateId,
            "onUpdate:modelValue": 
            geoIds => _ctx.updateField({ ...rule, geoIds }, index)
          ,
            onFilter: (val, upd) => _ctx.getGeos(val, upd, rule),
            options: _ctx.geos,
            "option-value": "id",
            "option-label": "name",
            "emit-value": "",
            "map-options": "",
            "use-input": "",
            "error-message": _ctx.errors?.rules?.[index]?.geoIds,
            error: !!_ctx.errors?.rules?.[index]?.geoIds
          }, null, 8, ["onBlur", "model-value", "disable", "onUpdate:modelValue", "onFilter", "options", "error-message", "error"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("b", null, "Нейминг", -1)),
          _createVNode(_component_q_input, {
            outlined: "",
            hint: 
            `bayerid = ${rule.affiliateId}. В сабах нельзя использовать нижнее подчеркивание _`
          ,
            "hide-hint": !rule.affiliateId,
            placeholder: "bayerid-ruleid_sub1_sub2_sub3_sub4_sub5_sub6_sub7",
            disable: !rule.affiliateId,
            "model-value": _ctx.getFullNaming(rule, index),
            "onUpdate:modelValue": 
            naming => _ctx.updateField({ ...rule, naming }, index)
          ,
            "error-message": _ctx.errors?.rules?.[index]?.naming,
            error: !!_ctx.errors?.rules?.[index]?.naming
          }, null, 8, ["hint", "hide-hint", "disable", "model-value", "onUpdate:modelValue", "error-message", "error"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[4] || (_cache[4] = _createElementVNode("b", null, [
            _createTextVNode("Ссылка для редиректа на оффер "),
            _createElementVNode("sup", null, "?")
          ], -1)),
          _createVNode(_component_q_tooltip, {
            "max-width": "500px",
            anchor: "bottom middle",
            self: "top middle",
            class: "q-tooltip"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" URL кампании с нашей ПП или с трекера веба. Если это ссылка от веба - то в какое то из полей обязательно нужно подставить ${click_id}, чтобы веб отправлял нам постбеки на TDS. Для передачи сабов из нейминга использовать ${sub1}, ${sub2}, ${sub3}, ${sub4}, ${sub5}, ${sub6}, ${sub7}. Пример: https://keitaro.io/s8df76sd7f8/?sub1=${sub1}&sub2=${sub2}&sub3=${sub3}&sub4=${sub4}&sub5=${sub5}&sub6=${click_id} ")
            ])),
            _: 1
          }),
          _createVNode(_component_q_input, {
            outlined: "",
            placeholder: "https://1.tracker.gambeat.io/campaign",
            "model-value": rule.redirectUrl,
            "onUpdate:modelValue": 
            redirectUrl => _ctx.updateField({ ...rule, redirectUrl }, index)
          ,
            "error-message": _ctx.errors?.rules?.[index]?.redirectUrl,
            error: !!_ctx.errors?.rules?.[index]?.redirectUrl
          }, null, 8, ["model-value", "onUpdate:modelValue", "error-message", "error"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[6] || (_cache[6] = _createElementVNode("b", null, [
            _createTextVNode("Google Campaign ID "),
            _createElementVNode("sup", null, "?")
          ], -1)),
          _createVNode(_component_q_tooltip, {
            "max-width": "400px",
            anchor: "bottom middle",
            self: "top middle",
            class: "q-tooltip"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" Оригинальный нейминг от Google, задается для идентификации первых 30 инсталлов. ")
            ])),
            _: 1
          }),
          _createVNode(_component_q_input, {
            outlined: "",
            placeholder: "123890298374832",
            "model-value": rule.originalGoogleCampaignId,
            "onUpdate:modelValue": 
            originalGoogleCampaignId =>
              _ctx.updateField({ ...rule, originalGoogleCampaignId }, index)
          ,
            "error-message": _ctx.errors?.rules?.[index]?.originalGoogleCampaignId,
            error: !!_ctx.errors?.rules?.[index]?.originalGoogleCampaignId
          }, null, 8, ["model-value", "onUpdate:modelValue", "error-message", "error"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_q_btn, {
            onClick: ($event: any) => (_ctx.deleteRule(index)),
            outline: "",
            round: "",
            color: "primary",
            icon: "delete"
          }, null, 8, ["onClick"])
        ])
      ]))
    }), 128)),
    _createVNode(_component_q_btn, {
      onClick: _ctx.addRule,
      round: "",
      color: "secondary",
      icon: "add"
    }, null, 8, ["onClick"])
  ]))
}
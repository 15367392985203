import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row q-mt-md"
}
const _hoisted_2 = { class: "flex items-center justify-center" }
const _hoisted_3 = {
  key: 1,
  class: "row q-ma-md"
}
const _hoisted_4 = {
  key: 0,
  class: "col-2"
}
const _hoisted_5 = {
  key: 1,
  class: "col-2"
}
const _hoisted_6 = { class: "row items-center justify-between" }
const _hoisted_7 = {
  key: 2,
  class: "col-2"
}
const _hoisted_8 = { class: "row items-center justify-between" }
const _hoisted_9 = {
  key: 3,
  class: "col-2"
}
const _hoisted_10 = { class: "row items-center justify-between" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 4,
  class: "col-2"
}
const _hoisted_13 = { class: "row items-center justify-between" }
const _hoisted_14 = {
  key: 5,
  class: "col-2"
}
const _hoisted_15 = { class: "row items-center justify-between" }
const _hoisted_16 = {
  key: 6,
  class: "col-2"
}
const _hoisted_17 = { class: "row items-center justify-between" }
const _hoisted_18 = {
  key: 7,
  class: "col-2"
}
const _hoisted_19 = {
  key: 8,
  class: "col-2"
}
const _hoisted_20 = { class: "row items-center justify-between" }
const _hoisted_21 = {
  key: 9,
  class: "col-2"
}
const _hoisted_22 = {
  key: 10,
  class: "col-2"
}
const _hoisted_23 = { class: "row items-center justify-between" }
const _hoisted_24 = {
  class: "col-2",
  style: {"width":"256px"}
}
const _hoisted_25 = {
  key: 11,
  class: "self-center q-mt-md q-mr-md"
}
const _hoisted_26 = { class: "q-mr-md q-mt-md self-center" }
const _hoisted_27 = { class: "q-mr-md q-mt-md self-center" }
const _hoisted_28 = {
  key: 12,
  class: "self-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_FiltersDialog = _resolveComponent("FiltersDialog")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_ctx.$q.screen.width < 900)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_btn, {
            "no-caps": "",
            class: "q-my-md q-mr-md",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFiltersDialog = !_ctx.showFiltersDialog)),
            outline: "",
            color: "secondary",
            icon: "tune",
            label: "Фильтры"
          })
        ]),
        (_ctx.showFiltersDialog)
          ? (_openBlock(), _createBlock(_component_FiltersDialog, {
              key: 0,
              open: _ctx.showFiltersDialog,
              currentFilters: _ctx.filters,
              onChangeFilters: _ctx.submitMobileAll,
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFiltersDialog = false)),
              tab: _ctx.tab
            }, null, 8, ["open", "currentFilters", "onChangeFilters", "tab"]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        (
        _ctx.hideForAffiliates &&
          _ctx.hideForStatistics &&
          _ctx.hideForBalances &&
          _ctx.hideForPushNotifications &&
          _ctx.hideForFinances &&
          _ctx.tab !== 'black-list-logs'
      )
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[36] || (_cache[36] = _createElementVNode("b", null, "Поиск:", -1)),
              _createVNode(_component_q_input, {
                dense: "",
                outlined: "",
                debounce: "500",
                class: "col-4 q-mr-lg",
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event))
              }, null, 8, ["modelValue"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.hideForBalances && !_ctx.showForPushGroups)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[37] || (_cache[37] = _createElementVNode("b", null, "Приложения:", -1)),
                _createVNode(_component_q_btn_toggle, {
                  class: "q-ml-sm filter-btn-toggle",
                  unelevated: "",
                  size: "xs",
                  modelValue: _ctx.filters.includeMobileAppIds,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filters.includeMobileAppIds) = $event)),
                  "toggle-color": _ctx.filters.includeMobileAppIds ? 'positive' : 'red',
                  options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                }, null, 8, ["modelValue", "toggle-color"])
              ]),
              _createVNode(_component_q_select, {
                dense: "",
                "use-chips": "",
                modelValue: _ctx.filters.mobileAppIds,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filters.mobileAppIds) = $event)),
                onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onChangeFilters('mobileAppIds', _ctx.filters.mobileAppIds))),
                outlined: "",
                ref: "mobileAppIds",
                multiple: "",
                class: "col-4 q-mr-lg",
                options: _ctx.apps,
                onFilter: _cache[6] || (_cache[6] = 
          (value, updateFn) => _ctx.getOptionsByField(value, updateFn, 'apps')
        ),
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["modelValue", "options"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.hideForPushNotifications && _ctx.tab !== 'black-list-logs')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[38] || (_cache[38] = _createElementVNode("b", null, "Аффилейты:", -1)),
                _createVNode(_component_q_btn_toggle, {
                  class: "q-ml-sm filter-btn-toggle",
                  unelevated: "",
                  size: "xs",
                  modelValue: _ctx.filters.includeAffiliateIds,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filters.includeAffiliateIds) = $event)),
                  "toggle-color": _ctx.filters.includeAffiliateIds ? 'positive' : 'red',
                  options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                }, null, 8, ["modelValue", "toggle-color"])
              ]),
              _createVNode(_component_q_select, {
                dense: "",
                modelValue: _ctx.filters.affiliateIds,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filters.affiliateIds) = $event)),
                onFilter: _ctx.getAffiliates,
                onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onChangeFilters('affiliateIds', _ctx.filters.affiliateIds))),
                outlined: "",
                ref: "affiliateIds",
                multiple: "",
                "use-chips": "",
                class: "col-4 q-mr-lg",
                options: _ctx.affiliates,
                "option-value": "id",
                "option-label": "email",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, {
                option: _withCtx((scope) => [
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_IconWithName, {
                            id: scope.opt.id,
                            name: scope.opt.email,
                            size: "20px",
                            color: "secondary",
                            icon: "account_box",
                            entity: "affiliate"
                          }, null, 8, ["id", "name"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1040)), [
                    [_directive_close_popup]
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "onFilter", "options"])
            ]))
          : _createCommentVNode("", true),
        (
        _ctx.showForPostbackBlackListTab &&
          _ctx.hideForBalances &&
          _ctx.hideForPushNotifications
      )
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _cache[39] || (_cache[39] = _createElementVNode("b", null, "ГЕО:", -1)),
                _createVNode(_component_q_btn_toggle, {
                  class: "q-ml-sm filter-btn-toggle",
                  unelevated: "",
                  size: "xs",
                  "model-value": _ctx.filters.includeGeoIds,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (
            _ctx.onChangeFilters('includeGeoIds', !_ctx.filters.includeGeoIds)
          )),
                  "toggle-color": _ctx.filters.includeGeoIds ? 'positive' : 'red',
                  options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                }, null, 8, ["model-value", "toggle-color"])
              ]),
              _createVNode(_component_q_select, {
                dense: "",
                modelValue: _ctx.filters.geoIds,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.filters.geoIds) = $event)),
                onBlur: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onChangeFilters('geoIds', _ctx.filters.geoIds))),
                outlined: "",
                ref: "geoIds",
                multiple: "",
                "use-chips": "",
                class: "col-4 q-mr-lg",
                options: _ctx.geos,
                onFilter: _cache[13] || (_cache[13] = 
          (value, updateFn) => _ctx.getOptionsByField(value, updateFn, 'geos')
        ),
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, {
                option: _withCtx((scope) => [
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        style: {"user-select":"none","display":"initial"},
                        class: "q-mr-sm",
                        height: "10",
                        width: "15",
                        src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${scope.opt.isoCode}.svg`
              
                      }, null, 8, _hoisted_11),
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_label, {
                            innerHTML: scope.opt.name
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1040)), [
                    [_directive_close_popup]
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "options"])
            ]))
          : _createCommentVNode("", true),
        (
        _ctx.hideForStatistics &&
          _ctx.hideForPostbackLogs &&
          _ctx.hideForBalances &&
          _ctx.hideForPushNotifications &&
          _ctx.hideForFinances
      )
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _cache[40] || (_cache[40] = _createElementVNode("b", null, "Источник трафа:", -1)),
                _createVNode(_component_q_btn_toggle, {
                  class: "q-ml-sm filter-btn-toggle",
                  unelevated: "",
                  size: "xs",
                  modelValue: _ctx.filters.includeTrafficSourceId,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.filters.includeTrafficSourceId) = $event)),
                  "toggle-color": _ctx.filters.includeTrafficSourceId ? 'positive' : 'red',
                  options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                }, null, 8, ["modelValue", "toggle-color"])
              ]),
              _createVNode(_component_q_select, {
                dense: "",
                modelValue: _ctx.filters.trafficSourceId,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.filters.trafficSourceId) = $event)),
                onBlur: _cache[16] || (_cache[16] = ($event: any) => (_ctx.onChangeFilters('trafficSourceId', _ctx.filters.trafficSourceId))),
                outlined: "",
                class: "col-4 q-mr-lg",
                options: _ctx.trafficSources,
                onFilter: _ctx.getTrafficSources,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["modelValue", "options", "onFilter"])
            ]))
          : _createCommentVNode("", true),
        (
        _ctx.hideForStatistics &&
          _ctx.hideForPostbackLogs &&
          _ctx.hideForMobileAppsPage &&
          _ctx.hideForBalances &&
          _ctx.hideForAffiliates &&
          _ctx.hideForPushNotifications &&
          _ctx.hideForFinances
      )
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _cache[41] || (_cache[41] = _createElementVNode("b", null, "Афф. менеджер:", -1)),
                _createVNode(_component_q_btn_toggle, {
                  class: "q-ml-sm filter-btn-toggle",
                  unelevated: "",
                  size: "xs",
                  modelValue: _ctx.filters.includeManagerId,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.filters.includeManagerId) = $event)),
                  "toggle-color": _ctx.filters.includeManagerId ? 'positive' : 'red',
                  options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                }, null, 8, ["modelValue", "toggle-color"])
              ]),
              _createVNode(_component_q_select, {
                dense: "",
                disable: true,
                modelValue: _ctx.filters.managerId,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.filters.managerId) = $event)),
                onBlur: _cache[19] || (_cache[19] = ($event: any) => (_ctx.onChangeFilters('managerId', _ctx.filters.managerId))),
                outlined: "",
                class: "col-4 q-mr-lg",
                options: _ctx.admins,
                onFilter: _cache[20] || (_cache[20] = 
          (value, updateFn) => _ctx.getOptionsByField(value, updateFn, 'admins')
        ),
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": "",
                "use-input": ""
              }, null, 8, ["modelValue", "options"])
            ]))
          : _createCommentVNode("", true),
        (
        _ctx.hideForStatistics &&
          _ctx.hideForPostbackLogs &&
          _ctx.hideForMobileAppsPage &&
          _ctx.hideForBalances &&
          _ctx.hideForPushNotifications &&
          _ctx.hideForFinances
      )
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _cache[42] || (_cache[42] = _createElementVNode("b", null, "Статус:", -1)),
                _createVNode(_component_q_btn_toggle, {
                  class: "q-ml-sm filter-btn-toggle",
                  unelevated: "",
                  size: "xs",
                  modelValue: _ctx.filters.includeStatus,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.filters.includeStatus) = $event)),
                  "toggle-color": _ctx.filters.includeStatus ? 'positive' : 'red',
                  options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                }, null, 8, ["modelValue", "toggle-color"])
              ]),
              _createVNode(_component_q_select, {
                dense: "",
                disable: true,
                modelValue: _ctx.filters.status,
                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.filters.status) = $event)),
                onBlur: _cache[23] || (_cache[23] = ($event: any) => (_ctx.onChangeFilters('status', _ctx.filters.status))),
                outlined: "",
                class: "col-4 q-mr-lg",
                options: _ctx.affiliateStatuses,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": ""
              }, null, 8, ["modelValue", "options"])
            ]))
          : _createCommentVNode("", true),
        (
        _ctx.hideForAffiliates &&
          _ctx.hideForPostbackLogs &&
          _ctx.hideForBalances &&
          _ctx.hideForPushNotifications
      )
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _cache[43] || (_cache[43] = _createElementVNode("b", null, "Вертикаль:", -1)),
              _createVNode(_component_q_select, {
                dense: "",
                modelValue: _ctx.filters.vertical,
                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.filters.vertical) = $event)),
                onBlur: _cache[25] || (_cache[25] = ($event: any) => (_ctx.onChangeFilters('vertical', _ctx.filters.vertical))),
                outlined: "",
                class: "col-4 q-mr-lg",
                options: _ctx.verticals,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": ""
              }, null, 8, ["modelValue", "options"])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.hideForMobileAppsPage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _cache[44] || (_cache[44] = _createElementVNode("b", null, "Статус:", -1)),
                _createVNode(_component_q_btn_toggle, {
                  class: "q-ml-sm filter-btn-toggle",
                  unelevated: "",
                  size: "xs",
                  modelValue: _ctx.filters.includeStatus,
                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.filters.includeStatus) = $event)),
                  "toggle-color": _ctx.filters.includeStatus ? 'positive' : 'red',
                  options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                }, null, 8, ["modelValue", "toggle-color"])
              ]),
              _createVNode(_component_q_select, {
                dense: "",
                modelValue: _ctx.filters.status,
                "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.filters.status) = $event)),
                onBlur: _cache[28] || (_cache[28] = ($event: any) => (_ctx.onChangeFilters('status', _ctx.filters.status))),
                outlined: "",
                class: "col-4 q-mr-lg",
                options: _ctx.mobileAppStatuses,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": ""
              }, null, 8, ["modelValue", "options"])
            ]))
          : _createCommentVNode("", true),
        (
        _ctx.hideForAffiliates &&
          _ctx.hideForMobileAppsPage &&
          _ctx.hideForPostbackLogs &&
          _ctx.hideForBalances &&
          _ctx.hideForPushNotifications
      )
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
              _cache[45] || (_cache[45] = _createElementVNode("b", null, "Группировать по:", -1)),
              _createVNode(_component_q_select, {
                dense: "",
                modelValue: _ctx.filters.groupBy,
                "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.filters.groupBy) = $event)),
                outlined: "",
                onBlur: _cache[30] || (_cache[30] = ($event: any) => (_ctx.onChangeFilters('groupBy', _ctx.filters.groupBy))),
                class: "col-4 q-mr-lg",
                options: _ctx.groupByOptions,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": ""
              }, null, 8, ["modelValue", "options"])
            ]))
          : _createCommentVNode("", true),
        (
        _ctx.tab === 'black-list-logs' ||
          _ctx.tab === 'installs' ||
          !_ctx.hideForMobileAppsPage
      )
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _cache[46] || (_cache[46] = _createElementVNode("b", null, "Маркет:", -1)),
                _createVNode(_component_q_btn_toggle, {
                  class: "q-ml-sm filter-btn-toggle",
                  unelevated: "",
                  size: "xs",
                  "model-value": _ctx.filters.includeAppMarket,
                  "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => (
            _ctx.onChangeFilters('includeAppMarket', !_ctx.filters.includeAppMarket)
          )),
                  "toggle-color": _ctx.filters.includeAppMarket ? 'positive' : 'red',
                  options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                }, null, 8, ["model-value", "toggle-color"])
              ]),
              _createVNode(_component_q_select, {
                dense: "",
                modelValue: _ctx.filters.appMarket,
                "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.filters.appMarket) = $event)),
                onBlur: _cache[33] || (_cache[33] = ($event: any) => (_ctx.onChangeFilters('appMarket', _ctx.filters.appMarket))),
                outlined: "",
                "use-chips": "",
                ref: "appMarket",
                class: "col-4 q-mr-lg",
                options: _ctx.appMarkets,
                "option-value": "id",
                "option-label": "name",
                "emit-value": "",
                "map-options": ""
              }, null, 8, ["modelValue", "options"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_24, [
          _cache[47] || (_cache[47] = _createElementVNode("b", null, "Дата:", -1)),
          _createVNode(_component_DatePicker, {
            id: "1",
            onChangeDate: _ctx.onChangeDate,
            from: _ctx.filters.dateFrom,
            to: _ctx.filters.dateTo
          }, null, 8, ["onChangeDate", "from", "to"])
        ]),
        (_ctx.tab === 'black-list-logs' || _ctx.tab === 'installs')
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
              _createVNode(_component_q_checkbox, {
                label: "Заглушка",
                "model-value": _ctx.filters.showBlockGeos,
                onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.onChangeFilters('showBlockGeos', !_ctx.filters.showBlockGeos)))
              }, null, 8, ["model-value"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_26, [
          _createVNode(_component_q_btn, {
            "no-caps": "",
            icon: "done",
            color: "positive",
            label: "Применить",
            size: "13px",
            onClick: _ctx.submitAll
          }, null, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createVNode(_component_q_btn, {
            round: "",
            size: "sm",
            color: "red",
            icon: "autorenew",
            onClick: _ctx.resetFilters
          }, null, 8, ["onClick"])
        ]),
        (!_ctx.hideForPostbackLogs)
          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
              _createVNode(_component_q_btn, {
                class: "q-mt-md",
                size: "sm",
                round: "",
                color: "primary",
                icon: "get_app",
                onClick: _cache[35] || (_cache[35] = ($event: any) => (_ctx.$emit('export')))
              })
            ]))
          : _createCommentVNode("", true)
      ]))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row justify-between items-center mobile-display-block" }
const _hoisted_2 = { class: "q-mb-md q-ml-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_center = _resolveComponent("center")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_PostbackNoteDialog = _resolveComponent("PostbackNoteDialog")!
  const _component_Installs = _resolveComponent("Installs")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_TdsPostbackLogs = _resolveComponent("TdsPostbackLogs")!
  const _component_MobileAppLogs = _resolveComponent("MobileAppLogs")!
  const _component_BlackListLogs = _resolveComponent("BlackListLogs")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-mx-lg q-my-xl q-pl-lg" }, {
    default: _withCtx(() => [
      (Object.keys(_ctx.$route.params)?.length)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 0,
            "no-caps": "",
            icon: "chevron_left",
            flat: "",
            label: "Назад",
            onClick: _ctx.backToStats
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_center, null, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("h6", null, "Логи", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_tabs, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
          "inline-label": "",
          "no-caps": "",
          breakpoint: 0,
          align: "justify",
          class: "shadow-2 q-ml-lg"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tab, {
              name: "installs",
              label: "Инсталлы"
            }),
            _createVNode(_component_q_tab, {
              name: "tds-logs",
              label: "Принятые постбеки"
            }),
            _createVNode(_component_q_tab, {
              name: "mobile-apps-logs",
              label: "Отправленные постбеки"
            }),
            _createVNode(_component_q_tab, {
              name: "black-list-logs",
              label: "Клоака"
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_btn, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPostbackNoteDialog = !_ctx.showPostbackNoteDialog)),
            class: "col-1 q-mr-md q-mt-lg",
            icon: "help",
            "no-caps": "",
            label: "Справка"
          })
        ])
      ]),
      _createVNode(_component_PostbackNoteDialog, {
        open: _ctx.showPostbackNoteDialog,
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPostbackNoteDialog = false))
      }, null, 8, ["open"]),
      _createVNode(_component_q_tab_panels, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tab) = $event)),
        animated: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab_panel, { name: "installs" }, {
            default: _withCtx(() => [
              _createVNode(_component_Installs)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "tds-logs" }, {
            default: _withCtx(() => [
              _createVNode(_component_TdsPostbackLogs)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "mobile-apps-logs" }, {
            default: _withCtx(() => [
              _createVNode(_component_MobileAppLogs)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "black-list-logs" }, {
            default: _withCtx(() => [
              _createVNode(_component_BlackListLogs)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
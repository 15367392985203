import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "row mobile-display-block" }
const _hoisted_3 = { class: "col-sm-12 col-lg-2 q-mr-lg" }
const _hoisted_4 = { class: "row justify-between items-center" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "col-sm-12 col-lg-2 q-mr-lg" }
const _hoisted_10 = { class: "col-sm-12 col-lg-6 q-mr-lg" }
const _hoisted_11 = { class: "row items-center" }
const _hoisted_12 = { class: "flex column col-4" }
const _hoisted_13 = { class: "q-mr-md" }
const _hoisted_14 = { class: "flex column col-7" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  class: "q-ml-sm absolute",
  style: {"right":"10px"}
}
const _hoisted_17 = { class: "row mobile-display-block" }
const _hoisted_18 = { class: "col-sm-12 col-lg-6" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "col-sm-12 col-lg-6" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = {
  key: 0,
  class: "q-mt-md q-mb-none"
}
const _hoisted_23 = {
  key: 1,
  class: "row mobile-display-block"
}
const _hoisted_24 = { class: "col-sm-12 col-lg-6" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { class: "col-sm-12 col-lg-6" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { class: "col-sm-12 col-lg-4" }
const _hoisted_29 = { class: "col-sm-12 col-lg-4" }
const _hoisted_30 = { class: "col-sm-12 col-lg-1 self-center" }
const _hoisted_31 = { class: "row self-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_center = _resolveComponent("center")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_popup_edit = _resolveComponent("q-popup-edit")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_Balances = _resolveComponent("Balances")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_AffiliateCommentDialog = _resolveComponent("AffiliateCommentDialog")!
  const _component_AddAffiliateTagsDialog = _resolveComponent("AddAffiliateTagsDialog")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl q-pl-lg" }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_center, {
            key: 0,
            class: "q-mt-xl"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_spinner, {
                color: "primary",
                size: "3em",
                thickness: 2
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[51] || (_cache[51] = _createElementVNode("h6", { class: "flex flex-center" }, "Редактирование аффилейта", -1)),
            _cache[52] || (_cache[52] = _createElementVNode("h6", { class: "q-mt-none" }, "Общая информация", -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _cache[29] || (_cache[29] = _createElementVNode("b", null, "ID", -1)),
                _createVNode(_component_q_input, {
                  dense: "",
                  class: "q-mb-md q-pb-xs",
                  outlined: "",
                  readonly: "",
                  modelValue: _ctx.affiliate.id,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.affiliate.id) = $event))
                }, null, 8, ["modelValue"]),
                _cache[30] || (_cache[30] = _createElementVNode("b", null, "Telegram", -1)),
                _createVNode(_component_q_input, {
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.affiliate.contacts.telegram,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.affiliate.contacts.telegram) = $event)),
                  error: !!_ctx.errors?.contacts,
                  "error-message": _ctx.errors?.contacts
                }, null, 8, ["modelValue", "error", "error-message"]),
                _cache[31] || (_cache[31] = _createElementVNode("b", null, "Skype", -1)),
                _createVNode(_component_q_input, {
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.affiliate.contacts.skype,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.affiliate.contacts.skype) = $event)),
                  error: !!_ctx.errors?.contacts,
                  "error-message": _ctx.errors?.contacts
                }, null, 8, ["modelValue", "error", "error-message"]),
                _cache[32] || (_cache[32] = _createElementVNode("b", null, "Цена за инсталл", -1)),
                _createVNode(_component_q_input, {
                  type: "number",
                  step: "0.01",
                  min: "0",
                  outlined: "",
                  placeholder: "Например: 0.45",
                  modelValue: _ctx.affiliate.installValue.amount,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.affiliate.installValue.amount) = $event)),
                  error: !!_ctx.errors?.installValue,
                  "error-message": _ctx.errors?.installValue
                }, {
                  append: _withCtx(() => _cache[18] || (_cache[18] = [
                    _createTextVNode("$")
                  ])),
                  _: 1
                }, 8, ["modelValue", "error", "error-message"]),
                _cache[33] || (_cache[33] = _createElementVNode("b", null, "Пароль", -1)),
                _createVNode(_component_q_input, {
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.newPassword,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newPassword) = $event)),
                  error: !!_ctx.errors?.contacts,
                  "error-message": _ctx.errors?.contacts
                }, null, 8, ["modelValue", "error", "error-message"]),
                _cache[34] || (_cache[34] = _createElementVNode("b", null, "Минимальная сумма вывода", -1)),
                _createVNode(_component_q_input, {
                  dense: "",
                  outlined: "",
                  min: "1",
                  max: "1000000",
                  modelValue: _ctx.affiliate.minPayoutAmount,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.affiliate.minPayoutAmount) = $event)),
                  modelModifiers: { number: true },
                  error: !!_ctx.errors?.minPayoutAmount,
                  "error-message": _ctx.errors?.minPayoutAmount
                }, null, 8, ["modelValue", "error", "error-message"]),
                _createElementVNode("div", null, [
                  _cache[27] || (_cache[27] = _createElementVNode("b", null, "Маркеры:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.affiliate.tag) + " ", 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.affiliate.markers, (marker, i) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: i,
                      class: "q-mr-sm",
                      onClick: _withModifiers(() => true, ["stop"])
                    }, [
                      _createVNode(_component_q_badge, {
                        class: "cursor-pointer",
                        style: _normalizeStyle(`background: ${marker.color} !important;`)
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(marker.name), 1),
                          _createVNode(_component_q_popup_edit, {
                            class: "bg-grey-9 text-white marker-popup",
                            anchor: "bottom middle"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_4, [
                                _createElementVNode("span", null, [
                                  _cache[19] || (_cache[19] = _createElementVNode("b", null, "Название:", -1)),
                                  _cache[20] || (_cache[20] = _createTextVNode()),
                                  _createElementVNode("b", null, _toDisplayString(marker.name), 1)
                                ]),
                                _createElementVNode("span", null, [
                                  _createVNode(_component_q_btn, {
                                    icon: "edit",
                                    size: "xs",
                                    round: "",
                                    flat: "",
                                    onClick: _withModifiers(($event: any) => (_ctx.addMarker(marker.id)), ["stop"])
                                  }, null, 8, ["onClick"]),
                                  _createVNode(_component_q_btn, {
                                    icon: "delete",
                                    size: "xs",
                                    round: "",
                                    flat: "",
                                    onClick: _withModifiers(($event: any) => (
                          _ctx.deleteMarker(_ctx.affiliate.id, marker.id, marker.name)
                        ), ["stop"])
                                  }, null, 8, ["onClick"])
                                ])
                              ]),
                              _createElementVNode("div", null, [
                                _cache[21] || (_cache[21] = _createElementVNode("b", null, "Описание:", -1)),
                                _cache[22] || (_cache[22] = _createTextVNode()),
                                _createElementVNode("span", {
                                  innerHTML: marker.reason
                                }, null, 8, _hoisted_5)
                              ]),
                              _createElementVNode("div", null, [
                                _cache[23] || (_cache[23] = _createElementVNode("b", null, "Добавлено:", -1)),
                                _createTextVNode(" " + _toDisplayString(_ctx.format(marker.createdAt)), 1)
                              ]),
                              _createElementVNode("div", null, [
                                _cache[24] || (_cache[24] = _createElementVNode("b", null, "Кто добавил:", -1)),
                                _createTextVNode(" " + _toDisplayString(marker.addedBySystem
                        ? 'Добавлено системой'
                        : marker.createdBy || 'Добавлено системой'), 1)
                              ]),
                              (
                      _ctx.format(marker.updatedAt) !== _ctx.format(marker.createdAt) &&
                        marker.updatedBy &&
                        marker.updatedAt
                    )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                    _cache[25] || (_cache[25] = _createElementVNode("b", null, "Изменено:", -1)),
                                    _createTextVNode(" " + _toDisplayString(_ctx.format(marker.updatedAt)), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (
                      _ctx.format(marker.updatedAt) !== _ctx.format(marker.createdAt) &&
                        marker.updatedBy &&
                        marker.updatedAt
                    )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    _cache[26] || (_cache[26] = _createElementVNode("b", null, "Кто изменил:", -1)),
                                    _createTextVNode(" " + _toDisplayString(marker.updatedBy), 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["style"])
                    ]))
                  }), 128)),
                  (!_ctx.affiliate.markers?.length)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, "—"))
                    : _createCommentVNode("", true),
                  _cache[28] || (_cache[28] = _createElementVNode("br", null, null, -1)),
                  _createVNode(_component_q_btn, {
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.addMarker(null))),
                    outline: "",
                    "no-caps": "",
                    class: "q-mt-md",
                    color: "secondary",
                    size: "sm",
                    icon: "add",
                    label: "Добавить маркер"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[35] || (_cache[35] = _createElementVNode("b", null, "Имя", -1)),
                _createVNode(_component_q_input, {
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.affiliate.name,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.affiliate.name) = $event)),
                  error: !!_ctx.errors?.name,
                  "error-message": _ctx.errors?.name
                }, null, 8, ["modelValue", "error", "error-message"]),
                _cache[36] || (_cache[36] = _createElementVNode("b", null, "Email", -1)),
                _createVNode(_component_q_input, {
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.affiliate.credentials.email,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.affiliate.credentials.email) = $event)),
                  error: !!_ctx.errors?.credentials,
                  "error-message": _ctx.errors?.credentials
                }, null, 8, ["modelValue", "error", "error-message"]),
                _cache[37] || (_cache[37] = _createElementVNode("b", null, "Страна", -1)),
                _createVNode(_component_q_select, {
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.affiliate.geo.id,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.affiliate.geo.id) = $event)),
                  options: _ctx.geos,
                  onFilter: _ctx.getGeos,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": "",
                  error: !!_ctx.errors?.geo,
                  "error-message": _ctx.errors?.geo
                }, null, 8, ["modelValue", "options", "onFilter", "error", "error-message"]),
                _cache[38] || (_cache[38] = _createElementVNode("b", null, "Валюта цены инсталла", -1)),
                _createVNode(_component_q_select, {
                  outlined: "",
                  disable: true,
                  "model-value": _ctx.affiliate.installValue.currency,
                  options: _ctx.currencies,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "error-message": _ctx.errors?.installValue,
                  error: !!_ctx.errors?.installValue
                }, null, 8, ["model-value", "options", "error-message", "error"]),
                _cache[39] || (_cache[39] = _createElementVNode("b", null, "Базовая валюта аккаунта", -1)),
                _createVNode(_component_q_select, {
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.affiliate.baseCurrency,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.affiliate.baseCurrency) = $event)),
                  options: _ctx.currencies,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  error: !!_ctx.errors?.baseCurrency,
                  "error-message": _ctx.errors?.baseCurrency
                }, null, 8, ["modelValue", "options", "error", "error-message"]),
                _cache[40] || (_cache[40] = _createElementVNode("b", null, "Валюта минимальной суммы вывода", -1)),
                _createVNode(_component_q_select, {
                  dense: "",
                  outlined: "",
                  modelValue: _ctx.affiliate.minPayoutCurrency,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.affiliate.minPayoutCurrency) = $event)),
                  options: _ctx.currencies,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  error: !!_ctx.errors?.minPayoutCurrency,
                  "error-message": _ctx.errors?.minPayoutCurrency
                }, null, 8, ["modelValue", "options", "error", "error-message"]),
                _cache[41] || (_cache[41] = _createElementVNode("b", null, "Аффилейт менеджер:", -1)),
                _createVNode(_component_q_select, {
                  dense: "",
                  outlined: "",
                  multiple: "",
                  "use-chips": "",
                  ref: "admins",
                  onBlur: _cache[12] || (_cache[12] = ($event: any) => (_ctx.clearFilter('admins'))),
                  modelValue: _ctx.affiliate.managerIds,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.affiliate.managerIds) = $event)),
                  onFilter: _ctx.getManagers,
                  options: _ctx.admins,
                  "option-value": "id",
                  "option-label": "email",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": "",
                  error: !!_ctx.errors?.managerIds,
                  "error-message": _ctx.errors?.managerIds
                }, null, 8, ["modelValue", "onFilter", "options", "error", "error-message"]),
                _createVNode(_component_q_checkbox, {
                  label: "ЛЬЕТ ОФФЕРЫ ПП (цена инсталла 0$)",
                  modelValue: _ctx.affiliate.isOurAppsTraffic,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.affiliate.isOurAppsTraffic) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[42] || (_cache[42] = _createElementVNode("b", { class: "q-mx-md" }, "Комментарий", -1)),
                  _createVNode(_component_q_btn, {
                    size: "sm",
                    "no-caps": "",
                    rounded: "",
                    color: "primary",
                    label: "Добавить",
                    onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.showCommentDialog = !_ctx.showCommentDialog))
                  })
                ]),
                _createVNode(_component_q_card_section, { class: "q-pt-none q-mt-lg" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.affiliate.comments, (comment, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "flex row q-mb-md q-pa-md relative-position",
                        style: {"border-radius":"6px","border":"1px solid #ccc"}
                      }, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.format(comment.createdAt)), 1),
                          _createVNode(_component_IconWithName, {
                            id: comment.createdBy?.id,
                            name: comment.createdBy?.credentials?.email,
                            size: "20px",
                            color: "primary",
                            icon: "manage_accounts",
                            entity: "manager"
                          }, null, 8, ["id", "name"])
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("span", {
                            innerHTML: comment.comment
                          }, null, 8, _hoisted_15)
                        ]),
                        _createElementVNode("div", _hoisted_16, [
                          _createVNode(_component_q_btn, {
                            size: "xs",
                            round: "",
                            icon: "delete",
                            color: "negative",
                            onClick: ($event: any) => (_ctx.deleteComment(comment.id))
                          }, null, 8, ["onClick"])
                        ])
                      ]))
                    }), 128))
                  ]),
                  _: 1
                })
              ])
            ]),
            _cache[53] || (_cache[53] = _createElementVNode("h6", { class: "q-mt-md q-mb-none" }, "Основные Балансы", -1)),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                (_ctx.holdBalances.length)
                  ? (_openBlock(), _createElementBlock("h6", _hoisted_19, _cache[43] || (_cache[43] = [
                      _createElementVNode("b", null, "Холд", -1)
                    ])))
                  : _createCommentVNode("", true),
                _createVNode(_component_Balances, {
                  balances: _ctx.holdBalances,
                  onUpdate: _ctx.updateHoldBalances
                }, null, 8, ["balances", "onUpdate"])
              ]),
              _createElementVNode("div", _hoisted_20, [
                (_ctx.availableBalances.length)
                  ? (_openBlock(), _createElementBlock("h6", _hoisted_21, _cache[44] || (_cache[44] = [
                      _createElementVNode("b", null, "Доступно", -1)
                    ])))
                  : _createCommentVNode("", true),
                _createVNode(_component_Balances, {
                  balances: _ctx.availableBalances,
                  onUpdate: _ctx.updateAvailableBalances
                }, null, 8, ["balances", "onUpdate"])
              ])
            ]),
            (_ctx.refHoldBalances.length || _ctx.refAvailableBalances.length)
              ? (_openBlock(), _createElementBlock("h6", _hoisted_22, " Реферальные Балансы "))
              : _createCommentVNode("", true),
            (_ctx.refHoldBalances.length || _ctx.refAvailableBalances.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    (_ctx.refHoldBalances.length)
                      ? (_openBlock(), _createElementBlock("h6", _hoisted_25, _cache[45] || (_cache[45] = [
                          _createElementVNode("b", null, "Холд", -1)
                        ])))
                      : _createCommentVNode("", true),
                    _createVNode(_component_Balances, { balances: _ctx.refHoldBalances }, null, 8, ["balances"])
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    (_ctx.refAvailableBalances.length)
                      ? (_openBlock(), _createElementBlock("h6", _hoisted_27, _cache[46] || (_cache[46] = [
                          _createElementVNode("b", null, "Доступно", -1)
                        ])))
                      : _createCommentVNode("", true),
                    _createVNode(_component_Balances, { balances: _ctx.refAvailableBalances }, null, 8, ["balances"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _cache[50] || (_cache[50] = _createElementVNode("h6", { class: "q-my-md" }, "Универсальный нейминг", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.affiliate.tdsRules, (rule, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "flex row q-gutter-x-md q-mb-md mobile-display-block",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_28, [
                    _cache[47] || (_cache[47] = _createElementVNode("b", null, "Нейминг", -1)),
                    _createVNode(_component_q_input, {
                      outlined: "",
                      "hide-hint": !rule.affiliateId,
                      placeholder: "bayerid-ruleid_sub1_sub2_sub3_sub4_sub5_sub6_sub7",
                      "model-value": _ctx.getFullNaming(rule, index),
                      "onUpdate:modelValue": 
                naming => _ctx.updateField({ ...rule, naming }, index)
              ,
                      "error-message": _ctx.errors?.tdsRules?.[index]?.naming,
                      error: !!_ctx.errors?.tdsRules?.[index]?.naming
                    }, null, 8, ["hide-hint", "model-value", "onUpdate:modelValue", "error-message", "error"])
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    _cache[49] || (_cache[49] = _createElementVNode("b", null, [
                      _createTextVNode("Ссылка для редиректа на оффер "),
                      _createElementVNode("sup", null, "?")
                    ], -1)),
                    _createVNode(_component_q_tooltip, {
                      "max-width": "500px",
                      anchor: "bottom middle",
                      self: "top middle",
                      class: "q-tooltip"
                    }, {
                      default: _withCtx(() => _cache[48] || (_cache[48] = [
                        _createTextVNode(" URL кампании с нашей ПП или с трекера веба. Если это ссылка от веба - то в какое то из полей обязательно нужно подставить ${click_id}, чтобы веб отправлял нам постбеки на TDS. Для передачи сабов из нейминга использовать ${sub1}, ${sub2}, ${sub3}, ${sub4}, ${sub5}, ${sub6}, ${sub7}, ${sub8}. Пример: https://keitaro.io/${sub1}?sub2=${sub2}&sub3=${sub3}&sub4=${sub4}&sub5=${sub5}&sub6=${click_id} В ссылки на НАШУ ПП Gambeat клик ID передается автоматически. ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_q_input, {
                      outlined: "",
                      onFocus: _ctx.resetErrors,
                      placeholder: "https://1.tracker.gambeat.io/campaign",
                      "model-value": rule.redirectUrl,
                      "onUpdate:modelValue": 
                redirectUrl => _ctx.updateField({ ...rule, redirectUrl }, index)
              ,
                      "error-message": _ctx.errors?.tdsRules?.[index]?.redirectUrl,
                      error: !!_ctx.errors?.tdsRules?.[index]?.redirectUrl
                    }, null, 8, ["onFocus", "model-value", "onUpdate:modelValue", "error-message", "error"])
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createVNode(_component_q_btn, {
                      onClick: ($event: any) => (_ctx.deleteRule(index)),
                      outline: "",
                      round: "",
                      color: "primary",
                      icon: "delete"
                    }, null, 8, ["onClick"])
                  ])
                ]))
              }), 128)),
              _createVNode(_component_q_btn, {
                onClick: _ctx.addRule,
                round: "",
                color: "secondary",
                icon: "add"
              }, null, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_31, [
              _createVNode(_component_q_btn, {
                class: "q-mt-lg",
                label: "Сохранить",
                type: "submit",
                color: "primary",
                disable: _ctx.isSaving,
                loading: _ctx.isSaving,
                onClick: _ctx.saveAffiliate
              }, null, 8, ["disable", "loading", "onClick"]),
              _createVNode(_component_q_btn, {
                flat: "",
                class: "q-mt-lg",
                color: "primary",
                label: "Отменить",
                onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.$router.push('/affiliates')))
              })
            ])
          ])),
      _createVNode(_component_AffiliateCommentDialog, {
        open: _ctx.showCommentDialog,
        onClose: _cache[17] || (_cache[17] = ($event: any) => (_ctx.showCommentDialog = false)),
        onRefresh: _ctx.onRequest,
        affiliateId: _ctx.affiliate.id
      }, null, 8, ["open", "onRefresh", "affiliateId"]),
      _createVNode(_component_AddAffiliateTagsDialog, {
        open: _ctx.showAddTagsDialog,
        affiliateId: _ctx.$route.params.id,
        markerId: _ctx.selectedMarkerId,
        onClose: _ctx.onCloseDialog,
        onRefresh: _ctx.onRequest
      }, null, 8, ["open", "affiliateId", "markerId", "onClose", "onRefresh"])
    ]),
    _: 1
  }))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "q-ml-lg q-pl-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_center = _resolveComponent("center")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_General = _resolveComponent("General")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_Affiliates = _resolveComponent("Affiliates")!
  const _component_Rules = _resolveComponent("Rules")!
  const _component_Metrics = _resolveComponent("Metrics")!
  const _component_TrafficSources = _resolveComponent("TrafficSources")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-layout-container q-mx-xl" }, {
    default: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("h6", { class: "text-h6 flex flex-center" }, " Добавление/Редактирование приложения ", -1)),
      _createVNode(_component_center, null, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_q_spinner, {
                key: 0,
                color: "primary",
                size: "3em",
                thickness: 2
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_tabs, {
              modelValue: _ctx.step,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.step) = $event)),
              "no-caps": "",
              class: "bg-grey-1 q-ma-lg",
              dense: "",
              align: "justify"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tab, {
                  class: _normalizeClass({ error: _ctx.getErrors('main') }),
                  name: "main",
                  label: "Главная"
                }, null, 8, ["class"]),
                _createVNode(_component_q_tab, {
                  class: _normalizeClass({ error: _ctx.getErrors('affiliates') }),
                  name: "affiliates",
                  label: "Аффилейты"
                }, null, 8, ["class"]),
                _createVNode(_component_q_tab, {
                  class: _normalizeClass({ error: _ctx.getErrors('rules') }),
                  name: "rules",
                  label: "Правила редиректа"
                }, null, 8, ["class"]),
                _createVNode(_component_q_tab, {
                  class: _normalizeClass({ error: _ctx.getErrors('metrics') }),
                  name: "metrics",
                  label: "Метрики"
                }, null, 8, ["class"]),
                _createVNode(_component_q_tab, {
                  class: _normalizeClass({ error: _ctx.getErrors('sources') }),
                  name: "sources",
                  label: "Источники трафика"
                }, null, 8, ["class"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_q_form, {
              onSubmit: _ctx.onSubmit,
              onReset: _ctx.onReset,
              class: "q-gutter-md"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tab_panels, {
                  modelValue: _ctx.step,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.step) = $event)),
                  animated: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tab_panel, { name: "main" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_General)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_tab_panel, { name: "affiliates" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Affiliates, { defaultOptions: _ctx.defaultOptions }, null, 8, ["defaultOptions"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_tab_panel, { name: "rules" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Rules, { defaultOptions: _ctx.defaultOptions }, null, 8, ["defaultOptions"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_tab_panel, { name: "metrics" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Metrics)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_tab_panel, { name: "sources" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TrafficSources)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_q_btn, {
                    disable: _ctx.isSaving,
                    loading: _ctx.isSaving,
                    label: "Сохранить",
                    type: "submit",
                    color: "primary"
                  }, null, 8, ["disable", "loading"]),
                  _createVNode(_component_q_btn, {
                    label: "Отменить",
                    type: "reset",
                    color: "primary",
                    flat: "",
                    class: "q-ml-sm"
                  })
                ])
              ]),
              _: 1
            }, 8, ["onSubmit", "onReset"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
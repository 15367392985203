import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '../../../public/images/tiktok.png'
import _imports_1 from '../../../public/images/unity.png'


const _hoisted_1 = { class: "q-mt-lg q-mx-md flex justify-end mobile-display-block" }
const _hoisted_2 = { class: "flex row items-center" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = {
  class: "row items-center no-wrap",
  style: {"width":"max-content"}
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  style: {"color":"red"}
}
const _hoisted_7 = ["id"]
const _hoisted_8 = { class: "flex no-wrap" }
const _hoisted_9 = ["alt", "src"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "flex no-wrap" }
const _hoisted_12 = ["alt", "src"]
const _hoisted_13 = { class: "row" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_ChangeInstallPriceDialog = _resolveComponent("ChangeInstallPriceDialog")!
  const _component_AttributionLinkDialog = _resolveComponent("AttributionLinkDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_page, { class: "q-ml-xl q-px-lg" }, {
      default: _withCtx(() => [
        _createVNode(_component_center, null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("h6", null, "Мобильные приложения", -1)
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_q_checkbox, {
              "model-value": _ctx.rows?.[0]?.sendFbPostback,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.turnFbPostback(!_ctx.rows?.[0]?.sendFbPostback))),
              label: "Вкл/Выкл FB постбеки",
              class: "q-mt-lg q-ml-md"
            }, null, 8, ["model-value"]),
            _createVNode(_component_q_tooltip, null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Отправлять постбеки не только в апс флайер на события Регистрация и Депозит, но и напрямую в Facebook. Используется в случаях некорректной работы апс флайера. ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_q_btn, {
              "no-caps": "",
              onClick: _ctx.changeInstallPrice,
              class: "q-mt-lg q-ml-md",
              label: "Цена инсталла",
              color: "primary"
            }, null, 8, ["onClick"])
          ]),
          _createVNode(_component_router_link, {
            class: "link-text",
            to: "/mobile-apps/new"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                "no-caps": "",
                class: "q-mt-lg q-ml-md",
                label: "Добавить приложение",
                color: "primary"
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Filters, {
              onReset: _ctx.resetFilters,
              onChange: _ctx.onChangeFilters,
              onChangeAll: _ctx.changeAll,
              "current-filters": _ctx.filters
            }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters"])
          ])
        ]),
        _createElementVNode("div", null, [
          _cache[4] || (_cache[4] = _createElementVNode("p", null, [
            _createElementVNode("b", null, [
              _createElementVNode("sup", null, "*"),
              _createTextVNode("Уникальная ссылка")
            ]),
            _createTextVNode(" - это ссылка на TDS, которая привязана к конкретному приложению. По этой ссылке приложение будет делать все запросы к TDS. Ее нужно предоставить администратору приложений, чтобы вставить в Remote Config выбранного приложения. ")
          ], -1)),
          _createVNode(_component_q_table, {
            rows: _ctx.rows,
            dense: "",
            columns: _ctx.columns,
            "row-key": "id",
            separator: "cell",
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination) = $event)),
            loading: _ctx.loading,
            onRequest: _ctx.onRequest,
            "binary-state-sort": ""
          }, {
            "body-cell-name": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_router_link, {
                      to: `/mobile-apps/${props.row.id}`,
                      class: "row items-center no-wrap"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          width: "30",
                          height: "30",
                          class: "q-mr-sm",
                          src: `${_ctx.storageUrl + '/' + props.row.image}`
                        }, null, 8, _hoisted_5),
                        _createElementVNode("span", {
                          style: _normalizeStyle(
                    props.row.status === _ctx.MobileAppStatus.Banned
                      ? 'color: red;'
                      : ''
                  )
                        }, _toDisplayString(props.row.name), 5)
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    _createElementVNode("div", null, [
                      (props.row.markedByFb)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_6, "(метка FB)"))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-tdsRedirectUrl": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    id: `tds_redirect_url_${props.row.id}`
                  }, _toDisplayString(props.row.tdsRedirectUrl), 9, _hoisted_7),
                  _createVNode(_component_q_btn, {
                    round: "",
                    flat: "",
                    color: "primary",
                    onClick: ($event: any) => (_ctx.copyTdsRedirectUrl(props.row.id)),
                    icon: "file_copy",
                    size: "12px",
                    class: "q-ml-md"
                  }, null, 8, ["onClick"])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-status": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (props.row.isEditingStatus)
                    ? (_openBlock(), _createBlock(_component_q_select, {
                        key: 0,
                        dense: "",
                        outlined: "",
                        modelValue: props.row.status,
                        "onUpdate:modelValue": [($event: any) => ((props.row.status) = $event), 
                  () => _ctx.updateStatus(props.row.id, props.row.status)
                ],
                        options: Object.values(_ctx.status),
                        "display-value": _ctx.status[props.row.status as MobileAppStatus],
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "display-value"]))
                    : (_openBlock(), _createBlock(_component_q_badge, {
                        key: 1,
                        color: _ctx.getStatusColor(props.row.status),
                        onClick: () => _ctx.toggleStatusEditing(props.row),
                        style: {"cursor":"pointer"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.status[props.row.status as MobileAppStatus]), 1)
                        ]),
                        _: 2
                      }, 1032, ["color", "onClick"]))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-config": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (props.row.isEditing)
                    ? (_openBlock(), _createBlock(_component_q_select, {
                        key: 0,
                        dense: "",
                        outlined: "",
                        modelValue: props.row.configStatus,
                        "onUpdate:modelValue": [($event: any) => ((props.row.configStatus) = $event), 
                  () => _ctx.updateConfigStatus(props.row.id, props.row.configStatus)
                ],
                        options: Object.values(_ctx.configStatus),
                        "emit-value": "",
                        "map-options": "",
                        "display-value": _ctx.configStatus[props.row.configStatus as MobileAppConfig]
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "display-value"]))
                    : (_openBlock(), _createBlock(_component_q_badge, {
                        key: 1,
                        color: _ctx.getConfigColor(props.row.configStatus),
                        onClick: () => _ctx.toggleEditing(props.row),
                        style: {"cursor":"pointer"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.configStatus[props.row.configStatus as MobileAppConfig]), 1)
                        ]),
                        _: 2
                      }, 1032, ["color", "onClick"]))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-affiliatesCount": _withCtx((props) => [
              _createVNode(_component_q_td, {
                props: props,
                onClick: _withModifiers(($event: any) => (
              _ctx.$router.push('/affiliates?mobileAppId=' + props.row.id)
            ), ["stop"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, { to: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(props.row.affiliatesCount), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["props", "onClick"])
            ]),
            "body-cell-openGeos": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.openGeos, (geo, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "flex",
                      style: {"width":"130px"}
                    }, [
                      _createElementVNode("span", _hoisted_8, [
                        _createElementVNode("img", {
                          class: "q-mr-sm flex",
                          height: "10",
                          width: "15",
                          alt: props.row.geo,
                          src: 
                    `http://purecatamphetamine.github.io/country-flag-icons/3x2/${geo.isoCode}.svg`
                  
                        }, null, 8, _hoisted_9),
                        _createTextVNode(_toDisplayString(geo.name), 1)
                      ])
                    ]))
                  }), 128)),
                  (!props.row.openGeos?.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, "-"))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-organicGeos": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.organicGeos, (geo, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "flex",
                      style: {"width":"130px"}
                    }, [
                      _createElementVNode("span", _hoisted_11, [
                        _createElementVNode("img", {
                          class: "q-mr-sm flex",
                          height: "10",
                          width: "15",
                          alt: props.row.geo,
                          src: 
                    `http://purecatamphetamine.github.io/country-flag-icons/3x2/${geo.isoCode}.svg`
                  
                        }, null, 8, _hoisted_12),
                        _createTextVNode(_toDisplayString(geo.name), 1)
                      ])
                    ]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-actions": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    round: "",
                    flat: "",
                    color: "primary",
                    onClick: ($event: any) => (_ctx.$router.push('/statistics?mobileAppId=' + props.row.id)),
                    icon: "bar_chart",
                    size: "12px",
                    class: "q-mr-sm"
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_q_btn, {
                    round: "",
                    flat: "",
                    color: "primary",
                    onClick: ($event: any) => (_ctx.$router.push('/mobile-apps/' + props.row.id)),
                    icon: "edit",
                    size: "12px",
                    class: "q-mr-sm"
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_q_btn, {
                    round: "",
                    flat: "",
                    color: "negative",
                    onClick: ($event: any) => (_ctx.onDelete(props.row.id)),
                    icon: "delete",
                    size: "12px",
                    class: "q-mr-sm"
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_q_btn, {
                    outline: "",
                    "no-caps": "",
                    class: "q-mr-sm",
                    label: "Прокинуть события",
                    color: "primary",
                    loading: _ctx.isSendingEvents[props.row.id],
                    disable: _ctx.isSendingEvents[props.row.id],
                    onClick: ($event: any) => (_ctx.sendEvents(props.row.id)),
                    size: "12px"
                  }, null, 8, ["loading", "disable", "onClick"]),
                  _createVNode(_component_q_btn, {
                    outline: "",
                    "no-caps": "",
                    class: "q-mr-sm",
                    disable: props.row.status === _ctx.MobileAppStatus.Banned,
                    label: "Бан",
                    color: "negative",
                    onClick: ($event: any) => (_ctx.banApp(props.row.id)),
                    size: "12px"
                  }, null, 8, ["disable", "onClick"]),
                  _createVNode(_component_q_btn, {
                    outline: "",
                    "no-caps": "",
                    class: "q-mr-sm",
                    label: "Инвайт",
                    color: "primary",
                    onClick: ($event: any) => (_ctx.publishApp(props.row, true)),
                    size: "12px"
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_q_btn, {
                    outline: "",
                    "no-caps": "",
                    class: "q-mr-sm",
                    label: "Опубликовать в бот",
                    color: "primary",
                    onClick: ($event: any) => (_ctx.publishApp(props.row)),
                    size: "12px"
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_q_btn, {
                    outline: "",
                    "no-caps": "",
                    label: "Метка FB",
                    color: "negative",
                    onClick: ($event: any) => (_ctx.markedByFb(props.row)),
                    size: "12px"
                  }, null, 8, ["onClick"])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-attributionLinks": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("img", {
                      width: "30",
                      height: "30",
                      class: "cursor-pointer",
                      onClick: ($event: any) => (
                  _ctx.openAttributionDialog('tt', props.row.oneSignalAppPackage)
                ),
                      src: _imports_0
                    }, null, 8, _hoisted_14),
                    _createElementVNode("img", {
                      width: "38",
                      height: "30",
                      class: "cursor-pointer",
                      onClick: ($event: any) => (
                  _ctx.openAttributionDialog('unity', props.row.oneSignalAppPackage)
                ),
                      src: _imports_1
                    }, null, 8, _hoisted_15)
                  ])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 1
          }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
        ])
      ]),
      _: 1
    }),
    (_ctx.showInstallPriceDialog)
      ? (_openBlock(), _createBlock(_component_ChangeInstallPriceDialog, {
          key: 0,
          open: _ctx.showInstallPriceDialog,
          onClose: _ctx.onCloseDialog
        }, null, 8, ["open", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.showAttributionLinkDialog)
      ? (_openBlock(), _createBlock(_component_AttributionLinkDialog, {
          key: 1,
          open: _ctx.showAttributionLinkDialog,
          onClose: _ctx.onCloseDialog,
          source: _ctx.attributionSource,
          package: _ctx.appPackage
        }, null, 8, ["open", "onClose", "source", "package"]))
      : _createCommentVNode("", true)
  ], 64))
}
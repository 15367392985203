import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "q-mb-lg" }
const _hoisted_2 = {
  class: "row items-center",
  style: {"width":"100%"}
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  class: "row justify-between items-center",
  style: {"flex":"1"}
}
const _hoisted_5 = { class: "q-mr-lg" }
const _hoisted_6 = { class: "text-grey-7" }
const _hoisted_7 = { class: "q-mr-lg" }
const _hoisted_8 = { class: "text-grey-7" }
const _hoisted_9 = { class: "q-mr-lg" }
const _hoisted_10 = { class: "text-grey-7" }
const _hoisted_11 = { class: "q-mr-sm" }
const _hoisted_12 = { class: "text-grey-7" }
const _hoisted_13 = {
  class: "row",
  style: {"width":"146px"}
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "flex column" }
const _hoisted_21 = { class: "flex column col-10" }
const _hoisted_22 = { class: "row justify-between items-center" }
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = { class: "flex items-center justify-center no-wrap" }
const _hoisted_29 = { class: "q-mr-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_popup_edit = _resolveComponent("q-popup-edit")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_AffiliateCommentsDialog = _resolveComponent("AffiliateCommentsDialog")!
  const _component_AddAffiliateTagsDialog = _resolveComponent("AddAffiliateTagsDialog")!
  const _component_AffiliateCommentDialog = _resolveComponent("AffiliateCommentDialog")!
  const _component_ReferralPercentDialog = _resolveComponent("ReferralPercentDialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_page, { class: "q-ml-xl q-mr-lg" }, {
      default: _withCtx(() => [
        _createVNode(_component_center, null, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createElementVNode("h6", null, "Аффилейты", -1)
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Filters, {
            onReset: _ctx.resetFilters,
            onChange: _ctx.onChangeFilters,
            onChangeAll: _ctx.changeAll,
            onExport: _ctx.onExport,
            "current-filters": _ctx.filters
          }, null, 8, ["onReset", "onChange", "onChangeAll", "onExport", "current-filters"]),
          _createVNode(_component_q_table, {
            dense: "",
            class: "q-ml-md",
            "rows-per-page-options": [5, 10, 25, 50, 100, 200, 500, 1000, 1500],
            rows: _ctx.rows,
            columns: _ctx.columns,
            "visible-columns": _ctx.pagination.visibleColumns,
            "row-key": "id",
            separator: "cell",
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pagination) = $event)),
            loading: _ctx.loading,
            onRequest: _ctx.onRequest,
            "binary-state-sort": "",
            "column-sort-order": "da"
          }, {
            top: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_q_select, {
                    modelValue: _ctx.pagination.visibleColumns,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination.visibleColumns) = $event)),
                    multiple: "",
                    outlined: "",
                    dense: "",
                    "options-dense": "",
                    "display-value": "Колонки",
                    "emit-value": "",
                    "map-options": "",
                    options: _ctx.columns,
                    "option-value": "name",
                    "option-label": "label",
                    "options-cover": "",
                    style: {"min-width":"150px"}
                  }, null, 8, ["modelValue", "options"]),
                  _createVNode(_component_q_btn, {
                    "no-caps": "",
                    class: "q-ml-sm",
                    icon: "autorenew",
                    color: "red",
                    label: "Сбросить",
                    size: "13px",
                    onClick: _ctx.resetColumns,
                    outline: ""
                  }, null, 8, ["onClick"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_btn, {
                      dense: "",
                      label: "Реферальная программа",
                      flat: "",
                      class: "q-mx-md",
                      "no-caps": "",
                      color: "primary",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showReferralsDialog = !_ctx.showReferralsDialog)),
                      icon: "connect_without_contact"
                    })
                  ]),
                  _createElementVNode("div", null, [
                    _cache[9] || (_cache[9] = _createElementVNode("b", { class: "q-mr-md" }, "Общий баланс: ", -1)),
                    _createElementVNode("span", _hoisted_5, [
                      _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.approvedUsdSum || 0)) + "$ ", 1),
                      _createElementVNode("small", _hoisted_6, [
                        _createTextVNode(" (" + _toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.holdUsdSum || 0)) + "$) ", 1),
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("Холд")
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    _createElementVNode("span", _hoisted_7, [
                      _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.approvedEurSum || 0)) + "€ ", 1),
                      _createElementVNode("small", _hoisted_8, [
                        _createTextVNode(" (" + _toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.holdEurSum || 0)) + "€) ", 1),
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode("Холд")
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    _createElementVNode("span", _hoisted_9, [
                      _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.approvedUahSum || 0)) + "₴ ", 1),
                      _createElementVNode("small", _hoisted_10, [
                        _createTextVNode(" (" + _toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.holdUahSum || 0)) + "₴) ", 1),
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("Холд")
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    _createElementVNode("span", _hoisted_11, [
                      _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.approvedPlnSum || 0)) + "zł ", 1),
                      _createElementVNode("small", _hoisted_12, [
                        _createTextVNode(" (" + _toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.holdPlnSum || 0)) + "zł) ", 1),
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode("Холд")
                          ])),
                          _: 1
                        })
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            "body-cell-id": _withCtx((props) => [
              _createVNode(_component_q_td, {
                props: props,
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_IconWithName, {
                      id: props.row.id,
                      size: "21px",
                      color: "secondary",
                      icon: "account_box",
                      entity: "affiliate"
                    }, null, 8, ["id"]),
                    _createVNode(_component_q_btn, {
                      style: {"font-size":"12px"},
                      dense: "",
                      round: "",
                      flat: "",
                      color: "primary",
                      onClick: _withModifiers(($event: any) => (_ctx.loginAffiliate(props.row.id)), ["stop"]),
                      icon: "play_circle_outline"
                    }, null, 8, ["onClick"]),
                    _createVNode(_component_q_btn, {
                      style: {"font-size":"12px"},
                      dense: "",
                      round: "",
                      flat: "",
                      color: "grey",
                      onClick: _withModifiers(($event: any) => (_ctx.viewComments(props.row.id)), ["stop"]),
                      icon: "comment"
                    }, {
                      default: _withCtx(() => [
                        (Number(props.row.commentsCount) > 0)
                          ? (_openBlock(), _createBlock(_component_q_badge, {
                              key: 0,
                              "text-color": "dark",
                              style: {"font-size":"8px"},
                              color: "orange",
                              floating: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(props.row.commentsCount || ''), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-contacts": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.affiliateEmail), 1)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-clicksDifference": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    style: _normalizeStyle(
                props.row.clicksDifference > 0
                  ? 'background: rgba(171, 254, 165, 0.2); color: #17c322;'
                  : props.row.clicksDifference === 0 ||
                    !props.row.clicksDifference
                  ? 'background: rgba(255, 208, 0, 0.1); color: #ff9500;'
                  : 'background: rgba(208, 54, 54, 0.1); color: #D03636;'
              )
                  }, [
                    (
                  props.row.clicksDifference === 0 ||
                    !props.row.clicksDifference
                )
                      ? (_openBlock(), _createElementBlock("small", _hoisted_14))
                      : (_openBlock(), _createElementBlock("small", _hoisted_15, _toDisplayString(props.row.clicksDifference > 0 ? '↑' : '↓'), 1)),
                    _createTextVNode(" " + _toDisplayString(props.row.clicksDifference > 0
                  ? `+${Math.round(props.row.clicksDifference || 0)} `
                  : `${Math.round(props.row.clicksDifference || 0)} `), 1)
                  ], 4)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-regsDifference": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    style: _normalizeStyle(
                props.row.regsDifference > 0
                  ? 'background: rgba(171, 254, 165, 0.2); color: #17c322;'
                  : props.row.regsDifference === 0 || !props.row.regsDifference
                  ? 'background: rgba(255, 208, 0, 0.1); color: #ff9500;'
                  : 'background: rgba(208, 54, 54, 0.1); color: #D03636;'
              )
                  }, [
                    (
                  props.row.regsDifference === 0 || !props.row.regsDifference
                )
                      ? (_openBlock(), _createElementBlock("small", _hoisted_16))
                      : (_openBlock(), _createElementBlock("small", _hoisted_17, _toDisplayString(props.row.regsDifference > 0 ? '↑' : '↓'), 1)),
                    _createTextVNode(" " + _toDisplayString(props.row.regsDifference > 0
                  ? `+${Math.round(props.row.regsDifference || 0)} `
                  : `${Math.round(props.row.regsDifference || 0)} `), 1)
                  ], 4)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-ftdDifference": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    style: _normalizeStyle(
                props.row.ftdDifference > 0
                  ? 'background: rgba(171, 254, 165, 0.2); color: #17c322;'
                  : props.row.ftdDifference === 0 || !props.row.ftdDifference
                  ? 'background: rgba(255, 208, 0, 0.1); color: #ff9500;'
                  : 'background: rgba(208, 54, 54, 0.1); color: #D03636;'
              )
                  }, [
                    (props.row.ftdDifference === 0 || !props.row.ftdDifference)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_18))
                      : (_openBlock(), _createElementBlock("small", _hoisted_19, _toDisplayString(props.row.ftdDifference > 0 ? '↑' : '↓'), 1)),
                    _createTextVNode(" " + _toDisplayString(props.row.ftdDifference > 0
                  ? `+${Math.round(props.row.ftdDifference || 0)} `
                  : `${Math.round(props.row.ftdDifference || 0)} `), 1)
                  ], 4)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-markers": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_20, [
                    (!props.row.markers?.length)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          dense: "",
                          flat: "",
                          "no-caps": "",
                          class: "q-ml-xs",
                          color: "secondary",
                          onClick: _withModifiers(($event: any) => (_ctx.addMarker(props.row.id, null)), ["stop"]),
                          icon: "add",
                          size: "xs"
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_21, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.markers, (marker, i) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: i,
                          class: "q-mr-sm",
                          onClick: _withModifiers(() => true, ["stop"])
                        }, [
                          _createVNode(_component_q_badge, {
                            class: "cursor-pointer",
                            style: _normalizeStyle(
                      `background: ${marker.color} !important; font-size: 11px;`
                    )
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("b", null, _toDisplayString(marker.name), 1),
                              _createVNode(_component_q_popup_edit, {
                                modelValue: _ctx.popupModel,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.popupModel) = $event)),
                                class: "bg-grey-9 text-white marker-popup",
                                anchor: "bottom middle"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_22, [
                                    _createElementVNode("span", null, [
                                      _cache[10] || (_cache[10] = _createElementVNode("b", null, "Название:", -1)),
                                      _cache[11] || (_cache[11] = _createTextVNode()),
                                      _createElementVNode("b", null, _toDisplayString(marker.name), 1)
                                    ]),
                                    _createElementVNode("span", null, [
                                      _createVNode(_component_q_btn, {
                                        icon: "edit",
                                        size: "xs",
                                        round: "",
                                        flat: "",
                                        onClick: _withModifiers(($event: any) => (_ctx.addMarker(props.row.id, marker.id)), ["stop"])
                                      }, null, 8, ["onClick"]),
                                      _createVNode(_component_q_btn, {
                                        icon: "delete",
                                        size: "xs",
                                        round: "",
                                        flat: "",
                                        onClick: _withModifiers(($event: any) => (
                              _ctx.deleteMarker(props.row.id, marker.id, marker.name)
                            ), ["stop"])
                                      }, null, 8, ["onClick"])
                                    ])
                                  ]),
                                  _createElementVNode("div", null, [
                                    _cache[12] || (_cache[12] = _createElementVNode("b", null, "Описание:", -1)),
                                    _cache[13] || (_cache[13] = _createTextVNode()),
                                    _createElementVNode("span", {
                                      innerHTML: marker.reason
                                    }, null, 8, _hoisted_23)
                                  ]),
                                  _createElementVNode("div", null, [
                                    _cache[14] || (_cache[14] = _createElementVNode("b", null, "Добавлено:", -1)),
                                    _createTextVNode(" " + _toDisplayString(_ctx.frmtDate(marker.createdAt)), 1)
                                  ]),
                                  _createElementVNode("div", null, [
                                    _cache[15] || (_cache[15] = _createElementVNode("b", null, "Кто добавил:", -1)),
                                    _createTextVNode(" " + _toDisplayString(marker.addedBySystem
                            ? 'Добавлено системой'
                            : marker.createdByEmails?.[0] ||
                              'Добавлено системой'), 1)
                                  ]),
                                  (
                          _ctx.frmtDate(marker.updatedAt) !==
                            _ctx.frmtDate(marker.createdAt) &&
                            marker.updatedByEmails?.[0] &&
                            marker.updatedAt
                        )
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                        _cache[16] || (_cache[16] = _createElementVNode("b", null, "Изменено:", -1)),
                                        _createTextVNode(" " + _toDisplayString(_ctx.frmtDate(marker.updatedAt)), 1)
                                      ]))
                                    : _createCommentVNode("", true),
                                  (
                          _ctx.frmtDate(marker.updatedAt) !==
                            _ctx.frmtDate(marker.createdAt) &&
                            marker.updatedByEmails?.[0] &&
                            marker.updatedAt
                        )
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                        _cache[17] || (_cache[17] = _createElementVNode("b", null, "Кто изменил:", -1)),
                                        _createTextVNode(" " + _toDisplayString(marker.updatedByEmails?.[0]), 1)
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["modelValue"])
                            ]),
                            _: 2
                          }, 1032, ["style"]),
                          (i === 0)
                            ? (_openBlock(), _createBlock(_component_q_btn, {
                                key: 0,
                                dense: "",
                                flat: "",
                                "no-caps": "",
                                class: "q-ml-xs",
                                color: "secondary",
                                onClick: _withModifiers(($event: any) => (_ctx.addMarker(props.row.id, null)), ["stop"]),
                                icon: "add",
                                size: "xs"
                              }, null, 8, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-managers": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_select, {
                      dense: "",
                      multiple: "",
                      ref: "managers",
                      "model-value": _ctx.getRowManagers(props.row.managers),
                      "onUpdate:modelValue": val => _ctx.onChangeManagers(val, props.row),
                      onBlur: _ctx.saveManagers,
                      outlined: "",
                      class: "col-4 q-mr-lg manager-select",
                      options: _ctx.admins,
                      onFilter: _ctx.getManagers,
                      "option-value": "id",
                      "option-label": "email",
                      "emit-value": "",
                      "map-options": "",
                      "use-input": ""
                    }, {
                      selected: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRowManagers(props.row.managers), (id, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            _createVNode(_component_IconWithName, {
                              id: id,
                              name: _ctx.getAdminEmail(id),
                              size: "20px",
                              color: "primary",
                              icon: "manage_accounts",
                              entity: "manager"
                            }, null, 8, ["id", "name"])
                          ]))
                        }), 128))
                      ]),
                      option: _withCtx((scope) => [
                        _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_IconWithName, {
                                  id: scope.opt.id,
                                  name: scope.opt.email,
                                  size: "20px",
                                  color: "primary",
                                  icon: "manage_accounts",
                                  entity: "manager"
                                }, null, 8, ["id", "name"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1040)), [
                          [_directive_close_popup]
                        ])
                      ]),
                      _: 2
                    }, 1032, ["model-value", "onUpdate:modelValue", "onBlur", "options", "onFilter"])
                  ])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-campaignsCount": _withCtx((props) => [
              _createVNode(_component_q_td, {
                props: props,
                onClick: _withModifiers(($event: any) => (_ctx.$router.push('/campaigns?affiliateId=' + props.row.id)), ["stop"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, { to: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(props.row.campaignsCount), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["props", "onClick"])
            ]),
            "body-cell-balancesApprove": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: 
                _ctx.getAffiliateBalancesApproved(
                  props.row.balances,
                  props.row.referralBalances,
                )
              
                  }, null, 8, _hoisted_26)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-balancesHold": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: 
                _ctx.getAffiliateBalancesHold(
                  props.row.balances,
                  props.row.referralBalances,
                )
              
                  }, null, 8, _hoisted_27)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-mobileAppsCount": _withCtx((props) => [
              _createVNode(_component_q_td, {
                props: props,
                onClick: _withModifiers(($event: any) => (
              _ctx.$router.push('/mobile-apps?affiliateId=' + props.row.id)
            ), ["stop"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, { to: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(props.row.mobileAppsCount), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["props", "onClick"])
            ]),
            "body-cell-rules": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.rules || [], (rule, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("span", _hoisted_29, _toDisplayString(rule.naming), 1),
                        _createVNode(_component_q_btn, {
                          style: {"font-size":"12px"},
                          color: "primary",
                          round: "",
                          flat: "",
                          icon: "content_copy",
                          onClick: ($event: any) => (_ctx.copy(rule.naming))
                        }, null, 8, ["onClick"])
                      ])
                    ]))
                  }), 128)),
                  _createTextVNode(" " + _toDisplayString(!props.row.rules?.length ? '—' : ''), 1)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 1
          }, 8, ["rows", "columns", "visible-columns", "pagination", "loading", "onRequest"])
        ])
      ]),
      _: 1
    }),
    (_ctx.showCommentsDialog)
      ? (_openBlock(), _createBlock(_component_AffiliateCommentsDialog, {
          key: 0,
          open: _ctx.showCommentsDialog,
          affiliateId: _ctx.selectedRowId,
          onClose: _ctx.onCloseDialog,
          onRefresh: _ctx.onRequest
        }, null, 8, ["open", "affiliateId", "onClose", "onRefresh"]))
      : _createCommentVNode("", true),
    _createVNode(_component_AddAffiliateTagsDialog, {
      open: _ctx.showAddTagsDialog,
      affiliateId: _ctx.selectedRowId,
      markerId: _ctx.selectedMarkerId,
      onClose: _ctx.onCloseDialog,
      onRefresh: _ctx.onRequest
    }, null, 8, ["open", "affiliateId", "markerId", "onClose", "onRefresh"]),
    _createVNode(_component_AffiliateCommentDialog, {
      open: _ctx.showCommentDialog,
      onClose: _ctx.onCloseDialog,
      onRefresh: _ctx.onRequest,
      affiliateId: _ctx.selectedRowId
    }, null, 8, ["open", "onClose", "onRefresh", "affiliateId"]),
    (_ctx.showReferralsDialog)
      ? (_openBlock(), _createBlock(_component_ReferralPercentDialog, {
          key: 1,
          open: _ctx.showReferralsDialog,
          onClose: _ctx.onCloseDialog
        }, null, 8, ["open", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "flex column justify-between" }
const _hoisted_2 = { class: "row justify-center q-my-md" }
const _hoisted_3 = { class: "flex column col-sm-12 col-lg-2 color-grey q-mr-sm" }
const _hoisted_4 = { class: "flex column col-sm-12 col-lg-2 color-grey q-mr-sm" }
const _hoisted_5 = { class: "flex column col-sm-12 col-lg-7" }
const _hoisted_6 = { class: "q-mt-md" }
const _hoisted_7 = { class: "row justify-between items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_infinite_scroll = _resolveComponent("q-infinite-scroll")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_file = _resolveComponent("q-file")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-mx-xl q-my-lg" }, {
    default: _withCtx(() => [
      _createElementVNode("h6", null, "Тикет номер " + _toDisplayString(_ctx.$route.params.id) + " ⚙️", 1),
      _createElementVNode("div", null, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "row q-mb-lg" }, [
          _createElementVNode("span", { class: "flex column col-sm-12 col-lg-2 q-mr-sm q-pl-md" }, [
            _createElementVNode("b", null, "Дата")
          ]),
          _createElementVNode("span", { class: "flex column col-sm-12 col-lg-2 q-mr-sm q-pl-md" }, [
            _createElementVNode("b", null, "Автор")
          ]),
          _createElementVNode("span", { class: "flex column col-sm-12 col-lg-7" }, [
            _createElementVNode("b", null, "Текст сообщения")
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_q_infinite_scroll, {
            class: "border-2",
            onLoad: _ctx.onLoad,
            reverse: "",
            style: {"max-height":"330px","overflow":"scroll"},
            id: "messages-scroll-area"
          }, _createSlots({
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (row, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "row q-py-lg message-block q-pl-md"
                }, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getFormatData(row.createdAt)), 1),
                  _createElementVNode("span", _hoisted_4, [
                    (row.affiliateEmail)
                      ? (_openBlock(), _createBlock(_component_IconWithName, {
                          key: 0,
                          id: row.affiliateId,
                          name: _ctx.user?.notFullAccess ? '' : row.affiliateEmail,
                          size: "20px",
                          color: "secondary",
                          icon: "account_box",
                          entity: "affiliate"
                        }, null, 8, ["id", "name"]))
                      : _createCommentVNode("", true),
                    (row.adminEmail)
                      ? (_openBlock(), _createBlock(_component_IconWithName, {
                          key: 1,
                          id: row.id,
                          name: row.adminEmail,
                          size: "20px",
                          color: "primary",
                          icon: "manage_accounts",
                          entity: "manager"
                        }, null, 8, ["id", "name"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("span", _hoisted_5, [
                    (row.file)
                      ? (_openBlock(), _createBlock(_component_q_badge, {
                          key: 0,
                          onClick: ($event: any) => (_ctx.downloadURI(row.file)),
                          color: "grey",
                          "text-color": "black",
                          style: {"width":"50px"},
                          class: "cursor-pointer"
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createElementVNode("b", null, "File", -1)
                          ])),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(row.text), 1)
                  ])
                ]))
              }), 128))
            ]),
            _: 2
          }, [
            (_ctx.loading)
              ? {
                  name: "loading",
                  fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_q_spinner, {
                        color: "primary",
                        name: "dots",
                        size: "40px"
                      })
                    ])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["onLoad"]),
          _createElementVNode("div", _hoisted_6, [
            _cache[3] || (_cache[3] = _createElementVNode("h6", { class: "q-my-md" }, "Новое сообщение", -1)),
            _createVNode(_component_q_input, {
              dense: "",
              class: "text-area-round",
              type: "textarea",
              modelValue: _ctx.message,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.message) = $event)),
              placeholder: "Введите текст сообщения",
              autofocus: ""
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, [
                _createVNode(_component_q_file, {
                  modelValue: _ctx.file,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.file) = $event)),
                  rounded: "",
                  outlined: "",
                  dense: "",
                  clearable: "",
                  "clear-icon": "close",
                  class: "attach-file q-mt-sm q-mb-lg p-10",
                  label: "Добавить файл"
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_q_icon, { name: "add" })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createElementVNode("span", null, [
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  icon: "send",
                  rounded: "",
                  loading: _ctx.isSaving,
                  disable: _ctx.isSaving,
                  class: "q-mt-sm q-mb-lg q-py-sm q-px-md",
                  label: "Отправить",
                  color: "primary",
                  onClick: _ctx.sendMessage
                }, null, 8, ["loading", "disable", "onClick"])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}

import axios from 'axios'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { showErrorMessageSnackBar } from '@/utils'
import { lookupOfferMarkers } from '@/utils/lookups'

export default defineComponent({
  name: 'AddOfferTagsDialog.',
  props: ['open', 'close', 'offerId', 'refresh', 'markerId'],
  watch: {
    async markerId() {
      this.loading = true
      if (this.markerId) {
        this.offerTag = (
          await axios.get(
            `/api/offer-markers/${this.markerId}?offerId=${this.offerId}`,
          )
        ).data
      }
      this.loading = false
    },
  },
  async created() {
    const datas = (await axios.get('/api/offer-markers')).data
    this.offerMarkers = datas
  },
  computed: {
    ...mapState('authModule', ['user']),
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      offerTag: { reason: null, color: null, name: null },
      loading: false,
      isSaving: false,
      errors: { reason: null, name: null },
      existedMarkerId: null,
      offerMarkers: [],
    }
  },
  methods: {
    addOfferTag() {
      this.isSaving = true
      const q = this.existedMarkerId
        ? { id: this.existedMarkerId, reason: this.offerTag.reason }
        : this.offerTag
      axios
        .post('/api/offer-markers/', { ...q, offerId: Number(this.offerId) })
        .then(() => {
          this.onClose()
          this.$emit('refresh')
        })
        .catch(error => {
          this.errors = error.response.data.errors
          showErrorMessageSnackBar('Произошла ошибка при сохранении.')
        })
      this.isSaving = false
    },
    onClose() {
      this.offerTag = { reason: null, color: null, name: null }
      this.existedMarkerId = null
      this.errors = { reason: null, name: null }
      this.$emit('close')
    },
    async getOptionsByField(value: string, update: Function) {
      await lookupOfferMarkers(this, value, update)
    },
  },
})

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row q-mt-md" }
const _hoisted_2 = {
  key: 0,
  class: "col-12"
}
const _hoisted_3 = {
  key: 1,
  class: "col-12"
}
const _hoisted_4 = {
  key: 2,
  class: "col-12"
}
const _hoisted_5 = {
  key: 3,
  class: "col-12"
}
const _hoisted_6 = {
  key: 4,
  class: "col-12"
}
const _hoisted_7 = {
  key: 5,
  class: "col-12"
}
const _hoisted_8 = {
  key: 6,
  class: "col-12"
}
const _hoisted_9 = {
  key: 7,
  class: "col-12"
}
const _hoisted_10 = {
  key: 8,
  class: "col-12"
}
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = {
  key: 9,
  class: "self-center q-mt-md q-mr-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.showDialog) = $event)),
    onKeyup: _cache[23] || (_cache[23] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: "card q-pa-none",
        style: {"min-width":"350px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { className: "q-card__section q-card__section--vert q-px-lg row justify-between items-center" }, {
            default: _withCtx(() => [
              _cache[24] || (_cache[24] = _createElementVNode("div", { className: "text-h6" }, "Фильтры", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  icon: "close",
                  flat: "",
                  rounded: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { className: "q-pt-none q-form q-px-lg" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (
              _ctx.hideForAffiliates &&
                _ctx.hideForStatistics &&
                _ctx.hideForBalances &&
                _ctx.hideForPushNotifications
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _cache[25] || (_cache[25] = _createElementVNode("b", null, "Поиск:", -1)),
                      _createVNode(_component_q_input, {
                        outlined: "",
                        debounce: "500",
                        class: "col-12 q-mr-lg",
                        modelValue: _ctx.search,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event))
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hideForBalances && !_ctx.showForPushGroups)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _cache[26] || (_cache[26] = _createElementVNode("b", null, "Приложение:", -1)),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.mobileAppId,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.mobileAppId) = $event)),
                        onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChangeFilters('mobileAppId', _ctx.filters.mobileAppId))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.apps,
                        onFilter: _cache[4] || (_cache[4] = 
                (value, updateFn) => _ctx.getOptionsByField(value, updateFn, 'apps')
              ),
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hideForPushNotifications)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _cache[27] || (_cache[27] = _createElementVNode("b", null, "Аффилейт:", -1)),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.affiliateId,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filters.affiliateId) = $event)),
                        onFilter: _ctx.getAffiliates,
                        onBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onChangeFilters('affiliateId', _ctx.filters.affiliateId))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.affiliates,
                        "option-value": "id",
                        "option-label": "email",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "onFilter", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.showForPostbackBlackListTab &&
                _ctx.hideForBalances &&
                _ctx.hideForPushNotifications
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _cache[28] || (_cache[28] = _createElementVNode("b", null, "Страны:", -1)),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.geoId,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filters.geoId) = $event)),
                        onBlur: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onChangeFilters('geoId', _ctx.filters.geoId))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.geos,
                        onFilter: _cache[9] || (_cache[9] = 
                (value, updateFn) => _ctx.getOptionsByField(value, updateFn, 'geos')
              ),
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForStatistics &&
                _ctx.hideForPostbackLogs &&
                _ctx.hideForBalances &&
                _ctx.hideForPushNotifications
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _cache[29] || (_cache[29] = _createElementVNode("b", null, "Источник трафика:", -1)),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.trafficSourceId,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.filters.trafficSourceId) = $event)),
                        onBlur: _cache[11] || (_cache[11] = ($event: any) => (
                _ctx.onChangeFilters('trafficSourceId', _ctx.filters.trafficSourceId)
              )),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.trafficSources,
                        onFilter: _ctx.getTrafficSources,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options", "onFilter"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForStatistics &&
                _ctx.hideForPostbackLogs &&
                _ctx.hideForMobileAppsPage &&
                _ctx.hideForBalances &&
                _ctx.hideForAffiliates &&
                _ctx.hideForPushNotifications
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _cache[30] || (_cache[30] = _createElementVNode("b", null, "Менеджер:", -1)),
                      _createVNode(_component_q_select, {
                        disable: true,
                        modelValue: _ctx.filters.managerId,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.filters.managerId) = $event)),
                        onBlur: _cache[13] || (_cache[13] = ($event: any) => (_ctx.onChangeFilters('managerId', _ctx.filters.managerId))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.admins,
                        onFilter: _cache[14] || (_cache[14] = 
                (value, updateFn) =>
                  _ctx.getOptionsByField(value, updateFn, 'admins')
              ),
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": "",
                        "use-input": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForStatistics &&
                _ctx.hideForPostbackLogs &&
                _ctx.hideForMobileAppsPage &&
                _ctx.hideForBalances &&
                _ctx.hideForPushNotifications
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _cache[31] || (_cache[31] = _createElementVNode("b", null, "Статус:", -1)),
                      _createVNode(_component_q_select, {
                        disable: true,
                        modelValue: _ctx.filters.status,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.filters.status) = $event)),
                        onBlur: _cache[16] || (_cache[16] = ($event: any) => (_ctx.onChangeFilters('status', _ctx.filters.status))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.affiliateStatuses,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForAffiliates &&
                _ctx.hideForPostbackLogs &&
                _ctx.hideForBalances &&
                _ctx.hideForPushNotifications
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _cache[32] || (_cache[32] = _createElementVNode("b", null, "Вертикаль:", -1)),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.vertical,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.filters.vertical) = $event)),
                        onBlur: _cache[18] || (_cache[18] = ($event: any) => (_ctx.onChangeFilters('vertical', _ctx.filters.vertical))),
                        outlined: "",
                        class: "col-12 q-mr-lg",
                        options: _ctx.verticals,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.hideForAffiliates &&
                _ctx.hideForMobileAppsPage &&
                _ctx.hideForPostbackLogs &&
                _ctx.hideForBalances &&
                _ctx.hideForPushNotifications
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _cache[33] || (_cache[33] = _createElementVNode("b", null, "Группировать по:", -1)),
                      _createVNode(_component_q_select, {
                        modelValue: _ctx.filters.groupBy,
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.filters.groupBy) = $event)),
                        outlined: "",
                        onBlur: _cache[20] || (_cache[20] = ($event: any) => (_ctx.onChangeFilters('groupBy', _ctx.filters.groupBy))),
                        class: "col-12 q-mr-lg",
                        options: _ctx.groupByOptions,
                        "option-value": "id",
                        "option-label": "name",
                        "emit-value": "",
                        "map-options": ""
                      }, null, 8, ["modelValue", "options"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_11, [
                  _cache[34] || (_cache[34] = _createElementVNode("b", null, "Дата:", -1)),
                  _createVNode(_component_DatePicker, {
                    onChangeDate: _ctx.onChangeDate,
                    from: _ctx.filters.dateFrom,
                    to: _ctx.filters.dateTo
                  }, null, 8, ["onChangeDate", "from", "to"])
                ]),
                (_ctx.tab === 'black-list-logs')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(_component_q_checkbox, {
                        label: "Block geo",
                        "model-value": _ctx.filters.showBlockGeos,
                        onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.onChangeFilters('showBlockGeos', !_ctx.filters.showBlockGeos)))
                      }, null, 8, ["model-value"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, {
            align: "center",
            class: "flex column text-primary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                disable: _ctx.loading,
                loading: _ctx.loading,
                "no-caps": "",
                rounded: "",
                color: "primary",
                label: "Добавить",
                onClick: _ctx.addFilters
              }, null, 8, ["disable", "loading", "onClick"]),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                class: "reset-btn q-mt-md",
                label: "Скинуть",
                onClick: _ctx.resetFilters
              }, null, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex q-col-gutter-md-lg q-mb-md row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_center = _resolveComponent("center")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("p", null, " Выбранные источники трафика отображаются в описании оффера в кабинете веба и будут доступны вебу при создании кампании. Вначале списка отображаются дефолтные источники трафика, если они есть. Их редактировать нельзя. ", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("p", null, [
      _createElementVNode("b", null, "Название источника трафика")
    ], -1)),
    _createVNode(_component_center, null, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_q_spinner, {
              key: 0,
              color: "primary",
              size: "3em",
              thickness: 2
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trafficSources, (trafficSource, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_q_input, {
                  dense: "",
                  class: "col-sm-8 col-lg-4",
                  outlined: "",
                  disable: trafficSource.isDefault,
                  modelValue: trafficSource.name,
                  "onUpdate:modelValue": ($event: any) => ((trafficSource.name) = $event),
                  onBlur: ($event: any) => (_ctx.updateTrafficSources(index, trafficSource.name)),
                  error: !!_ctx.errors?.trafficSources,
                  "error-message": _ctx.errors?.trafficSources
                }, null, 8, ["disable", "modelValue", "onUpdate:modelValue", "onBlur", "error", "error-message"]),
                _createVNode(_component_q_checkbox, {
                  class: "q-ml-md q-mb-md",
                  modelValue: trafficSource.isActive,
                  "onUpdate:modelValue": ($event: any) => ((trafficSource.isActive) = $event),
                  onClick: _ctx.updateTrafficSources
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"])
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}

import Filters from '@/components/filters/Filters.vue'
import { getData } from '@/utils/getData'
import { FiltersType, getDefaultFilters } from '@/utils/request'
import { defineComponent } from 'vue'
import { groupByOptions } from '../../config/groupByOptions'
import { getColumns } from './getColumns'
import { exportToCsv } from '@/utils/export'
import { LocationQueryValue } from 'vue-router'
import { FormatMoney, FormatMoneyParse } from 'format-money-js'
import { formatValue } from '@/utils'
import { omit } from 'lodash'

type RowType = {
  status: string
  usdAdv: number
  eurAdv: number
  usdAff: number
  eurAff: number
  usdMargin: number
  eurMargin: number
}

export default defineComponent({
  name: 'AffAdvReports',
  components: { Filters },
  data() {
    const filtersL = this.$storage.getStorageSync('financeReportGmbt') || {}
    const queryFilters = this.$route.query
    for (const [filter, value] of Object.entries(queryFilters)) {
      if (Array.isArray(value)) {
        queryFilters[filter] = value.map(v => {
          const num = Number(v)
          return isNaN(num) ? v : ((num as unknown) as LocationQueryValue)
        })
      } else {
        const numberValue = Number(value)
        queryFilters[filter] = isNaN(numberValue)
          ? [value]
          : [(numberValue as unknown) as LocationQueryValue]
      }
    }
    const filters = {
      ...getDefaultFilters(),
      ...filtersL,
      ...queryFilters,
      noAso: (filtersL.noAso || queryFilters.noAso) ?? false,
      noTraffborne:
        (filtersL.noTraffborne || queryFilters.noTraffborne) ?? false,
    }
    const defaultValues = {
      usdAdv: 0,
      eurAdv: 0,
      usdAff: 0,
      eurAff: 0,
      usdMargin: 0,
      eurMargin: 0,
    }
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      loading: false,
      filters,
      columns: getColumns(),
      rows: [] as RowType[],
      columnKeys: Object.keys(defaultValues),
      chartData: [],
      defaultValues,
      moneyFormatter,
    }
  },
  mounted() {
    this.onRequestChartData()
  },
  computed: {
    formattedRows(): Array<{
      [key: string]: string | number | undefined | FormatMoneyParse
    }> {
      return this.rows.map(row => ({
        ...row,
        usdAdv: this.formatMoney(row.usdAdv),
        eurAdv: this.formatMoney(row.eurAdv),
        usdAff: this.formatMoney(row.usdAff),
        eurAff: this.formatMoney(row.eurAff),
        usdMargin: this.formatMoney(row.usdMargin),
        eurMargin: this.formatMoney(row.eurMargin),
      }))
    },
    total(): Record<string, number> {
      return this.rows.reduce(
        (acc, row) => {
          acc.usdAdv += row.usdAdv || 0
          acc.eurAdv += row.eurAdv || 0
          acc.usdAff += row.usdAff || 0
          acc.eurAff += row.eurAff || 0
          acc.usdMargin += row.usdMargin || 0
          acc.eurMargin += row.eurMargin || 0
          return acc
        },
        { ...this.defaultValues },
      )
    },
  },
  watch: {
    async filters() {
      this.$storage.setStorageSync('financeReportGmbt', this.filters)
    },
  },
  methods: {
    async onRequestChartData() {
      const orderBy = { [String(this.filters?.groupBy)]: 'ASC' }
      const filters = omit(this.filters, 'offerIds')
      this.chartData = await getData(
        {
          filters,
          limit: 0,
          offset: 0,
          orderBy,
        },
        '/api/finances',
      )
      const aggregate = (key: string, items: RowType[]) =>
        items.reduce(
          (acc: RowType, item: { [x: string]: any }) => {
            acc.usdAdv += item[`usdAdvertiser${key}Sum`] || 0
            acc.eurAdv += item[`eurAdvertiser${key}Sum`] || 0
            acc.usdAff += item[`usd${key}Sum`] || 0
            acc.eurAff += item[`eur${key}Sum`] || 0
            acc.usdMargin += Math.max(
              0,
              (item[`usdAdvertiser${key}Sum`] || 0) -
                (item[`usd${key}Sum`] || 0),
            )
            acc.eurMargin += Math.max(
              0,
              (item[`eurAdvertiser${key}Sum`] || 0) -
                (item[`eur${key}Sum`] || 0),
            )
            return acc
          },
          { ...this.defaultValues, status: key },
        )

      this.rows = ['Hold', 'Approved', 'Rejected'].map(status => ({
        ...(status === 'Hold'
          ? aggregate('Hold', this.chartData)
          : status === 'Approved'
          ? aggregate('Approved', this.chartData)
          : aggregate('Rejected', this.chartData)),
      }))
    },
    formatMoney(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
      )
    },
    resetFilters() {
      this.filters = {
        ...getDefaultFilters(),
        noAso: false,
        noTraffborne: false,
      }
      this.$storage.setStorageSync('financeReportGmbt', {
        ...getDefaultFilters(),
      })
      this.onRequestChartData()
    },
    getGroupByArray() {
      return groupByOptions
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) return
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters }
      this.onRequestChartData()
    },
    onExport() {
      const totalRow = {
        status: 'Total',
        usdAdv: this.total.usdAdv,
        eurAdv: this.total.eurAdv,
        usdAff: this.total.usdAff,
        eurAff: this.total.eurAff,
        usdMargin: this.total.usdMargin,
        eurMargin: this.total.eurMargin,
      }
      const exportData = [...this.rows, totalRow]
      exportToCsv(exportData, this.columns)
    },
  },
})

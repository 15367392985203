import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "q-pa-md"
}
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-px-xl q-layout-container" }, {
    default: _withCtx(() => [
      _cache[21] || (_cache[21] = _createElementVNode("h6", { class: "text-h6 flex flex-center" }, " Добавление/Редактирование рекламодателя ", -1)),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_q_spinner, {
            key: 0,
            color: "primary",
            size: "3em",
            thickness: 2
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_form, {
              onSubmit: _ctx.onSubmit,
              onReset: _ctx.onReset,
              class: "q-gutter-md"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _cache[3] || (_cache[3] = _createElementVNode("b", null, "Имя рекламодателя", -1)),
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    placeholder: "ex. Gagarin Partners, Parimatch...",
                    "model-value": _ctx.advertiser.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = name => _ctx.update({ name })),
                    error: !!_ctx.errors?.name,
                    "error-message": _ctx.errors?.name
                  }, null, 8, ["model-value", "error", "error-message"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _cache[4] || (_cache[4] = _createElementVNode("b", null, "Токен", -1)),
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    placeholder: "gp",
                    "model-value": _ctx.advertiser.token,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = token => _ctx.update({ token })),
                    error: !!_ctx.errors?.token,
                    "error-message": _ctx.errors?.token
                  }, null, 8, ["model-value", "error", "error-message"]),
                  _cache[5] || (_cache[5] = _createElementVNode("p", null, " Токен нужен для того, чтобы отличать запросы к нашему серверу от рекла от запросов потенциальных злоумышленников. Можно считать токен паролем, который знает только тот, кто настраивает постбек от рекламодателя к нам. ", -1))
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _cache[6] || (_cache[6] = _createElementVNode("b", null, "Шаблон редирект URL", -1)),
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    placeholder: "gp",
                    "model-value": _ctx.advertiser.redirectUrlTemplate,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = 
              redirectUrlTemplate => _ctx.update({ redirectUrlTemplate })
            ),
                    error: !!_ctx.errors?.redirectUrlTemplate,
                    "error-message": _ctx.errors?.redirectUrlTemplate
                  }, null, 8, ["model-value", "error", "error-message"]),
                  _cache[7] || (_cache[7] = _createElementVNode("p", null, " В шаблоне можно использовать переменные ", -1)),
                  _cache[8] || (_cache[8] = _createElementVNode("ul", null, [
                    _createElementVNode("li", null, " ${promo_url} – URL промо (из настроек оффера) на которую осуществляется редирект "),
                    _createElementVNode("li", null, " ${event_id} или ${click_id} – ID события перехода (клика) – критически важная информация для связывания переходов с конверсиями "),
                    _createElementVNode("li", null, "${affiliate_id} – ID аффилиата в нашей сети"),
                    _createElementVNode("li", null, "${source} – Источник трафика потока аффилейта"),
                    _createElementVNode("li", null, "${stream_id} – ID потока аффилейта в нашей сети"),
                    _createElementVNode("li", null, " ${sid1} , ${sid2} ... ${sid7} – sid метки аффилейта, которые можно передать рекламодателю. Подставляются из ссылки потока аффилейта. "),
                    _createElementVNode("li", null, " ${landing_params} – Уникальные параметры ленда рекламодателя (например id ленда в системе рекламодателя). Задается на конкретный ленд на форме добавления оффера. ")
                  ], -1)),
                  _cache[9] || (_cache[9] = _createElementVNode("p", null, "Пример 1 (Gagarin): ${promo_url}/${click_id}", -1)),
                  _cache[10] || (_cache[10] = _createElementVNode("p", null, "Пример 2 (RocketPlay): ${promo_url}?click_id=${click_id}", -1)),
                  _cache[11] || (_cache[11] = _createElementVNode("p", null, " Пример 3 (1WIN): ${promo_url}&sub1=${click_id}&sub2=${affiliate_id}${landing_params} ", -1))
                ]),
                _cache[12] || (_cache[12] = _createElementVNode("h5", null, "Как настроить постбек от рекламодателя к нам?", -1)),
                _cache[13] || (_cache[13] = _createElementVNode("p", null, " Нужно указать URL с параметрами https://tracker.gambeat.io/p?token=1&click_id=2&event=3&geo=4... ", -1)),
                _cache[14] || (_cache[14] = _createElementVNode("p", null, [
                  _createElementVNode("b", null, " Для гемблинга параметры в строке запроса (query string) URL постбека – это: ")
                ], -1)),
                _cache[15] || (_cache[15] = _createElementVNode("ul", null, [
                  _createElementVNode("li", null, " token – следует указать значение из настроек рекламодателя в нашей сети "),
                  _createElementVNode("li", null, " click_id – значение, которое было передано в URL при редиректе на рекламодателя (см. настройки шаблона URL выше) "),
                  _createElementVNode("li", null, " event – Registration, FirstDeposit, Deposit, RevShare, Approved, Rejected или Trash. Регистрации могут попасть в треш после получения события Trash. Первые депозиты могут быть апрувнуты либо отклонены после получения событий Approved либо Rejected соответственно. "),
                  _createElementVNode("li", null, " geo – необязательный параметр, указывается в случае event=Registration – ISO-2 код страны игрока, например CA, для Канады. * Если geo не указано в постбеке от рекламодателя к нам, то для определения выплаты береться гео клика, сохраненное у нас в системе ранее. Если гео клика ранее определить не удалось - береться гео, указанное при создании потока. "),
                  _createElementVNode("li", null, " amount, currency – необязательные параметры, указываются в случае если event=FirstDeposit – сумма депозита и ISO-3 код валюты депозита, например, CAD для канадского доллара. В случае event=RevShare в amount приходит сумма нашего дохода по ревшаре; значение может быть отрицательным. "),
                  _createElementVNode("li", null, " referer_event_id – необязательный параметр, уникальный ID события на стороне рекламодателя. Используется в случае отправки нескольких постбеков об одном событии одновременно. Пример: рекламодатель имеет отправку постбеков на \"первый\" депозит и на \"любой\" депозит; если поставить оба постбека (FirstDeposit и Deposit), то прийдет 2 постбека на один и тот же депозит. "),
                  _createElementVNode("li", null, " player_id – необязательный параметр, уникальный ID игрока в системе рекламодателя. ")
                ], -1)),
                _cache[16] || (_cache[16] = _createElementVNode("p", null, [
                  _createElementVNode("b", null, " Для крипты аналогично, отличаются только доступные события: ")
                ], -1)),
                _cache[17] || (_cache[17] = _createElementVNode("ul", null, [
                  _createElementVNode("li", null, " event – Registration, Deposit, Approved, Rejected или Trash. Регистрации могут попасть в треш после получения события Trash. Депозиты и Регистрации могут быть апрувнуты либо отклонены после получения событий Approved либо Rejected соответственно. ")
                ], -1)),
                _cache[18] || (_cache[18] = _createElementVNode("p", null, " Пример (регистрация): https://tracker.gambeat.io/p?token=9c2597cb-e26a-41a5-b796-4bf3b5f2efae&event=Registration&click_id={subid1}&geo=UA ", -1)),
                _cache[19] || (_cache[19] = _createElementVNode("p", null, " Пример (первый депозит): https://tracker.gambeat.io/p?token=9c2597cb-e26a-41a5-b796-4bf3b5f2efae&event=FirstDeposit&click_id={subid1}&currency=UAH&amount={totalAmount} ", -1)),
                _cache[20] || (_cache[20] = _createElementVNode("p", null, " Пример (ревшара): https://tracker.gambeat.io/p?token=9c2597cb-e26a-41a5-b796-4bf3b5f2efae&event=RevShare&click_id={subid1}&currency=UAH&amount={totalAmount} ", -1)),
                _createElementVNode("div", null, [
                  _createVNode(_component_q_btn, {
                    label: "Сохранить",
                    type: "submit",
                    color: "primary"
                  }),
                  _createVNode(_component_q_btn, {
                    label: "Отменить",
                    type: "reset",
                    color: "primary",
                    flat: "",
                    class: "q-ml-sm"
                  })
                ])
              ]),
              _: 1
            }, 8, ["onSubmit", "onReset"])
          ]))
    ]),
    _: 1
  }))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row items-center" }
const _hoisted_2 = { class: "flex row items-center" }
const _hoisted_3 = ["alt", "src"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_table, {
      dense: "",
      "rows-per-page-options": [25, 50, 100, 200, 500, 1000, 50000],
      rows: _ctx.rows,
      columns: _ctx.columns,
      "row-key": "id",
      separator: "cell",
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
      loading: _ctx.loading,
      onRequest: _ctx.onRequest,
      "binary-state-sort": "",
      "column-sort-order": "da"
    }, {
      top: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_q_btn, {
            "no-caps": "",
            "icon-right": _ctx.heroOutline24ArrowDownTray,
            class: "q-btn__main-size text-weight-bold q-mr-lg",
            label: "Export",
            color: "primary",
            outline: "",
            onClick: _ctx.exportCsv
          }, null, 8, ["icon-right", "onClick"]),
          _cache[1] || (_cache[1] = _createElementVNode("b", null, "Лиды", -1))
        ])
      ]),
      loading: _withCtx(() => [
        _createVNode(_component_q_inner_loading, {
          showing: "",
          color: "primary"
        })
      ]),
      "body-cell-offerName": _withCtx((props) => [
        _createVNode(_component_q_td, { props: props }, {
          default: _withCtx(() => [
            _createVNode(_component_IconWithName, {
              id: props.row.offerId,
              name: props.row.offerName,
              size: "20px",
              color: "orange-7",
              icon: "description",
              entity: "offer"
            }, null, 8, ["id", "name"])
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      "body-cell-advertiserName": _withCtx((props) => [
        _createVNode(_component_q_td, { props: props }, {
          default: _withCtx(() => [
            _createVNode(_component_IconWithName, {
              id: props.row.advertiserId,
              name: props.row.advertiserName,
              size: "20px",
              color: "primary",
              icon: "people",
              entity: "advertiser"
            }, null, 8, ["id", "name"])
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      "body-cell-isoCode": _withCtx((props) => [
        _createVNode(_component_q_td, { props: props }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "q-mr-sm",
                height: "10",
                width: "15",
                alt: props.row.isoCode,
                src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.isoCode}.svg`
              
              }, null, 8, _hoisted_3),
              _createTextVNode(" " + _toDisplayString(props.row.isoCode), 1)
            ])
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      "body-cell-amount": _withCtx((props) => [
        _createVNode(_component_q_td, { props: props }, {
          default: _withCtx(() => [
            (props.row.amount !== null && props.row.amount !== undefined)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.amount)) + " ", 1),
                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromRow(props.row.currency)), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString('—')))
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      "body-cell-advertiserAmount": _withCtx((props) => [
        _createVNode(_component_q_td, { props: props }, {
          default: _withCtx(() => [
            (
              props.row.advertiserAmount !== null &&
                props.row.advertiserAmount !== undefined
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.advertiserAmount)) + " ", 1),
                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromRow(props.row.advertiserCurrency)), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString('—')))
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      _: 1
    }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
  ]))
}

import { mapActions, mapMutations, mapState } from 'vuex'
import axios from 'axios'
import { defineComponent } from 'vue'
import { enumToArray } from '@/utils'
import { lookupAdvertisers } from '@/utils/lookups'
import { PaymentSystem } from '@/enums/PaymentSystem'
import { PaymentSystemNetwork } from '@/enums/PaymentSystemNetwork'
import { payoutCurrencies } from '@/utils'
import { Advertiser } from '@/store/types'
import { Currency } from '@/enums/Currency'

export default defineComponent({
  name: 'OurWalletsEdit',
  computed: mapState('payoutSystemAccountModule', [
    'payoutSystemAccount',
    'errors',
  ]),
  async created() {
    if (!this.$route.params.id) {
      this.reset()
      this.loading = false
      return
    }
    await this.getData(this.$route.params.id)
    this.loading = false
  },
  data: function() {
    return {
      loading: true,
      advertisers: [],
      currencies: payoutCurrencies,
      paymentSystems: enumToArray(PaymentSystem),
      paymentSystemsEnum: PaymentSystem,
      paymentSystemNetworks: enumToArray(PaymentSystemNetwork),
    }
  },
  watch: {
    'payoutSystemAccount.paymentSystem'(newVal) {
      if (
        newVal === this.paymentSystemsEnum.USDT ||
        newVal === this.paymentSystemsEnum.USDC
      ) {
        this.payoutSystemAccount.currency = Currency.USD
        this.payoutSystemAccount.paymentSystemNetwork =
          newVal === this.paymentSystemsEnum.USDC
            ? PaymentSystemNetwork.ERC20
            : PaymentSystemNetwork.TRC20
      } else {
        this.payoutSystemAccount.paymentSystemNetwork = null
      }
    },
  },
  methods: {
    ...mapActions('payoutSystemAccountModule', ['getData']),
    ...mapMutations('payoutSystemAccountModule', [
      'update',
      'reset',
      'setErrors',
    ]),
    async getAdvertisers(value: string, update: Function) {
      await lookupAdvertisers(this, value, update)
    },
    getPartnerIds(partners: Advertiser[]) {
      return [...partners.map((prtn: Advertiser) => prtn.id)]
    },
    onSubmit: function() {
      this.setErrors({})
      const errors: Record<string, string> = {}
      if (!this.payoutSystemAccount.account) {
        errors.account = 'Введите номер кошелька'
      }
      if (Object.keys(errors).length) {
        this.setErrors(errors)
        return
      }
      const method = !this.$route.params.id ? 'post' : 'put'
      axios[method]('/api/wallets', this.payoutSystemAccount, {
        params: { id: this.$route.params.id },
      })
        .then(() => {
          this.setErrors({})
          this.$router.push('/our-wallets')
        })
        .catch(({ response }) => {
          this.setErrors(response.data.errors)
        })
    },
    onReset: function() {
      this.reset()
      this.$router.push('/our-wallets')
    },
  },
})

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "q-mb-lg" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_PieChart = _resolveComponent("PieChart")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl" }, {
    default: _withCtx(() => [
      _createVNode(_component_center, { class: "q-mt-xl" }, {
        default: _withCtx(() => _cache[15] || (_cache[15] = [
          _createElementVNode("h6", null, "FIN TOTAL", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Filters, {
          onReset: _ctx.resetFilters,
          onChange: _ctx.onChangeFilters,
          onChangeAll: _ctx.changeAll,
          "current-filters": _ctx.filters
        }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters"]),
        (_ctx.filters.groupBy === 'date' || _ctx.filters.groupBy === 'week')
          ? (_openBlock(), _createBlock(_component_LineChart, {
              key: 0,
              rows: _ctx.chartData,
              groupBy: _ctx.filters.groupBy,
              allColumns: _ctx.columns,
              columns: _ctx.pagination.selectedColumns,
              loading: _ctx.chartLoading,
              groupByOptions: _ctx.getGroupByArray()
            }, null, 8, ["rows", "groupBy", "allColumns", "columns", "loading", "groupByOptions"]))
          : (_openBlock(), _createBlock(_component_PieChart, {
              key: 1,
              rows: _ctx.chartData,
              groupBy: _ctx.filters.groupBy,
              metric: _ctx.pagination.selectedColumns[0],
              loading: _ctx.chartLoading
            }, null, 8, ["rows", "groupBy", "metric", "loading"])),
        _createVNode(_component_q_table, {
          dense: "",
          "column-sort-order": "da",
          class: "q-mx-md",
          rows: _ctx.rows,
          columns: _ctx.columns,
          "row-key": "id",
          separator: "cell",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.pagination) = $event)),
          loading: _ctx.loading,
          onRequest: _ctx.onRequest,
          onRowClick: _ctx.onRowClick,
          "binary-state-sort": ""
        }, _createSlots({
          header: _withCtx((props) => [
            _createVNode(_component_q_tr, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_th, {
                  key: "groupByField",
                  props: props,
                  rowspan: "2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getGroupedField('name')), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, { colspan: "3" }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createTextVNode("Инсталлы, шт.")
                  ])),
                  _: 1
                }),
                _createVNode(_component_q_th, { colspan: "3" }, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode("Профит, $")
                  ])),
                  _: 1
                }),
                _createVNode(_component_q_th, {
                  props: props,
                  rowspan: "2",
                  key: "profit"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.profit,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.profit) = $event)),
                      val: "xs",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeSelected('profit')))
                    }, null, 8, ["modelValue"]),
                    _cache[18] || (_cache[18] = _createTextVNode("Профит с 1 инсталла, $"))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_tr, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "paidInstalls",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.paidInstalls,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.paidInstalls) = $event)),
                      val: "xs",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeSelected('paidInstalls')))
                    }, null, 8, ["modelValue"]),
                    _cache[19] || (_cache[19] = _createTextVNode("Платные инсталлы"))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "freeInstalls",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.freeInstalls,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.freeInstalls) = $event)),
                      val: "xs",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeSelected('freeInstalls')))
                    }, null, 8, ["modelValue"]),
                    _cache[20] || (_cache[20] = _createTextVNode("Бесплатные инсталлы"))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "ppInstalls",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.ppInstalls,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.ppInstalls) = $event)),
                      val: "xs",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.changeSelected('ppInstalls')))
                    }, null, 8, ["modelValue"]),
                    _cache[21] || (_cache[21] = _createTextVNode("Инсталлы на офферы пп"))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "paidInstallSum",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.paidInstallSum,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.paidInstallSum) = $event)),
                      val: "xs",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.changeSelected('paidInstallSum')))
                    }, null, 8, ["modelValue"]),
                    _cache[22] || (_cache[22] = _createTextVNode("Платные инсталлы"))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "ppInstallSum",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.ppInstallSum,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.ppInstallSum) = $event)),
                      val: "xs",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.changeSelected('ppInstallSum')))
                    }, null, 8, ["modelValue"]),
                    _cache[23] || (_cache[23] = _createTextVNode("Инсталлы на офферы пп (APPROVE)"))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "ppInstallSumHold",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.ppInstallSumHold,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.ppInstallSumHold) = $event)),
                      val: "xs",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.changeSelected('ppInstallSumHold')))
                    }, null, 8, ["modelValue"]),
                    _cache[24] || (_cache[24] = _createTextVNode("Инсталлы на офферы пп (HOLD)"))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_tr, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_th, {
                  class: "text-left border-bottom",
                  colspan: "1"
                }, {
                  default: _withCtx(() => _cache[25] || (_cache[25] = [
                    _createElementVNode("b", null, "TOTAL:", -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_q_th, {
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("b", null, _toDisplayString(_ctx.total('paidInstalls', 'sum')), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_th, {
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("b", null, _toDisplayString(_ctx.total('freeInstalls', 'sum')), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_th, {
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("b", null, _toDisplayString(_ctx.total('ppInstalls', 'sum')), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_th, {
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("b", null, [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('paidInstallSum', 'sum'))), 1),
                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_th, {
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("b", null, [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('ppInstallSum', 'sum'))), 1),
                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_th, {
                  class: "text-right border-bottom monospaced text-gray-500",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("b", null, [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('ppInstallSumHold', 'sum'))), 1),
                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_th, {
                  class: "text-right border-bottom monospaced",
                  colspan: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("b", null, [
                        _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('profit', 'avg'))), 1),
                        _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          "body-cell-groupByField": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                (_ctx.filters.groupBy !== 'week')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.getGroupedField('field', props.row)), 1))
                  : _createCommentVNode("", true),
                (_ctx.filters.groupBy === 'week')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.getISOWeekName(_ctx.getGroupedField('field', props.row))), 1))
                  : _createCommentVNode("", true),
                (_ctx.filters.groupBy === 'mobileAppId')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, " [ID:" + _toDisplayString(props.row.mobileAppId) + "]", 1))
                  : _createCommentVNode("", true),
                (_ctx.filters.groupBy === 'affiliateId')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, " [ID:" + _toDisplayString(props.row.affiliateId) + "]", 1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-paidInstallSum": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.paidInstallSum)), 1),
                _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-ppInstallSum": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.ppInstallSum)), 1),
                _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-ppInstallSumHold": _withCtx((props) => [
            _createVNode(_component_q_td, {
              props: props,
              class: "text-gray-500"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.ppInstallSumHold)), 1),
                _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-profit": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.profit)), 1),
                _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 2
        }, [
          (_ctx.rows?.length)
            ? {
                name: "bottom-row",
                fn: _withCtx(() => [
                  _createVNode(_component_q_tr, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_td, {
                        class: "text-left border-top",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => _cache[26] || (_cache[26] = [
                          _createElementVNode("b", null, "TOTAL:", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('paidInstalls', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('freeInstalls', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('ppInstalls', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('paidInstallSum', 'sum'))) + " ", 1),
                              _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('ppInstallSum', 'sum'))), 1),
                              _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right border-top monospaced text-gray-500",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('ppInstallSumHold', 'sum'))), 1),
                              _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right border-top monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, [
                              _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('profit', 'avg'))), 1),
                              _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyFromCookie()), 1)
                            ])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["rows", "columns", "pagination", "loading", "onRequest", "onRowClick"])
      ])
    ]),
    _: 1
  }))
}
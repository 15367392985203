export function getColumns() {
  return [
    {
      name: 'affiliateIds',
      label: 'Email',
      field: 'affiliateIds',
      align: 'left',
    },
    { name: 'geoIds', label: 'Гео', field: 'geoIds', align: 'left' },
    { name: 'value', label: 'Ставка веба', field: 'value', align: 'left' },
    { name: 'currency', label: 'Валюта', field: 'currency', align: 'left' },
    {
      name: 'advertiserValueAmount',
      label: 'Ставка рекла',
      field: 'advertiserValueAmount',
      align: 'left',
    },
    {
      name: 'advertiserValueCurrency',
      label: 'Валюта',
      field: 'advertiserValueCurrency',
      align: 'left',
    },
    { name: 'type', label: 'Conv. type', field: 'type', align: 'left' },
    {
      name: 'trafficSource',
      label: 'Источник',
      field: 'trafficSource',
      align: 'left',
    },
    {
      name: 'percent',
      label: 'Ревшара, %',
      field: 'percent',
      align: 'left',
    },
    {
      name: 'holdInDays',
      label: 'Холд',
      field: 'holdInDays',
      align: 'left',
    },
    {
      name: 'leadLimitPerMonth',
      label: 'Фикс. Капа',
      field: 'leadLimitPerMonth',
      align: 'left',
    },
    {
      name: 'leadLimitPeriod',
      label: 'Период',
      field: 'leadLimitPeriod',
      align: 'left',
    },
    {
      name: 'informLeadLimit',
      label: 'Инф. Капа',
      field: 'informLeadLimit',
      align: 'left',
    },
    {
      name: 'informLeadLimitPeriod',
      label: 'Период',
      field: 'informLeadLimitPeriod',
      align: 'left',
    },
    {
      name: 'postClickReg',
      label: 'Постклик Reg',
      field: 'postClickReg',
      align: 'left',
    },
    {
      name: 'postClickEvent',
      label: 'Постклик Click',
      field: 'postClickEvent',
      align: 'left',
    },
    {
      name: 'postClickMonth',
      label: 'Постклик в пределах календарного месяца',
      field: 'postClickMonth',
      align: 'left',
    },
    {
      name: 'disablePostback',
      label: 'Отключить прием постбека',
      field: 'disablePostback',
      align: 'left',
    },
    { name: 'actions', label: 'Actions', field: 'actions', align: 'right' },
  ]
}

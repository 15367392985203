
import axios from 'axios'
import { defineComponent } from 'vue'
import {
  getDefaultFilters,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import Filters from '@/components/filters/Filters.vue'
import { getColumns } from './getColumns'
import { FiltersType } from '@/utils/request'
import { confirmDialog } from '@/utils'
import IconWithName from '@/components/icons/IconWithName.vue'
import { PaymentSystem } from '@/enums/PaymentSystem'
import { PaymentSystemNetwork } from '@/enums/PaymentSystemNetwork'

export default defineComponent({
  name: 'OurWallets',
  components: { IconWithName, Filters },
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data() {
    return {
      ...getTableProperties('id', 50),
      filters: { ...getDefaultFilters() },
      columns: getColumns(),
      paymentSystemsEnum: PaymentSystem,
      paymentSystemsNetworkEnum: PaymentSystemNetwork,
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  methods: {
    async deleteRow(props: TableRequestProps) {
      confirmDialog('Вы действительно хотите удалить кошелек?', async () => {
        this.loading = true
        await axios.delete('/api/wallets', {
          params: { id: props.row?.id },
        })
        await this.onRequest({ pagination: this.pagination })
      })
    },
    async editRow(props: TableRequestProps) {
      this.$router.push('/wallets/' + props.row?.id)
    },
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/wallets/all-partners')
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.filters = { ...getDefaultFilters() }
    },
  },
})

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDialog) = $event)),
    onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"min-width":"350px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("div", { class: "text-h6" }, "Задать цену инсталла", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
            default: _withCtx(() => [
              _cache[7] || (_cache[7] = _createTextVNode(" Цена инсталла: ")),
              _createVNode(_component_q_input, {
                outlined: "",
                modelValue: _ctx.price,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.price) = $event)),
                type: "number",
                step: "0.10",
                min: "0",
                max: "10",
                autofocus: "",
                onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
              }, {
                append: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("$")
                ])),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                label: "Закрыть",
                color: "primary",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
              }),
              _createVNode(_component_q_btn, {
                flat: "",
                "no-caps": "",
                label: "Сохранить",
                color: "primary",
                onClick: _ctx.save,
                loading: _ctx.loading,
                disable: _ctx.loading
              }, null, 8, ["onClick", "loading", "disable"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
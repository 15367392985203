import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = { class: "q-mr-lg" }
const _hoisted_3 = { class: "q-mb-lg" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "flex column" }
const _hoisted_7 = { class: "flex column col-10" }
const _hoisted_8 = { class: "row justify-between items-center" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  class: "width-200",
  style: {"overflow":"hidden","text-overflow":"ellipsis"}
}
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["alt", "src"]
const _hoisted_21 = { key: 0 }
const _hoisted_22 = {
  key: 1,
  class: "text-gray-500"
}
const _hoisted_23 = { key: 2 }
const _hoisted_24 = {
  key: 3,
  class: "text-red"
}
const _hoisted_25 = {
  class: "flex row items-center",
  style: {"width":"max-content"}
}
const _hoisted_26 = ["alt", "src"]
const _hoisted_27 = { key: 0 }
const _hoisted_28 = {
  key: 1,
  class: "text-gray-500"
}
const _hoisted_29 = { key: 2 }
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_popup_edit = _resolveComponent("q-popup-edit")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_RewardsPopup = _resolveComponent("RewardsPopup")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_AddOfferMarkerDialog = _resolveComponent("AddOfferMarkerDialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_page, { class: "q-ml-xl q-mr-lg" }, {
      default: _withCtx(() => [
        _createVNode(_component_center, null, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("h6", null, "Офферы", -1)
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_router_link, {
            class: "link-text",
            to: "/offers/new"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                class: "q-ml-md",
                label: "Добавить оффер",
                color: "primary",
                "no-caps": ""
              })
            ]),
            _: 1
          }),
          _createElementVNode("b", _hoisted_2, "Количество фоновых операций: " + _toDisplayString(_ctx.uploadingOperations), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Filters, {
            onReset: _ctx.resetFilters,
            onChange: _ctx.onChangeFilters,
            onChangeAll: _ctx.changeAll,
            "current-filters": _ctx.complexFilters
          }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters"]),
          _createVNode(_component_q_table, {
            dense: "",
            class: "q-ml-md",
            "rows-per-page-options": [5, 10, 25, 50, 100, 200, 500, 1000],
            rows: _ctx.rows,
            columns: _ctx.columns,
            "visible-columns": _ctx.pagination.visibleColumns,
            "row-key": "id",
            separator: "cell",
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pagination) = $event)),
            loading: _ctx.loading,
            onRequest: _ctx.onRequest,
            "binary-state-sort": "",
            "column-sort-order": "da"
          }, {
            top: _withCtx(() => [
              _createVNode(_component_q_select, {
                modelValue: _ctx.pagination.visibleColumns,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination.visibleColumns) = $event)),
                multiple: "",
                outlined: "",
                dense: "",
                "options-dense": "",
                "display-value": "Колонки",
                "emit-value": "",
                "map-options": "",
                options: _ctx.columns,
                "option-value": "name",
                "option-label": "label",
                "options-cover": "",
                style: {"min-width":"150px"}
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                class: "q-ml-md",
                icon: "autorenew",
                color: "red",
                label: "Сбросить",
                size: "13px",
                onClick: _ctx.resetColumns,
                outline: ""
              }, null, 8, ["onClick"])
            ]),
            "body-cell-id": _withCtx((props) => [
              _createVNode(_component_q_td, {
                props: props,
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    href: '/offers/' + props.row.id
                  }, [
                    _createVNode(_component_IconWithName, {
                      id: props.row.id,
                      size: "18px",
                      color: "orange-7",
                      icon: "description",
                      entity: "offer"
                    }, null, 8, ["id"])
                  ], 8, _hoisted_4)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-name": _withCtx((props) => [
              _createVNode(_component_q_td, {
                props: props,
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    href: '/offers/' + props.row.id
                  }, [
                    _createElementVNode("span", null, _toDisplayString(props.row.name), 1)
                  ], 8, _hoisted_5)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-advertiserManagerId": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_select, {
                      dense: "",
                      clearable: "",
                      ref: "managers",
                      "model-value": props.row.advertiserManagerId,
                      "onUpdate:modelValue": (val) => _ctx.onChangeManagers(val, props.row),
                      onBlur: _ctx.saveManagers,
                      outlined: "",
                      class: "col-4 q-mr-lg manager-select",
                      options: _ctx.advertiserManagers,
                      onFilter: _ctx.getManagers,
                      "option-value": "id",
                      "option-label": "email",
                      "emit-value": "",
                      "map-options": "",
                      "use-input": ""
                    }, {
                      selected: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_IconWithName, {
                            id: props.row.advertiserManagerId,
                            name: _ctx.getAdminEmail(props.row.advertiserManagerId),
                            size: "20px",
                            color: "primary",
                            icon: "manage_accounts",
                            entity: "manager"
                          }, null, 8, ["id", "name"])
                        ])
                      ]),
                      option: _withCtx((scope) => [
                        _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_IconWithName, {
                                  id: scope.opt.id,
                                  name: scope.opt.email,
                                  size: "20px",
                                  color: "primary",
                                  icon: "manage_accounts",
                                  entity: "manager"
                                }, null, 8, ["id", "name"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1040)), [
                          [_directive_close_popup]
                        ])
                      ]),
                      _: 2
                    }, 1032, ["model-value", "onUpdate:modelValue", "onBlur", "options", "onFilter"])
                  ])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-markers": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    (!props.row.markers?.length)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          dense: "",
                          flat: "",
                          "no-caps": "",
                          class: "q-ml-xs",
                          color: "secondary",
                          onClick: _withModifiers(($event: any) => (_ctx.addMarker(props.row.id)), ["stop"]),
                          icon: "add",
                          size: "xs"
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_7, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.markers, (marker, i) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: i,
                          class: "q-mr-sm",
                          onClick: _withModifiers(() => true, ["stop"])
                        }, [
                          _createVNode(_component_q_badge, {
                            class: "cursor-pointer",
                            style: _normalizeStyle(
                      `background: ${marker.color} !important; font-size: 11px;`
                    )
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("b", null, _toDisplayString(marker.name), 1),
                              _createVNode(_component_q_popup_edit, {
                                modelValue: _ctx.popupModel,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.popupModel) = $event)),
                                class: "bg-grey-9 text-white marker-popup",
                                anchor: "bottom middle"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("span", null, [
                                      _cache[4] || (_cache[4] = _createElementVNode("b", null, "Название:", -1)),
                                      _cache[5] || (_cache[5] = _createTextVNode()),
                                      _createElementVNode("b", null, _toDisplayString(marker.name), 1)
                                    ]),
                                    _createElementVNode("span", null, [
                                      _createVNode(_component_q_btn, {
                                        icon: "edit",
                                        size: "xs",
                                        round: "",
                                        flat: "",
                                        onClick: _withModifiers(($event: any) => (_ctx.addMarker(props.row.id, marker.id)), ["stop"])
                                      }, null, 8, ["onClick"]),
                                      _createVNode(_component_q_btn, {
                                        icon: "delete",
                                        size: "xs",
                                        round: "",
                                        flat: "",
                                        onClick: _withModifiers(($event: any) => (
                              _ctx.deleteMarker(props.row.id, marker.id, marker.name)
                            ), ["stop"])
                                      }, null, 8, ["onClick"])
                                    ])
                                  ]),
                                  _createElementVNode("div", null, [
                                    _cache[6] || (_cache[6] = _createElementVNode("b", null, "Описание:", -1)),
                                    _cache[7] || (_cache[7] = _createTextVNode()),
                                    _createElementVNode("span", {
                                      innerHTML: marker.reason
                                    }, null, 8, _hoisted_9)
                                  ]),
                                  _createElementVNode("div", null, [
                                    _cache[8] || (_cache[8] = _createElementVNode("b", null, "Добавлено:", -1)),
                                    _createTextVNode(" " + _toDisplayString(_ctx.frmtDate(marker.createdAt)), 1)
                                  ]),
                                  _createElementVNode("div", null, [
                                    _cache[9] || (_cache[9] = _createElementVNode("b", null, "Кто добавил:", -1)),
                                    _createTextVNode(" " + _toDisplayString(marker.addedBySystem
                            ? 'Добавлено системой'
                            : props.row.offerMarkerCreatedByEmails?.[0] ||
                              'Добавлено системой'), 1)
                                  ]),
                                  (
                          _ctx.frmtDate(marker.updatedAt) !==
                            _ctx.frmtDate(marker.createdAt) &&
                            props.row.offerMarkerUpdatedByEmails?.[0] &&
                            marker.updatedAt
                        )
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                        _cache[10] || (_cache[10] = _createElementVNode("b", null, "Изменено:", -1)),
                                        _createTextVNode(" " + _toDisplayString(_ctx.frmtDate(marker.updatedAt)), 1)
                                      ]))
                                    : _createCommentVNode("", true),
                                  (
                          _ctx.frmtDate(marker.updatedAt) !==
                            _ctx.frmtDate(marker.createdAt) &&
                            props.row.offerMarkerUpdatedByEmails?.[0] &&
                            marker.updatedAt
                        )
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                        _cache[11] || (_cache[11] = _createElementVNode("b", null, "Кто изменил:", -1)),
                                        _createTextVNode(" " + _toDisplayString(props.row.offerMarkerUpdatedByEmails?.[0]), 1)
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["modelValue"])
                            ]),
                            _: 2
                          }, 1032, ["style"]),
                          (i === 0)
                            ? (_openBlock(), _createBlock(_component_q_btn, {
                                key: 0,
                                dense: "",
                                flat: "",
                                "no-caps": "",
                                class: "q-ml-xs",
                                color: "secondary",
                                onClick: _withModifiers(($event: any) => (_ctx.addMarker(props.row.id)), ["stop"]),
                                icon: "add",
                                size: "xs"
                              }, null, 8, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-clicksDifference": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    style: _normalizeStyle(
                props.row.clicksDifference > 0
                  ? 'background: rgba(171, 254, 165, 0.2); color: #17c322;'
                  : props.row.clicksDifference === 0 ||
                    !props.row.clicksDifference
                  ? 'background: rgba(255, 208, 0, 0.1); color: #ff9500;'
                  : 'background: rgba(208, 54, 54, 0.1); color: #D03636;'
              )
                  }, [
                    (
                  props.row.clicksDifference === 0 ||
                    !props.row.clicksDifference
                )
                      ? (_openBlock(), _createElementBlock("small", _hoisted_12))
                      : (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(props.row.clicksDifference > 0 ? '↑' : '↓'), 1)),
                    _createTextVNode(" " + _toDisplayString(props.row.clicksDifference > 0
                  ? `+${Math.round(props.row.clicksDifference || 0)} `
                  : `${Math.round(props.row.clicksDifference || 0)} `), 1)
                  ], 4)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-regsDifference": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    style: _normalizeStyle(
                props.row.regsDifference > 0
                  ? 'background: rgba(171, 254, 165, 0.2); color: #17c322;'
                  : props.row.regsDifference === 0 || !props.row.regsDifference
                  ? 'background: rgba(255, 208, 0, 0.1); color: #ff9500;'
                  : 'background: rgba(208, 54, 54, 0.1); color: #D03636;'
              )
                  }, [
                    (
                  props.row.regsDifference === 0 || !props.row.regsDifference
                )
                      ? (_openBlock(), _createElementBlock("small", _hoisted_14))
                      : (_openBlock(), _createElementBlock("small", _hoisted_15, _toDisplayString(props.row.regsDifference > 0 ? '↑' : '↓'), 1)),
                    _createTextVNode(" " + _toDisplayString(props.row.regsDifference > 0
                  ? `+${Math.round(props.row.regsDifference || 0)} `
                  : `${Math.round(props.row.regsDifference || 0)} `), 1)
                  ], 4)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-ftdDifference": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    style: _normalizeStyle(
                props.row.ftdDifference > 0
                  ? 'background: rgba(171, 254, 165, 0.2); color: #17c322;'
                  : props.row.ftdDifference === 0 || !props.row.ftdDifference
                  ? 'background: rgba(255, 208, 0, 0.1); color: #ff9500;'
                  : 'background: rgba(208, 54, 54, 0.1); color: #D03636;'
              )
                  }, [
                    (props.row.ftdDifference === 0 || !props.row.ftdDifference)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_16))
                      : (_openBlock(), _createElementBlock("small", _hoisted_17, _toDisplayString(props.row.ftdDifference > 0 ? '↑' : '↓'), 1)),
                    _createTextVNode(" " + _toDisplayString(props.row.ftdDifference > 0
                  ? `+${Math.round(props.row.ftdDifference || 0)} `
                  : `${Math.round(props.row.ftdDifference || 0)} `), 1)
                  ], 4)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-advertiserName": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_IconWithName, {
                      id: props.row.advertiserId,
                      name: props.row.advertiserName,
                      size: "20px",
                      color: "primary",
                      icon: "people",
                      entity: "advertiser"
                    }, null, 8, ["id", "name"]),
                    _createVNode(_component_q_tooltip, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.advertiserName +
                    ' [ ID#' +
                    props.row.advertiserId +
                    ' ]'), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-status": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_badge, {
                    color: 
                props.row.status === _ctx.statuses.Active
                  ? 'positive'
                  : props.row.status === _ctx.statuses.Private
                  ? 'blue'
                  : props.row.status === _ctx.statuses.Draft
                  ? 'orange'
                  : 'red'
              
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.statuses[props.row.status]), 1)
                    ]),
                    _: 2
                  }, 1032, ["color"])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-rewardType": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRewardTypes(props.row), (type, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      _createElementVNode("span", { innerHTML: type }, null, 8, _hoisted_19)
                    ]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-trafficSources": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.trafficSources, (s, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      _createElementVNode("div", null, _toDisplayString(s.name), 1)
                    ]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-rewards": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.rewards, (r, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(r.geocodes, (code, geoIdx) => {
                        return (_openBlock(), _createElementBlock("div", { key: geoIdx }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(
                    r.stopGeo
                      ? 'flex row items-center text-negative'
                      : 'flex row items-center'
                  ),
                            style: {"width":"max-content"}
                          }, [
                            _createElementVNode("img", {
                              style: {"user-select":"none"},
                              class: "q-mr-sm",
                              height: "10",
                              width: "15",
                              alt: code,
                              src: 
                      `http://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`
                    
                            }, null, 8, _hoisted_20),
                            _createTextVNode(" " + _toDisplayString(r.geonames[geoIdx]) + " - ", 1),
                            (r.type !== _ctx.rewardTypes.RevShare && !r.withoutValue)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(`${r.valueAmount} ${_ctx.currency[r.valueCurrency]} ${
                        r.minDepComment?.trim()
                          ? r.baselineComment?.trim()
                            ? `(Мин.деп: ${r.minDepComment.trim()}, Бейслайн: ${r.baselineComment.trim()})`
                            : `(Мин.деп: ${r.minDepComment.trim()})`
                          : r.baselineComment?.trim()
                          ? `(Бейслайн: ${r.baselineComment.trim()})`
                          : ''
                      }`), 1))
                              : _createCommentVNode("", true),
                            (r.withoutValue)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_22, "(Без ставки)"))
                              : _createCommentVNode("", true),
                            (r.type === _ctx.rewardTypes.RevShare)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(`${r.percent} % (revshare) `), 1))
                              : _createCommentVNode("", true),
                            _createTextVNode(" " + _toDisplayString(r.trafficSourceId
                      ? ` [${_ctx.getTsNameById(props.row, r.trafficSourceId)}] `
                      : '') + " ", 1),
                            (r.stopGeo)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_24, "(STOP)"))
                              : _createCommentVNode("", true)
                          ], 2)
                        ]))
                      }), 128))
                    ]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-advertiserRewards": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.rewards, (r, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(r.geocodes, (code, geoIdx) => {
                        return (_openBlock(), _createElementBlock("div", { key: geoIdx }, [
                          _createElementVNode("div", _hoisted_25, [
                            _createElementVNode("img", {
                              style: {"user-select":"none"},
                              class: "q-mr-sm",
                              height: "10",
                              width: "15",
                              alt: code,
                              src: 
                      `http://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`
                    
                            }, null, 8, _hoisted_26),
                            _createTextVNode(" " + _toDisplayString(r.geonames[geoIdx]) + " - ", 1),
                            (r.type !== _ctx.rewardTypes.RevShare && !r.withoutValue)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(`${r.advertiserValueAmount} ${
                        _ctx.currency[r.advertiserValueCurrency]
                      }`), 1))
                              : _createCommentVNode("", true),
                            (r.withoutValue)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_28, "(Без ставки)"))
                              : _createCommentVNode("", true),
                            (r.type === _ctx.rewardTypes.RevShare)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(`${r.percent} % (revshare)`), 1))
                              : _createCommentVNode("", true),
                            _createTextVNode(" " + _toDisplayString(r.trafficSourceId
                      ? ` [${_ctx.getTsNameById(props.row, r.trafficSourceId)}]`
                      : ''), 1)
                          ])
                        ]))
                      }), 128))
                    ]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-comment": _withCtx((props) => [
              _createVNode(_component_q_td, {
                props: props,
                style: {"width":"170px"}
              }, {
                default: _withCtx(() => [
                  (props.row.comment)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "text-ellipsis cursor-pointer",
                        innerHTML: props.row.comment,
                        style: {"height":"21px","width":"170px"}
                      }, null, 8, _hoisted_30))
                    : _createCommentVNode("", true),
                  (props.row.comment)
                    ? (_openBlock(), _createBlock(_component_q_tooltip, {
                        key: 1,
                        innerHTML: props.row.comment
                      }, null, 8, ["innerHTML"]))
                    : _createCommentVNode("", true),
                  (!props.row.comment)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_31, "—"))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-affiliates": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    dense: "",
                    flat: "",
                    outline: "",
                    label: "Аффилейты",
                    "no-caps": "",
                    color: "secondary",
                    size: "sm",
                    icon: "account_box"
                  }, {
                    default: _withCtx(() => [
                      (Number(props.row.affiliatesCount))
                        ? (_openBlock(), _createBlock(_component_q_badge, {
                            key: 0,
                            color: "red",
                            floating: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(props.row.affiliatesCount), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true),
                      _createVNode(_component_q_menu, {
                        style: {"border":"1px solid #000"},
                        anchor: "bottom left",
                        self: "top end",
                        class: "q-pb-sm q-px-sm"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_RewardsPopup, {
                            "offer-id": props.row.id,
                            "offer-name": props.row.name
                          }, null, 8, ["offer-id", "offer-name"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-campaignsCount": _withCtx((props) => [
              _createVNode(_component_q_td, {
                props: props,
                onClick: _withModifiers(($event: any) => (_ctx.$router.push('/campaigns?offerId=' + props.row.id)), ["stop"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, { to: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(props.row.campaignsCount), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["props", "onClick"])
            ]),
            "body-cell-actions": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    dense: "",
                    round: "",
                    flat: "",
                    color: "primary",
                    size: "sm",
                    onClick: _withModifiers(($event: any) => (_ctx.onRowClick(props.row)), ["stop"]),
                    icon: "edit",
                    class: "q-mr-sm"
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_q_btn, {
                    dense: "",
                    round: "",
                    flat: "",
                    color: "negative",
                    size: "sm",
                    onClick: _withModifiers(($event: any) => (_ctx.deleteRow(props)), ["stop"]),
                    icon: "delete"
                  }, null, 8, ["onClick"])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 1
          }, 8, ["rows", "columns", "visible-columns", "pagination", "loading", "onRequest"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_AddOfferMarkerDialog, {
      open: _ctx.showAddTagsDialog,
      offerId: _ctx.selectedRowId,
      markerId: _ctx.selectedMarkerId,
      onClose: _ctx.onCloseDialog,
      onRefresh: _ctx.onRefresh
    }, null, 8, ["open", "offerId", "markerId", "onClose", "onRefresh"])
  ], 64))
}
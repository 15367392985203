import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showDialog) = $event)),
    onKeyup: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_q_spinner, {
            key: 0,
            color: "primary",
            size: "3em",
            thickness: 2
          }))
        : (_openBlock(), _createBlock(_component_q_card, {
            key: 1,
            style: {"min-width":"350px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, " Холд по " + _toDisplayString(_ctx.selected.length > 1 ? 'конверсиям' : 'конверсии'), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
                default: _withCtx(() => [
                  _cache[6] || (_cache[6] = _createTextVNode(" Количество дней: ")),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    modelValue: _ctx.holdInDays,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.holdInDays) = $event)),
                    modelModifiers: { number: true },
                    autofocus: "",
                    onKeyup: [
                      _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('close')), ["enter"])),
                      _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
                    ]
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    flat: "",
                    label: "Закрыть",
                    color: "primary",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
                  }),
                  _createVNode(_component_q_btn, {
                    flat: "",
                    label: "Сохранить",
                    color: "primary",
                    onClick: _ctx.changeHold,
                    loading: _ctx.loading
                  }, null, 8, ["onClick", "loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
<template>
  <div class="flex cursor-pointer relative-position row">
    <div
      style="height: 40px !important;"
      class="row items-center cursor-pointer"
      type="text"
      :id="'daterange' + id"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="q-mr-sm"
        style="width: 16px; height: 15px; margin-left: 8px;"
      >
        <path
          d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
        />
        <path
          fill-rule="evenodd"
          d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
          clip-rule="evenodd"
        />
      </svg>
      <!-- Здесь появляется выбранный диапазон -->
      <span></span>
      <div class="q-ml-sm q-mr-md q-icon">
        <svg viewBox="0 0 24 24">
          <path
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            style="fill: none; stroke-width: 1.5; stroke: currentColor; stroke-linecap: round; stroke-linejoin: round;"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import {
  endOfMonth,
  endOfWeek,
  isMonday,
  previousMonday,
  startOfMonth,
  startOfYear,
  endOfYear,
  sub,
} from 'date-fns'
import moment from 'moment'
import i18n from '@/locales/i18n'
import { heroOutline24ChevronDown } from 'quasar-extras-svg-icons/hero-icons-v2'

export default defineComponent({
  name: 'DatePicker',
  props: ['from', 'to', 'changeDate', 'id', 'label'],
  setup() {
    return {
      heroOutline24ChevronDown,
    }
  },
  data() {
    // Удаляем прошлый #daterangeX, если остался
    window.$('#daterange' + this.id).remove()

    // Локально храним даты (Date-объекты), как у вас было
    const dateValue = {
      startDate: this.from ? this.from : (this.id === '2' ? null : new Date('2021/02/01')),
      endDate:   this.to   ? this.to   : (this.id === '2' ? null : new Date()),
    }
    return {
      dateValue,
    }
  },
  methods: {
    // Проверяем дату: если null или невалидная, возвращаем 'Не задано'
    formatDateOrEmpty(date) {
      if (!date) {
        return 'Не задано'
      }
      const m = moment(date)
      return m.isValid() ? m.format('YYYY/MM/DD') : 'Не задано'
    },

    // Обновляем текст внутри <span>
    updateSpanLabel(startDate, endDate) {
      const startStr = this.formatDateOrEmpty(startDate)
      const endStr   = this.formatDateOrEmpty(endDate)
      window.$(`#daterange${this.id} span`).html(`${startStr} - ${endStr}`)
    },
  },
  watch: {
    // Когда prop "from" меняется у родителя, обновим локальную dateValue, сам плагин и <span>
    from(newVal) {
      this.dateValue.startDate = newVal
      const start = moment(newVal)
      const end   = moment(this.to)

      // Обновим в daterangepicker
      const drp = window.$(`#daterange${this.id}`).data('daterangepicker')
      if (drp) {
        if (start.isValid()) {
          drp.setStartDate(start.format('YYYY/MM/DD'))
        } else {
          // Если null или невалидно — даём дефолт (чтобы календарь не ломался)
          drp.setStartDate(moment('2021/02/01', 'YYYY/MM/DD'))
        }
      }
      // Обновим текст
      this.updateSpanLabel(newVal, this.to)
    },

    // Аналогично при изменении "to"
    to(newVal) {
      this.dateValue.endDate = newVal
      const start = moment(this.from)
      const end   = moment(newVal)

      const drp = window.$(`#daterange${this.id}`).data('daterangepicker')
      if (drp) {
        if (end.isValid()) {
          drp.setEndDate(end.format('YYYY/MM/DD'))
        } else {
          drp.setEndDate(moment().format('YYYY/MM/DD'))
        }
      }
      this.updateSpanLabel(this.from, newVal)
    },

    // При любом изменении локального объекта dateValue
    // теперь эмитим в родитель СТРОКИ в формате YYYY/MM/DD
    dateValue: {
      deep: true,
      handler() {
        // Формируем объект со строками. Если дата null/невалидна — вернём null
        const payload = {
          startDate: moment(this.dateValue.startDate).isValid()
            ? moment(this.dateValue.startDate).format('YYYY/MM/DD')
            : null,
          endDate: moment(this.dateValue.endDate).isValid()
            ? moment(this.dateValue.endDate).format('YYYY/MM/DD')
            : null,
        }
        this.$emit('changeDate', payload)
      },
    },
  },
  mounted() {
    const ref = this

    // Когда пользователь нажал "Применить" в календаре
    function applyHandler(ev, picker) {
      // Ставим в локальное хранилище (Date)
      ref.dateValue.startDate = picker.startDate.toDate()
      ref.dateValue.endDate   = picker.endDate.toDate()

      // Обновляем <span>
      ref.updateSpanLabel(ref.dateValue.startDate, ref.dateValue.endDate)
    }

    // Когда пользователь нажал "Отмена" или закрыл без выбора
    function cancelHandler() {
      // Ничего не меняем, просто показываем уже хранимые значения
      ref.updateSpanLabel(ref.dateValue.startDate, ref.dateValue.endDate)
    }

    // Делаем валидный moment для start/end, чтобы при null не было NaN
    const startMoment = moment(ref.dateValue.startDate)
    const endMoment   = moment(ref.dateValue.endDate)

    // Если они невалидны, подставим дефолт
    const initStart = startMoment.isValid()
      ? startMoment.format('YYYY/MM/DD')
      : moment('2021/02/01', 'YYYY/MM/DD').format('YYYY/MM/DD')

    const initEnd = endMoment.isValid()
      ? endMoment.format('YYYY/MM/DD')
      : moment().format('YYYY/MM/DD')

    // Инициализируем daterangepicker
    window.$('#daterange' + ref.id).daterangepicker(
      {
        autoUpdateInput: false, // чтобы не было Invalid Date
        dark: true,
        darkMode: true,
        showDropdowns: true,
        minYear: 2022,
        maxYear: 2040,
        showISOWeekNumbers: true,
        locale: {
          format: 'YYYY/MM/DD',
          separator: ' — ',
          firstDay: 1,
          applyLabel: i18n.t('apply'),
          cancelLabel: i18n.t('cancel'),
          daysOfWeek: [
            i18n.t('sunday'),
            i18n.t('monday'),
            i18n.t('tuesday'),
            i18n.t('wednesday'),
            i18n.t('thursday'),
            i18n.t('friday'),
            i18n.t('saturday'),
          ],
          monthNames: [
            i18n.t('january'),
            i18n.t('february'),
            i18n.t('march'),
            i18n.t('april'),
            i18n.t('may'),
            i18n.t('june'),
            i18n.t('july'),
            i18n.t('august'),
            i18n.t('september'),
            i18n.t('october'),
            i18n.t('november'),
            i18n.t('december'),
          ],
        },
        showCustomRangeLabel: false,
        linkedCalendars: false,
        alwaysShowCalendars: true,
        startDate: initStart,
        endDate: initEnd,
        drops: 'auto',
        applyButtonClasses:
          'daterange-apply-button bg-primary text-white q-btn q-btn__main-size q-btn--no-uppercase q-btn--unelevated q-btn--rectangle',
        cancelButtonClasses:
          'daterange-cancel-button q-btn q-btn__main-size text-white q-btn--no-uppercase q-btn--unelevated q-btn--rectangle',
        ranges: {
          [i18n.t('today')]: [new Date(), new Date()],
          [i18n.t('yesterday')]: (function() {
            const date = sub(new Date(), { days: 1 })
            return [date, date]
          })(),
          [i18n.t('current_week')]: (function() {
            const date = new Date()
            if (isMonday(date)) {
              return [date, date]
            }
            return [previousMonday(date), date]
          })(),
          [i18n.t('last_week')]: (function() {
            const date = new Date()
            if (isMonday(date)) {
              return [sub(date, { days: 7 }), sub(date, { days: 1 })]
            }
            const dayOnPrevWeek = sub(date, { weeks: 1 })
            if (isMonday(dayOnPrevWeek)) {
              return [
                dayOnPrevWeek,
                endOfWeek(dayOnPrevWeek, { weekStartsOn: 1 }),
              ]
            }
            return [
              previousMonday(dayOnPrevWeek),
              endOfWeek(dayOnPrevWeek, { weekStartsOn: 1 }),
            ]
          })(),
          [i18n.t('current_month')]: (function() {
            const date = new Date()
            return [startOfMonth(date), date]
          })(),
          [i18n.t('last_month')]: (function() {
            const date = sub(new Date(), { months: 1 })
            return [startOfMonth(date), endOfMonth(date)]
          })(),
          [i18n.t('current_year')]: (function() {
            const date = new Date()
            return [startOfYear(date), date]
          })(),
          'Прошлый год': (function() {
            const date = sub(new Date(), { years: 1 })
            return [startOfYear(date), endOfYear(date)]
          })(),
          [i18n.t('all_time')]: [new Date('2022-02-01'), new Date()],
        },
      },
    )

    // Навешиваем свои события
    window.$(`#daterange${ref.id}`).on('apply.daterangepicker', applyHandler)
    window.$(`#daterange${ref.id}`).on('cancel.daterangepicker', cancelHandler)

    // Изначально пишем текст
    this.updateSpanLabel(this.dateValue.startDate, this.dateValue.endDate)
  },
})
</script>

<style lang="scss">
.daterangepicker {
  font-family: Nunito, sans-serif;
  .ranges {
    li {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  .calendar-table {
    th,
    td {
      height: 32px;
    }
    th {
      color: #718096;
    }
    td {
      &:not(.off) {
        color: #2d3748;
      }
    }
  }
  .drp-buttons {
    border-top: unset;
  }
  td.active,
  td.active:hover {
    background: #d9ebff;
    color: #2d3748;
  }
  td {
    &.start-date {
      border-top-left-radius: 100%;
      border-bottom-left-radius: 100%;
    }
    &.end-date {
      border-top-right-radius: 100%;
      border-bottom-right-radius: 100%;
    }
    &.start-date.end-date {
      border-radius: 100%;
    }
  }
}
.daterangepicker .daterange-label {
  color: #6f757b;
  font-family: Nunito;
  transform: translateY(-50%);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  position: absolute;
  left: 11px;
  top: 0;
  background: #fff;
  padding: 0 8px;
  line-height: normal;
}
.daterangepicker .drp-buttons .btn {
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
}
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 14px;
  font-weight: 500;
  background: transparent;
  border: unset;
  color: #2d3748;
}
</style>

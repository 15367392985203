import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  class: "row",
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_2 = {
  key: 0,
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_3 = ["alt", "src"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { class: "link" }
const _hoisted_8 = { class: "link" }
const _hoisted_9 = { class: "link" }
const _hoisted_10 = { class: "link" }
const _hoisted_11 = { class: "link" }
const _hoisted_12 = { class: "link" }
const _hoisted_13 = { class: "link" }
const _hoisted_14 = {
  class: "row",
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_15 = {
  key: 0,
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_16 = ["alt", "src"]
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 2 }
const _hoisted_19 = { key: 3 }
const _hoisted_20 = {
  class: "row justify-end",
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_21 = {
  key: 0,
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_22 = ["alt", "src"]
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { key: 2 }
const _hoisted_25 = { key: 3 }
const _hoisted_26 = { class: "link" }
const _hoisted_27 = { class: "link" }
const _hoisted_28 = { class: "link" }
const _hoisted_29 = { class: "link" }
const _hoisted_30 = { class: "link" }
const _hoisted_31 = { class: "link" }
const _hoisted_32 = { class: "link" }
const _hoisted_33 = { class: "link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_PieChart = _resolveComponent("PieChart")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.filters.groupBy === 'date' || _ctx.filters.groupBy === 'week')
      ? (_openBlock(), _createBlock(_component_LineChart, {
          key: 0,
          rows: _ctx.chartData,
          groupBy: _ctx.filters.groupBy,
          columns: _ctx.selectedColumns,
          allColumns: _ctx.columns,
          groupByOptions: _ctx.getGroupByArray(),
          loading: _ctx.chartLoading
        }, null, 8, ["rows", "groupBy", "columns", "allColumns", "groupByOptions", "loading"]))
      : (_openBlock(), _createBlock(_component_PieChart, {
          key: 1,
          rows: _ctx.chartData,
          groupBy: _ctx.filters.groupBy,
          metric: _ctx.selectedColumns[0],
          loading: _ctx.chartLoading
        }, null, 8, ["rows", "groupBy", "metric", "loading"])),
    _createVNode(_component_q_table, {
      rows: _ctx.rows,
      expanded: _ctx.expandedKeys,
      "onUpdate:expanded": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.expandedKeys) = $event)),
      dense: "",
      "column-sort-order": "da",
      columns: _ctx.columns,
      "visible-columns": _ctx.visibleColumns,
      "row-key": "id",
      separator: "cell",
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.pagination) = $event)),
      loading: _ctx.loading,
      onRequest: _ctx.onRequest,
      "binary-state-sort": ""
    }, _createSlots({
      top: _withCtx(() => [
        _createVNode(_component_q_select, {
          modelValue: _ctx.visibleColumns,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visibleColumns) = $event)),
          multiple: "",
          outlined: "",
          dense: "",
          "options-dense": "",
          "display-value": "Колонки",
          "emit-value": "",
          "map-options": "",
          options: _ctx.columns,
          "option-value": "name",
          "option-label": "fullLabel",
          "options-cover": "",
          style: {"min-width":"150px"}
        }, null, 8, ["modelValue", "options"]),
        _createVNode(_component_q_btn, {
          "no-caps": "",
          class: "q-ml-md",
          icon: "autorenew",
          color: "red",
          label: "Сбросить",
          size: "13px",
          onClick: _ctx.resetColumns,
          outline: ""
        }, null, 8, ["onClick"])
      ]),
      header: _withCtx((props) => [
        _createVNode(_component_q_tr, null, {
          default: _withCtx(() => [
            (_ctx.getDateSlot)
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: "groupByField",
                  props: props,
                  rowspan: "2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getGroupedField('name')), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.getTrafficSlot)
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 1,
                  colspan: _ctx.getTrafficSlot
                }, {
                  default: _withCtx(() => _cache[48] || (_cache[48] = [
                    _createTextVNode("Трафик")
                  ])),
                  _: 1
                }, 8, ["colspan"]))
              : _createCommentVNode("", true),
            (_ctx.getRegsSlot)
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 2,
                  colspan: _ctx.getRegsSlot
                }, {
                  default: _withCtx(() => _cache[49] || (_cache[49] = [
                    _createTextVNode(" Регистрации ")
                  ])),
                  _: 1
                }, 8, ["colspan"]))
              : _createCommentVNode("", true),
            (_ctx.getDepositsSlot)
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 3,
                  colspan: _ctx.getDepositsSlot
                }, {
                  default: _withCtx(() => _cache[50] || (_cache[50] = [
                    _createTextVNode("Первый депозит")
                  ])),
                  _: 1
                }, 8, ["colspan"]))
              : _createCommentVNode("", true),
            (_ctx.getDDepositsSlot)
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 4,
                  colspan: _ctx.getDDepositsSlot
                }, {
                  default: _withCtx(() => _cache[51] || (_cache[51] = [
                    _createTextVNode("Повторные депозиты")
                  ])),
                  _: 1
                }, 8, ["colspan"]))
              : _createCommentVNode("", true),
            (_ctx.getConversionSlot)
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 5,
                  colspan: _ctx.getConversionSlot
                }, {
                  default: _withCtx(() => _cache[52] || (_cache[52] = [
                    _createTextVNode("Конверсии")
                  ])),
                  _: 1
                }, 8, ["colspan"]))
              : _createCommentVNode("", true),
            (_ctx.getCrSlot)
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 6,
                  colspan: _ctx.getCrSlot
                }, {
                  default: _withCtx(() => _cache[53] || (_cache[53] = [
                    _createTextVNode("Показатели")
                  ])),
                  _: 1
                }, 8, ["colspan"]))
              : _createCommentVNode("", true),
            (_ctx.getFinancesSlot)
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 7,
                  colspan: _ctx.getFinancesSlot
                }, {
                  default: _withCtx(() => _cache[54] || (_cache[54] = [
                    _createTextVNode("Финансы")
                  ])),
                  _: 1
                }, 8, ["colspan"]))
              : _createCommentVNode("", true),
            (_ctx.getRevShareSlot)
              ? (_openBlock(), _createBlock(_component_q_th, {
                  key: 8,
                  colspan: "1"
                }, {
                  default: _withCtx(() => _cache[55] || (_cache[55] = [
                    _createTextVNode("RevShare")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_q_tr, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
              'left-border': true,
            }),
              key: "clicks",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: _ctx.isDisabled('clicks') || _ctx.isDisabled2('clicks'),
                  modelValue: _ctx.clicks,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.clicks) = $event)),
                  val: "xs",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeSelected('clicks')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('clicks') || _ctx.isDisabled2('clicks')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[56] || (_cache[56] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('clicks') || _ctx.isDisabled2('clicks')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[57] || (_cache[57] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[58] || (_cache[58] = _createTextVNode("Hit"))
              ]),
              _: 2
            }, 1032, ["props"]),
            (_ctx.filters.groupBy !== 'date' && _ctx.filters.groupBy !== 'week')
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
              'left-border': true,
            }),
                  key: "clicksDelta",
                  props: props
                }, {
                  default: _withCtx(() => _cache[59] || (_cache[59] = [
                    _createTextVNode("Δ Hit")
                  ])),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "uniqueClicks",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: 
                _ctx.isDisabled('uniqueClicks') || _ctx.isDisabled2('uniqueClicks')
              ,
                  modelValue: _ctx.uniqueClicks,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.uniqueClicks) = $event)),
                  val: "xs",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeSelected('uniqueClicks')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('uniqueClicks') || _ctx.isDisabled2('uniqueClicks')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[60] || (_cache[60] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('uniqueClicks') || _ctx.isDisabled2('uniqueClicks')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[61] || (_cache[61] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[62] || (_cache[62] = _createTextVNode("Host"))
              ]),
              _: 2
            }, 1032, ["props"]),
            (_ctx.filters.groupBy !== 'date' && _ctx.filters.groupBy !== 'week')
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
            }),
                  key: "uniqueClicksDelta",
                  props: props
                }, {
                  default: _withCtx(() => _cache[63] || (_cache[63] = [
                    _createTextVNode("Δ Host")
                  ])),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "registrationCount",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: 
                _ctx.isDisabled('registrationCount') ||
                  _ctx.isDisabled2('registrationCount')
              ,
                  modelValue: _ctx.registrationCount,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.registrationCount) = $event)),
                  val: "xs",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeSelected('registrationCount')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('registrationCount') ||
                  _ctx.isDisabled2('registrationCount')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[64] || (_cache[64] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('registrationCount') ||
                  _ctx.isDisabled2('registrationCount')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[65] || (_cache[65] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[66] || (_cache[66] = _createTextVNode("Количество"))
              ]),
              _: 2
            }, 1032, ["props"]),
            (_ctx.filters.groupBy !== 'date' && _ctx.filters.groupBy !== 'week')
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
            }),
                  key: "registrationCountDelta",
                  props: props
                }, {
                  default: _withCtx(() => _cache[67] || (_cache[67] = [
                    _createTextVNode("Δ Количество")
                  ])),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "firstDepositCount",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: 
                _ctx.isDisabled('firstDepositCount') ||
                  _ctx.isDisabled2('firstDepositCount')
              ,
                  modelValue: _ctx.firstDepositCount,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.firstDepositCount) = $event)),
                  val: "xs",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.changeSelected('firstDepositCount')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('firstDepositCount') ||
                  _ctx.isDisabled2('firstDepositCount')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[68] || (_cache[68] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('firstDepositCount') ||
                  _ctx.isDisabled2('firstDepositCount')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[69] || (_cache[69] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[70] || (_cache[70] = _createTextVNode("Количество"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "firstDepositSum",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: 
                _ctx.isDisabled('firstDepositSum') || _ctx.isDisabled2('firstDepositSum')
              ,
                  modelValue: _ctx.firstDepositSum,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.firstDepositSum) = $event)),
                  val: "xs",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.changeSelected('firstDepositSum')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('firstDepositSum') ||
                  _ctx.isDisabled2('firstDepositSum')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[71] || (_cache[71] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('firstDepositSum') ||
                  _ctx.isDisabled2('firstDepositSum')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[72] || (_cache[72] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[73] || (_cache[73] = _createTextVNode("Сумма, $"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "depositCount",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: 
                _ctx.isDisabled('depositCount') || _ctx.isDisabled2('depositCount')
              ,
                  modelValue: _ctx.depositCount,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.depositCount) = $event)),
                  val: "xs",
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.changeSelected('depositCount')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('depositCount') || _ctx.isDisabled2('depositCount')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[74] || (_cache[74] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('depositCount') || _ctx.isDisabled2('depositCount')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[75] || (_cache[75] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[76] || (_cache[76] = _createTextVNode("Количество"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "depositSum",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: _ctx.isDisabled('depositSum') || _ctx.isDisabled2('depositSum'),
                  modelValue: _ctx.depositSum,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.depositSum) = $event)),
                  val: "xs",
                  onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.changeSelected('depositSum')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('depositSum') || _ctx.isDisabled2('depositSum')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[77] || (_cache[77] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('depositSum') || _ctx.isDisabled2('depositSum')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[78] || (_cache[78] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[79] || (_cache[79] = _createTextVNode("Сумма, $"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "uniqueDepositCount",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: 
                _ctx.isDisabled('uniqueDepositCount') ||
                  _ctx.isDisabled2('uniqueDepositCount')
              ,
                  modelValue: _ctx.uniqueDepositCount,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.uniqueDepositCount) = $event)),
                  val: "xs",
                  onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.changeSelected('uniqueDepositCount')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('uniqueDepositCount') ||
                  _ctx.isDisabled2('uniqueDepositCount')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[80] || (_cache[80] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('uniqueDepositCount') ||
                  _ctx.isDisabled2('uniqueDepositCount')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[81] || (_cache[81] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[82] || (_cache[82] = _createTextVNode("Уникальный, шт."))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "approvedLeads",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: 
                _ctx.isDisabled('approvedLeads') || _ctx.isDisabled2('approvedLeads')
              ,
                  modelValue: _ctx.approvedLeads,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.approvedLeads) = $event)),
                  val: "xs",
                  onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.changeSelected('approvedLeads')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('approvedLeads') || _ctx.isDisabled2('approvedLeads')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[83] || (_cache[83] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('approvedLeads') || _ctx.isDisabled2('approvedLeads')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[84] || (_cache[84] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[85] || (_cache[85] = _createTextVNode("Принято"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "holdLeads",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: _ctx.isDisabled('holdLeads') || _ctx.isDisabled2('holdLeads'),
                  modelValue: _ctx.holdLeads,
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.holdLeads) = $event)),
                  val: "xs",
                  onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.changeSelected('holdLeads')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('holdLeads') || _ctx.isDisabled2('holdLeads')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[86] || (_cache[86] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('holdLeads') || _ctx.isDisabled2('holdLeads')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[87] || (_cache[87] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[88] || (_cache[88] = _createTextVNode("Холд"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "rejectedLeads",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: 
                _ctx.isDisabled('rejectedLeads') || _ctx.isDisabled2('rejectedLeads')
              ,
                  modelValue: _ctx.rejectedLeads,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.rejectedLeads) = $event)),
                  val: "xs",
                  onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.changeSelected('rejectedLeads')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('rejectedLeads') || _ctx.isDisabled2('rejectedLeads')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[89] || (_cache[89] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('rejectedLeads') || _ctx.isDisabled2('rejectedLeads')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[90] || (_cache[90] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[91] || (_cache[91] = _createTextVNode("Отменено"))
              ]),
              _: 2
            }, 1032, ["props"]),
            (_ctx.filters.groupBy !== 'date' && _ctx.filters.groupBy !== 'week')
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
            }),
                  key: "approvedLeadsDelta",
                  props: props
                }, {
                  default: _withCtx(() => _cache[92] || (_cache[92] = [
                    _createTextVNode("Δ Принято")
                  ])),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.filters.groupBy !== 'date' && _ctx.filters.groupBy !== 'week')
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
            }),
                  key: "holdLeadsDelta",
                  props: props
                }, {
                  default: _withCtx(() => _cache[93] || (_cache[93] = [
                    _createTextVNode("Δ Холд")
                  ])),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            (_ctx.filters.groupBy !== 'date' && _ctx.filters.groupBy !== 'week')
              ? (_openBlock(), _createBlock(_component_q_th, {
                  class: _normalizeClass({
              'pointer-cursor': true,
            }),
                  key: "rejectedLeadsDelta",
                  props: props
                }, {
                  default: _withCtx(() => _cache[94] || (_cache[94] = [
                    _createTextVNode("Δ Отменено")
                  ])),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "epc",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: _ctx.isDisabled('epc') || _ctx.isDisabled2('epc'),
                  modelValue: _ctx.epc,
                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.epc) = $event)),
                  val: "xs",
                  onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.changeSelected('epc')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('epc') || _ctx.isDisabled2('epc')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[95] || (_cache[95] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('epc') || _ctx.isDisabled2('epc')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[96] || (_cache[96] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[97] || (_cache[97] = _createTextVNode("EPC"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "crUniqueReg",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: _ctx.isDisabled('crUniqueReg') || _ctx.isDisabled2('crUniqueReg'),
                  modelValue: _ctx.crUniqueReg,
                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.crUniqueReg) = $event)),
                  val: "xs",
                  onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.changeSelected('crUniqueReg')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('crUniqueReg') || _ctx.isDisabled2('crUniqueReg')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[98] || (_cache[98] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('crUniqueReg') || _ctx.isDisabled2('crUniqueReg')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[99] || (_cache[99] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[100] || (_cache[100] = _createTextVNode("Host/Reg"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "cr",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: _ctx.isDisabled('cr') || _ctx.isDisabled2('cr'),
                  modelValue: _ctx.cr,
                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.cr) = $event)),
                  val: "xs",
                  onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.changeSelected('cr')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('cr') || _ctx.isDisabled2('cr')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[101] || (_cache[101] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('cr') || _ctx.isDisabled2('cr')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[102] || (_cache[102] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[103] || (_cache[103] = _createTextVNode("Host/Dep"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "crFtdReg",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: _ctx.isDisabled('crFtdReg') || _ctx.isDisabled2('crFtdReg'),
                  modelValue: _ctx.crFtdReg,
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.crFtdReg) = $event)),
                  val: "xs",
                  onClick: _cache[30] || (_cache[30] = ($event: any) => (_ctx.changeSelected('crFtdReg')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('crFtdReg') || _ctx.isDisabled2('crFtdReg')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[104] || (_cache[104] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('crFtdReg') || _ctx.isDisabled2('crFtdReg')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[105] || (_cache[105] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[106] || (_cache[106] = _createTextVNode("Reg/Dep"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "approvedPercent",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: 
                _ctx.isDisabled('approvedPercent') || _ctx.isDisabled2('approvedPercent')
              ,
                  modelValue: _ctx.approvedPercent,
                  "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.approvedPercent) = $event)),
                  val: "xs",
                  onClick: _cache[32] || (_cache[32] = ($event: any) => (_ctx.changeSelected('approvedPercent')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('approvedPercent') ||
                  _ctx.isDisabled2('approvedPercent')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[107] || (_cache[107] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('approvedPercent') ||
                  _ctx.isDisabled2('approvedPercent')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[108] || (_cache[108] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[109] || (_cache[109] = _createTextVNode("Апрув, %"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "approvedSum",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: _ctx.isDisabled('approvedSum') || _ctx.isDisabled2('approvedSum'),
                  modelValue: _ctx.approvedSum,
                  "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.approvedSum) = $event)),
                  val: "xs",
                  onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.changeSelected('approvedSum')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('approvedSum') || _ctx.isDisabled2('approvedSum')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[110] || (_cache[110] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('approvedSum') || _ctx.isDisabled2('approvedSum')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[111] || (_cache[111] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[112] || (_cache[112] = _createTextVNode("Принято"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "holdSum",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: _ctx.isDisabled('holdSum') || _ctx.isDisabled2('holdSum'),
                  modelValue: _ctx.holdSum,
                  "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.holdSum) = $event)),
                  val: "xs",
                  onClick: _cache[36] || (_cache[36] = ($event: any) => (_ctx.changeSelected('holdSum')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('holdSum') || _ctx.isDisabled2('holdSum')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[113] || (_cache[113] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('holdSum') || _ctx.isDisabled2('holdSum')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[114] || (_cache[114] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[115] || (_cache[115] = _createTextVNode("Холд"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "rejectedSum",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: _ctx.isDisabled('rejectedSum') || _ctx.isDisabled2('rejectedSum'),
                  modelValue: _ctx.rejectedSum,
                  "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.rejectedSum) = $event)),
                  val: "xs",
                  onClick: _cache[38] || (_cache[38] = ($event: any) => (_ctx.changeSelected('rejectedSum')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('rejectedSum') || _ctx.isDisabled2('rejectedSum')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[116] || (_cache[116] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('rejectedSum') || _ctx.isDisabled2('rejectedSum')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[117] || (_cache[117] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[118] || (_cache[118] = _createTextVNode("Отменено"))
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_th, {
              class: _normalizeClass({
              'pointer-cursor': true,
            }),
              key: "revShareRevenueSum",
              props: props
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  size: "xs",
                  disable: 
                _ctx.isDisabled('revShareRevenueSum') ||
                  _ctx.isDisabled2('revShareRevenueSum')
              ,
                  modelValue: _ctx.revShareRevenueSum,
                  "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.revShareRevenueSum) = $event)),
                  val: "xs",
                  onClick: _cache[40] || (_cache[40] = ($event: any) => (_ctx.changeSelected('revShareRevenueSum')))
                }, null, 8, ["disable", "modelValue"]),
                (
                (_ctx.isDisabled('rejectedSum') || _ctx.isDisabled2('rejectedSum')) &&
                  _ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[119] || (_cache[119] = [
                        _createTextVNode("Нельзя выбрать больше 5 колонок"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" Нельзя совмещать линейные графики с многосегментными (Конверсии, Финансы)")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
                (_ctx.isDisabled('rejectedSum') || _ctx.isDisabled2('rejectedSum')) &&
                  !_ctx.isDateGroupingSelected
              )
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[120] || (_cache[120] = [
                        _createTextVNode("Нельзя выбрать больше 1 колонки для мультисегментного графика ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _cache[121] || (_cache[121] = _createTextVNode("Доход"))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 2
        }, 1024)
      ]),
      body: _withCtx((props) => [
        _createVNode(_component_q_tr, {
          props: props,
          style: _normalizeStyle(
            this.expandedIds.includes(props.row.id) ? 'background: #def3b4' : ''
          )
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_td, {
              props: props,
              key: "groupByField"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, [
                  (_ctx.filters.groupBy === 'geoId')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                        _createElementVNode("img", {
                          style: {"user-select":"none","display":"initial"},
                          class: "q-mr-xs",
                          height: "10",
                          width: "15",
                          alt: props.row.geoCode,
                          src: 
                    `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.geoCode}.svg`
                  
                        }, null, 8, _hoisted_3),
                        _createTextVNode(" " + _toDisplayString(props.row.geoCode || '??') + " ", 1),
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getGroupedField('field', props.row) || 'Неизвестно'), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.filters.groupBy === 'date')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.formatDateLikeChart(_ctx.getGroupedField('field', props.row))), 1))
                    : _createCommentVNode("", true),
                  (
                  _ctx.filters.groupBy !== 'week' &&
                    _ctx.filters.groupBy !== 'date' &&
                    _ctx.filters.groupBy !== 'geoId' &&
                    _ctx.filters.groupBy !== 'affiliateId' &&
                    _ctx.filters.groupBy !== 'offerId'
                )
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.getGroupedField('field', props.row) || 'Неизвестно'), 1))
                    : _createCommentVNode("", true),
                  (_ctx.filters.groupBy === 'week')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.getISOWeekName(_ctx.getGroupedField('field', props.row))), 1))
                    : _createCommentVNode("", true),
                  (_ctx.filters.groupBy === 'offerId')
                    ? (_openBlock(), _createBlock(_component_IconWithName, {
                        key: 4,
                        id: props.row.offerId,
                        name: _ctx.getGroupedField('field', props.row) || 'Неизвестно',
                        size: "18px",
                        color: "orange-7",
                        icon: "description",
                        entity: "offer"
                      }, null, 8, ["id", "name"]))
                    : _createCommentVNode("", true),
                  (_ctx.filters.groupBy === 'affiliateId')
                    ? (_openBlock(), _createBlock(_component_IconWithName, {
                        key: 5,
                        id: props.row.affiliateId,
                        name: _ctx.getGroupedField('field', props.row) || '',
                        size: "20px",
                        color: "secondary",
                        icon: "account_box",
                        entity: "affiliate"
                      }, null, 8, ["id", "name"]))
                    : _createCommentVNode("", true),
                  (_ctx.filters.groupBy2)
                    ? (_openBlock(), _createBlock(_component_q_btn, {
                        key: 6,
                        class: "q-ml-sm",
                        size: "sm",
                        dense: "",
                        flat: "",
                        onClick: ($event: any) => (_ctx.onExpandRow(props.row)),
                        icon: 
                  this.expandedIds.includes(props.row.id)
                    ? 'arrow_drop_up'
                    : 'arrow_drop_down'
                
                      }, null, 8, ["onClick", "icon"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "clicks",
              props: props,
              onClick: _withModifiers(($event: any) => (
              _ctx.detailClicks({ [_ctx.filters.groupBy]: props.row[_ctx.filters.groupBy] })
            ), ["stop"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, _toDisplayString(props.row.clicks || 0), 1)
              ]),
              _: 2
            }, 1032, ["props", "onClick"]),
            _createVNode(_component_q_td, {
              key: "uniqueClicks",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(props.row.uniqueClicks || 0), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "registrationCount",
              props: props,
              onClick: _withModifiers(($event: any) => (
              _ctx.detailConversions({
                [_ctx.filters.groupBy]: props.row[_ctx.filters.groupBy],
                eventType: _ctx.eventTypes.Registration,
              })
            ), ["stop"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, _toDisplayString(props.row.registrationCount || 0), 1)
              ]),
              _: 2
            }, 1032, ["props", "onClick"]),
            _createVNode(_component_q_td, {
              key: "firstDepositCount",
              props: props,
              onClick: _withModifiers(($event: any) => (
              _ctx.detailConversions({
                [_ctx.filters.groupBy]: props.row[_ctx.filters.groupBy],
                rewardTypes: [
                  _ctx.rewardType.FTD,
                  _ctx.rewardType.CPA,
                  _ctx.rewardType.BL,
                  _ctx.rewardType.RevShare,
                ],
                eventType: _ctx.eventTypes.FirstDeposit,
              })
            ), ["stop"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, _toDisplayString(props.row.firstDepositCount), 1)
              ]),
              _: 2
            }, 1032, ["props", "onClick"]),
            _createVNode(_component_q_td, {
              key: "firstDepositSum",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.firstDepositSum)), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              props: props,
              key: "depositCount"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, _toDisplayString(props.row.depositCount), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "depositSum",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.depositSum)), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "uniqueDepositCount",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(props.row.uniqueDepositCount), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "approvedLeads",
              props: props,
              onClick: _withModifiers(($event: any) => (
              _ctx.detailConversions({
                status: _ctx.leadStatus.Approved,
                [_ctx.filters.groupBy]: props.row[_ctx.filters.groupBy],
              })
            ), ["stop"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_11, _toDisplayString(props.row.approvedLeads), 1)
              ]),
              _: 2
            }, 1032, ["props", "onClick"]),
            _createVNode(_component_q_td, {
              key: "holdLeads",
              props: props,
              onClick: _withModifiers(($event: any) => (
              _ctx.detailConversions({
                status: _ctx.leadStatus.Hold,
                [_ctx.filters.groupBy]: props.row[_ctx.filters.groupBy],
              })
            ), ["stop"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_12, _toDisplayString(props.row.holdLeads), 1)
              ]),
              _: 2
            }, 1032, ["props", "onClick"]),
            _createVNode(_component_q_td, {
              key: "rejectedLeads",
              props: props,
              onClick: _withModifiers(($event: any) => (
              _ctx.detailConversions({
                status: _ctx.leadStatus.Rejected,
                [_ctx.filters.groupBy]: props.row[_ctx.filters.groupBy],
              })
            ), ["stop"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_13, _toDisplayString(props.row.rejectedLeads), 1)
              ]),
              _: 2
            }, 1032, ["props", "onClick"]),
            _createVNode(_component_q_td, {
              key: "epc",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.epc)), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "crUniqueReg",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.crUniqueReg)), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "cr",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.cr)), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "crFtdReg",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.crFtdReg)), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "approvedPercent",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.approvedPercent)), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "approvedSum",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.approvedSum)), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "holdSum",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.holdSum)), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "rejectedSum",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.rejectedSum)), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "revShareRevenueSum",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.revShareRevenueSum)), 1)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 2
        }, 1032, ["props", "style"]),
        (this.expandedIds.includes(props.row.id))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.children, (child, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  _createVNode(_component_q_tr, {
                    style: {"background":"aliceblue"},
                    props: props
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_td, {
                        props: props,
                        key: "groupByField"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_14, [
                            (_ctx.filters.groupBy2 === 'geoId')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                                  _createElementVNode("img", {
                                    style: {"user-select":"none","display":"initial"},
                                    class: "q-mr-xs",
                                    height: "10",
                                    width: "15",
                                    alt: props.row.children?.[index].geoCode,
                                    src: 
                        `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.children?.[index].geoCode}.svg`
                      
                                  }, null, 8, _hoisted_16),
                                  _createTextVNode(" " + _toDisplayString(props.row.children?.[index].geoCode || '??') + " ", 1),
                                  _createVNode(_component_q_tooltip, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getGroupedField2('field', props.row.children?.[index]) ||
                        'Неизвестно'), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.filters.groupBy2 === 'date')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.formatDateLikeChart(
                        _ctx.getGroupedField2('field', props.row.children?.[index]),
                      )), 1))
                              : _createCommentVNode("", true),
                            (
                      _ctx.filters.groupBy2 !== 'week' &&
                        _ctx.filters.groupBy2 !== 'date' &&
                        _ctx.filters.groupBy2 !== 'geoId' &&
                        _ctx.filters.groupBy2 !== 'affiliateId' &&
                        _ctx.filters.groupBy2 !== 'offerId'
                    )
                              ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.getGroupedField2('field', props.row.children?.[index]) ||
                        'Неизвестно'), 1))
                              : _createCommentVNode("", true),
                            (_ctx.filters.groupBy2 === 'week')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.getISOWeekName(
                        _ctx.getGroupedField2('field', props.row.children?.[index]),
                      )), 1))
                              : _createCommentVNode("", true),
                            (_ctx.filters.groupBy2 === 'offerId')
                              ? (_openBlock(), _createBlock(_component_IconWithName, {
                                  key: 4,
                                  id: props.row.children?.[index].offerId,
                                  name: 
                      _ctx.getGroupedField2('field', props.row.children?.[index]) ||
                        'Неизвестно'
                    ,
                                  size: "18px",
                                  color: "orange-7",
                                  icon: "description"
                                }, null, 8, ["id", "name"]))
                              : _createCommentVNode("", true),
                            (_ctx.filters.groupBy2 === 'affiliateId')
                              ? (_openBlock(), _createBlock(_component_IconWithName, {
                                  key: 5,
                                  id: props.row.children?.[index].affiliateId,
                                  name: 
                      _ctx.getGroupedField2('field', props.row.children?.[index]) ||
                        ''
                    ,
                                  size: "20px",
                                  color: "secondary",
                                  icon: "account_box",
                                  entity: "affiliate"
                                }, null, 8, ["id", "name"]))
                              : _createCommentVNode("", true),
                            (_ctx.filters.groupBy3)
                              ? (_openBlock(), _createBlock(_component_q_btn, {
                                  key: 6,
                                  class: "q-ml-sm",
                                  size: "sm",
                                  dense: "",
                                  flat: "",
                                  onClick: ($event: any) => (_ctx.onExpandRow(props.row.children?.[index])),
                                  icon: 
                      this.expandedIds.includes(props.row.children?.[index].id)
                        ? 'arrow_drop_up'
                        : 'arrow_drop_down'
                    
                                }, null, 8, ["onClick", "icon"]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "clicks",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].clicks || 0), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "uniqueClicks",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].uniqueClicks || 0), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "registrationCount",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].registrationCount || 0), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "firstDepositCount",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].firstDepositCount), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "firstDepositSum",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                      props.row.children?.[index].firstDepositSum,
                    )), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        props: props,
                        key: "depositCount"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].depositCount), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "depositSum",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.children?.[index].depositSum)), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "uniqueDepositCount",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].uniqueDepositCount), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "approvedLeads",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].approvedLeads), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "holdLeads",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].holdLeads), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "rejectedLeads",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].rejectedLeads), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "epc",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.children?.[index].epc)), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "crUniqueReg",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.children?.[index].crUniqueReg)), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "cr",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.children?.[index].cr)), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "crFtdReg",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.children?.[index].crFtdReg)), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "approvedPercent",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                      props.row.children?.[index].approvedPercent,
                    )), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "approvedSum",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.children?.[index].approvedSum)), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "holdSum",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.children?.[index].holdSum)), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "rejectedSum",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(props.row.children?.[index].rejectedSum)), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "revShareRevenueSum",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                      props.row.children?.[index].revShareRevenueSum,
                    )), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"])
                    ]),
                    _: 2
                  }, 1032, ["props"]),
                  (this.expandedIds.includes(child.id))
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(child.children, (child2, index2) => {
                        return (_openBlock(), _createBlock(_component_q_tr, {
                          style: {"background":"#ffffde"},
                          props: props,
                          key: index2
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_td, {
                              props: props,
                              key: "groupByField",
                              class: "text-right"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_20, [
                                  (_ctx.filters.groupBy3 === 'geoId')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_21, [
                                        _createElementVNode("img", {
                                          style: {"user-select":"none","display":"initial"},
                                          class: "q-mr-xs",
                                          height: "10",
                                          width: "15",
                                          alt: 
                          props.row.children?.[index].children?.[index2].geoCode
                        ,
                                          src: 
                          `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.children?.[index].children?.[index2].geoCode}.svg`
                        
                                        }, null, 8, _hoisted_22),
                                        _createTextVNode(" " + _toDisplayString(props.row.children?.[index].children?.[index2]
                          .geoCode || '??') + " ", 1),
                                        _createVNode(_component_q_tooltip, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.getGroupedField3(
                          'field',
                          props.row.children?.[index].children?.[index2],
                        ) || 'Неизвестно'), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]))
                                    : _createCommentVNode("", true),
                                  (_ctx.filters.groupBy3 === 'date')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.formatDateLikeChart(
                          _ctx.getGroupedField3(
                            'field',
                            props.row.children?.[index].children?.[index2],
                          ),
                        )), 1))
                                    : _createCommentVNode("", true),
                                  (
                        _ctx.filters.groupBy3 !== 'week' &&
                          _ctx.filters.groupBy3 !== 'date' &&
                          _ctx.filters.groupBy3 !== 'geoId' &&
                          _ctx.filters.groupBy3 !== 'affiliateId' &&
                          _ctx.filters.groupBy3 !== 'offerId'
                      )
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.getGroupedField3(
                          'field',
                          props.row.children?.[index].children?.[index2],
                        ) || 'Неизвестно'), 1))
                                    : _createCommentVNode("", true),
                                  (_ctx.filters.groupBy3 === 'week')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_ctx.getISOWeekName(
                          _ctx.getGroupedField3(
                            'field',
                            props.row.children?.[index].children?.[index2],
                          ),
                        )), 1))
                                    : _createCommentVNode("", true),
                                  (_ctx.filters.groupBy3 === 'offerId')
                                    ? (_openBlock(), _createBlock(_component_IconWithName, {
                                        key: 4,
                                        id: 
                        props.row.children?.[index].children?.[index2].offerId
                      ,
                                        name: 
                        _ctx.getGroupedField3(
                          'field',
                          props.row.children?.[index].children?.[index2],
                        ) || 'Неизвестно'
                      ,
                                        size: "18px",
                                        color: "orange-7",
                                        icon: "description",
                                        entity: "offer"
                                      }, null, 8, ["id", "name"]))
                                    : _createCommentVNode("", true),
                                  (_ctx.filters.groupBy3 === 'affiliateId')
                                    ? (_openBlock(), _createBlock(_component_IconWithName, {
                                        key: 5,
                                        id: 
                        props.row.children?.[index].children?.[index2]
                          .affiliateId
                      ,
                                        name: 
                        _ctx.getGroupedField3(
                          'field',
                          props.row.children?.[index].children?.[index2],
                        ) || ''
                      ,
                                        size: "20px",
                                        color: "secondary",
                                        icon: "account_box",
                                        entity: "affiliate"
                                      }, null, 8, ["id", "name"]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "clicks",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2].clicks || 0), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "uniqueClicks",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2]
                        .uniqueClicks || 0), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "registrationCount",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2]
                        .registrationCount || 0), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "firstDepositCount",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2]
                        .firstDepositCount), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "firstDepositSum",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                        props.row.children?.[index].children?.[index2]
                          .firstDepositSum,
                      )), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              props: props,
                              key: "depositCount"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2]
                        .depositCount), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "depositSum",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                        props.row.children?.[index].children?.[index2]
                          .depositSum,
                      )), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "uniqueDepositCount",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2]
                        .uniqueDepositCount), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "approvedLeads",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2]
                        .approvedLeads), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "holdLeads",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2].holdLeads), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "rejectedLeads",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(props.row.children?.[index].children?.[index2]
                        .rejectedLeads), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "epc",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                        props.row.children?.[index].children?.[index2].epc,
                      )), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "crUniqueReg",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                        props.row.children?.[index].children?.[index2]
                          .crUniqueReg,
                      )), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "cr",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                        props.row.children?.[index].children?.[index2].cr,
                      )), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "crFtdReg",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                        props.row.children?.[index].children?.[index2].crFtdReg,
                      )), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "approvedPercent",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                        props.row.children?.[index].children?.[index2]
                          .approvedPercent,
                      )), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "approvedSum",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                        props.row.children?.[index].children?.[index2]
                          .approvedSum,
                      )), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "holdSum",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                        props.row.children?.[index].children?.[index2].holdSum,
                      )), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "rejectedSum",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                        props.row.children?.[index].children?.[index2]
                          .rejectedSum,
                      )), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "revShareRevenueSum",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.formatColumnValue(
                        props.row.children?.[index].children?.[index2]
                          .revShareRevenueSum,
                      )), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"])
                          ]),
                          _: 2
                        }, 1032, ["props"]))
                      }), 128))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128)),
              _createVNode(_component_q_tr, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_td, { colspan: "100%" }, {
                    default: _withCtx(() => _cache[122] || (_cache[122] = [
                      _createElementVNode("hr", null, null, -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["props"])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.rows?.length)
        ? {
            name: "bottom-row",
            fn: _withCtx(() => [
              _createVNode(_component_q_tr, null, {
                default: _withCtx(() => [
                  (_ctx.getDateSlot)
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 0,
                        class: "text-left",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => _cache[123] || (_cache[123] = [
                          _createElementVNode("b", null, "Total:", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('clicks'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 1,
                        class: "text-right",
                        colspan: "1",
                        onClick: _withModifiers(_ctx.detailClicks, ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('clicks', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (
              _ctx.filters.groupBy !== 'date' &&
                _ctx.filters.groupBy !== 'week' &&
                _ctx.visibleColumns.includes('clicksDelta')
            )
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 2,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('clicksDelta', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('uniqueClicks'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 3,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(_ctx.total('uniqueClicks', 'sum')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (
              _ctx.filters.groupBy !== 'date' &&
                _ctx.filters.groupBy !== 'week' &&
                _ctx.visibleColumns.includes('uniqueClicksDelta')
            )
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 4,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('uniqueClicksDelta', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('registrationCount'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 5,
                        class: "text-right",
                        colspan: "1",
                        onClick: _cache[41] || (_cache[41] = _withModifiers(($event: any) => (
              _ctx.detailConversions({
                eventType: _ctx.eventTypes.Registration,
              })
            ), ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('registrationCount', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (
              _ctx.filters.groupBy !== 'date' &&
                _ctx.filters.groupBy !== 'week' &&
                _ctx.visibleColumns.includes('registrationCountDelta')
            )
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 6,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('registrationCountDelta', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('firstDepositCount'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 7,
                        class: "text-right",
                        colspan: "1",
                        onClick: _cache[42] || (_cache[42] = _withModifiers(($event: any) => (
              _ctx.detailConversions({
                rewardTypes: [
                  _ctx.rewardType.FTD,
                  _ctx.rewardType.CPA,
                  _ctx.rewardType.BL,
                  _ctx.rewardType.RevShare,
                ],
                eventType: _ctx.eventTypes.FirstDeposit,
              })
            ), ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('firstDepositCount', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (
              _ctx.filters.groupBy !== 'date' &&
                _ctx.filters.groupBy !== 'week' &&
                _ctx.visibleColumns.includes('firstDepositCountDelta')
            )
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 8,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('firstDepositCountDelta', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('firstDepositSum'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 9,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(_ctx.total('firstDepositSum', 'sum')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('depositCount'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 10,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_29, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('depositCount', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('depositSum'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 11,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(_ctx.total('depositSum', 'sum')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('uniqueDepositCount'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 12,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_30, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('uniqueDepositCount', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('approvedLeads'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 13,
                        class: "text-right",
                        colspan: "1",
                        onClick: _cache[43] || (_cache[43] = _withModifiers(($event: any) => (_ctx.detailConversions({ status: _ctx.leadStatus.Approved })), ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('approvedLeads', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('holdLeads'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 14,
                        class: "text-right",
                        colspan: "1",
                        onClick: _cache[44] || (_cache[44] = _withModifiers(($event: any) => (_ctx.detailConversions({ status: _ctx.leadStatus.Hold })), ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_32, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('holdLeads', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('rejectedLeads'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 15,
                        class: "text-right",
                        colspan: "1",
                        onClick: _cache[45] || (_cache[45] = _withModifiers(($event: any) => (_ctx.detailConversions({ status: _ctx.leadStatus.Rejected })), ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_33, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('rejectedLeads', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (
              _ctx.filters.groupBy !== 'date' &&
                _ctx.filters.groupBy !== 'week' &&
                _ctx.visibleColumns.includes('approvedLeadsDelta')
            )
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 16,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('approvedLeadsDelta', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (
              _ctx.filters.groupBy !== 'date' &&
                _ctx.filters.groupBy !== 'week' &&
                _ctx.visibleColumns.includes('holdLeadsDelta')
            )
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 17,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('holdLeadsDelta', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (
              _ctx.filters.groupBy !== 'date' &&
                _ctx.filters.groupBy !== 'week' &&
                _ctx.visibleColumns.includes('rejectedLeadsDelta')
            )
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 18,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.total('rejectedLeadsDelta', 'sum')), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('epc'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 19,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(_ctx.total('epc', 'avg')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('crUniqueReg'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 20,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(_ctx.totalCrUniqueReg), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('cr'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 21,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(_ctx.total('cr', 'avg')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('crFtdReg'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 22,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(_ctx.totalCrFtdReg), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('approvedPercent'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 23,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(_ctx.total('approvedPercent', 'avg')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('approvedSum'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 24,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(_ctx.total('approvedSum', 'sum')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('holdSum'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 25,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(_ctx.total('holdSum', 'sum')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.visibleColumns.includes('rejectedSum'))
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 26,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(_ctx.total('rejectedSum', 'sum')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.getRevShareSlot)
                    ? (_openBlock(), _createBlock(_component_q_td, {
                        key: 27,
                        class: "text-right",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(_ctx.total('revShareRevenueSum', 'sum')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["rows", "expanded", "columns", "visible-columns", "pagination", "loading", "onRequest"])
  ]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "column" }
const _hoisted_2 = { class: "row justify-between items-center" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_AddDomainDialog = _resolveComponent("AddDomainDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[3] || (_cache[3] = _createElementVNode("h5", { class: "text-h6 col-6 q-my-md" }, "Домен", -1)),
        _createElementVNode("div", null, [
          _createVNode(_component_q_btn, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAddDomainDialog = !_ctx.showAddDomainDialog)),
            round: "",
            size: "9px",
            class: "q-mr-md",
            color: "secondary",
            icon: "add"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_select, {
          dense: "",
          clearable: "",
          disable: !_ctx.campaign.affiliateId,
          "model-value": _ctx.campaign.redirectDomainId,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = redirectDomainId => _ctx.update({ redirectDomainId })),
          outlined: "",
          class: "col-12",
          options: _ctx.redirectDomains,
          onFilter: _ctx.getRedirectDomains,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          "use-input": "",
          error: !!_ctx.errors?.redirectDomainId,
          "error-message": _ctx.errors?.redirectDomainId
        }, null, 8, ["disable", "model-value", "options", "onFilter", "error", "error-message"])
      ])
    ]),
    _createVNode(_component_AddDomainDialog, {
      open: _ctx.showAddDomainDialog,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAddDomainDialog = false))
    }, null, 8, ["open"])
  ], 64))
}
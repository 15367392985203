import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "flex row items-center" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { class: "link" }
const _hoisted_8 = { class: "link" }
const _hoisted_9 = { class: "link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_PieChart = _resolveComponent("PieChart")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl" }, {
    default: _withCtx(() => [
      _createVNode(_component_center, null, {
        default: _withCtx(() => _cache[22] || (_cache[22] = [
          _createElementVNode("h6", null, "Статистика", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Filters, {
            onReset: _ctx.resetFilters,
            onChange: _ctx.onChangeFilters,
            onChangeAll: _ctx.changeAll,
            "current-filters": _ctx.filters
          }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters"])
        ])
      ]),
      (_ctx.filters.groupBy === 'date' || _ctx.filters.groupBy === 'week')
        ? (_openBlock(), _createBlock(_component_LineChart, {
            key: 0,
            rows: _ctx.chartData,
            groupBy: _ctx.filters.groupBy,
            columns: _ctx.pagination.selectedColumns,
            allColumns: _ctx.columns,
            groupByOptions: _ctx.getGroupByArray(),
            loading: _ctx.chartLoading
          }, null, 8, ["rows", "groupBy", "columns", "allColumns", "groupByOptions", "loading"]))
        : (_openBlock(), _createBlock(_component_PieChart, {
            key: 1,
            rows: _ctx.chartData,
            groupBy: _ctx.filters.groupBy,
            metric: _ctx.pagination.selectedColumns[0],
            loading: _ctx.chartLoading
          }, null, 8, ["rows", "groupBy", "metric", "loading"])),
      _createElementVNode("div", null, [
        _createVNode(_component_q_table, {
          dense: "",
          "column-sort-order": "da",
          rows: _ctx.rows,
          columns: _ctx.columns,
          "row-key": "id",
          separator: "cell",
          class: "multirow-table",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.pagination) = $event)),
          loading: _ctx.loading,
          onRequest: _ctx.onRequest,
          "binary-state-sort": ""
        }, _createSlots({
          header: _withCtx((props) => [
            _createVNode(_component_q_tr, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_th, {
                  key: "groupByField",
                  props: props,
                  rowspan: "2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getGroupedField('name')), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, { colspan: "1" }, {
                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                    _createTextVNode("Трафик")
                  ])),
                  _: 1
                }),
                _createVNode(_component_q_th, { colspan: "1" }, {
                  default: _withCtx(() => _cache[24] || (_cache[24] = [
                    _createTextVNode("Регистрации")
                  ])),
                  _: 1
                }),
                _createVNode(_component_q_th, { colspan: "3" }, {
                  default: _withCtx(() => _cache[25] || (_cache[25] = [
                    _createTextVNode("Первый депозит")
                  ])),
                  _: 1
                }),
                _createVNode(_component_q_th, { colspan: "4" }, {
                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                    _createTextVNode("Показатели")
                  ])),
                  _: 1
                })
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_tr, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "installs",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.installs,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.installs) = $event)),
                      val: "xs",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeSelected('installs')))
                    }, null, 8, ["modelValue"]),
                    _cache[27] || (_cache[27] = _createTextVNode("Инсталлы"))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "registrationCount",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.registrationCount,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.registrationCount) = $event)),
                      val: "xs",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeSelected('registrationCount')))
                    }, null, 8, ["modelValue"]),
                    _cache[28] || (_cache[28] = _createTextVNode("Количество, шт"))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "firstDepositCount",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.firstDepositCount,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.firstDepositCount) = $event)),
                      val: "xs",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeSelected('firstDepositCount')))
                    }, null, 8, ["modelValue"]),
                    _cache[29] || (_cache[29] = _createTextVNode("Количество, шт"))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "firstDepositSum",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.firstDepositSum,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.firstDepositSum) = $event)),
                      val: "xs",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.changeSelected('firstDepositSum')))
                    }, null, 8, ["modelValue"]),
                    _cache[30] || (_cache[30] = _createTextVNode("Выплата, $"))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "firstDepositSumEur",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.firstDepositSumEur,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.firstDepositSumEur) = $event)),
                      val: "xs",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.changeSelected('firstDepositSumEur')))
                    }, null, 8, ["modelValue"]),
                    _cache[31] || (_cache[31] = _createTextVNode("Выплата, €"))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "crReg",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.crReg,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.crReg) = $event)),
                      val: "xs",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.changeSelected('crReg')))
                    }, null, 8, ["modelValue"]),
                    _cache[32] || (_cache[32] = _createTextVNode("CR Reg, %"))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "crFtd",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.crFtd,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.crFtd) = $event)),
                      val: "xs",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.changeSelected('crFtd')))
                    }, null, 8, ["modelValue"]),
                    _cache[33] || (_cache[33] = _createTextVNode("CR FTD, %"))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "crFtdReg",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.crFtdReg,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.crFtdReg) = $event)),
                      val: "xs",
                      onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.changeSelected('crFtdReg')))
                    }, null, 8, ["modelValue"]),
                    _cache[34] || (_cache[34] = _createTextVNode("CR FTD / Reg, %"))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_th, {
                  class: _normalizeClass({
                'pointer-cursor': true,
                'left-border': true,
              }),
                  key: "installSpend",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      size: "xs",
                      modelValue: _ctx.installSpend,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.installSpend) = $event)),
                      val: "xs",
                      onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.changeSelected('installSpend')))
                    }, null, 8, ["modelValue"]),
                    _cache[35] || (_cache[35] = _createTextVNode("Расход за инсталлы, $"))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 2
            }, 1024)
          ]),
          "body-cell-groupByField": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                (_ctx.filters.groupBy !== 'week')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.getGroupedField('field', props.row) || 'Неопределено'), 1))
                  : _createCommentVNode("", true),
                (_ctx.filters.groupBy === 'week')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.getISOWeekName(_ctx.getGroupedField('field', props.row))), 1))
                  : _createCommentVNode("", true),
                (_ctx.filters.groupBy === 'mobileAppId')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, " [ID:" + _toDisplayString(props.row.mobileAppId) + "]", 1))
                  : _createCommentVNode("", true),
                (_ctx.filters.groupBy === 'affiliateId')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(props.row.affiliateId ? ` [ID:${props.row.affiliateId}]` : ''), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-installs": _withCtx((props) => [
            _createVNode(_component_q_td, {
              props: props,
              onClick: _withModifiers(($event: any) => (
              _ctx.detailConversions({
                [_ctx.filters.groupBy]: props.row[_ctx.filters.groupBy],
                tab: 'installs',
              })
            ), ["stop"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, { to: "" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.installs || 0), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["props", "onClick"])
          ]),
          "body-cell-registrationCount": _withCtx((props) => [
            _createVNode(_component_q_td, {
              props: props,
              onClick: _withModifiers(($event: any) => (
              _ctx.detailConversions({
                [_ctx.filters.groupBy]: props.row[_ctx.filters.groupBy],
                eventType: _ctx.eventType.Registration,
                tab: 'tds-logs',
              })
            ), ["stop"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, { to: "" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.registrationCount || 0), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["props", "onClick"])
          ]),
          "body-cell-firstDepositCount": _withCtx((props) => [
            _createVNode(_component_q_td, {
              props: props,
              onClick: _withModifiers(($event: any) => (
              _ctx.detailConversions({
                [_ctx.filters.groupBy]: props.row[_ctx.filters.groupBy],
                eventType: _ctx.eventType.FirstDeposit,
                tab: 'tds-logs',
              })
            ), ["stop"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, { to: "" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.firstDepositCount || 0), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["props", "onClick"])
          ]),
          "body-cell-firstDepositSum": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.firstDepositSum || 0)) + " ", 1),
                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyUsd()), 1)
                ])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-firstDepositSumEur": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.firstDepositSumEur || 0)) + " ", 1),
                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyEur()), 1)
                ])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-installSpend": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.installSpend || 0)) + " ", 1),
                  _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyUsd()), 1)
                ])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 2
        }, [
          (_ctx.rows?.length)
            ? {
                name: "bottom-row",
                fn: _withCtx(() => [
                  _createVNode(_component_q_tr, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_td, {
                        class: "text-left",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => _cache[36] || (_cache[36] = [
                          _createTextVNode("Всего:")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right monospaced",
                        colspan: "1",
                        onClick: _cache[18] || (_cache[18] = _withModifiers(($event: any) => (_ctx.detailConversions({ tab: 'installs' })), ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.total('installs', 'sum')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right monospaced",
                        colspan: "1",
                        onClick: _cache[19] || (_cache[19] = _withModifiers(($event: any) => (
                _ctx.detailConversions({
                  eventType: _ctx.eventType.Registration,
                  tab: 'tds-logs',
                })
              ), ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.total('registrationCount', 'sum')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right monospaced",
                        colspan: "1",
                        onClick: _cache[20] || (_cache[20] = _withModifiers(($event: any) => (
                _ctx.detailConversions({
                  eventType: _ctx.eventType.FirstDeposit,
                  tab: 'tds-logs',
                })
              ), ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.total('firstDepositCount', 'sum')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('firstDepositSum', 'sum'))) + " ", 1),
                          _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyUsd()), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('firstDepositSumEur', 'sum'))) + " ", 1),
                          _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyEur()), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.total('crReg', 'avg')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.total('crFtd', 'avg')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.total('crFtdReg', 'avg')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_td, {
                        class: "text-right monospaced",
                        colspan: "1"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.total('installSpend', 'sum'))) + " ", 1),
                          _createElementVNode("small", null, _toDisplayString(_ctx.getCurrencyUsd()), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["rows", "columns", "pagination", "loading", "onRequest"])
      ])
    ]),
    _: 1
  }))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "column" }
const _hoisted_2 = { class: "flex column" }
const _hoisted_3 = { class: "flex column" }
const _hoisted_4 = {
  key: 0,
  class: "flex column"
}
const _hoisted_5 = { class: "flex column" }
const _hoisted_6 = { class: "flex column" }
const _hoisted_7 = { class: "flex column" }
const _hoisted_8 = { class: "flex items-center" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[10] || (_cache[10] = _createElementVNode("b", null, "Название", -1)),
      _createVNode(_component_q_input, {
        dense: "",
        clearable: "",
        outlined: "",
        "model-value": _ctx.campaign.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = name => _ctx.update({ name })),
        error: !!_ctx.errors?.name,
        "error-message": _ctx.errors?.name
      }, null, 8, ["model-value", "error", "error-message"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[11] || (_cache[11] = _createElementVNode("b", null, "Аффилейт", -1)),
      _createVNode(_component_q_select, {
        dense: "",
        clearable: "",
        "model-value": _ctx.campaign.affiliateId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = affiliateId => _ctx.update({ affiliateId })),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('select'))),
        outlined: "",
        options: _ctx.affiliates,
        onFilter: _ctx.getAffiliates,
        "option-value": "id",
        "option-label": "email",
        "emit-value": "",
        "map-options": "",
        "use-input": "",
        error: !!_ctx.errors?.affiliateId,
        "error-message": _ctx.errors?.affiliateId
      }, {
        option: _withCtx((scope) => [
          _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
            default: _withCtx(() => [
              _createVNode(_component_q_item_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_IconWithName, {
                    id: scope.opt.id,
                    name: scope.opt.email,
                    size: "20px",
                    color: "secondary",
                    icon: "account_box",
                    entity: "affiliate"
                  }, null, 8, ["id", "name"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1040)), [
            [_directive_close_popup]
          ])
        ]),
        _: 1
      }, 8, ["model-value", "options", "onFilter", "error", "error-message"])
    ]),
    (!_ctx.user?.isLimitedAccess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("b", null, [
            _cache[13] || (_cache[13] = _createTextVNode("Фантомный аффилейт (подмена)")),
            _cache[14] || (_cache[14] = _createElementVNode("sup", null, "?", -1)),
            _createVNode(_component_q_tooltip, null, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("Опционально. Подменить affiliate_id. Если выставлен кастомный параметр affiliate_id на поток, то этот же affiliate_id передается реклу при клике")
              ])),
              _: 1
            })
          ]),
          _createVNode(_component_q_select, {
            dense: "",
            clearable: "",
            "model-value": _ctx.campaign.fantomAffiliateId,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = fantomAffiliateId => _ctx.update({ fantomAffiliateId })),
            onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('select'))),
            outlined: "",
            options: _ctx.affiliates,
            onFilter: _cache[5] || (_cache[5] = (val, fn) => _ctx.getAffiliates(val, fn, { fantom: true })),
            "option-value": "id",
            "option-label": "email",
            "emit-value": "",
            "map-options": "",
            "use-input": "",
            error: !!_ctx.errors?.fantomAffiliateId,
            "error-message": _ctx.errors?.fantomAffiliateId
          }, {
            option: _withCtx((scope) => [
              _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_IconWithName, {
                        id: scope.opt.id,
                        name: scope.opt.email,
                        size: "20px",
                        color: "secondary",
                        icon: "account_box",
                        entity: "affiliate"
                      }, null, 8, ["id", "name"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1040)), [
                [_directive_close_popup]
              ])
            ]),
            _: 1
          }, 8, ["model-value", "options", "error", "error-message"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _cache[15] || (_cache[15] = _createElementVNode("b", null, "Оффер", -1)),
      _createVNode(_component_q_select, {
        dense: "",
        clearable: "",
        disable: !_ctx.campaign.affiliateId,
        modelValue: _ctx.campaign.offerId,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.campaign.offerId) = $event)),
        onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('select'))),
        outlined: "",
        options: _ctx.offers,
        onFilter: _ctx.getOffers,
        "option-value": "id",
        "option-label": "name",
        "emit-value": "",
        "map-options": "",
        "use-input": "",
        error: !!_ctx.errors?.offerId,
        "error-message": _ctx.errors?.offerId
      }, {
        option: _withCtx((scope) => [
          _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
            default: _withCtx(() => [
              _createVNode(_component_q_item_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_IconWithName, {
                    id: scope.opt.id,
                    name: scope.opt.name,
                    size: "20px",
                    color: "orange-7",
                    icon: "description",
                    entity: "offer"
                  }, null, 8, ["id", "name"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1040)), [
            [_directive_close_popup]
          ])
        ]),
        _: 1
      }, 8, ["disable", "modelValue", "options", "onFilter", "error", "error-message"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[16] || (_cache[16] = _createElementVNode("b", null, "Источник трафика", -1)),
      _createVNode(_component_q_select, {
        dense: "",
        clearable: "",
        "model-value": _ctx.campaign.trafficSourceId,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = trafficSourceId => _ctx.update({ trafficSourceId })),
        outlined: "",
        disable: !_ctx.campaign.offerId,
        options: _ctx.trafficSources,
        onFilter: _ctx.getTrafficSources,
        "option-value": "id",
        "option-label": "name",
        "emit-value": "",
        "map-options": "",
        "use-input": "",
        error: !!_ctx.errors?.trafficSourceId,
        "error-message": _ctx.errors?.trafficSourceId
      }, null, 8, ["model-value", "disable", "options", "onFilter", "error", "error-message"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[17] || (_cache[17] = _createElementVNode("b", null, "Гео", -1)),
      _createVNode(_component_q_select, {
        dense: "",
        outlined: "",
        clearable: "",
        disable: !_ctx.campaign.offerId,
        "model-value": _ctx.campaign.geoId,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = geoId => _ctx.update({ geoId })),
        onFilter: _ctx.getGeos,
        options: _ctx.geos,
        "option-value": "id",
        "option-label": "name",
        "emit-value": "",
        "map-options": "",
        "use-input": "",
        "error-message": _ctx.errors?.geoId,
        error: !!_ctx.errors?.geoId
      }, {
        "selected-item": _withCtx((scope) => [
          _createElementVNode("span", _hoisted_8, [
            _createElementVNode("img", {
              style: {"user-select":"none","display":"initial"},
              class: "q-mr-sm",
              height: "10",
              width: "15",
              src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${scope.opt.isoCode}.svg`
              
            }, null, 8, _hoisted_9),
            _createTextVNode(" " + _toDisplayString(scope.opt.name), 1)
          ])
        ]),
        option: _withCtx((scope) => [
          _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
            default: _withCtx(() => [
              _createElementVNode("img", {
                style: {"user-select":"none","display":"initial"},
                class: "q-mr-sm",
                height: "10",
                width: "15",
                src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${scope.opt.isoCode}.svg`
              
              }, null, 8, _hoisted_10),
              _createVNode(_component_q_item_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, {
                    innerHTML: scope.opt.name
                  }, null, 8, ["innerHTML"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1040)), [
            [_directive_close_popup]
          ])
        ]),
        _: 1
      }, 8, ["disable", "model-value", "onFilter", "options", "error-message", "error"])
    ])
  ]))
}
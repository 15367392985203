import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "row items-center",
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "flex column items-start q-mr-sm" }
const _hoisted_3 = { class: "row items-center" }
const _hoisted_4 = { class: "row items-baseline no-wrap monospaced justify-end" }
const _hoisted_5 = { class: "row items-baseline no-wrap monospaced justify-end" }
const _hoisted_6 = { class: "row items-baseline no-wrap monospaced justify-end" }
const _hoisted_7 = { class: "row items-baseline no-wrap monospaced justify-end" }
const _hoisted_8 = { class: "row items-baseline no-wrap monospaced justify-end" }
const _hoisted_9 = { class: "row items-baseline no-wrap monospaced justify-end" }
const _hoisted_10 = { class: "row items-baseline no-wrap monospaced justify-end" }
const _hoisted_11 = { class: "row items-baseline no-wrap monospaced justify-end" }
const _hoisted_12 = { class: "row items-baseline no-wrap monospaced justify-end" }
const _hoisted_13 = { class: "row items-baseline no-wrap monospaced justify-end" }
const _hoisted_14 = { class: "row items-baseline no-wrap monospaced justify-end" }
const _hoisted_15 = { class: "row items-baseline no-wrap monospaced justify-end" }
const _hoisted_16 = {
  class: "flex row items-center",
  style: {"width":"max-content"}
}
const _hoisted_17 = ["alt", "src"]
const _hoisted_18 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_LeadLimitEditPopup = _resolveComponent("LeadLimitEditPopup")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_LeadLimitChangelogPopup = _resolveComponent("LeadLimitChangelogPopup")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_AffiliateCommentDialog = _resolveComponent("AffiliateCommentDialog")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Filters, {
      onReset: _ctx.resetFilters,
      onChange: _ctx.onChangeFilters,
      onChangeAll: _ctx.changeAll,
      "current-filters": _ctx.filters,
      onExport: _ctx.onExport,
      tab: "limits"
    }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters", "onExport"]),
    _createElementVNode("div", null, [
      _createVNode(_component_q_table, {
        dense: "",
        "rows-per-page-options": [10, 25, 50, 100, 200, 500, 0],
        rows: _ctx.rows,
        columns: _ctx.columns,
        "row-key": "id",
        separator: "cell",
        pagination: _ctx.pagination,
        "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
        loading: _ctx.loading,
        onRequest: _ctx.onRequest,
        "binary-state-sort": ""
      }, {
        top: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[1] || (_cache[1] = _createElementVNode("b", null, "TOTAL:", -1)),
              _createVNode(_component_q_btn, {
                style: {"width":"100%"},
                class: "q-mb-xs",
                unelevated: "",
                "no-caps": "",
                color: "primary",
                icon: "access_time",
                label: 
                _ctx.rows?.length ? 'Выданная капа: ' + _ctx.rows[0]?.totalMaxValue : 0
              
              }, null, 8, ["label"]),
              _createVNode(_component_q_btn, {
                class: "q-mb-xs",
                style: {"width":"100%"},
                color: "positive",
                unelevated: "",
                "no-caps": "",
                icon: "done",
                label: 
                _ctx.rows?.length ? 'Пролитая капа: ' + _ctx.rows[0]?.totalValue : 0
              
              }, null, 8, ["label"]),
              _createVNode(_component_q_btn, {
                class: "q-mb-xs",
                style: {"width":"100%"},
                color: "negative",
                unelevated: "",
                "no-caps": "",
                icon: "close",
                label: 
                _ctx.rows?.length
                  ? 'Невыставлено кап: ' + _ctx.rows[0]?.notDefinedCount
                  : 0
              
              }, null, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_q_list, {
                dense: "",
                bordered: "",
                separator: "",
                class: "rounded-borders q-mr-sm"
              }, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    class: "text-white bg-grey-9",
                    dense: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createElementVNode("b", null, "Прогнозируемый оборот:", -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    dense: "",
                    class: "bg-grey-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.usdPredictableReturn || 0)), 1),
                            _cache[3] || (_cache[3] = _createElementVNode("small", { class: "q-ml-xs" }, _toDisplayString(` USD`), -1))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    dense: "",
                    class: "bg-grey-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.eurPredictableReturn || 0)), 1),
                            _cache[4] || (_cache[4] = _createElementVNode("small", { class: "q-ml-xs" }, _toDisplayString(` EUR`), -1))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    dense: "",
                    class: "bg-grey-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.uahPredictableReturn || 0)), 1),
                            _cache[5] || (_cache[5] = _createElementVNode("small", { class: "q-ml-xs" }, _toDisplayString(` UAH`), -1))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_q_list, {
                dense: "",
                bordered: "",
                separator: "",
                class: "rounded-borders q-mr-sm"
              }, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    class: "text-white bg-grey-9",
                    dense: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createElementVNode("b", null, "Реальный оборот:", -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    dense: "",
                    class: "bg-grey-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.usdRealReturn || 0)), 1),
                            _cache[7] || (_cache[7] = _createElementVNode("small", { class: "q-ml-xs" }, _toDisplayString(` USD`), -1))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    dense: "",
                    class: "bg-grey-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_8, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.eurRealReturn || 0)), 1),
                            _cache[8] || (_cache[8] = _createElementVNode("small", { class: "q-ml-xs" }, _toDisplayString(` EUR`), -1))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    dense: "",
                    class: "bg-grey-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_9, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.uahRealReturn || 0)), 1),
                            _cache[9] || (_cache[9] = _createElementVNode("small", { class: "q-ml-xs" }, _toDisplayString(` UAH`), -1))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_q_list, {
                dense: "",
                bordered: "",
                separator: "",
                class: "rounded-borders q-mr-sm"
              }, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    class: "text-white bg-grey-9",
                    dense: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createElementVNode("b", null, "Прогнозируемый профит:", -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    dense: "",
                    class: "bg-grey-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_10, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.usdPredictableProfit || 0)), 1),
                            _cache[11] || (_cache[11] = _createElementVNode("small", { class: "q-ml-xs" }, _toDisplayString(` USD`), -1))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    dense: "",
                    class: "bg-grey-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_11, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.eurPredictableProfit || 0)), 1),
                            _cache[12] || (_cache[12] = _createElementVNode("small", { class: "q-ml-xs" }, _toDisplayString(` EUR`), -1))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    dense: "",
                    class: "bg-grey-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_12, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.uahPredictableProfit || 0)), 1),
                            _cache[13] || (_cache[13] = _createElementVNode("small", { class: "q-ml-xs" }, _toDisplayString(` UAH`), -1))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_q_list, {
                dense: "",
                bordered: "",
                separator: "",
                class: "rounded-borders q-mr-sm"
              }, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    dense: "",
                    class: "text-white bg-grey-9"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createElementVNode("b", null, "Реальный профит:", -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    dense: "",
                    class: "bg-grey-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_13, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.usdRealProfit || 0)), 1),
                            _cache[15] || (_cache[15] = _createElementVNode("small", { class: "q-ml-xs" }, _toDisplayString(` USD`), -1))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    dense: "",
                    class: "bg-grey-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_14, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.eurRealProfit || 0)), 1),
                            _cache[16] || (_cache[16] = _createElementVNode("small", { class: "q-ml-xs" }, _toDisplayString(` EUR`), -1))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    clickable: "",
                    dense: "",
                    class: "bg-grey-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_15, [
                            _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.rows[0]?.uahRealProfit || 0)), 1),
                            _cache[17] || (_cache[17] = _createElementVNode("small", { class: "q-ml-xs" }, _toDisplayString(` UAH`), -1))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_ripple]
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ]),
        "body-cell-affiliateEmail": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_IconWithName, {
                id: props.row.affiliateId,
                name: _ctx.user?.notFullAccess ? '' : props.row.affiliateEmail,
                size: "20px",
                color: "secondary",
                icon: "account_box",
                entity: "affiliate"
              }, null, 8, ["id", "name"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-adminEmail": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_IconWithName, {
                id: props.row.adminId,
                name: props.row.adminEmail,
                size: "20px",
                color: "primary",
                icon: "manage_accounts",
                entity: "manager"
              }, null, 8, ["id", "name"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-offerName": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_IconWithName, {
                id: props.row.offerId,
                name: props.row.offerName,
                size: "20px",
                color: "orange-7",
                icon: "description",
                entity: "offer"
              }, null, 8, ["id", "name"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-geoCode": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("img", {
                  class: "q-mr-sm",
                  height: "10",
                  width: "15",
                  alt: props.row.geoCode,
                  src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.row.geoCode}.svg`
              
                }, null, 8, _hoisted_17),
                _createTextVNode(" " + _toDisplayString(props.row.geoName), 1)
              ])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-status": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_q_badge, {
                  color: _ctx.getLeadLimitBadgeColor(props.row.status)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getLeadLimitStatus(props.row.status)), 1)
                  ]),
                  _: 2
                }, 1032, ["color"])
              ])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-predictableReturn": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.predictableReturn)), 1),
              _createElementVNode("small", null, _toDisplayString(` ${_ctx.currencies[props.row.advertiserValueCurrency]}`), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-predictableProfit": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.predictableProfit)), 1),
              _createElementVNode("small", null, _toDisplayString(` ${_ctx.currencies[props.row.advertiserValueCurrency]}`), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-realReturn": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.realReturn)), 1),
              _createElementVNode("small", null, _toDisplayString(` ${_ctx.currencies[props.row.advertiserValueCurrency]}`), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-realProfit": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatMoney(props.row.realProfit)), 1),
              _createElementVNode("small", null, _toDisplayString(` ${_ctx.currencies[props.row.advertiserValueCurrency]}`), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-maxValue": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(props.row.maxValue) + " ", 1),
                _createVNode(_component_q_btn, {
                  size: "xs",
                  flat: "",
                  icon: "edit",
                  color: "primary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_menu, {
                      class: "q-pa-md",
                      style: {"border":"1px solid #ccc"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_LeadLimitEditPopup, {
                          "lead-limit-row": props.row,
                          onRefresh: _ctx.onRequest
                        }, null, 8, ["lead-limit-row", "onRefresh"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-comments": _withCtx((props) => [
          _createVNode(_component_q_td, {
            props: props,
            style: {"width":"170px"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.comments, (row, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  _createElementVNode("div", {
                    class: "text-ellipsis cursor-pointer",
                    innerHTML: row.comment,
                    style: {"height":"21px","width":"170px"}
                  }, null, 8, _hoisted_18),
                  _createVNode(_component_q_tooltip, {
                    innerHTML: row.comment
                  }, null, 8, ["innerHTML"])
                ]))
              }), 128)),
              _createVNode(_component_q_btn, {
                flat: "",
                icon: "add",
                color: "primary",
                size: "sm",
                onClick: _withModifiers(($event: any) => (_ctx.openCommentDialog(props.row)), ["stop"])
              }, null, 8, ["onClick"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_q_btn, {
                  size: "xs",
                  label: "INFO",
                  "no-caps": "",
                  color: "primary",
                  class: "q-mr-sm"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_menu, { class: "q-pa-sm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_LeadLimitChangelogPopup, {
                          "lead-limit-row": props.row
                        }, null, 8, ["lead-limit-row"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_q_btn, {
                  onClick: ($event: any) => (_ctx.resetLeadLimit(props.row.id)),
                  size: "xs",
                  label: !props.row.isActive ? 'Капа закрыта' : 'Закрыть капу',
                  "no-caps": "",
                  class: "q-mr-sm",
                  color: !props.row.isActive ? 'positive' : 'negative',
                  loading: _ctx.leadLimitIsReseting,
                  disable: _ctx.leadLimitIsReseting || !props.row.isActive
                }, null, 8, ["onClick", "label", "color", "loading", "disable"]),
                _createVNode(_component_q_btn, {
                  round: "",
                  flat: "",
                  color: "negative",
                  onClick: ($event: any) => (_ctx.onDelete(props.row.id)),
                  icon: "delete",
                  size: "12px"
                }, null, 8, ["onClick"])
              ])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "pagination", "loading", "onRequest"]),
      _createVNode(_component_AffiliateCommentDialog, {
        open: _ctx.showAddCommentDialog,
        onClose: _ctx.closeCommentDialog,
        onRefresh: _ctx.onRequest,
        affiliateId: _ctx.selectedRow.affiliateId,
        "lead-limit-id": _ctx.selectedRow.id
      }, null, 8, ["open", "onClose", "onRefresh", "affiliateId", "lead-limit-id"])
    ])
  ], 64))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "col-sm-12 q-ml-xs col-md-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "col-sm-12 q-ml-xs col-md-1 width-100" }
const _hoisted_5 = { class: "col-sm-12 q-ml-xs col-md-1 width-100" }
const _hoisted_6 = { class: "col-sm-12 q-ml-xs col-md-1 width-100" }
const _hoisted_7 = { class: "col-sm-12 q-ml-xs col-md-1 width-100" }
const _hoisted_8 = { class: "col-sm-12 q-ml-xs col-md-1" }
const _hoisted_9 = { class: "col-sm-12 q-ml-xs col-md-1" }
const _hoisted_10 = {
  key: 0,
  class: "col-sm-12 q-ml-xs col-md-1 width-100"
}
const _hoisted_11 = {
  key: 1,
  class: "col-sm-12 q-ml-xs col-md-1"
}
const _hoisted_12 = { class: "col-sm-12 q-ml-xs col-md-1 width-100" }
const _hoisted_13 = { class: "col-sm-12 q-ml-xs col-md-1 width-100" }
const _hoisted_14 = { class: "col-sm-12 q-ml-xs col-md-1 self-center width-80" }
const _hoisted_15 = { class: "col-sm-12 q-ml-xs col-md-1 self-center width-80 q-mr-md" }
const _hoisted_16 = { class: "col-sm-12 q-ml-xs col-md-1 self-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rewards, (reward, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "flex row q-gutter-x-md mobile-display-block"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("b", null, "ГЕО", -1)),
          _createVNode(_component_q_select, {
            dense: "",
            ref_for: true,
            ref: `target-geos-${index}`,
            onBlur: ($event: any) => (_ctx.clearFilter(`target-geos-${index}`)),
            outlined: "",
            multiple: "",
            "use-chips": "",
            "model-value": reward.geoIds,
            "onUpdate:modelValue": 
            value => _ctx.updateField({ ...reward, geoIds: value }, index)
          ,
            options: _ctx.geos,
            onFilter: _ctx.getGeos,
            "option-value": "id",
            "option-label": "name",
            "emit-value": "",
            "map-options": "",
            "use-input": "",
            error: !!_ctx.errors?.commonRewards?.[index]?.geoIds,
            "error-message": _ctx.errors?.commonRewards?.[index]?.geoIds
          }, {
            option: _withCtx((scope) => [
              _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps({ ref_for: true }, scope.itemProps, _toHandlers(scope.itemEvents)), {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    style: {"user-select":"none","display":"initial"},
                    class: "q-mr-sm",
                    height: "10",
                    width: "15",
                    src: 
                  `http://purecatamphetamine.github.io/country-flag-icons/3x2/${scope.opt.isoCode}.svg`
                
                  }, null, 8, _hoisted_3),
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, {
                        innerHTML: scope.opt.name
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1040)), [
                [_directive_close_popup]
              ])
            ]),
            _: 2
          }, 1032, ["onBlur", "model-value", "onUpdate:modelValue", "options", "onFilter", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("b", null, "Ставка веба", -1)),
          _createVNode(_component_q_input, {
            dense: "",
            disable: reward.type === _ctx.rewardTypesEnum.RevShare,
            outlined: "",
            type: "number",
            step: ".01",
            min: "0",
            "model-value": reward.value.amount,
            "onUpdate:modelValue": 
            value =>
              _ctx.updateField(
                {
                  ...reward,
                  value: { ...reward.value, amount: Number(value) || 0 },
                },
                index,
              )
          ,
            error: !!_ctx.errors?.commonRewards?.[index]?.value,
            "error-message": _ctx.errors?.commonRewards?.[index]?.value
          }, null, 8, ["disable", "model-value", "onUpdate:modelValue", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[2] || (_cache[2] = _createElementVNode("b", null, "Валюта", -1)),
          _createVNode(_component_q_select, {
            outlined: "",
            dense: "",
            "model-value": reward.value.currency,
            "onUpdate:modelValue": 
            currency =>
              _ctx.updateField(
                {
                  ...reward,
                  value: { ...reward.value, currency },
                },
                index,
              )
          ,
            options: _ctx.currencies,
            "option-value": "id",
            "option-label": "name",
            "emit-value": "",
            "map-options": "",
            error: !!_ctx.errors?.commonRewards?.[index]?.value,
            "error-message": _ctx.errors?.commonRewards?.[index]?.value
          }, null, 8, ["model-value", "onUpdate:modelValue", "options", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[3] || (_cache[3] = _createElementVNode("b", null, "Ставка рекла", -1)),
          _createVNode(_component_q_input, {
            dense: "",
            disable: reward.type === _ctx.rewardTypesEnum.RevShare,
            outlined: "",
            type: "number",
            step: ".01",
            min: "0",
            "model-value": reward.advertiserValueAmount,
            "onUpdate:modelValue": 
            value =>
              _ctx.updateField(
                {
                  ...reward,
                  advertiserValueAmount: Number(value) || 0,
                },
                index,
              )
          ,
            error: !!_ctx.errors?.commonRewards?.[index]?.advertiserValueAmount,
            "error-message": _ctx.errors?.commonRewards?.[index]?.advertiserValueAmount
          }, null, 8, ["disable", "model-value", "onUpdate:modelValue", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[4] || (_cache[4] = _createElementVNode("b", null, "Валюта", -1)),
          _createVNode(_component_q_select, {
            dense: "",
            outlined: "",
            "model-value": reward.advertiserValueCurrency,
            "onUpdate:modelValue": 
            advertiserValueCurrency =>
              _ctx.updateField(
                {
                  ...reward,
                  advertiserValueCurrency,
                },
                index,
              )
          ,
            options: _ctx.currencies,
            "option-value": "id",
            "option-label": "name",
            "emit-value": "",
            "map-options": "",
            error: !!_ctx.errors?.commonRewards?.[index]?.advertiserValueCurrency,
            "error-message": 
            _ctx.errors?.commonRewards?.[index]?.advertiserValueCurrency
          
          }, null, 8, ["model-value", "onUpdate:modelValue", "options", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[5] || (_cache[5] = _createElementVNode("b", null, "Тип конверсии", -1)),
          _createVNode(_component_q_select, {
            dense: "",
            outlined: "",
            disable: _ctx.offer.vertical === null,
            "model-value": reward.type,
            "onUpdate:modelValue": 
            value =>
              _ctx.updateField({ ...reward, type: value, percent: null }, index)
          ,
            options: _ctx.rewardTypes,
            "option-value": "id",
            "option-label": "name",
            "emit-value": "",
            "map-options": "",
            error: !!_ctx.errors?.commonRewards?.[index]?.type,
            "error-message": _ctx.errors?.commonRewards?.[index]?.type
          }, null, 8, ["disable", "model-value", "onUpdate:modelValue", "options", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[7] || (_cache[7] = _createElementVNode("b", null, [
            _createTextVNode("Источник "),
            _createElementVNode("sup", null, "?")
          ], -1)),
          _createVNode(_component_q_tooltip, {
            "max-width": "200px",
            anchor: "bottom middle",
            self: "top middle",
            class: "q-tooltip"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" Опционально. Если выбран источник трафика, то выплата за конверсию расчитывается исходя из выбранного источника трафика при создании потока. Если для выбранного в потоке источника трафика нету выплаты - начисляется дефолтная. ")
            ])),
            _: 1
          }),
          _createVNode(_component_q_select, {
            dense: "",
            ref_for: true,
            ref: `trafficSources-${index}`,
            outlined: "",
            clearable: "",
            "model-value": reward.trafficSource?.name,
            "onUpdate:modelValue": name => _ctx.updateTrafficSource(reward, name, index),
            onFilter: _ctx.getTrafficSources,
            options: _ctx.trafficSources,
            "use-input": "",
            error: !!_ctx.errors?.commonRewards?.[index]?.trafficSource,
            "error-message": _ctx.errors?.commonRewards?.[index]?.trafficSource
          }, null, 8, ["model-value", "onUpdate:modelValue", "onFilter", "options", "error", "error-message"])
        ]),
        (reward.type === _ctx.rewardTypesEnum.RevShare)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _cache[9] || (_cache[9] = _createElementVNode("b", null, "Ревшара, %", -1)),
              _createVNode(_component_q_tooltip, null, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("Общая ставка по офферу для вебов (%) по RevShare")
                ])),
                _: 1
              }),
              _createVNode(_component_q_input, {
                dense: "",
                outlined: "",
                type: "number",
                min: "0",
                "model-value": reward.percent,
                "onUpdate:modelValue": 
            value =>
              _ctx.updateField({ ...reward, percent: Number(value) || null }, index)
          ,
                error: !!_ctx.errors?.commonRewards?.[index]?.percent,
                "error-message": _ctx.errors?.commonRewards?.[index]?.percent
              }, null, 8, ["model-value", "onUpdate:modelValue", "error", "error-message"])
            ]))
          : _createCommentVNode("", true),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _cache[11] || (_cache[11] = _createElementVNode("b", null, [
                _createTextVNode("Продажа"),
                _createElementVNode("sup", null, "?")
              ], -1)),
              _createVNode(_component_q_tooltip, {
                "max-width": "200px",
                anchor: "bottom middle",
                self: "top middle",
                class: "q-tooltip"
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode(" Используется при работе по модели с доп. продажами. Пример: 1 депозит - выплата 10$, 2 депозит - выплата 15$. ")
                ])),
                _: 1
              }),
              _createVNode(_component_q_select, {
                dense: "",
                disable: reward.type !== _ctx.rewardTypesEnum.FTD,
                outlined: "",
                "model-value": reward.upsaleNumber,
                "onUpdate:modelValue": 
            val =>
              _ctx.updateField(
                { ...reward, upsaleNumber: Number(val) || null },
                index,
              )
          ,
                hint: "Порядковый номер депозита",
                options: [1, 2, 3, 4, 5],
                error: !!_ctx.errors?.commonRewards?.[index]?.upsaleNumber,
                "error-message": _ctx.errors?.commonRewards?.[index]?.upsaleNumber
              }, null, 8, ["disable", "model-value", "onUpdate:modelValue", "error", "error-message"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_12, [
          _cache[13] || (_cache[13] = _createElementVNode("b", null, [
            _createTextVNode("Мин.Деп"),
            _createElementVNode("sup", null, "?")
          ], -1)),
          _createVNode(_component_q_tooltip, {
            "max-width": "200px",
            anchor: "bottom middle",
            self: "top middle",
            class: "q-tooltip"
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createTextVNode(" Комментарий по мин.депу. Если информация заполнена в данной ячейке, то она выводится на странице офферов после ставки в таблице офферов (если поле не проставлено, то текст не выводится). ")
            ])),
            _: 1
          }),
          _createVNode(_component_q_input, {
            dense: "",
            outlined: "",
            placeholder: "Ex: 20 EUR",
            "model-value": reward.minDepComment,
            "onUpdate:modelValue": 
            val => _ctx.updateField({ ...reward, minDepComment: val || null }, index)
          ,
            error: !!_ctx.errors?.commonRewards?.[index]?.minDepComment,
            "error-message": _ctx.errors?.commonRewards?.[index]?.minDepComment
          }, null, 8, ["model-value", "onUpdate:modelValue", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[15] || (_cache[15] = _createElementVNode("b", null, [
            _createTextVNode("Бейс"),
            _createElementVNode("sup", null, "?")
          ], -1)),
          _createVNode(_component_q_tooltip, {
            "max-width": "200px",
            anchor: "bottom middle",
            self: "top middle",
            class: "q-tooltip"
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode(" Комментарий по бейслайну. Если информация заполнена в данной ячейке, то она выводится на странице офферов после ставки в таблице офферов (если поле не проставлено, то текст не выводится). ")
            ])),
            _: 1
          }),
          _createVNode(_component_q_input, {
            dense: "",
            outlined: "",
            placeholder: "Ex: 20 EUR",
            "model-value": reward.baselineComment,
            "onUpdate:modelValue": 
            val =>
              _ctx.updateField({ ...reward, baselineComment: val || null }, index)
          ,
            error: !!_ctx.errors?.commonRewards?.[index]?.baselineComment,
            "error-message": _ctx.errors?.commonRewards?.[index]?.baselineComment
          }, null, 8, ["model-value", "onUpdate:modelValue", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_q_checkbox, {
            label: "STOP",
            "keep-color": "",
            color: "negative",
            modelValue: reward.stopGeo,
            "onUpdate:modelValue": ($event: any) => ((reward.stopGeo) = $event)
          }, null, 8, ["modelValue", "onUpdate:modelValue"]),
          _createVNode(_component_q_tooltip, null, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [
              _createTextVNode("Стоп выбранных гео на оффере")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_q_checkbox, {
            label: "Без ставки",
            color: "dark",
            "keep-color": "",
            modelValue: reward.withoutValue,
            "onUpdate:modelValue": ($event: any) => ((reward.withoutValue) = $event)
          }, null, 8, ["modelValue", "onUpdate:modelValue"]),
          _createVNode(_component_q_tooltip, null, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createTextVNode("Гео без ставки")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_q_btn, {
            size: "sm",
            onClick: ($event: any) => (_ctx.deleteReward(index)),
            outline: "",
            round: "",
            color: "negative",
            icon: "delete"
          }, null, 8, ["onClick"])
        ])
      ]))
    }), 128)),
    _createVNode(_component_q_btn, {
      onClick: _ctx.addReward,
      round: "",
      color: "secondary",
      icon: "add",
      size: "sm"
    }, null, 8, ["onClick"])
  ]))
}
import {
  Errors,
  PayoutSystemAccountData,
  PayoutSystemAccountState,
  RootModule,
} from '@/store/types'
import axios from 'axios'
import { ActionContext } from 'vuex'
import router from '@/router'

function getDefaultData(): PayoutSystemAccountState {
  return {
    errors: {},
    payoutSystemAccount: {
      partnersIds: [],
      advertiserId: null,
      account: '',
      currency: 0,
      paymentSystem: 3,
      paymentSystemNetwork: 0,
    },
  }
}

const payoutSystemAccountModule = {
  state: getDefaultData(),
  mutations: {
    update(
      state: PayoutSystemAccountState,
      value: PayoutSystemAccountData,
    ): void {
      state.payoutSystemAccount = { ...state.payoutSystemAccount, ...value }
      state.errors = {}
    },
    reset(state: PayoutSystemAccountState): void {
      const { payoutSystemAccount, errors } = getDefaultData()
      state.payoutSystemAccount = payoutSystemAccount
      state.errors = errors
    },
    setErrors(state: PayoutSystemAccountState, value: Errors): void {
      state.errors = value
    },
  },
  actions: {
    async getData(
      { commit }: ActionContext<PayoutSystemAccountState, RootModule>,
      payoutSystemAccountId: string,
    ) {
      try {
        const { data } = await axios.get(
          '/api/wallets/' + payoutSystemAccountId,
        )
        commit('update', data)
        commit('setErrors', {})
      } catch (err) {
        await router.push('/statistics')
        throw new Error(err as string)
      }
    },
  },
  namespaced: true,
}

export default payoutSystemAccountModule

import {
  enumToArray,
  getGamblingRewardTypes,
  getLeadLimitStatuses,
  getLeadStatuses,
  payoutCurrencies,
} from '@/utils'
import { PostbackEventType } from '@/enums/PostbackEventType'
import { Vertical } from '@/enums/Vertical'
import axios from 'axios'
import { CampaignStatus } from '@/enums/CampaignStatus'
import { OfferStatus } from '@/enums/OfferStatus'

export function getFiltersData(
  search = null,
  redirectEventIdSearch = null,
  conversionAmount = null,
  conversionAdvAmount = null,
) {
  return {
    presets: [],
    promos: [],
    offers: [],
    campaigns: [],
    admins: [],
    geos: [],
    categories: [],
    affiliates: [],
    advertisers: [],
    advertiserManagers: [],
    markers: [],
    offerMarkers: [],
    trafficSourceNames: [],
    search,
    redirectEventIdSearch,
    conversionAmount,
    conversionAdvAmount,
    statuses: getLeadStatuses(),
    currencies: payoutCurrencies,
    leadLimitStatuses: getLeadLimitStatuses(),
    campaignStatuses: enumToArray(CampaignStatus),
    rewardTypes: getGamblingRewardTypes(),
    postbackEventTypes: enumToArray(PostbackEventType),
    verticals: enumToArray(Vertical),
    offerStatuses: enumToArray(OfferStatus),
  }
}

export function getIncludeOptions() {
  return {
    hideChart: true,
    includeAdvertiserManagerIds: true,
    includeAppMarket: true,
    includeOfferId: true,
    includeOfferIds: true,
    includeGeoId: true,
    includeGeoIds: true,
    includeAdvertiserId: true,
    includeAdvertiserIds: true,
    includeAffiliateId: true,
    includeAffiliateIds: true,
    includeFantomAffiliateId: true,
    includeFantomAffiliateIds: true,
    includeLandingId: true,
    includeRewardType: true,
    includeManagerId: true,
    includeManagerIds: true,
    includeCampaignId: true,
    includeCampaignIds: true,
    includeStatus: true,
    includeMarkerId: true,
    includeOfferMarkerId: true,
    includeMobileAppId: true,
    includeCategoryId: true,
    includeOfferCategoryId: true,
    includeTrafficSourceId: true,
    includeMobileAppIds: true,
  }
}

export async function initOptionsByRouteParams(ref: any) {
  if (
    ref.$route.params.offerId ||
    ref.$route.query.offerId ||
    (ref.filters.offerId && !ref.offers.length)
  ) {
    ref.offers = (await axios.get('/api/offers')).data
  }
  if (
    ref.$route.query.advertiserId ||
    ref.$route.params.advertiserId ||
    (ref.filters.advertiserId && !ref.advertisers.length)
  ) {
    ref.advertisers = (await axios.get('/api/advertisers')).data
  }
  if (
    ref.$route.params.campaignId ||
    (ref.filters.campaignId && !ref.campaigns.length)
  ) {
    ref.campaigns = (await axios.get('/api/campaigns')).data
  }
  if (ref.$route.params.geoId || (ref.filters.geoId && !ref.geos.length)) {
    if (ref.$route.path === '/offers') {
      ref.geos = (await axios.get('/api/geos?isOffersPage=true')).data
    } else {
      ref.geos = (await axios.get('/api/geos/')).data
    }
  }
  if (
    ref.$route.params.affiliateId ||
    ref.$route.query.affiliateId ||
    (ref.filters.affiliateId && !ref.affiliates.length)
  ) {
    const affiliates = (await axios.get('/api/affiliates/list')).data
    ref.affiliates = affiliates.map((affiliate: any) => ({
      ...affiliate,
      email: 'ID:' + affiliate.id + ' — ' + affiliate.credentials.email,
    }))
  }
  if (
    ref.$route.params.categoryId ||
    ref.$route.params.offerCategoryId ||
    (ref.filters.categoryId && !ref.categories.length)
  ) {
    ref.offerCategories = (await axios.get('/api/offer-categories')).data
  }
  if (
    ref.$route.params.landingId ||
    ref.$route.params.preLandingId ||
    (ref.filters.landingId && !ref.promos.length)
  ) {
    ref.promos = (await axios.get('/api/promos')).data
  }
}

export async function initFilterOptions(ref: any) {
  if (ref.$route.query.mobileAppId) {
    ref.apps = (await axios.get('/api/apps')).data
  }
  ref.campaigns = (await axios.get('/api/campaigns')).data
  ref.offerMarkers = (await axios.get('/api/offer-markers')).data
  ref.markers = (await axios.get('/api/markers')).data
  ref.offers = (await axios.get('/api/offers')).data
  if (ref.$route.path === '/offers') {
    ref.geos = (await axios.get('/api/geos?isOffersPage=true')).data
  } else {
    ref.geos = (await axios.get('/api/geos/')).data
  }
  ref.advertisers = (await axios.get('/api/advertisers/')).data
  const advertiserManagers = (
    await axios.get('/api/admins/advertiser-managers/')
  ).data
  ref.advertiserManagers = advertiserManagers.map((affiliate: any) => ({
    ...affiliate,
    email: 'ID:' + affiliate.id + ' — ' + affiliate.credentials.email,
  }))
  const affiliates = (await axios.get('/api/affiliates/list')).data
  ref.affiliates = affiliates.map((affiliate: any) => ({
    ...affiliate,
    email: 'ID:' + affiliate.id + ' — ' + affiliate.credentials.email,
  }))
  const admins = (await axios.get('/api/admins/managers')).data
  ref.admins = admins.map((admin: any) => ({
    ...admin,
    email: 'ID:' + admin.id + ' — ' + admin.credentials.email,
  }))
  ref.categories = (await axios.get('/api/offer-categories')).data
  ref.promos = (await axios.get('/api/promos')).data
}

export function formatFiltersFromQuery(ref: any) {
  if (
    Object.keys(ref.$route.params).length ||
    Object.keys(ref.$route.query).length
  ) {
    const paramsFilters = Object.keys(ref.$route.params).length
      ? ref.$route.params
      : ref.$route.query
    for (const [key, value] of Object.entries(paramsFilters)) {
      if (!value || value === 'undefined' || value === 'null') {
        continue
      }
      if (
        [
          'rewardTypes',
          'affiliateIds',
          'advertiserIds',
          'advertiserManagerIds',
          'geoIds',
          'offerIds',
          'managerIds',
        ].includes(key)
      ) {
        paramsFilters[key] = String(value)
          .split(',')
          .map(e => Number(e)) as any
        continue
      }
      if (Number(value) || Number(value) === 0) {
        paramsFilters[key] = (Number(value) as unknown) as string
      }
    }
    ref.filters = { ...ref.filters, ...paramsFilters, isDetailing: false }
    for (const [key, value] of Object.entries(ref.filters)) {
      if (
        value === undefined ||
        value === null ||
        value === '' ||
        value === 'undefined' ||
        value === 'null'
      ) {
        delete (ref.filters as any)[key]
      }
    }
    if (ref.$route.query?.fromPage) {
      if (ref.$route.query.affiliateId) {
        ;(ref.filters as any).affiliateIds = [
          Number(ref.$route.query.affiliateId),
        ]
      }
      return
    }
    if (ref.$route.query?.target === 'conversions') {
      ref.statsTab = 'conversions'
    }
    if (ref.$route.query?.target === 'consumers') {
      ref.statsTab = 'consumers'
    }
    if (ref.$route.query?.target === 'referrals') {
      ref.statsTab = 'referrals'
    }
    for (const [key, value] of Object.entries(ref.filters)) {
      if (value === 'false') {
        ref.filters[key] = false
      }
      if (value === 'true') {
        ref.filters[key] = true
      }
    }
  }
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row justify-between items-center" }
const _hoisted_2 = { class: "row items-center col-7" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_PostbackNoteDialog = _resolveComponent("PostbackNoteDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.postbacks, (postback, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "postback-block no-shadow q-px-none",
        key: index
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[2] || (_cache[2] = _createElementVNode("h5", { class: "text-h6" }, "Postback", -1)),
            _createVNode(_component_q_btn, {
              "no-caps": "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPostbackNoteDialog = !_ctx.showPostbackNoteDialog)),
              class: "col-1 max-height-btn",
              flat: "",
              size: "12px",
              icon: "info_outline"
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_q_checkbox, {
              "left-label": "",
              color: "dark",
              modelValue: postback.isGetPostback,
              "onUpdate:modelValue": ($event: any) => ((postback.isGetPostback) = $event),
              label: "GET postback"
            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
            _createVNode(_component_q_tooltip, null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Если галочка включена, вебмастеру будет отправлятся GET postback вместо POST ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_q_btn, {
              onClick: _ctx.addPostback,
              round: "",
              size: "9px",
              class: "q-mr-md",
              color: "secondary",
              icon: "add"
            }, null, 8, ["onClick"])
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_q_input, {
            dense: "",
            outlined: "",
            class: "col-12 q-mr-lg link-text",
            placeholder: "Ссылка",
            modelValue: postback.url,
            "onUpdate:modelValue": ($event: any) => ((postback.url) = $event),
            error: !!_ctx.errors?.postbacks,
            "error-message": _ctx.errors?.postbacks
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_btn, {
                "no-caps": "",
                onClick: ($event: any) => (_ctx.removePostback(index)),
                flat: "",
                rounded: "",
                size: "12px",
                disable: _ctx.postbacks.length < 2,
                color: "white",
                icon: "delete_outline"
              }, null, 8, ["onClick", "disable"])
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_option_group, {
            modelValue: postback.types,
            "onUpdate:modelValue": ($event: any) => ((postback.types) = $event),
            options: _ctx.postbackTypes,
            color: "primary",
            type: "checkbox",
            inline: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
        ])
      ]))
    }), 128)),
    _createVNode(_component_PostbackNoteDialog, {
      open: _ctx.showPostbackNoteDialog,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPostbackNoteDialog = false))
    }, null, 8, ["open"])
  ], 64))
}
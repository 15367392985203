import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_AffiliatesTable = _resolveComponent("AffiliatesTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Filters, {
      onReset: _ctx.resetFilters,
      onChange: _ctx.onChangeFilters,
      onChangeAll: _ctx.changeAll,
      "current-filters": _ctx.filters
    }, null, 8, ["onReset", "onChange", "onChangeAll", "current-filters"]),
    _createElementVNode("div", null, [
      _createVNode(_component_AffiliatesTable, {
        rewards: _ctx.rewards,
        affiliates: _ctx.affiliates,
        geos: _ctx.geos,
        currencies: _ctx.currencies,
        trafficSources: _ctx.trafficSources,
        errors: _ctx.errors,
        getGeos: _ctx.getGeos,
        rewardTypes: _ctx.rewardTypes,
        offer: _ctx.offer,
        leadLimitPeriods: _ctx.leadLimitPeriods,
        columns: _ctx.columns,
        onUpdateReward: _ctx.updateField,
        onDeleteReward: _ctx.deleteReward,
        onAddReward: _ctx.addReward
      }, null, 8, ["rewards", "affiliates", "geos", "currencies", "trafficSources", "errors", "getGeos", "rewardTypes", "offer", "leadLimitPeriods", "columns", "onUpdateReward", "onDeleteReward", "onAddReward"])
    ])
  ], 64))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-2" }
const _hoisted_3 = {
  key: 0,
  class: "q-ml-md"
}
const _hoisted_4 = { class: "col-2 self-center q-ml-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentBalances, (balance, index) => {
    return (_openBlock(), _createElementBlock("div", { key: index }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("b", _hoisted_2, _toDisplayString(balance.isRentAppsBalance ? 'Баланс по приложениям' : 'Баланс в пп') + " " + _toDisplayString(balance.isReferralBalance ? `ref #${balance.affiliateId}` : ''), 1),
          _createVNode(_component_q_input, {
            outlined: "",
            class: "q-mr-lg",
            placeholder: "Ссылка",
            modelValue: balance.value.amount,
            "onUpdate:modelValue": ($event: any) => ((balance.value.amount) = $event),
            modelModifiers: { number: true },
            error: !!_ctx.errors?.balances,
            "error-message": _ctx.errors?.balances,
            disable: balance.isReferralBalance
          }, null, 8, ["modelValue", "onUpdate:modelValue", "error", "error-message", "disable"])
        ]),
        _createElementVNode("div", null, [
          _cache[0] || (_cache[0] = _createElementVNode("b", { class: "col-2" }, "Валюта", -1)),
          _createVNode(_component_q_select, {
            outlined: "",
            disable: balance.isRentAppsBalance || balance.isReferralBalance,
            modelValue: balance.value.currency,
            "onUpdate:modelValue": ($event: any) => ((balance.value.currency) = $event),
            options: _ctx.currencies,
            "option-value": "id",
            "option-label": "name",
            "emit-value": "",
            "map-options": "",
            "error-message": _ctx.errors?.balances,
            error: !!_ctx.errors?.balances
          }, null, 8, ["disable", "modelValue", "onUpdate:modelValue", "options", "error-message", "error"])
        ]),
        (balance.offer?.name)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[1] || (_cache[1] = _createElementVNode("b", { class: "col-2" }, "Оффер", -1)),
              _createVNode(_component_q_input, {
                outlined: "",
                readonly: "",
                class: "q-mr-lg",
                placeholder: "-",
                "model-value": balance.offer.name
              }, null, 8, ["model-value"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_btn, {
            onClick: ($event: any) => (_ctx.changeBalance(index, 'increase')),
            round: "",
            color: "secondary",
            icon: "add",
            disable: balance.isReferralBalance
          }, null, 8, ["onClick", "disable"]),
          _createVNode(_component_q_btn, {
            onClick: ($event: any) => (_ctx.changeBalance(index, 'decrease')),
            round: "",
            color: "secondary",
            icon: "remove",
            disable: balance.isReferralBalance
          }, null, 8, ["onClick", "disable"])
        ])
      ])
    ]))
  }), 128))
}
import { ColumnType } from '@/utils'

export function getColumns(): ColumnType[] {
  return [
    { name: 'status', label: 'Status', align: 'left', field: 'status' },
    { name: 'usdAdv', label: 'ADV, $', align: 'right', field: 'usdAdv' },
    { name: 'eurAdv', label: 'ADV, €', align: 'right', field: 'eurAdv' },
    { name: 'usdAff', label: 'WEB, $', align: 'right', field: 'usdAff' },
    { name: 'eurAff', label: 'WEB, €', align: 'right', field: 'eurAff' },
    {
      name: 'usdMargin',
      label: 'Margin, $',
      align: 'right',
      field: 'usdMargin',
    },
    {
      name: 'eurMargin',
      label: 'Margin, €',
      align: 'right',
      field: 'eurMargin',
    },
  ]
}

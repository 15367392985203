
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import store from '@/store'
import { AffiliateReward } from '@/store/types'
import { enumToArray, getRewardTypesByVertical } from '@/utils'
import { RewardType } from '@/enums/RewardType'
import { lookupGeos } from '@/utils/lookups'
import { Vertical } from '@/enums/Vertical'
import { payoutCurrencies } from '@/utils'
import { LeadLimitPeriod } from '@/enums/LeadLimitPeriod'
import AffiliatesTable from './AffiliatesTable.vue'
import Filters from '@/components/filters/Filters.vue'
import { getColumns } from './getColumns'
import { getDefaultFiltersLast7Days } from '@/utils/request'
import { LocationQueryValue } from 'vue-router'
import { FiltersType } from '@/utils/request'

export default defineComponent({
  name: 'Affiliates',
  components: { Filters, AffiliatesTable },
  props: ['defaultOptions'],
  created(): void {
    const rewards = store.state.offersModule.offer.affiliateRewards
    if (!rewards.length) {
      this.update({
        affiliateRewards: [
          ...rewards,
          {
            value: {},
            affiliateIds: [],
            postClickMonth: false,
            disablePostback: false,
          },
        ],
      })
    }
  },
  computed: {
    ...mapState('offersModule', ['errors', 'offer']),
    rewards() {
      const rewards = store.state.offersModule.offer.affiliateRewards
      if (!this.filters) return rewards
      const {
        affiliateId,
        geoIds,
        trafficSourceNames = [],
        includeAffiliateId,
        includeGeoIds,
        includeTrafficSourceId,
      } = this.filters || {}
      return [...rewards].filter(
        ({ geoIds: rewardGeoIds, affiliateIds, trafficSource }) => {
          const rewardGeoIdList = Array.isArray(rewardGeoIds)
            ? rewardGeoIds
            : [rewardGeoIds]
          if (geoIds && Array.isArray(geoIds) && geoIds?.length) {
            const geoIdList = Array.isArray(geoIds) ? geoIds : [geoIds]
            const hasGeoMatch = geoIdList.some(geoId =>
              rewardGeoIdList.includes(geoId),
            )
            if (includeGeoIds ? !hasGeoMatch : hasGeoMatch) return false
          }
          if (affiliateId) {
            const hasAffiliateMatch = affiliateIds.includes(affiliateId)
            if (includeAffiliateId ? !hasAffiliateMatch : hasAffiliateMatch)
              return false
          }
          if (trafficSourceNames.length) {
            const hasTrafficMatch = trafficSourceNames.includes(
              trafficSource?.name,
            )
            if (includeTrafficSourceId ? !hasTrafficMatch : hasTrafficMatch)
              return false
          }
          return true
        },
      )
    },
  },
  data: function() {
    const filtersL = this.$storage.getStorageSync('rewardFilters') || {}
    const queryFilters = this.$route.query
    for (const [filter, value] of Object.entries(queryFilters)) {
      if (Array.isArray(value)) {
        queryFilters[filter] = value.map(v => {
          const num = Number(v)
          return isNaN(num) ? v : ((num as unknown) as LocationQueryValue)
        })
      } else {
        const numberValue = Number(value)
        queryFilters[filter] = isNaN(numberValue)
          ? [value]
          : [(numberValue as unknown) as LocationQueryValue]
      }
    }
    const filters = {
      ...getDefaultFiltersLast7Days(),
      ...filtersL,
      ...queryFilters,
    }
    return {
      geos: [...(this.defaultOptions.geos || [])],
      affiliates: [...(this.defaultOptions.affiliates || [])],
      rewardTypes: getRewardTypesByVertical(
        store.state.offersModule.offer.vertical,
      ),
      leadLimitPeriods: enumToArray(LeadLimitPeriod),
      leadLimitIsReseting: false,
      currencies: payoutCurrencies,
      verticalsEnum: Vertical,
      trafficSources: store.state.offersModule.offer.trafficSources || [],
      columns: getColumns(),
      filters,
    }
  },
  watch: {
    async filters() {
      this.$storage.setStorageSync('rewardFilters', this.filters)
    },
  },
  methods: {
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) return
      this.filters = { ...this.filters, [field]: value }
      this.$forceUpdate()
    },
    resetFilters() {
      this.filters = { ...getDefaultFiltersLast7Days() }
      this.$storage.setStorageSync('rewardFilters', {
        ...getDefaultFiltersLast7Days(),
      })
    },
    ...mapMutations('offersModule', ['update']),
    addReward() {
      this.update({
        affiliateRewards: [
          ...store.state.offersModule.offer.affiliateRewards,
          {
            value: {},
            affiliateIds: [],
            geoIds: [],
            postClickMonth: false,
            disablePostback: false,
          },
        ],
      })
    },
    deleteReward(rewardIndex: number) {
      const affiliateRewards = this.rewards.filter(
        (_: AffiliateReward, index: number) => index !== rewardIndex,
      )
      this.update({ affiliateRewards })
    },
    updateField(reward: AffiliateReward, index: number) {
      if (reward.type === RewardType.RevShare) {
        reward = { ...reward, value: { ...reward.value, amount: null } }
      }
      const affiliateRewards = this.rewards
      affiliateRewards[index] = reward
      this.update({ affiliateRewards })
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update)
    },
  },
})

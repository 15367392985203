import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row q-mb-lg" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_PostbackNoteDialog = _resolveComponent("PostbackNoteDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.postbacks, (postback, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_q_option_group, {
            modelValue: postback.types,
            "onUpdate:modelValue": ($event: any) => ((postback.types) = $event),
            options: _ctx.postbackTypes,
            color: "primary",
            type: "checkbox",
            inline: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("b", { class: "col-1 text-h6" }, "Postback", -1)),
          _createVNode(_component_q_input, {
            outlined: "",
            class: "col-7 q-mr-lg",
            placeholder: "Ссылка",
            modelValue: postback.url,
            "onUpdate:modelValue": ($event: any) => ((postback.url) = $event),
            error: !!_ctx.errors?.postbacks,
            "error-message": _ctx.errors?.postbacks
          }, null, 8, ["modelValue", "onUpdate:modelValue", "error", "error-message"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_btn, {
              onClick: _ctx.addPostback,
              round: "",
              color: "secondary",
              icon: "add"
            }, null, 8, ["onClick"])
          ])
        ])
      ]))
    }), 128)),
    _createVNode(_component_q_btn, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPostbackNoteDialog = !_ctx.showPostbackNoteDialog)),
      class: "col-1 q-mr-md q-mt-lg",
      round: "",
      icon: "help"
    }),
    _cache[3] || (_cache[3] = _createTextVNode(" Справка ")),
    _createVNode(_component_PostbackNoteDialog, {
      open: _ctx.showPostbackNoteDialog,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPostbackNoteDialog = false))
    }, null, 8, ["open"])
  ]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "flex row justify-around mobile-display-block" }
const _hoisted_3 = { class: "col-sm-12 col-lg-4" }
const _hoisted_4 = { class: "col-sm-12 col-lg-3" }
const _hoisted_5 = { class: "col-sm-12 col-lg-3" }
const _hoisted_6 = { class: "col-sm-12 col-lg-3" }
const _hoisted_7 = { class: "flex row justify-around mobile-display-block" }
const _hoisted_8 = { class: "col-sm-12 col-lg-3" }
const _hoisted_9 = { class: "col-sm-12 col-lg-4" }
const _hoisted_10 = { class: "col-sm-12 col-lg-4" }
const _hoisted_11 = { class: "row items-center" }
const _hoisted_12 = { class: "row items-center" }
const _hoisted_13 = { class: "q-mr-md" }
const _hoisted_14 = {
  key: 0,
  class: "row items-center"
}
const _hoisted_15 = { class: "q-mr-md" }
const _hoisted_16 = { class: "flex row justify-around mobile-display-block" }
const _hoisted_17 = { class: "col-sm-12 col-lg-4" }
const _hoisted_18 = { class: "col-sm-12 col-lg-4" }
const _hoisted_19 = { class: "flex row justify-around mobile-display-block" }
const _hoisted_20 = { class: "col-sm-12 col-lg-4" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col-sm-12 col-lg-6 offset-md-1 q-mt-xl" }
const _hoisted_23 = { class: "flex row" }
const _hoisted_24 = ["src", "error", "error-message"]
const _hoisted_25 = { class: "flex column" }
const _hoisted_26 = { class: "flex row q-mt-md" }
const _hoisted_27 = ["src", "error", "error-message"]
const _hoisted_28 = { class: "flex column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_editor = _resolveComponent("q-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[22] || (_cache[22] = _createElementVNode("b", null, "Название приложения", -1)),
        _createVNode(_component_q_input, {
          outlined: "",
          placeholder: "Fruit paradise",
          "model-value": _ctx.mobileApp.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = name => _ctx.update({ name })),
          error: !!_ctx.errors?.name,
          "error-message": _ctx.errors?.name
        }, null, 8, ["model-value", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[23] || (_cache[23] = _createElementVNode("b", null, "Статус", -1)),
        _createVNode(_component_q_select, {
          outlined: "",
          class: "q-mb-md",
          "model-value": _ctx.mobileApp.status,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = status => _ctx.update({ status })),
          options: _ctx.statuses,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          error: !!_ctx.errors?.status,
          "error-message": _ctx.errors?.status
        }, null, 8, ["model-value", "options", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[24] || (_cache[24] = _createElementVNode("b", null, "Config", -1)),
        _createVNode(_component_q_select, {
          outlined: "",
          class: "q-mb-md",
          "model-value": _ctx.mobileApp.configStatus,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = configStatus => _ctx.update({ configStatus })),
          options: _ctx.configStatuses,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          error: !!_ctx.errors?.configStatus,
          "error-message": _ctx.errors?.configStatus
        }, null, 8, ["model-value", "options", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[25] || (_cache[25] = _createElementVNode("b", null, "Маркет", -1)),
        _createVNode(_component_q_select, {
          outlined: "",
          "model-value": _ctx.mobileApp.appMarket,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = appMarket => _ctx.update({ appMarket })),
          options: _ctx.appMarkets,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          error: !!_ctx.errors?.appMarket,
          "error-message": _ctx.errors?.appMarket
        }, null, 8, ["model-value", "options", "error", "error-message"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _cache[26] || (_cache[26] = _createElementVNode("b", null, "Вертикаль", -1)),
        _createVNode(_component_q_select, {
          outlined: "",
          "model-value": _ctx.mobileApp.vertical,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = vertical => _ctx.update({ vertical })),
          options: _ctx.verticals,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          error: !!_ctx.errors?.vertical,
          "error-message": _ctx.errors?.vertical
        }, null, 8, ["model-value", "options", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[27] || (_cache[27] = _createElementVNode("b", null, "Редирект URL неизвестного трафика", -1)),
        _createVNode(_component_q_input, {
          outlined: "",
          hint: "Весь трафик для которого не найдено соответствующее правило и который должен уйти на оффер будет перенаправляться на эту ссылку.",
          placeholder: "https://1.tracker.gambeat.io/campaign",
          "model-value": _ctx.mobileApp.unknownTrafficRedirectUrl,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = 
            unknownTrafficRedirectUrl =>
              _ctx.update({
                unknownTrafficRedirectUrl:
                  unknownTrafficRedirectUrl?.trim() || null,
              })
          ),
          error: !!_ctx.errors?.unknownTrafficRedirectUrl,
          "error-message": _ctx.errors?.unknownTrafficRedirectUrl
        }, null, 8, ["model-value", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_q_checkbox, {
            class: "q-mr-md",
            label: "БЕСПЛАТНЫЕ ИНСТАЛЛЫ",
            "model-value": _ctx.mobileApp.freeInstalls,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = freeInstalls => _ctx.update({ freeInstalls }))
          }, null, 8, ["model-value"]),
          _createVNode(_component_q_checkbox, {
            label: "СРЕЗАТЬ VPN/PROXY",
            "model-value": _ctx.mobileApp.vpnCheckerOn,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = vpnCheckerOn => _ctx.update({ vpnCheckerOn }))
          }, null, 8, ["model-value"]),
          _createVNode(_component_q_checkbox, {
            "toggle-indeterminate": "",
            label: "Вкл/Выкл оффер (схема 4). По дефолту не задано",
            "model-value": _ctx.mobileApp.isDisplayOffer,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = isDisplayOffer => _ctx.update({ isDisplayOffer }))
          }, null, 8, ["model-value"]),
          _createVNode(_component_q_checkbox, {
            label: "Отправлять постбеки в клоаку при инсталле (схема 3)",
            "model-value": _ctx.mobileApp.sendInstallToCloak,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = 
              sendInstallToCloak => _ctx.update({ sendInstallToCloak })
            )
          }, null, 8, ["model-value"]),
          _cache[34] || (_cache[34] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("b", _hoisted_13, [
              _cache[29] || (_cache[29] = _createTextVNode("White Page URL")),
              _cache[30] || (_cache[30] = _createElementVNode("sup", null, "?", -1)),
              _createVNode(_component_q_tooltip, null, {
                default: _withCtx(() => _cache[28] || (_cache[28] = [
                  _createTextVNode("Опционально. Вайт. Используется если клоака в тдс, а не отдельно (Схема 3).")
                ])),
                _: 1
              })
            ]),
            _createVNode(_component_q_input, {
              outlined: "",
              dense: "",
              "input-style": "width: 400px !important",
              placeholder: "https://google.com",
              "model-value": _ctx.mobileApp.whitePageUrl,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = 
                whitePageUrl =>
                  _ctx.update({ whitePageUrl: whitePageUrl?.trim() || null })
              ),
              error: !!_ctx.errors?.whitePageUrl,
              "error-message": _ctx.errors?.whitePageUrl
            }, null, 8, ["model-value", "error", "error-message"])
          ]),
          _cache[35] || (_cache[35] = _createElementVNode("br", null, null, -1)),
          false
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("b", _hoisted_15, [
                  _cache[32] || (_cache[32] = _createTextVNode("Custom TDS URL (Binom, Keitaro, etc.)")),
                  _cache[33] || (_cache[33] = _createElementVNode("sup", null, "?", -1)),
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => _cache[31] || (_cache[31] = [
                      _createTextVNode("Опционально. Ссылка будет использоваться везде для приложения вместо дефолтной ссылки тдски.")
                    ])),
                    _: 1
                  })
                ]),
                _createVNode(_component_q_input, {
                  outlined: "",
                  dense: "",
                  "input-style": "width: 400px !important",
                  placeholder: "https://campaign.binom.org/qwsddsf",
                  "model-value": _ctx.mobileApp.customTdsUrl,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = 
                customTdsUrl =>
                  _ctx.update({ customTdsUrl: customTdsUrl?.trim() || null })
              ),
                  error: !!_ctx.errors?.customTdsUrl,
                  "error-message": _ctx.errors?.customTdsUrl
                }, null, 8, ["model-value", "error", "error-message"])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _cache[36] || (_cache[36] = _createElementVNode("b", null, "Открытые Гео (по дефолту \"Все\", кроме запрещенных, если не указано)", -1)),
        _createVNode(_component_q_select, {
          outlined: "",
          multiple: "",
          dense: "",
          "use-chips": "",
          ref: `rule-open-geos`,
          onBlur: _cache[12] || (_cache[12] = ($event: any) => (_ctx.clearFilter(`rule-open-geos`))),
          "model-value": _ctx.mobileApp.openGeoIds,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = openGeoIds => _ctx.update({ openGeoIds })),
          onFilter: _cache[14] || (_cache[14] = (val, upd) => _ctx.getGeos(val, upd)),
          options: _ctx.geos,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          "use-input": ""
        }, null, 8, ["model-value", "options"])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _cache[37] || (_cache[37] = _createElementVNode("b", null, "Запрещенные Гео", -1)),
        _createVNode(_component_q_select, {
          outlined: "",
          multiple: "",
          dense: "",
          "use-chips": "",
          ref: `rule-block-geos`,
          onBlur: _cache[15] || (_cache[15] = ($event: any) => (_ctx.clearFilter(`rule-block-geos`))),
          "model-value": _ctx.mobileApp.blockGeoIds,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = blockGeoIds => _ctx.update({ blockGeoIds })),
          onFilter: _cache[17] || (_cache[17] = (val, upd) => _ctx.getGeos(val, upd)),
          options: _ctx.geos,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          "use-input": ""
        }, null, 8, ["model-value", "options"])
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _cache[38] || (_cache[38] = _createElementVNode("b", null, "Ссылка на приложение в Google Play Market", -1)),
        _createVNode(_component_q_input, {
          outlined: "",
          "model-value": _ctx.mobileApp.playMarketUrl,
          "onUpdate:modelValue": _cache[18] || (_cache[18] = playMarketUrl => _ctx.update({ playMarketUrl })),
          error: !!_ctx.errors?.playMarketUrl,
          "error-message": _ctx.errors?.playMarketUrl
        }, null, 8, ["model-value", "error", "error-message"])
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _cache[41] || (_cache[41] = _createElementVNode("b", null, "Описание приложения", -1)),
        _createVNode(_component_q_editor, {
          class: _normalizeClass([
            'q-mb-md',
            'editor',
            { 'editor-error': !!_ctx.errors?.description },
          ]),
          "model-value": _ctx.mobileApp.description,
          "onUpdate:modelValue": _cache[19] || (_cache[19] = description => _ctx.update({ description })),
          toolbar: [
            ['bold', 'italic', 'underline'],
            ['unordered', 'ordered'],
            [
              {
                label: _ctx.$q.lang.editor.formatting,
                icon: _ctx.$q.iconSet.editor.formatting,
                list: 'no-icons',
                options: ['p', 'h3', 'h4', 'h5', 'h6'],
              },
            ],
          ]
        }, null, 8, ["class", "model-value", "toolbar"]),
        _withDirectives(_createElementVNode("p", { class: "error-message" }, _toDisplayString(_ctx.errors?.description), 513), [
          [_vShow, !!_ctx.errors?.description]
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("img", {
            class: "q-mr-md photo",
            src: `${_ctx.storageUrl + '/' + _ctx.mobileApp.image}`,
            error: !!_ctx.errors?.image,
            "error-message": _ctx.errors?.image
          }, null, 8, _hoisted_24),
          _createElementVNode("div", _hoisted_25, [
            _cache[39] || (_cache[39] = _createElementVNode("b", null, "Иконка приложения", -1)),
            _createElementVNode("input", {
              type: "file",
              onInput: _cache[20] || (_cache[20] = ($event: any) => (_ctx.update({ image: $event.target.files[0] })))
            }, null, 32)
          ])
        ]),
        _withDirectives(_createElementVNode("p", { class: "error-message" }, _toDisplayString(_ctx.errors?.image), 513), [
          [_vShow, !!_ctx.errors?.image]
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("img", {
            class: "q-mr-md photo",
            src: `${_ctx.storageUrl + '/' + _ctx.mobileApp.playMarketImage}`,
            error: !!_ctx.errors?.playMarketImage,
            "error-message": _ctx.errors?.playMarketImage
          }, null, 8, _hoisted_27),
          _createElementVNode("div", _hoisted_28, [
            _cache[40] || (_cache[40] = _createElementVNode("b", null, "Скрин приложения в PlayMarket", -1)),
            _createElementVNode("input", {
              type: "file",
              onInput: _cache[21] || (_cache[21] = ($event: any) => (_ctx.update({ playMarketImage: $event.target.files[0] })))
            }, null, 32)
          ])
        ]),
        _withDirectives(_createElementVNode("p", { class: "error-message" }, _toDisplayString(_ctx.errors?.playMarketImage), 513), [
          [_vShow, !!_ctx.errors?.playMarketImage]
        ])
      ])
    ])
  ]))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex row" }
const _hoisted_2 = { class: "flex row" }
const _hoisted_3 = { class: "flex col-5 column" }
const _hoisted_4 = { class: "flex row" }
const _hoisted_5 = { class: "flex col-5 column q-mr-sm" }
const _hoisted_6 = { class: "flex col-5 column" }
const _hoisted_7 = {
  key: 0,
  class: "q-mt-lg q-ml-lg text-grey"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  class: "row",
  style: {"width":"max-content"}
}
const _hoisted_10 = {
  class: "row",
  style: {"width":"max-content"}
}
const _hoisted_11 = ["alt", "src"]
const _hoisted_12 = {
  key: 0,
  class: "row",
  style: {"width":"max-content"}
}
const _hoisted_13 = ["alt", "src"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showDialog) = $event)),
    "full-width": "",
    onKeyup: [
      _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.$emit('close')), ["enter"])),
      _cache[10] || (_cache[10] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
    ]
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_q_spinner, {
            key: 0,
            color: "primary",
            size: "3em",
            thickness: 2
          }))
        : (_openBlock(), _createBlock(_component_q_card, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { horizontal: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createElementVNode("b", null, "Информация о конверсии", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
                        default: _withCtx(() => [
                          _cache[12] || (_cache[12] = _createTextVNode(" Название потока ")),
                          _createVNode(_component_q_input, {
                            dense: "",
                            readonly: "",
                            modelValue: _ctx.conversion.campaignName,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.conversion.campaignName) = $event)),
                            autofocus: "",
                            onKeyup: [
                              _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('close')), ["enter"])),
                              _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
                            ]
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
                        default: _withCtx(() => [
                          _cache[13] || (_cache[13] = _createTextVNode(" ID потока ")),
                          _createVNode(_component_q_input, {
                            dense: "",
                            readonly: "",
                            modelValue: _ctx.conversion.campaignId,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.conversion.campaignId) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
                        default: _withCtx(() => [
                          _cache[14] || (_cache[14] = _createTextVNode(" Ссылка потока ")),
                          _createVNode(_component_q_input, {
                            dense: "",
                            readonly: "",
                            modelValue: _ctx.campaignUrl,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.campaignUrl) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
                        default: _withCtx(() => [
                          _cache[15] || (_cache[15] = _createTextVNode(" SID ")),
                          _createVNode(_component_q_input, {
                            dense: "",
                            readonly: "",
                            "model-value": _ctx.getSids(_ctx.conversion.properties || [])
                          }, null, 8, ["model-value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
                        default: _withCtx(() => [
                          _cache[16] || (_cache[16] = _createTextVNode(" Лендинг ")),
                          _createVNode(_component_q_input, {
                            dense: "",
                            readonly: "",
                            modelValue: _ctx.conversion.promoName,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.conversion.promoName) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
                        default: _withCtx(() => [
                          _cache[17] || (_cache[17] = _createTextVNode(" Источник ")),
                          _createVNode(_component_q_input, {
                            dense: "",
                            readonly: "",
                            modelValue: _ctx.conversion.trafficSourceName,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.conversion.trafficSourceName) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            _createElementVNode("span", null, [
                              _createTextVNode(" Реферальные начисления рефоводу, " + _toDisplayString(_ctx.currencies[_ctx.referralCurrency] || '$'), 1),
                              _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
                              _createVNode(_component_q_input, {
                                dense: "",
                                readonly: "",
                                "model-value": 
                    _ctx.conversion.referralAmount
                      ? _ctx.format(_ctx.conversion.referralAmount)
                      : '—'
                  
                              }, null, 8, ["model-value"])
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("span", _hoisted_3, [
                              _cache[19] || (_cache[19] = _createTextVNode(" Сумма FTD, $")),
                              _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1)),
                              _createVNode(_component_q_input, {
                                dense: "",
                                readonly: "",
                                "model-value": 
                    _ctx.conversion.ftdSum ? _ctx.format(_ctx.conversion.ftdSum) : '—'
                  
                              }, null, 8, ["model-value"])
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("span", _hoisted_5, [
                              _cache[21] || (_cache[21] = _createTextVNode(" Количество RD")),
                              _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
                              _createVNode(_component_q_input, {
                                dense: "",
                                readonly: "",
                                "model-value": Number(_ctx.conversion.rdCount) || '—'
                              }, null, 8, ["model-value"])
                            ]),
                            _createElementVNode("span", _hoisted_6, [
                              _cache[23] || (_cache[23] = _createTextVNode(" Сумма RD, $")),
                              _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1)),
                              _createVNode(_component_q_input, {
                                dense: "",
                                readonly: "",
                                "model-value": _ctx.format(_ctx.conversion.rdSum)
                              }, null, 8, ["model-value"])
                            ])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_separator, { vertical: "" }),
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, {
                        class: "row justify-between items-center",
                        style: {"flex":"1"}
                      }, {
                        default: _withCtx(() => [
                          _cache[25] || (_cache[25] = _createElementVNode("b", null, "CHANGELOG", -1)),
                          _createElementVNode("div", null, [
                            _createVNode(_component_q_btn, {
                              "no-caps": "",
                              icon: "close",
                              flat: "",
                              rounded: "",
                              onClick: _ctx.onClose
                            }, null, 8, ["onClick"])
                          ])
                        ]),
                        _: 1
                      }),
                      (!_ctx.conversion.changelogs?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, " No changes "))
                        : (_openBlock(), _createBlock(_component_q_table, {
                            key: 1,
                            dense: "",
                            class: "q-ml-md",
                            rows: _ctx.conversion.changelogs,
                            columns: _ctx.columns,
                            "row-key": "id",
                            separator: "cell",
                            loading: _ctx.loading
                          }, {
                            "body-cell-createdAt": _withCtx((props) => [
                              _createVNode(_component_q_td, { props: props }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(`${_ctx.frmtDate(props.row.createdAt)}`), 1)
                                ]),
                                _: 2
                              }, 1032, ["props"])
                            ]),
                            "body-cell-updatedById": _withCtx((props) => [
                              _createVNode(_component_q_td, { props: props }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_IconWithName, {
                                    id: props.row.updatedById,
                                    name: _ctx.getAdminEmail(props.row.updatedById),
                                    size: "20px",
                                    color: "primary",
                                    icon: "manage_accounts",
                                    entity: "manager"
                                  }, null, 8, ["id", "name"])
                                ]),
                                _: 2
                              }, 1032, ["props"])
                            ]),
                            "body-cell-status": _withCtx((props) => [
                              _createVNode(_component_q_td, { props: props }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", null, _toDisplayString(`${_ctx.leadStatuses[props.row.prevStatus]} `), 1),
                                  (props.row.prevStatus !== props.row.status)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, " => " + _toDisplayString(` ${_ctx.leadStatuses[props.row.status]}`), 1))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["props"])
                            ]),
                            "body-cell-geoId": _withCtx((props) => [
                              _createVNode(_component_q_td, { props: props }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("span", _hoisted_10, [
                                      (_ctx.getGeoById(props.row.prevGeoId)?.isoCode)
                                        ? (_openBlock(), _createElementBlock("img", {
                                            key: 0,
                                            class: "q-mr-sm",
                                            height: "10",
                                            width: "15",
                                            alt: _ctx.getGeoById(props.row.prevGeoId)?.isoCode,
                                            src: 
                        `http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                          _ctx.getGeoById(props.row.prevGeoId)?.isoCode
                        }.svg`
                      
                                          }, null, 8, _hoisted_11))
                                        : _createCommentVNode("", true),
                                      _createTextVNode(_toDisplayString(_ctx.getGeoById(props.row.prevGeoId)?.isoCode), 1)
                                    ]),
                                    (props.row.prevGeoId !== props.row.geoId)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                          _cache[26] || (_cache[26] = _createTextVNode(" => ")),
                                          (_ctx.getGeoById(props.row.geoId)?.isoCode)
                                            ? (_openBlock(), _createElementBlock("img", {
                                                key: 0,
                                                class: "q-mr-sm",
                                                height: "10",
                                                width: "15",
                                                alt: _ctx.getGeoById(props.row.geoId)?.isoCode,
                                                src: 
                        `http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                          _ctx.getGeoById(props.row.geoId)?.isoCode
                        }.svg`
                      
                                              }, null, 8, _hoisted_13))
                                            : _createCommentVNode("", true),
                                          _createTextVNode(_toDisplayString(_ctx.getGeoById(props.row.geoId)?.isoCode), 1)
                                        ]))
                                      : _createCommentVNode("", true)
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["props"])
                            ]),
                            "body-cell-type": _withCtx((props) => [
                              _createVNode(_component_q_td, { props: props }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", null, _toDisplayString(`${_ctx.rewardTypes[props.row.prevType]} `), 1),
                                  (props.row.prevType !== props.row.type)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, " => " + _toDisplayString(` ${_ctx.rewardTypes[props.row.type]}`), 1))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["props"])
                            ]),
                            "body-cell-amount": _withCtx((props) => [
                              _createVNode(_component_q_td, { props: props }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", null, _toDisplayString(`${props.row.prevValueAmount} ${
                    _ctx.currencies[props.row.prevValueCurrency]
                  } `), 1),
                                  (props.row.valueAmount !== props.row.prevValueAmount)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, " => " + _toDisplayString(` ${props.row.valueAmount} ${
                      _ctx.currencies[props.row.valueCurrency]
                    }`), 1))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["props"])
                            ]),
                            "body-cell-advertiserAmount": _withCtx((props) => [
                              _createVNode(_component_q_td, { props: props }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", null, _toDisplayString(`${props.row.prevAdvertiserValueAmount} ${
                    _ctx.currencies[props.row.prevAdvertiserValueCurrency]
                  } `), 1),
                                  (
                    props.row.advertiserValueAmount !==
                      props.row.prevAdvertiserValueAmount
                  )
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, " => " + _toDisplayString(` ${props.row.advertiserValueAmount} ${
                      _ctx.currencies[props.row.advertiserValueCurrency]
                    }`), 1))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["props"])
                            ]),
                            "body-cell-holdInDays": _withCtx((props) => [
                              _createVNode(_component_q_td, { props: props }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", null, _toDisplayString(`${props.row.prevHoldInDays || 0} `), 1),
                                  (props.row.holdInDays !== props.row.prevHoldInDays)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, " => " + _toDisplayString(` ${props.row.holdInDays || 0}`), 1))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["props"])
                            ]),
                            _: 1
                          }, 8, ["rows", "columns", "loading"]))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    flat: "",
                    label: "OK",
                    color: "primary",
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('close')))
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
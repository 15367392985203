import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "q-ml-xl"
}
const _hoisted_2 = { class: "flex row items-center q-mb-md" }
const _hoisted_3 = { class: "flex column" }
const _hoisted_4 = { class: "flex row" }
const _hoisted_5 = { class: "flex column" }
const _hoisted_6 = { class: "flex column" }
const _hoisted_7 = { class: "flex column self-center" }
const _hoisted_8 = { class: "q-pt-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_center = _resolveComponent("center")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_q_field = _resolveComponent("q-field")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_center, {
          key: 0,
          class: "q-mt-lg"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_spinner, {
              color: "primary",
              size: "3em",
              thickness: 2
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_q_btn, {
              class: "q-mr-sm",
              onClick: _ctx.addRule,
              round: "",
              color: "secondary",
              icon: "add"
            }, null, 8, ["onClick"]),
            _cache[0] || (_cache[0] = _createElementVNode("b", null, "Добавить", -1))
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rules, (rule, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "flex row q-mb-md mobile-display-block",
              key: index
            }, [
              _createElementVNode("div", _hoisted_3, [
                _cache[2] || (_cache[2] = _createElementVNode("div", null, [
                  _createTextVNode("Время отправки "),
                  _createElementVNode("sup", null, "?")
                ], -1)),
                _createVNode(_component_q_tooltip, {
                  "max-width": "400px",
                  anchor: "bottom left",
                  self: "top middle",
                  class: "q-tooltip"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("Время отправки по GMT+1, учитывайте часовой пояс для Вашего гео")
                  ])),
                  _: 1
                }),
                _createVNode(_component_q_input, {
                  filled: "",
                  dense: "",
                  "model-value": rule.time,
                  "onUpdate:modelValue": time => _ctx.updateField({ ...rule, time }, index),
                  placeholder: "Например: 14:20, 18:13, 20:20"
                }, null, 8, ["model-value", "onUpdate:modelValue"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex items-center q-ml-lg q-pl-sm" }, "Дни:", -1)),
                  _createVNode(_component_q_field, {
                    class: "max-content",
                    borderless: "",
                    dark: "",
                    dense: "",
                    error: !!_ctx.errors?.days,
                    "error-message": _ctx.errors?.days
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_option_group, {
                        class: "q-px-lg",
                        modelValue: rule.days,
                        "onUpdate:modelValue": ($event: any) => ((rule.days) = $event),
                        options: _ctx.days,
                        color: "primary",
                        type: "checkbox",
                        inline: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                    ]),
                    _: 2
                  }, 1032, ["error", "error-message"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex items-center q-ml-lg q-pl-sm" }, "События:", -1)),
                  _createVNode(_component_q_field, {
                    class: "max-content",
                    borderless: "",
                    dark: "",
                    dense: "",
                    error: !!_ctx.errors?.types,
                    "error-message": _ctx.errors?.types
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_option_group, {
                        class: "q-px-lg",
                        modelValue: rule.types,
                        "onUpdate:modelValue": ($event: any) => ((rule.types) = $event),
                        options: _ctx.types,
                        color: "primary",
                        type: "checkbox",
                        inline: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                    ]),
                    _: 2
                  }, 1032, ["error", "error-message"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_btn, {
                      onClick: ($event: any) => (_ctx.deleteRule(index)),
                      outline: "",
                      round: "",
                      color: "primary",
                      icon: "delete"
                    }, null, 8, ["onClick"])
                  ])
                ])
              ])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_q_btn, {
              disable: _ctx.isSaving,
              loading: _ctx.isSaving,
              label: "Сохранить",
              onClick: _ctx.onSubmit,
              type: "submit",
              color: "primary",
              "no-caps": ""
            }, null, 8, ["disable", "loading", "onClick"])
          ])
        ]))
  ]))
}
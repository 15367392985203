import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-mb-lg" }
const _hoisted_2 = { class: "row justify-between full-width" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl" }, {
    default: _withCtx(() => [
      _createVNode(_component_center, null, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createElementVNode("h6", null, "Курсы валют", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_table, {
          rows: _ctx.filteredRows,
          columns: _ctx.columns,
          "row-key": "id",
          separator: "cell",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pagination) = $event)),
          loading: _ctx.loading,
          onRequest: _ctx.onRequest,
          "binary-state-sort": ""
        }, {
          top: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_q_select, {
                  label: "Валюта",
                  modelValue: _ctx.selectedCurrencies,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCurrencies) = $event)),
                  outlined: "",
                  dense: "",
                  "emit-value": "",
                  "map-options": "",
                  "use-chips": "",
                  multiple: "",
                  class: "q-mr-md",
                  style: {"min-width":"150px !important"},
                  options: _ctx.currencies,
                  "option-value": "id",
                  "option-label": "name"
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_q_input, {
                  outlined: "",
                  type: "number",
                  class: "q-mr-md",
                  label: "Коррекция валют, %",
                  "model-value": _ctx.correctedPercent,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = val => _ctx.onCorrectPercent(val)),
                  dense: ""
                }, null, 8, ["model-value"]),
                _createVNode(_component_q_checkbox, {
                  label: "Показать только базовые валюты",
                  modelValue: _ctx.showMainCurrencies,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showMainCurrencies) = $event)),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onRequest(null)))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  label: "Сохранить",
                  color: "primary",
                  onClick: _ctx.save,
                  loading: _ctx.isSaving
                }, null, 8, ["onClick", "loading"])
              ])
            ])
          ]),
          "body-cell-value": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_input, {
                  type: "number",
                  outlined: "",
                  disable: props.row.type === _ctx.currencyCorrectTypesEnum.Auto,
                  "model-value": props.row.value,
                  "onUpdate:modelValue": val => _ctx.onChangeAmount(val, props.row),
                  dense: ""
                }, null, 8, ["disable", "model-value", "onUpdate:modelValue"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-correctedValue": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_input, {
                  disable: "",
                  outlined: "",
                  modelValue: props.row.correctedValue,
                  "onUpdate:modelValue": ($event: any) => ((props.row.correctedValue) = $event),
                  modelModifiers: { number: true },
                  dense: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-type": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_select, {
                  modelValue: props.row.type,
                  "onUpdate:modelValue": ($event: any) => ((props.row.type) = $event),
                  outlined: "",
                  dense: "",
                  "emit-value": "",
                  "map-options": "",
                  options: _ctx.currencyCorrectTypes,
                  "option-value": "id",
                  "option-label": "name"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-isDefault": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  modelValue: props.row.isDefault,
                  "onUpdate:modelValue": ($event: any) => ((props.row.isDefault) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
      ])
    ]),
    _: 1
  }))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row q-mt-md"
}
const _hoisted_2 = { class: "flex items-center justify-center" }
const _hoisted_3 = {
  key: 1,
  class: "row q-ma-md"
}
const _hoisted_4 = {
  key: 0,
  class: "col-2"
}
const _hoisted_5 = {
  key: 1,
  class: "col-2"
}
const _hoisted_6 = {
  key: 2,
  class: "col-2"
}
const _hoisted_7 = { class: "row items-center justify-between" }
const _hoisted_8 = {
  key: 3,
  class: "col-2"
}
const _hoisted_9 = { class: "row items-center justify-between" }
const _hoisted_10 = {
  key: 4,
  class: "col-2"
}
const _hoisted_11 = { class: "row items-center justify-between" }
const _hoisted_12 = {
  key: 5,
  class: "col-2"
}
const _hoisted_13 = {
  key: 6,
  class: "col-2"
}
const _hoisted_14 = { class: "row items-center justify-between" }
const _hoisted_15 = {
  key: 7,
  class: "col-2"
}
const _hoisted_16 = { class: "row items-center justify-between" }
const _hoisted_17 = {
  key: 8,
  class: "col-2"
}
const _hoisted_18 = { class: "row items-center justify-between" }
const _hoisted_19 = {
  key: 9,
  class: "col-2"
}
const _hoisted_20 = { class: "row items-center justify-between" }
const _hoisted_21 = {
  key: 10,
  class: "col-2"
}
const _hoisted_22 = { class: "row items-center justify-between" }
const _hoisted_23 = {
  key: 11,
  class: "col-2"
}
const _hoisted_24 = { class: "row items-center justify-between" }
const _hoisted_25 = { class: "flex items-center" }
const _hoisted_26 = ["src"]
const _hoisted_27 = ["src"]
const _hoisted_28 = {
  key: 12,
  class: "col-2"
}
const _hoisted_29 = { class: "row items-center justify-between" }
const _hoisted_30 = ["src"]
const _hoisted_31 = {
  key: 1,
  class: "q-mr-sm"
}
const _hoisted_32 = {
  key: 13,
  class: "col-2"
}
const _hoisted_33 = { class: "row items-center justify-between" }
const _hoisted_34 = {
  key: 14,
  class: "col-2"
}
const _hoisted_35 = { class: "row items-center justify-between" }
const _hoisted_36 = {
  key: 15,
  class: "col-2"
}
const _hoisted_37 = { class: "row items-center justify-between" }
const _hoisted_38 = {
  key: 16,
  class: "col-2"
}
const _hoisted_39 = { class: "row items-center justify-between" }
const _hoisted_40 = {
  key: 17,
  class: "col-2"
}
const _hoisted_41 = { class: "row items-center justify-between" }
const _hoisted_42 = {
  key: 18,
  class: "col-2"
}
const _hoisted_43 = { class: "row items-center justify-between" }
const _hoisted_44 = {
  key: 19,
  class: "col-2"
}
const _hoisted_45 = { class: "row items-center justify-between" }
const _hoisted_46 = {
  key: 20,
  class: "col-2"
}
const _hoisted_47 = { class: "row items-center justify-between" }
const _hoisted_48 = {
  key: 21,
  class: "col-2"
}
const _hoisted_49 = { class: "row items-center justify-between" }
const _hoisted_50 = {
  key: 22,
  class: "col-2"
}
const _hoisted_51 = { class: "row items-center justify-between" }
const _hoisted_52 = {
  key: 23,
  class: "col-2"
}
const _hoisted_53 = {
  key: 24,
  class: "col-2"
}
const _hoisted_54 = { class: "row items-center justify-between" }
const _hoisted_55 = {
  key: 25,
  class: "col-2"
}
const _hoisted_56 = { class: "row items-center no-wrap" }
const _hoisted_57 = {
  key: 26,
  class: "col-2"
}
const _hoisted_58 = { class: "row items-center justify-between" }
const _hoisted_59 = {
  key: 27,
  class: "col-2"
}
const _hoisted_60 = { class: "row items-center justify-between" }
const _hoisted_61 = {
  key: 28,
  class: "col-2"
}
const _hoisted_62 = { class: "row items-center justify-between" }
const _hoisted_63 = {
  key: 29,
  class: "col-2"
}
const _hoisted_64 = {
  key: 30,
  class: "col-2"
}
const _hoisted_65 = {
  key: 31,
  class: "col-2"
}
const _hoisted_66 = {
  key: 32,
  class: "col-2"
}
const _hoisted_67 = {
  key: 33,
  class: "col-2"
}
const _hoisted_68 = {
  key: 34,
  class: "col-2"
}
const _hoisted_69 = { class: "row" }
const _hoisted_70 = {
  key: 35,
  class: "col-2"
}
const _hoisted_71 = {
  key: 36,
  class: "col-2"
}
const _hoisted_72 = {
  key: 37,
  class: "col-2"
}
const _hoisted_73 = {
  key: 38,
  class: "col-2"
}
const _hoisted_74 = { class: "q-mr-md q-mt-md self-center" }
const _hoisted_75 = {
  key: 39,
  class: "q-mr-md q-mt-md self-center"
}
const _hoisted_76 = { class: "q-mr-md q-mt-md self-center" }
const _hoisted_77 = {
  key: 40,
  class: "q-mr-md self-center q-mt-md"
}
const _hoisted_78 = {
  key: 41,
  class: "q-mr-md self-center q-mt-md"
}
const _hoisted_79 = {
  key: 42,
  class: "self-center q-mt-md"
}
const _hoisted_80 = {
  key: 43,
  class: "self-center q-mt-md"
}
const _hoisted_81 = {
  key: 44,
  class: "self-center q-mt-md"
}
const _hoisted_82 = {
  key: 45,
  class: "self-center q-mt-md"
}
const _hoisted_83 = {
  key: 46,
  class: "q-ml-sm q-mt-md self-center"
}
const _hoisted_84 = {
  key: 47,
  class: "q-ml-sm q-mt-md self-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_FiltersDialog = _resolveComponent("FiltersDialog")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_SavePresetDialog = _resolveComponent("SavePresetDialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$q.screen.width < 900)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_q_btn, {
              "no-caps": "",
              class: "q-my-md q-mr-md",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFiltersDialog = !_ctx.showFiltersDialog)),
              outline: "",
              color: "secondary",
              icon: "tune",
              label: "Фильтры"
            })
          ]),
          (_ctx.showFiltersDialog)
            ? (_openBlock(), _createBlock(_component_FiltersDialog, {
                key: 0,
                open: _ctx.showFiltersDialog,
                currentFilters: _ctx.filters,
                onChangeFilters: _ctx.submitMobileAll,
                onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFiltersDialog = false)),
                tab: _ctx.tab
              }, null, 8, ["open", "currentFilters", "onChangeFilters", "tab"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (
        !_ctx.hideForConversions ||
          (_ctx.hideForWallets &&
            _ctx.hideForStatistics &&
            _ctx.hideForAdvertisers &&
            _ctx.hideForOffers &&
            _ctx.hideForPayouts &&
            _ctx.hideForFinances &&
            _ctx.tab !== 'payouts')
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _cache[106] || (_cache[106] = _createElementVNode("b", null, "Поиск:", -1)),
                _createVNode(_component_q_input, {
                  dense: "",
                  outlined: "",
                  debounce: "500",
                  autogrow: "",
                  clearable: "",
                  class: "col-4 q-mr-lg",
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event))
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideForConversions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _cache[109] || (_cache[109] = _createElementVNode("b", null, "Клик IDs ", -1)),
                _createElementVNode("sup", null, [
                  _cache[108] || (_cache[108] = _createTextVNode(" ? ")),
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => _cache[107] || (_cache[107] = [
                      _createTextVNode("Вставьте несколько click id. Каждый click id с новой строки")
                    ])),
                    _: 1
                  })
                ]),
                _createVNode(_component_q_input, {
                  dense: "",
                  clearable: "",
                  outlined: "",
                  debounce: "500",
                  autogrow: "",
                  class: "col-4 q-mr-lg",
                  modelValue: _ctx.redirectEventIdSearch,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.redirectEventIdSearch) = $event))
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForFinancesReport &&
          _ctx.hideForEditedOffers &&
          _ctx.hideForNewOffers &&
          _ctx.hideForAffiliates &&
          _ctx.hideForPayouts &&
          _ctx.hideForLogs &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForStatistics &&
          _ctx.hideForFinances &&
          _ctx.hideForOffers
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _cache[110] || (_cache[110] = _createElementVNode("b", null, "Оффер:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeOfferId,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (
            _ctx.onChangeFilters('includeOfferId', !_ctx.filters.includeOfferId)
          )),
                    "toggle-color": _ctx.filters.includeOfferId ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  clearable: "",
                  modelValue: _ctx.filters.offerId,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filters.offerId) = $event)),
                  onBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onChangeFilters('offerId', _ctx.filters.offerId))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.offers,
                  onFilter: _ctx.getOffers,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_IconWithName, {
                              id: scope.opt.id,
                              name: scope.opt.name,
                              size: "20px",
                              color: "orange-7",
                              icon: "description",
                              entity: "offer"
                            }, null, 8, ["id", "name"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (
        (!_ctx.hideForAffReports || _ctx.hideForFinancesReport) &&
          _ctx.hideForWallets &&
          _ctx.hideForEditedOffers &&
          _ctx.hideForNewOffers &&
          (!_ctx.hideForStatistics ||
            !_ctx.hideForFinances ||
            !_ctx.hideForLogs ||
            !_ctx.hideForOffers)
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _cache[111] || (_cache[111] = _createElementVNode("b", null, "Оффер:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    modelValue: _ctx.filters.includeOfferIds,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filters.includeOfferIds) = $event)),
                    "toggle-color": _ctx.filters.includeOfferIds ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["modelValue", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.offerIds,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filters.offerIds) = $event)),
                  onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onChangeFilters('offerIds', _ctx.filters.offerIds))),
                  ref: "offerIds",
                  outlined: "",
                  multiple: "",
                  "use-chips": "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.offers,
                  onFilter: _ctx.getOffers,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_IconWithName, {
                              id: scope.opt.id,
                              name: scope.opt.name,
                              size: "20px",
                              color: "orange-7",
                              icon: "description",
                              entity: "offer"
                            }, null, 8, ["id", "name"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForStatistics &&
          _ctx.hideForConversions &&
          _ctx.hideForPayouts &&
          _ctx.hideForAffiliates &&
          _ctx.hideForOffers &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForFinances &&
          _ctx.tab !== 'limits'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[112] || (_cache[112] = _createElementVNode("b", null, "Поток:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeCampaignId,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (
            _ctx.onChangeFilters('includeCampaignId', !_ctx.filters.includeCampaignId)
          )),
                    "toggle-color": _ctx.filters.includeCampaignId ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.campaignId,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.filters.campaignId) = $event)),
                  onBlur: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onChangeFilters('campaignId', _ctx.filters.campaignId))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.campaigns,
                  onFilter: _ctx.getCampaigns,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, null, 8, ["modelValue", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForStatistics &&
          _ctx.hideForConversions &&
          _ctx.hideForPayouts &&
          _ctx.hideForAffiliates &&
          _ctx.hideForOffers &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForCampaigns &&
          _ctx.hideForClickLogs &&
          _ctx.hideForFinances
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _cache[113] || (_cache[113] = _createElementVNode("b", null, "Событие:", -1)),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.eventType,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.filters.eventType) = $event)),
                  onBlur: _cache[14] || (_cache[14] = ($event: any) => (_ctx.onChangeFilters('eventType', _ctx.filters.eventType))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.postbackEventTypes,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (
        !_ctx.hideForEditedOffers ||
          !_ctx.hideForNewOffers ||
          (_ctx.hideForWallets &&
            _ctx.hideForOffers &&
            _ctx.hideForAdvertisers &&
            _ctx.hideForStatistics &&
            _ctx.hideForFinances &&
            _ctx.tab !== 'payouts')
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _cache[114] || (_cache[114] = _createElementVNode("b", null, "Аффилейт:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    modelValue: _ctx.filters.includeAffiliateId,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.filters.includeAffiliateId) = $event)),
                    "toggle-color": _ctx.filters.includeAffiliateId ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["modelValue", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  clearable: "",
                  modelValue: _ctx.filters.affiliateId,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.filters.affiliateId) = $event)),
                  onFilter: _ctx.getAffiliates,
                  onBlur: _cache[17] || (_cache[17] = ($event: any) => (_ctx.onChangeFilters('affiliateId', _ctx.filters.affiliateId))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.affiliates,
                  "option-value": "id",
                  "option-label": "email",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_IconWithName, {
                              id: scope.opt.id,
                              name: scope.opt.email,
                              size: "20px",
                              color: "secondary",
                              icon: "account_box",
                              entity: "affiliate"
                            }, null, 8, ["id", "name"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "onFilter", "options"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideForStatistics || !_ctx.hideForFinances)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _cache[115] || (_cache[115] = _createElementVNode("b", null, "Аффилейт:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    modelValue: _ctx.filters.includeAffiliateIds,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.filters.includeAffiliateIds) = $event)),
                    "toggle-color": _ctx.filters.includeAffiliateIds ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["modelValue", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.affiliateIds,
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.filters.affiliateIds) = $event)),
                  onFilter: _ctx.getAffiliates,
                  onBlur: _cache[20] || (_cache[20] = ($event: any) => (_ctx.onChangeFilters('affiliateIds', _ctx.filters.affiliateIds))),
                  outlined: "",
                  ref: "affiliateIds",
                  multiple: "",
                  "use-chips": "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.affiliates,
                  "option-value": "id",
                  "option-label": "email",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_IconWithName, {
                              id: scope.opt.id,
                              name: scope.opt.email,
                              size: "20px",
                              color: "secondary",
                              icon: "account_box",
                              entity: "affiliate"
                            }, null, 8, ["id", "name"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "onFilter", "options"])
              ]))
            : _createCommentVNode("", true),
          (
        ((!_ctx.hideForStatistics && _ctx.tab !== 'referral-stats') ||
          (!_ctx.hideForCampaigns && _ctx.tab !== 'limits') ||
          !_ctx.hideForLogs) &&
          !_ctx.user?.isLimitedAccess
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _cache[116] || (_cache[116] = _createElementVNode("b", null, "Фантом. Афф:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeFantomAffiliateIds,
                    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (
            _ctx.onChangeFilters(
              'includeFantomAffiliateIds',
              !_ctx.filters.includeFantomAffiliateIds,
            )
          )),
                    "toggle-color": _ctx.filters.includeFantomAffiliateIds ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.fantomAffiliateIds,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.filters.fantomAffiliateIds) = $event)),
                  onFilter: _cache[23] || (_cache[23] = (val, fn) => _ctx.getAffiliates(val, fn, { fantom: true })),
                  onBlur: _cache[24] || (_cache[24] = ($event: any) => (
          _ctx.onChangeFilters('fantomAffiliateIds', _ctx.filters.fantomAffiliateIds)
        )),
                  outlined: "",
                  ref: "fantomAffiliateIds",
                  multiple: "",
                  "use-chips": "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.affiliates,
                  "option-value": "id",
                  "option-label": "email",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_IconWithName, {
                              id: scope.opt.id,
                              name: scope.opt.email,
                              size: "20px",
                              color: "secondary",
                              icon: "account_box",
                              entity: "affiliate"
                            }, null, 8, ["id", "name"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForEditedOffers &&
          _ctx.hideForNewOffers &&
          _ctx.hideForAffiliates &&
          _ctx.hideForPayouts &&
          _ctx.hideForCampaigns &&
          _ctx.hideForFinances &&
          _ctx.hideForStatistics &&
          _ctx.hideForOffers
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _cache[117] || (_cache[117] = _createElementVNode("b", null, "Рекламодатель:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    modelValue: _ctx.filters.includeAdvertiserId,
                    "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.filters.includeAdvertiserId) = $event)),
                    "toggle-color": _ctx.filters.includeAdvertiserId ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["modelValue", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  clearable: "",
                  modelValue: _ctx.filters.advertiserId,
                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.filters.advertiserId) = $event)),
                  onBlur: _cache[27] || (_cache[27] = ($event: any) => (_ctx.onChangeFilters('advertiserId', _ctx.filters.advertiserId))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.advertisers,
                  onFilter: _ctx.getAdvertisers,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_IconWithName, {
                              id: scope.opt.id,
                              name: scope.opt.name,
                              size: "20px",
                              color: "primary",
                              icon: "people",
                              entity: "advertiser"
                            }, null, 8, ["id", "name"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForEditedOffers &&
          _ctx.hideForNewOffers &&
          ((!_ctx.hideForStatistics && _ctx.tab !== 'referral-stats') ||
            !_ctx.hideForOffers ||
            !_ctx.hideForFinances ||
            (!_ctx.hideForCampaigns && _ctx.tab !== 'limits'))
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _cache[118] || (_cache[118] = _createElementVNode("b", null, "Рекламодатель:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    modelValue: _ctx.filters.includeAdvertiserIds,
                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.filters.includeAdvertiserIds) = $event)),
                    "toggle-color": _ctx.filters.includeAdvertiserIds ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["modelValue", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.advertiserIds,
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.filters.advertiserIds) = $event)),
                  onBlur: _cache[30] || (_cache[30] = ($event: any) => (_ctx.onChangeFilters('advertiserIds', _ctx.filters.advertiserIds))),
                  outlined: "",
                  ref: "advertiserIds",
                  multiple: "",
                  "use-chips": "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.advertisers,
                  onFilter: _ctx.getAdvertisers,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_IconWithName, {
                              id: scope.opt.id,
                              name: scope.opt.name,
                              size: "20px",
                              color: "primary",
                              icon: "people",
                              entity: "advertiser"
                            }, null, 8, ["id", "name"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForPayouts &&
          _ctx.hideForLogs &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForStatistics &&
          _ctx.hideForFinances &&
          _ctx.hideForOffers &&
          _ctx.tab !== 'payouts'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _cache[119] || (_cache[119] = _createElementVNode("b", null, "ГЕО:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeGeoId,
                    "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => (
            _ctx.onChangeFilters('includeGeoId', !_ctx.filters.includeGeoId)
          )),
                    "toggle-color": _ctx.filters.includeGeoId ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  clearable: "",
                  modelValue: _ctx.filters.geoId,
                  "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.filters.geoId) = $event)),
                  onBlur: _cache[33] || (_cache[33] = ($event: any) => (_ctx.onChangeFilters('geoId', _ctx.filters.geoId))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.geos,
                  onFilter: _ctx.getGeos,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  "selected-item": _withCtx((scope) => [
                    _createElementVNode("span", _hoisted_25, [
                      _createElementVNode("img", {
                        style: {"user-select":"none","display":"initial"},
                        class: "q-mr-sm",
                        height: "10",
                        width: "15",
                        src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${scope.opt.isoCode}.svg`
              
                      }, null, 8, _hoisted_26),
                      _createTextVNode(" " + _toDisplayString(scope.opt.name), 1)
                    ])
                  ]),
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          style: {"user-select":"none","display":"initial"},
                          class: "q-mr-sm",
                          height: "10",
                          width: "15",
                          src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${scope.opt.isoCode}.svg`
              
                        }, null, 8, _hoisted_27),
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, {
                              innerHTML: scope.opt.name
                            }, null, 8, ["innerHTML"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (
        !_ctx.hideForStatistics || !_ctx.hideForFinances || !_ctx.hideForLogs || !_ctx.hideForOffers
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _cache[120] || (_cache[120] = _createElementVNode("b", null, "ГЕО:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    modelValue: _ctx.filters.includeGeoIds,
                    "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.filters.includeGeoIds) = $event)),
                    modelModifiers: { boolean: true },
                    "toggle-color": _ctx.filters.includeGeoIds ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["modelValue", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.geoIds,
                  "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.filters.geoIds) = $event)),
                  onBlur: _cache[36] || (_cache[36] = ($event: any) => (_ctx.onChangeFilters('geoIds', _ctx.filters.geoIds))),
                  outlined: "",
                  ref: "geoIds",
                  multiple: "",
                  "use-chips": "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.geos,
                  onFilter: _ctx.getGeos,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps({ class: "items-center" }, scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        (scope.opt.id > 0)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              style: {"user-select":"none","display":"initial"},
                              class: "q-mr-sm",
                              height: "10",
                              width: "15",
                              src: 
                `http://purecatamphetamine.github.io/country-flag-icons/3x2/${scope.opt.isoCode}.svg`
              
                            }, null, 8, _hoisted_30))
                          : (_openBlock(), _createElementBlock("span", _hoisted_31, "🌍")),
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, {
                              innerHTML: scope.opt.name
                            }, null, 8, ["innerHTML"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForPayouts &&
          _ctx.hideForOffers &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForCampaigns &&
          _ctx.hideForConversions &&
          _ctx.hideForStatistics &&
          _ctx.hideForAffiliates &&
          _ctx.hideForFinances
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _cache[121] || (_cache[121] = _createElementVNode("b", null, "Афф. менеджер:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    modelValue: _ctx.filters.includeManagerId,
                    "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.filters.includeManagerId) = $event)),
                    "toggle-color": _ctx.filters.includeManagerId ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["modelValue", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  clearable: "",
                  modelValue: _ctx.filters.managerId,
                  "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.filters.managerId) = $event)),
                  onBlur: _cache[39] || (_cache[39] = ($event: any) => (_ctx.onChangeFilters('managerId', _ctx.filters.managerId))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.admins,
                  onFilter: _ctx.getManagers,
                  "option-value": "id",
                  "option-label": "email",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_IconWithName, {
                              id: scope.opt.id,
                              name: scope.opt.email,
                              size: "20px",
                              color: "primary",
                              icon: "manage_accounts",
                              entity: "manager"
                            }, null, 8, ["id", "name"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (
        ((!_ctx.hideForStatistics &&
          _ctx.tab !== 'consumers' &&
          _ctx.tab !== 'referral-stats') ||
          !_ctx.hideForAffiliates ||
          !_ctx.hideForFinances ||
          _ctx.tab === 'limits') &&
          _ctx.tab !== 'payouts'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                _createElementVNode("div", _hoisted_35, [
                  _cache[122] || (_cache[122] = _createElementVNode("b", null, "Афф. менеджер:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    modelValue: _ctx.filters.includeManagerIds,
                    "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.filters.includeManagerIds) = $event)),
                    "toggle-color": _ctx.filters.includeManagerIds ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["modelValue", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  "use-chips": "",
                  ref: "managerIds",
                  multiple: "",
                  modelValue: _ctx.filters.managerIds,
                  "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.filters.managerIds) = $event)),
                  onBlur: _cache[42] || (_cache[42] = ($event: any) => (_ctx.onChangeFilters('managerIds', _ctx.filters.managerIds))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.admins,
                  onFilter: _ctx.getManagers,
                  "option-value": "id",
                  "option-label": "email",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_IconWithName, {
                              id: scope.opt.id,
                              name: scope.opt.email,
                              size: "20px",
                              color: "primary",
                              icon: "manage_accounts",
                              entity: "manager"
                            }, null, 8, ["id", "name"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForEditedOffers &&
          _ctx.hideForNewOffers &&
          (!_ctx.hideForFinances || !_ctx.hideForOffers || !_ctx.hideForStatistics)
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                _createElementVNode("div", _hoisted_37, [
                  _cache[123] || (_cache[123] = _createElementVNode("b", null, "Adv. manager:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    modelValue: _ctx.filters.includeAdvertiserManagerIds,
                    "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.filters.includeAdvertiserManagerIds) = $event)),
                    "toggle-color": 
            _ctx.filters.includeAdvertiserManagerIds ? 'positive' : 'red'
          ,
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["modelValue", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  "use-chips": "",
                  ref: "advertiserManagerIds",
                  multiple: "",
                  modelValue: _ctx.filters.advertiserManagerIds,
                  "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.filters.advertiserManagerIds) = $event)),
                  onBlur: _cache[45] || (_cache[45] = ($event: any) => (
          _ctx.onChangeFilters('advertiserManagerIds', _ctx.filters.advertiserManagerIds)
        )),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.advertiserManagers,
                  onFilter: _ctx.getAdvertiserManagers,
                  "option-value": "id",
                  "option-label": "email",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_IconWithName, {
                              id: scope.opt.id,
                              name: scope.opt.email,
                              size: "20px",
                              color: "primary",
                              icon: "manage_accounts",
                              entity: "manager"
                            }, null, 8, ["id", "name"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (
        (!_ctx.hideForStatistics && _ctx.tab === 'general') ||
          (!_ctx.hideForOffers &&
            _ctx.hideForFinancesReport &&
            _ctx.hideForEditedOffers &&
            _ctx.hideForNewOffers &&
            _ctx.hideForConversions &&
            _ctx.hideForAffiliates &&
            _ctx.hideForPayouts &&
            _ctx.hideForLogs &&
            _ctx.hideForAdvertisers &&
            _ctx.hideForCampaigns &&
            _ctx.hideForFinances)
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _cache[124] || (_cache[124] = _createElementVNode("b", null, "Маркер офера:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeOfferMarkerId,
                    "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => (
            _ctx.onChangeFilters(
              'includeOfferMarkerId',
              !_ctx.filters.includeOfferMarkerId,
            )
          )),
                    "toggle-color": _ctx.filters.includeOfferMarkerId ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  clearable: "",
                  modelValue: _ctx.filters.offerMarkerIds,
                  "onUpdate:modelValue": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.filters.offerMarkerIds) = $event)),
                  ref: "offerMarkers",
                  onBlur: _cache[48] || (_cache[48] = ($event: any) => (_ctx.onChangeFilters('offerMarkers', _ctx.filters.offerMarkerIds))),
                  outlined: "",
                  "use-chips": "",
                  multiple: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.offerMarkers,
                  onFilter: _ctx.getOfferMarkers,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, {
                              innerHTML: scope.opt.name
                            }, null, 8, ["innerHTML"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_q_item_section, { side: "" }, {
                          default: _withCtx(() => [
                            (scope.opt.id !== 0)
                              ? _withDirectives((_openBlock(), _createBlock(_component_q_btn, {
                                  key: 0,
                                  icon: "delete",
                                  rounded: "",
                                  outline: "",
                                  style: {"font-size":"10px !important"},
                                  onClick: _withModifiers(($event: any) => (_ctx.deleteOfferMarker(scope.opt.id)), ["stop"])
                                }, null, 8, ["onClick"])), [
                                  [_directive_close_popup]
                                ])
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (
        (!_ctx.hideForAffiliates && _ctx.tab !== 'payouts' && _ctx.tab !== 'campaigns') ||
          (!_ctx.hideForFinances && _ctx.hideForFinancesReport)
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                _createElementVNode("div", _hoisted_41, [
                  _cache[125] || (_cache[125] = _createElementVNode("b", null, "Маркер:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeMarkerId,
                    "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => (
            _ctx.onChangeFilters('includeMarkerId', !_ctx.filters.includeMarkerId)
          )),
                    "toggle-color": _ctx.filters.includeMarkerId ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  clearable: "",
                  modelValue: _ctx.filters.markerIds,
                  "onUpdate:modelValue": _cache[50] || (_cache[50] = ($event: any) => ((_ctx.filters.markerIds) = $event)),
                  onBlur: _cache[51] || (_cache[51] = ($event: any) => (_ctx.onChangeFilters('markers', _ctx.filters.markerIds))),
                  outlined: "",
                  "use-chips": "",
                  multiple: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.markers,
                  onFilter: _ctx.getMarkers,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, {
                              innerHTML: scope.opt.name
                            }, null, 8, ["innerHTML"])
                          ]),
                          _: 2
                        }, 1024),
                        (
                !['New', 'Awol', 'Potential', 'Active'].includes(scope.opt.name)
              )
                          ? (_openBlock(), _createBlock(_component_q_item_section, {
                              key: 0,
                              side: ""
                            }, {
                              default: _withCtx(() => [
                                (scope.opt.id !== 0)
                                  ? _withDirectives((_openBlock(), _createBlock(_component_q_btn, {
                                      key: 0,
                                      icon: "delete",
                                      rounded: "",
                                      outline: "",
                                      style: {"font-size":"10px !important"},
                                      onClick: _withModifiers(($event: any) => (_ctx.deleteMarker(scope.opt.id)), ["stop"])
                                    }, null, 8, ["onClick"])), [
                                      [_directive_close_popup]
                                    ])
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForStatistics &&
          _ctx.hideForPayouts &&
          _ctx.hideForLogs &&
          _ctx.hideForOffers &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForAffiliates &&
          _ctx.hideForFinances &&
          _ctx.tab !== 'limits'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                _createElementVNode("div", _hoisted_43, [
                  _cache[126] || (_cache[126] = _createElementVNode("b", null, "Статус потока:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeStatus,
                    "onUpdate:modelValue": _cache[52] || (_cache[52] = ($event: any) => (
            _ctx.onChangeFilters('includeStatus', !_ctx.filters.includeStatus)
          )),
                    "toggle-color": _ctx.filters.includeStatus ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.status,
                  "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => ((_ctx.filters.status) = $event)),
                  onBlur: _cache[54] || (_cache[54] = ($event: any) => (_ctx.onChangeFilters('status', _ctx.filters.status))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.campaignStatuses,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.tab === 'limits')
            ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                _createElementVNode("div", _hoisted_45, [
                  _cache[127] || (_cache[127] = _createElementVNode("b", null, "Статус капы:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeStatus,
                    "onUpdate:modelValue": _cache[55] || (_cache[55] = ($event: any) => (
            _ctx.onChangeFilters('includeStatus', !_ctx.filters.includeStatus)
          )),
                    "toggle-color": _ctx.filters.includeStatus ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.leadLimitStatus,
                  "onUpdate:modelValue": _cache[56] || (_cache[56] = ($event: any) => ((_ctx.filters.leadLimitStatus) = $event)),
                  onBlur: _cache[57] || (_cache[57] = ($event: any) => (_ctx.onChangeFilters('leadLimitStatus', _ctx.filters.leadLimitStatus))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.leadLimitStatuses,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForEditedOffers &&
          _ctx.hideForNewOffers &&
          !_ctx.hideForConversions
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                _createElementVNode("div", _hoisted_47, [
                  _cache[128] || (_cache[128] = _createElementVNode("b", null, "Статус:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeStatus,
                    "onUpdate:modelValue": _cache[58] || (_cache[58] = ($event: any) => (
            _ctx.onChangeFilters('includeStatus', !_ctx.filters.includeStatus)
          )),
                    "toggle-color": _ctx.filters.includeStatus ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.status,
                  "onUpdate:modelValue": _cache[59] || (_cache[59] = ($event: any) => ((_ctx.filters.status) = $event)),
                  onBlur: _cache[60] || (_cache[60] = ($event: any) => (_ctx.onChangeFilters('status', _ctx.filters.status))),
                  outlined: "",
                  multiple: "",
                  "use-chips": "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.statuses,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (
        (_ctx.hideForWallets &&
          _ctx.hideForStatistics &&
          _ctx.hideForCampaigns &&
          _ctx.hideForLogs &&
          _ctx.hideForOffers &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForAffiliates &&
          _ctx.hideForFinances) ||
          _ctx.tab === 'payouts'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                _createElementVNode("div", _hoisted_49, [
                  _cache[129] || (_cache[129] = _createElementVNode("b", null, "Статус выплаты:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeStatus,
                    "onUpdate:modelValue": _cache[61] || (_cache[61] = ($event: any) => (
            _ctx.onChangeFilters('includeStatus', !_ctx.filters.includeStatus)
          )),
                    "toggle-color": _ctx.filters.includeStatus ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.status,
                  "onUpdate:modelValue": _cache[62] || (_cache[62] = ($event: any) => ((_ctx.filters.status) = $event)),
                  onBlur: _cache[63] || (_cache[63] = ($event: any) => (_ctx.onChangeFilters('status', _ctx.filters.status))),
                  outlined: "",
                  "use-chips": "",
                  ref: "status",
                  multiple: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.payoutStatuses,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForEditedOffers &&
          _ctx.hideForNewOffers &&
          _ctx.hideForStatistics &&
          _ctx.hideForPayouts &&
          _ctx.hideForLogs &&
          _ctx.hideForAffiliates &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForCampaigns &&
          _ctx.hideForFinances
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                _createElementVNode("div", _hoisted_51, [
                  _cache[130] || (_cache[130] = _createElementVNode("b", null, "Статус:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeStatus,
                    "onUpdate:modelValue": _cache[64] || (_cache[64] = ($event: any) => (
            _ctx.onChangeFilters('includeStatus', !_ctx.filters.includeStatus)
          )),
                    "toggle-color": _ctx.filters.includeStatus ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.status,
                  "onUpdate:modelValue": _cache[65] || (_cache[65] = ($event: any) => ((_ctx.filters.status) = $event)),
                  onBlur: _cache[66] || (_cache[66] = ($event: any) => (_ctx.onChangeFilters('status', _ctx.filters.status))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.offerStatuses,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          false
            ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                _cache[131] || (_cache[131] = _createElementVNode("b", null, "Вертикаль:", -1)),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.vertical,
                  "onUpdate:modelValue": _cache[67] || (_cache[67] = ($event: any) => ((_ctx.filters.vertical) = $event)),
                  onBlur: _cache[68] || (_cache[68] = ($event: any) => (_ctx.onChangeFilters('vertical', _ctx.filters.vertical))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.verticals,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForEditedOffers &&
          _ctx.hideForNewOffers &&
          !_ctx.hideForOffers
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                _createElementVNode("div", _hoisted_54, [
                  _cache[132] || (_cache[132] = _createElementVNode("b", null, "Категория:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeOfferCategoryId,
                    "onUpdate:modelValue": _cache[69] || (_cache[69] = ($event: any) => (
            _ctx.onChangeFilters(
              'includeOfferCategoryId',
              !_ctx.filters.includeOfferCategoryId,
            )
          )),
                    "toggle-color": _ctx.filters.includeOfferCategoryId ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.offerCategoryId,
                  "onUpdate:modelValue": _cache[70] || (_cache[70] = ($event: any) => ((_ctx.filters.offerCategoryId) = $event)),
                  onBlur: _cache[71] || (_cache[71] = ($event: any) => (_ctx.onChangeFilters('offerCategoryId', _ctx.filters.offerCategoryId))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.categories,
                  onFilter: _ctx.getOfferCategories,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, null, 8, ["modelValue", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideForOffers)
            ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                _createElementVNode("div", _hoisted_56, [
                  _cache[133] || (_cache[133] = _createElementVNode("b", null, "Источник трафа:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    modelValue: _ctx.filters.includeTrafficSourceId,
                    "onUpdate:modelValue": _cache[72] || (_cache[72] = ($event: any) => ((_ctx.filters.includeTrafficSourceId) = $event)),
                    "toggle-color": _ctx.filters.includeTrafficSourceId ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["modelValue", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.trafficSourceNames,
                  "onUpdate:modelValue": _cache[73] || (_cache[73] = ($event: any) => ((_ctx.filters.trafficSourceNames) = $event)),
                  onBlur: _cache[74] || (_cache[74] = ($event: any) => (
          _ctx.onChangeFilters('trafficSourceNames', _ctx.filters.trafficSourceNames)
        )),
                  onFilter: _cache[75] || (_cache[75] = (value, updateFn) => _ctx.getTrafficSources(value, updateFn)),
                  outlined: "",
                  "use-chips": "",
                  multiple: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.trafficSourceNames
                }, null, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForAffiliates &&
          _ctx.hideForPayouts &&
          _ctx.hideForLogs &&
          _ctx.hideForOffers &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForCampaigns &&
          _ctx.hideForFinances &&
          _ctx.tab !== 'consumers' &&
          _ctx.tab !== 'referral-stats'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                _createElementVNode("div", _hoisted_58, [
                  _cache[134] || (_cache[134] = _createElementVNode("b", null, "Тип конверсии:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeRewardType,
                    "onUpdate:modelValue": _cache[76] || (_cache[76] = ($event: any) => (
            _ctx.onChangeFilters('includeRewardType', !_ctx.filters.includeRewardType)
          )),
                    "toggle-color": _ctx.filters.includeRewardType ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.rewardType,
                  "onUpdate:modelValue": _cache[77] || (_cache[77] = ($event: any) => ((_ctx.filters.rewardType) = $event)),
                  onBlur: _cache[78] || (_cache[78] = ($event: any) => (_ctx.onChangeFilters('rewardType', _ctx.filters.rewardType))),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.rewardTypes,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForAffiliates &&
          _ctx.hideForPayouts &&
          _ctx.hideForLogs &&
          _ctx.hideForOffers &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForCampaigns &&
          _ctx.hideForFinances &&
          _ctx.tab !== 'consumers' &&
          _ctx.tab !== 'referral-stats' &&
          !_ctx.user.showStatisticsOnly
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                _createElementVNode("div", _hoisted_60, [
                  _cache[135] || (_cache[135] = _createElementVNode("b", null, "Лендинги:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeLandingId,
                    "onUpdate:modelValue": _cache[79] || (_cache[79] = ($event: any) => (
            _ctx.onChangeFilters('includeLandingId', !_ctx.filters.includeLandingId)
          )),
                    "toggle-color": _ctx.filters.includeLandingId ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.landingId,
                  "onUpdate:modelValue": _cache[80] || (_cache[80] = ($event: any) => ((_ctx.filters.landingId) = $event)),
                  outlined: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.promos,
                  onBlur: _cache[81] || (_cache[81] = ($event: any) => (_ctx.onChangeFilters('landingId', _ctx.filters.landingId))),
                  onFilter: _cache[82] || (_cache[82] = 
          (value, updateFn) => _ctx.getOptionsByField(value, updateFn, 'promos')
        ),
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, null, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForEditedOffers &&
          _ctx.hideForNewOffers &&
          _ctx.hideForAffiliates &&
          _ctx.hideForPayouts &&
          _ctx.hideForLogs &&
          _ctx.hideForOffers &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForCampaigns &&
          _ctx.hideForStatistics &&
          _ctx.hideForFinances
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                _createElementVNode("div", _hoisted_62, [
                  _cache[136] || (_cache[136] = _createElementVNode("b", null, "Категория:", -1)),
                  _createVNode(_component_q_btn_toggle, {
                    class: "q-ml-sm filter-btn-toggle",
                    unelevated: "",
                    size: "xs",
                    "model-value": _ctx.filters.includeCategoryId,
                    "onUpdate:modelValue": _cache[83] || (_cache[83] = ($event: any) => (
            _ctx.onChangeFilters('includeCategoryId', !_ctx.filters.includeCategoryId)
          )),
                    "toggle-color": _ctx.filters.includeCategoryId ? 'positive' : 'red',
                    options: [
            { label: 'IS', value: true },
            { label: 'IS NOT', value: false },
          ]
                  }, null, 8, ["model-value", "toggle-color"])
                ]),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.categoryId,
                  "onUpdate:modelValue": _cache[84] || (_cache[84] = ($event: any) => ((_ctx.filters.categoryId) = $event)),
                  outlined: "",
                  disable: _ctx.filters.vertical === undefined,
                  class: "col-4 q-mr-lg",
                  options: _ctx.categories,
                  onBlur: _cache[85] || (_cache[85] = ($event: any) => (_ctx.onChangeFilters('categoryId', _ctx.filters.categoryId))),
                  onFilter: _ctx.getCategories,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": "",
                  "use-input": ""
                }, null, 8, ["modelValue", "disable", "options", "onFilter"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForFinancesReport &&
          _ctx.hideForConversions &&
          _ctx.hideForAffiliates &&
          _ctx.hideForPayouts &&
          _ctx.hideForLogs &&
          _ctx.hideForOffers &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForCampaigns &&
          _ctx.tab !== 'consumers'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                _cache[137] || (_cache[137] = _createElementVNode("b", null, "Группировка 1:", -1)),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.groupBy,
                  "onUpdate:modelValue": _cache[86] || (_cache[86] = ($event: any) => ((_ctx.filters.groupBy) = $event)),
                  outlined: "",
                  onBlur: _cache[87] || (_cache[87] = ($event: any) => (_ctx.onChangeFilters('groupBy', _ctx.filters.groupBy))),
                  class: "col-4 q-mr-lg",
                  options: _ctx.groupByOptions,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForFinancesReport &&
          _ctx.hideForConversions &&
          _ctx.hideForAffiliates &&
          _ctx.hideForPayouts &&
          _ctx.hideForLogs &&
          _ctx.hideForOffers &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForCampaigns &&
          _ctx.tab !== 'consumers'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                _cache[138] || (_cache[138] = _createElementVNode("b", null, "Группировка 2:", -1)),
                _createVNode(_component_q_select, {
                  dense: "",
                  clearable: "",
                  modelValue: _ctx.filters.groupBy2,
                  "onUpdate:modelValue": _cache[88] || (_cache[88] = ($event: any) => ((_ctx.filters.groupBy2) = $event)),
                  outlined: "",
                  onBlur: _cache[89] || (_cache[89] = ($event: any) => (_ctx.onChangeFilters('groupBy2', _ctx.filters.groupBy2))),
                  class: "col-4 q-mr-lg",
                  options: _ctx.groupByOptions2,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForFinancesReport &&
          _ctx.hideForConversions &&
          _ctx.hideForAffiliates &&
          _ctx.hideForPayouts &&
          _ctx.hideForLogs &&
          _ctx.hideForOffers &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForCampaigns &&
          _ctx.tab !== 'consumers'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                _cache[139] || (_cache[139] = _createElementVNode("b", null, "Группировка 3:", -1)),
                _createVNode(_component_q_select, {
                  dense: "",
                  disable: !_ctx.filters.groupBy2,
                  clearable: "",
                  modelValue: _ctx.filters.groupBy3,
                  "onUpdate:modelValue": _cache[90] || (_cache[90] = ($event: any) => ((_ctx.filters.groupBy3) = $event)),
                  outlined: "",
                  onBlur: _cache[91] || (_cache[91] = ($event: any) => (_ctx.onChangeFilters('groupBy3', _ctx.filters.groupBy3))),
                  class: "col-4 q-mr-lg",
                  options: _ctx.groupByOptions3,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["disable", "modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.hideForWallets && _ctx.hideForEditedOffers && _ctx.hideForNewOffers)
            ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
                _cache[140] || (_cache[140] = _createElementVNode("b", null, "Дата:", -1)),
                _createVNode(_component_DatePicker, {
                  id: "1",
                  onChangeDate: _ctx.onChangeDate,
                  from: _ctx.filters.dateFrom,
                  to: _ctx.filters.dateTo
                }, null, 8, ["onChangeDate", "from", "to"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForEditedOffers &&
          _ctx.hideForNewOffers &&
          _ctx.hideForAffiliates &&
          !_ctx.hideForOffers
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                _cache[141] || (_cache[141] = _createElementVNode("b", null, "Предыдущий период:", -1)),
                _createVNode(_component_DatePicker, {
                  id: "3",
                  onChangeDate: _ctx.onChangePrevDate,
                  from: _ctx.filters.prevDateFrom,
                  to: _ctx.filters.prevDateTo
                }, null, 8, ["onChangeDate", "from", "to"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideForConversions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                _createElementVNode("div", _hoisted_69, [
                  _cache[142] || (_cache[142] = _createElementVNode("b", { class: "q-mr-md" }, "Conversion date", -1)),
                  _createVNode(_component_q_btn, {
                    flat: "",
                    label: "Сброс",
                    size: "xs",
                    color: "negative",
                    onClick: _cache[92] || (_cache[92] = ($event: any) => (_ctx.onChangeConversionDate({ startDate: null, endDate: null })))
                  })
                ]),
                _createVNode(_component_DatePicker, {
                  id: "2",
                  onChangeDate: _ctx.onChangeConversionDate,
                  from: _ctx.filters.conversionDateFrom,
                  to: _ctx.filters.conversionDateTo
                }, null, 8, ["onChangeDate", "from", "to"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideForConversions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_70, [
                _cache[143] || (_cache[143] = _createElementVNode("b", null, "Ставка веба:", -1)),
                _createVNode(_component_q_input, {
                  dense: "",
                  outlined: "",
                  debounce: "500",
                  autogrow: "",
                  clearable: "",
                  class: "col-4 q-mr-lg",
                  modelValue: _ctx.conversionAmount,
                  "onUpdate:modelValue": _cache[93] || (_cache[93] = ($event: any) => ((_ctx.conversionAmount) = $event))
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideForConversions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_71, [
                _cache[144] || (_cache[144] = _createElementVNode("b", null, "Ставка рекла:", -1)),
                _createVNode(_component_q_input, {
                  dense: "",
                  outlined: "",
                  debounce: "500",
                  autogrow: "",
                  clearable: "",
                  class: "col-4 q-mr-lg",
                  modelValue: _ctx.conversionAdvAmount,
                  "onUpdate:modelValue": _cache[94] || (_cache[94] = ($event: any) => ((_ctx.conversionAdvAmount) = $event))
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideForWallets || !_ctx.hideForConversions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_72, [
                _cache[145] || (_cache[145] = _createElementVNode("div", { class: "row items-center justify-between" }, [
                  _createElementVNode("b", null, "Валюта:")
                ], -1)),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.filters.currency,
                  "onUpdate:modelValue": _cache[95] || (_cache[95] = ($event: any) => ((_ctx.filters.currency) = $event)),
                  onBlur: _cache[96] || (_cache[96] = ($event: any) => (_ctx.onChangeFilters('currency', _ctx.filters.currency))),
                  outlined: "",
                  "use-chips": "",
                  ref: "currency",
                  multiple: "",
                  class: "col-4 q-mr-lg",
                  options: _ctx.currencies,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.hideForWallets &&
          _ctx.hideForEditedOffers &&
          _ctx.hideForNewOffers &&
          _ctx.hideForConversions &&
          _ctx.hideForAffiliates &&
          _ctx.hideForPayouts &&
          _ctx.hideForLogs &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForCampaigns &&
          _ctx.hideForFinances &&
          _ctx.tab !== 'consumers' &&
          _ctx.tab !== 'referral-stats'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                _cache[146] || (_cache[146] = _createElementVNode("b", null, "Пресет:", -1)),
                _createVNode(_component_q_select, {
                  dense: "",
                  modelValue: _ctx.presetId,
                  "onUpdate:modelValue": _cache[97] || (_cache[97] = ($event: any) => ((_ctx.presetId) = $event)),
                  outlined: "",
                  onFilter: _ctx.getPresets,
                  class: "col-4 q-mr-lg",
                  options: _ctx.presets,
                  "option-value": "id",
                  "option-label": "name",
                  "emit-value": "",
                  "map-options": ""
                }, {
                  option: _withCtx((scope) => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, {
                              innerHTML: scope.opt.name
                            }, null, 8, ["innerHTML"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_q_item_section, { side: "" }, {
                          default: _withCtx(() => [
                            _withDirectives(_createVNode(_component_q_btn, {
                              icon: "delete",
                              rounded: "",
                              outline: "",
                              style: {"font-size":"10px !important"},
                              onClick: _withModifiers(($event: any) => (_ctx.deletePreset(scope.opt.id)), ["stop"])
                            }, null, 8, ["onClick"]), [
                              [_directive_close_popup]
                            ])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "onFilter", "options"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_74, [
            _createVNode(_component_q_btn, {
              "no-caps": "",
              icon: "done",
              color: "positive",
              label: "Применить",
              size: "13px",
              onClick: _ctx.submitAll
            }, null, 8, ["onClick"])
          ]),
          (
        _ctx.hideForWallets &&
          _ctx.hideForEditedOffers &&
          _ctx.hideForNewOffers &&
          _ctx.hideForConversions &&
          _ctx.hideForAffiliates &&
          _ctx.hideForPayouts &&
          _ctx.hideForLogs &&
          _ctx.hideForAdvertisers &&
          _ctx.hideForCampaigns &&
          _ctx.hideForFinances &&
          _ctx.tab !== 'consumers' &&
          _ctx.tab !== 'referral-stats'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_75, [
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  icon: "save",
                  color: "primary",
                  label: "Сохранить пресет",
                  size: "13px",
                  onClick: _cache[98] || (_cache[98] = ($event: any) => (_ctx.showPresetDialog = !_ctx.showPresetDialog)),
                  outline: ""
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_76, [
            _createVNode(_component_q_btn, {
              round: "",
              size: "sm",
              color: "negative",
              icon: "autorenew",
              onClick: _ctx.resetFilters
            }, null, 8, ["onClick"])
          ]),
          (
        (!_ctx.hideForCampaigns && _ctx.user.isSuperAdmin && _ctx.tab === 'limits') ||
          (!_ctx.hideForStatistics && _ctx.tab !== 'consumers') ||
          !_ctx.hideForFinances ||
          !_ctx.hideForLogs ||
          !_ctx.hideForPayouts ||
          (!_ctx.hideForAffiliates && (_ctx.user.id === 24 || _ctx.user.isSuperAdmin)) ||
          _ctx.tab === 'payouts'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_77, [
                _createVNode(_component_q_btn, {
                  round: "",
                  color: "primary",
                  icon: "get_app",
                  size: "sm",
                  disable: _ctx.expLoading,
                  loading: _ctx.expLoading,
                  onClick: _ctx.startLoading
                }, null, 8, ["disable", "loading", "onClick"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideForConversions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                _createVNode(_component_q_btn, {
                  square: "",
                  label: "All",
                  color: "dark",
                  icon: "keyboard_double_arrow_down",
                  size: "sm",
                  disable: _ctx.expAllLoading,
                  loading: _ctx.expAllLoading,
                  onClick: _ctx.exportAllFn
                }, null, 8, ["disable", "loading", "onClick"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideForConversions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
                _createVNode(_component_q_checkbox, {
                  label: "Подробнее",
                  "model-value": _ctx.filters.isDetailing,
                  onClick: _cache[99] || (_cache[99] = ($event: any) => (_ctx.onChangeFilters('isDetailing', !_ctx.filters.isDetailing)))
                }, null, 8, ["model-value"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideForFinancesReport)
            ? (_openBlock(), _createElementBlock("div", _hoisted_80, [
                _createVNode(_component_q_checkbox, {
                  label: "Без ASO",
                  modelValue: _ctx.filters.noAso,
                  "onUpdate:modelValue": _cache[100] || (_cache[100] = ($event: any) => ((_ctx.filters.noAso) = $event))
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideForFinancesReport)
            ? (_openBlock(), _createElementBlock("div", _hoisted_81, [
                _createVNode(_component_q_checkbox, {
                  label: "Без Traffborne",
                  modelValue: _ctx.filters.noTraffborne,
                  "onUpdate:modelValue": _cache[101] || (_cache[101] = ($event: any) => ((_ctx.filters.noTraffborne) = $event))
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideForCampaigns && _ctx.tab !== 'limits' && !_ctx.user?.isLimitedAccess)
            ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                _createVNode(_component_q_checkbox, {
                  label: "Фантом",
                  "model-value": _ctx.filters.fantomAffiliateOnly,
                  onClick: _cache[102] || (_cache[102] = ($event: any) => (
          _ctx.onChangeFilters('fantomAffiliateOnly', !_ctx.filters.fantomAffiliateOnly)
        ))
                }, null, 8, ["model-value"])
              ]))
            : _createCommentVNode("", true),
          (
        (!_ctx.hideForStatistics &&
          _ctx.tab !== 'consumers' &&
          _ctx.tab !== 'referral-stats' &&
          _ctx.hideForConversions) ||
          (!_ctx.hideForFinances && _ctx.hideForFinancesReport)
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_83, [
                _createVNode(_component_q_toggle, {
                  modelValue: _ctx.filters.factualStats,
                  "onUpdate:modelValue": _cache[103] || (_cache[103] = ($event: any) => ((_ctx.filters.factualStats) = $event)),
                  color: "positive",
                  label: "Фактические данные"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_tooltip, {
                  "max-width": "400px",
                  class: "q-tooltip"
                }, {
                  default: _withCtx(() => _cache[147] || (_cache[147] = [
                    _createTextVNode("Фактическая статистика показывает депозиты в дни когда они реально были сделаны. Историческая статистика показывает регистрации и депозиты на день клика.")
                  ])),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (
        (!_ctx.hideForStatistics && _ctx.tab !== 'consumers' && _ctx.hideForConversions) ||
          (!_ctx.hideForFinances && _ctx.hideForFinancesReport)
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_84, [
                _createVNode(_component_q_toggle, {
                  modelValue: _ctx.filters.hideChart,
                  "onUpdate:modelValue": _cache[104] || (_cache[104] = ($event: any) => ((_ctx.filters.hideChart) = $event)),
                  color: "dark",
                  label: "Скрыть график"
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true)
        ])),
    _createVNode(_component_SavePresetDialog, {
      open: _ctx.showPresetDialog,
      filters: _ctx.filters,
      onClose: _cache[105] || (_cache[105] = ($event: any) => (_ctx.showPresetDialog = false))
    }, null, 8, ["open", "filters"])
  ], 64))
}
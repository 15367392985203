import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_GeneralTab = _resolveComponent("GeneralTab")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_ConversionsTab = _resolveComponent("ConversionsTab")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl" }, {
    default: _withCtx(() => [
      _createVNode(_component_center, null, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("h6", null, "Статистика", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_q_tabs, {
        modelValue: _ctx.statsTab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.statsTab) = $event)),
        "inline-label": "",
        "no-caps": "",
        breakpoint: 0,
        align: "justify",
        class: "shadow-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab, {
            name: "general",
            label: "Общая"
          }),
          _createVNode(_component_q_tab, {
            name: "conversions",
            label: "Конверсии"
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_q_tab_panels, {
        class: "q-mt-lg",
        modelValue: _ctx.statsTab,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.statsTab) = $event)),
        animated: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab_panel, { name: "general" }, {
            default: _withCtx(() => [
              _createVNode(_component_GeneralTab, {
                filters: _ctx.filters,
                onRefresh: _ctx.resetFilters,
                onChange: _ctx.onChangeFilters,
                onChangeAll: _ctx.changeAll
              }, null, 8, ["filters", "onRefresh", "onChange", "onChangeAll"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "conversions" }, {
            default: _withCtx(() => [
              _createVNode(_component_ConversionsTab, {
                filters: _ctx.filters,
                onRefresh: _ctx.resetFilters,
                onChange: _ctx.onChangeFilters,
                onChangeAll: _ctx.changeAll
              }, null, 8, ["filters", "onRefresh", "onChange", "onChangeAll"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
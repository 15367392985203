
import { defineComponent } from 'vue'
import { getColumns, getColumnsForExport } from './getColumns'
import {
  getPagination,
  request,
  Row,
  Table,
  TableRequestProps,
} from '@/utils/request'
import Filters from '@/components/filters/Filters.vue'
import {
  getAffiliateBalancesApproved,
  getAffiliateBalancesHold,
  loginAsAffiliate,
} from '@/utils/affiliate'
import { getDefaultFiltersLast7Days } from '@/utils/request'
import { FiltersType } from '@/utils/request'
import AddAffiliateTagsDialog from '@/components/dialogs/AddAffiliateTagsDialog.vue'
import axios from 'axios'
import {
  confirmDialog,
  formatDate,
  formatValue,
  showErrorMessageSnackBar,
  showSuccessSnackBar,
} from '@/utils'
import IconWithName from '@/components/icons/IconWithName.vue'
import AffiliateCommentDialog from '@/components/dialogs/AffiliateCommentDialog.vue'
import ReferralPercentDialog from '@/components/dialogs/ReferralPercentDialog.vue'
import { lookupManagers } from '@/utils/lookups'
import { LocationQueryValue } from 'vue-router'
import AffiliateCommentsDialog from '@/components/dialogs/AffiliateCommentsDialog.vue'
import { FormatMoney } from 'format-money-js'
import { exportToCsv } from '@/utils/export'
import { BalanceValueMoney } from '@/store/types'

export default defineComponent({
  name: 'AffiliatesList',
  components: {
    AffiliateCommentsDialog,
    ReferralPercentDialog,
    AffiliateCommentDialog,
    IconWithName,
    AddAffiliateTagsDialog,
    Filters,
  },
  async created() {
    const admins = (await axios.get('/api/admins')).data
    this.admins = admins.map((admin: any) => ({
      ...admin,
      email: `#${admin.id} ` + admin.credentials.email,
    }))
    await this.onRequest({ pagination: this.pagination })
  },
  data() {
    const filtersL = this.$storage.getStorageSync('affiliateFiltersGmbt') || {}
    const paginationL =
      this.$storage.getStorageSync('affiliatePaginationGmbt') || {}
    const queryFilters = this.$route.query
    for (const [filter, value] of Object.entries(queryFilters)) {
      queryFilters[filter] = (Number(value) as unknown) as LocationQueryValue
    }
    const filters = {
      ...getDefaultFiltersLast7Days(),
      ...filtersL,
      ...queryFilters,
    }
    const pagination = {
      ...getPagination('id', 50),
      visibleColumns: getColumns().map(c => c.name),
      ...paginationL,
    }
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      rows: [],
      loading: true,
      filters,
      pagination,
      comments: [],
      columns: getColumns(),
      showAddTagsDialog: false,
      selectedRowId: null,
      selectedMarkerId: null,
      showCommentDialog: false,
      showCommentsDialog: false,
      showReferralsDialog: false,
      popupModel: null,
      admins: [],
      newManagers: [],
      moneyFormatter,
    }
  },
  watch: {
    async filters() {
      this.$storage.setStorageSync('affiliateFiltersGmbt', this.filters)
      this.$storage.removeStorageSync('affiliatePaginationGmbt')
      this.pagination.page = 1
      await this.onRequest({ pagination: this.pagination })
    },
    pagination: {
      handler() {
        this.$storage.setStorageSync('affiliatePaginationGmbt', this.pagination)
      },
      deep: true,
    },
  },
  methods: {
    async onExport() {
      const rows: any = this.rows.map((r: any) => ({
        id: r.id,
        email: r.affiliateEmail,
        telegram: r.affiliateTelegram,
        skype: r.affiliateSkype,
      }))
      for (const aff of rows) {
        const comments = (await axios.get('/api/affiliates/comments/' + aff.id))
          .data
        aff.comments = comments
          .map((c: any) =>
            c.comment
              .replace(/<style([\s\S]*?)<\/style>/gi, '')
              .replace(/<script([\s\S]*?)<\/script>/gi, '')
              .replace(/<\/div>/gi, '\n')
              .replace(/<\/li>/gi, '\n')
              .replace(/<li>/gi, '  *  ')
              .replace(/<\/ul>/gi, '\n')
              .replace(/<\/p>/gi, '\n')
              .replace(/<[^>]+>/gi, ''),
          )
          .reverse()
      }
      exportToCsv(rows, getColumnsForExport())
    },
    formatMoney(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
        {
          decimals: 2,
          separator: ' ',
        },
      )
    },
    async onRequest(props: TableRequestProps) {
      const options = props || { pagination: this.pagination }
      await request(options, this as Table, '/api/affiliates')
    },
    onRowClick(evt: string, row: Row) {
      this.$router.push('/affiliates/' + row.id)
    },
    addMarker(affiliateId: number, markerId: number) {
      this.selectedRowId = affiliateId as any
      this.selectedMarkerId = markerId as any
      this.showAddTagsDialog = !this.showAddTagsDialog
    },
    async deleteMarker(affiliateId: number, markerId: number, name: string) {
      confirmDialog(
        `Вы действительно хотите удалить маркер ${name}? с аффилейта ID:${affiliateId}?`,
        async () => {
          await axios.post(`/api/markers/delete-from-aff`, {
            id: markerId,
            affiliateId,
          })
          await this.onRequest({ pagination: this.pagination })
        },
      )
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    resetFilters() {
      this.filters = { ...getDefaultFiltersLast7Days() }
      this.$storage.setStorageSync(
        'affiliateFiltersGmbt',
        getDefaultFiltersLast7Days(),
      )
      this.$storage.removeStorageSync('affiliatePaginationGmbt')
      this.pagination.page = 1
    },
    async loginAffiliate(id: string) {
      await loginAsAffiliate(id)
    },
    frmtDate(date: Date) {
      return formatDate(date)
    },
    onCloseDialog() {
      this.showAddTagsDialog = false
      this.showCommentDialog = false
      this.showCommentsDialog = false
      this.showReferralsDialog = false
      this.selectedMarkerId = null
      this.comments = []
    },
    format(val: string) {
      return formatDate(val)
    },
    addComment(id: number) {
      this.selectedRowId = id as any
      this.showCommentDialog = true
    },
    viewComments(id: number) {
      this.selectedRowId = id as any
      this.showCommentsDialog = true
      const row: any = this.rows.find((r: any) => r.id === id)
      this.comments = row?.comments
    },
    getAdminById(id: number) {
      return this.admins.find((a: any) => a.id === id)
    },
    getAffiliateBalancesApproved(
      balances: BalanceValueMoney[],
      refBalances: any[],
    ) {
      return getAffiliateBalancesApproved(balances || [], refBalances || [])
    },
    getAffiliateBalancesHold(
      balances: BalanceValueMoney[],
      refBalances: any[],
    ) {
      return getAffiliateBalancesHold(balances || [], refBalances || [])
    },
    async getManagers(value: string, update: Function) {
      await lookupManagers(this, value, update)
    },
    getRowManagers(managers: any[]): number[] {
      if (!managers?.length) {
        return []
      }
      return managers.map((aml: any) => aml.adminId)
    },
    onChangeManagers(managerIds: number[], row: any) {
      const record: any = this.newManagers.find(
        (a: any) => a.affiliateId === row.id,
      )
      if (!record) {
        this.newManagers.push({
          affiliateId: row.id,
          managerIds,
        } as never)
      } else {
        record.managerIds = managerIds
      }
      row.managers = managerIds.map((adminId: number) => ({ adminId }))
    },
    saveManagers() {
      this.clearFilter('managers')
      if (this.newManagers.length) {
        axios
          .post('/api/affiliates/managers', this.newManagers)
          .then(() => {
            showSuccessSnackBar('Изменения сохранены')
          })
          .catch(() => {
            showErrorMessageSnackBar('Произошла ошибка :(')
          })
      }
    },
    clearFilter(selectedRef: string) {
      if (this.$refs[selectedRef] !== void 0) {
        ;(this.$refs[selectedRef] as any).updateInputValue('')
      }
    },
    getAdminEmail(id: number) {
      return (this.admins.find((ad: any) => ad.id === id) as any)?.credentials
        ?.email
    },
    copy(text: string) {
      const element = document.createElement('textarea')
      document.body.appendChild(element)
      element.value = text
      element.select()
      document.execCommand('copy')
      document.body.removeChild(element)
      showSuccessSnackBar('Скопировано')
    },
    resetColumns() {
      this.pagination.visibleColumns = getColumns().map(c => c.name)
    },
  },
})

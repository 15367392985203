import { formatDate } from '@/utils'
import { Currency } from '@/enums/Currency'
import { ColumnType } from '@/utils'

export function getColumns(): ColumnType[] {
  return [
    {
      name: 'id',
      label: 'ID',
      field: 'id',
      align: 'left',
      sortable: true,
    },
    {
      name: 'account',
      label: 'Кошелек',
      field: 'account',
      align: 'left',
    },
    {
      name: 'paymentSystem',
      label: 'Платежная система',
      field: 'paymentSystem',
      align: 'right',
      classes: 'monospaced',
      sortable: true,
    },
    {
      name: 'currency',
      label: 'Валюта',
      field: 'currency',
      align: 'right',
      classes: 'monospaced',
      sortable: true,
      format: (val: string) => Currency[Number(val)],
    },
    {
      name: 'createdAt',
      label: 'Дата добавления',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'partners',
      label: 'Рекламодатель',
      field: 'partners',
      align: 'left',
      sortable: true,
    },
    { name: 'actions', label: 'Действия', field: 'actions', align: 'center' },
  ]
}

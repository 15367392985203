import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "row q-mx-lg"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-5" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "col-7" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "row justify-between items-center" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 3 }
const _hoisted_14 = { class: "col-4" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = {
  key: 0,
  class: "text-negative"
}
const _hoisted_18 = { class: "row items-center q-mb-md" }
const _hoisted_19 = { class: "flex column col-6 overflow-auto q-mr-lg" }
const _hoisted_20 = { class: "q-mr-md" }
const _hoisted_21 = { class: "flex column col-5 overflow-auto" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = {
  class: "q-ml-sm absolute",
  style: {"right":"10px"}
}
const _hoisted_24 = { class: "col q-mt-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_center = _resolveComponent("center")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_popup_edit = _resolveComponent("q-popup-edit")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_IconWithName = _resolveComponent("IconWithName")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_Offers = _resolveComponent("Offers")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_CampaignsPage = _resolveComponent("CampaignsPage")!
  const _component_PayoutPage = _resolveComponent("PayoutPage")!
  const _component_Referrals = _resolveComponent("Referrals")!
  const _component_LoginLogs = _resolveComponent("LoginLogs")!
  const _component_ManagerChangelog = _resolveComponent("ManagerChangelog")!
  const _component_BalanceChangelog = _resolveComponent("BalanceChangelog")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_AddAffiliateTagsDialog = _resolveComponent("AddAffiliateTagsDialog")!
  const _component_AffiliateCommentDialog = _resolveComponent("AffiliateCommentDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_page, { class: "q-layout-container q-ml-xl" }, {
      default: _withCtx(() => [
        _cache[43] || (_cache[43] = _createElementVNode("h6", { class: "text-h6 flex flex-center q-mb-none" }, "Аффилейт, Профиль", -1)),
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_center, {
              key: 0,
              class: "q-mt-lg"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_spinner, {
                  color: "primary",
                  size: "3em",
                  thickness: 2
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_q_card, { class: "q-mx-md card q-mt-lg" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                          (_ctx.affiliate.image)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                class: "photo q-mr-sm",
                                src: `${_ctx.storageUrl + '/' + _ctx.affiliate.image}`,
                                style: {"font-size":"100px"}
                              }, null, 8, _hoisted_4))
                            : (_openBlock(), _createBlock(_component_q_icon, {
                                key: 1,
                                name: "account_circle",
                                style: {"font-size":"100px","color":"#ccc"}
                              }))
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          _cache[16] || (_cache[16] = _createElementVNode("b", null, "ID:", -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.affiliate.id) + " ", 1),
                          _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                          _cache[18] || (_cache[18] = _createElementVNode("b", null, "Email:", -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.affiliate.credentials.email) + " ", 1),
                          _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
                          _cache[20] || (_cache[20] = _createElementVNode("b", null, "Ник:", -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.affiliate.name) + " ", 1),
                          _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                          (_ctx.affiliate.referrer)
                            ? (_openBlock(), _createElementBlock("b", _hoisted_6, "Метки (откуда пришел веб):"))
                            : _createCommentVNode("", true),
                          _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
                          (_ctx.affiliate.referrer)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.affiliate.referrer), (row, index) => {
                                  return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(row[1] ? row : ''), 1))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.affiliate.referrer)
                            ? (_openBlock(), _createElementBlock("br", _hoisted_8))
                            : _createCommentVNode("", true),
                          _cache[23] || (_cache[23] = _createElementVNode("b", null, "Страна:", -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.affiliate.geo?.name) + " ", 1),
                          _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1)),
                          _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1)),
                          _cache[26] || (_cache[26] = _createElementVNode("b", null, "Маркеры:", -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.affiliate.tag) + " ", 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.affiliate.markers, (marker, i) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: i,
                              class: "q-mr-sm",
                              onClick: _withModifiers(() => true, ["stop"])
                            }, [
                              _createVNode(_component_q_badge, {
                                class: "cursor-pointer",
                                style: _normalizeStyle(`background: ${marker.color} !important;`)
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("b", null, _toDisplayString(marker.name), 1),
                                  _createVNode(_component_q_popup_edit, {
                                    class: "bg-grey-9 text-white marker-popup",
                                    anchor: "bottom middle"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_9, [
                                        _createElementVNode("span", null, [
                                          _cache[8] || (_cache[8] = _createElementVNode("b", null, "Название:", -1)),
                                          _cache[9] || (_cache[9] = _createTextVNode()),
                                          _createElementVNode("b", null, _toDisplayString(marker.name), 1)
                                        ]),
                                        _createElementVNode("span", null, [
                                          _createVNode(_component_q_btn, {
                                            icon: "edit",
                                            size: "xs",
                                            round: "",
                                            flat: "",
                                            onClick: _withModifiers(($event: any) => (_ctx.addMarker(marker.id)), ["stop"])
                                          }, null, 8, ["onClick"]),
                                          _createVNode(_component_q_btn, {
                                            icon: "delete",
                                            size: "xs",
                                            round: "",
                                            flat: "",
                                            onClick: _withModifiers(($event: any) => (
                            _ctx.deleteMarker(_ctx.affiliate.id, marker.id, marker.name)
                          ), ["stop"])
                                          }, null, 8, ["onClick"])
                                        ])
                                      ]),
                                      _createElementVNode("div", null, [
                                        _cache[10] || (_cache[10] = _createElementVNode("b", null, "Описание:", -1)),
                                        _cache[11] || (_cache[11] = _createTextVNode()),
                                        _createElementVNode("span", {
                                          innerHTML: marker.reason
                                        }, null, 8, _hoisted_10)
                                      ]),
                                      _createElementVNode("div", null, [
                                        _cache[12] || (_cache[12] = _createElementVNode("b", null, "Добавлено:", -1)),
                                        _createTextVNode(" " + _toDisplayString(_ctx.frmtDate(marker.createdAt)), 1)
                                      ]),
                                      _createElementVNode("div", null, [
                                        _cache[13] || (_cache[13] = _createElementVNode("b", null, "Кто добавил:", -1)),
                                        _createTextVNode(" " + _toDisplayString(marker.addedBySystem
                          ? 'Добавлено системой'
                          : marker.createdBy || 'Добавлено системой'), 1)
                                      ]),
                                      (
                        _ctx.frmtDate(marker.updatedAt) !==
                          _ctx.frmtDate(marker.createdAt) &&
                          marker.updatedBy &&
                          marker.updatedAt
                      )
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                            _cache[14] || (_cache[14] = _createElementVNode("b", null, "Изменено:", -1)),
                                            _createTextVNode(" " + _toDisplayString(_ctx.frmtDate(marker.updatedAt)), 1)
                                          ]))
                                        : _createCommentVNode("", true),
                                      (
                        _ctx.frmtDate(marker.updatedAt) !==
                          _ctx.frmtDate(marker.createdAt) &&
                          marker.updatedBy &&
                          marker.updatedAt
                      )
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                            _cache[15] || (_cache[15] = _createElementVNode("b", null, "Кто изменил:", -1)),
                                            _createTextVNode(" " + _toDisplayString(marker.updatedBy), 1)
                                          ]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["style"])
                            ]))
                          }), 128)),
                          (!_ctx.affiliate.markers?.length)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_13, "—"))
                            : _createCommentVNode("", true),
                          _createVNode(_component_q_btn, {
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addMarker(null))),
                            outline: "",
                            "no-caps": "",
                            class: "q-mt-md",
                            color: "secondary",
                            size: "sm",
                            icon: "add",
                            label: "Добавить маркер"
                          })
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_card, { class: "q-mx-md card q-mt-lg" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => _cache[27] || (_cache[27] = [
                      _createElementVNode("div", { class: "text-h6" }, "Контакты", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_14, [
                        _cache[28] || (_cache[28] = _createElementVNode("b", null, "Email:", -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.affiliate.credentials?.email) + " ", 1),
                        _cache[29] || (_cache[29] = _createElementVNode("br", null, null, -1)),
                        _cache[30] || (_cache[30] = _createElementVNode("b", null, "Telegram Ник:", -1)),
                        _createTextVNode(" @" + _toDisplayString(_ctx.affiliate.contacts?.telegram) + " ", 1),
                        _cache[31] || (_cache[31] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.affiliate.contacts?.skype
                ? 'Skype: ' + _ctx.affiliate.contacts?.skype
                : '') + " ", 1),
                        _cache[32] || (_cache[32] = _createElementVNode("br", null, null, -1))
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_card, { class: "q-mx-md card q-mt-lg" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _cache[33] || (_cache[33] = _createElementVNode("div", { class: "text-h6" }, "Балансы", -1)),
                      _cache[34] || (_cache[34] = _createElementVNode("b", null, "Основные балансы:", -1)),
                      _createElementVNode("pre", null, _toDisplayString(_ctx.getBalances(_ctx.affiliate.balances, false)), 1),
                      (_ctx.affiliate.referralBalances?.length)
                        ? (_openBlock(), _createElementBlock("b", _hoisted_15, "Реферальные балансы:"))
                        : _createCommentVNode("", true),
                      (_ctx.affiliate.referralBalances?.length)
                        ? (_openBlock(), _createElementBlock("pre", _hoisted_16, _toDisplayString(_ctx.getBalances(_ctx.affiliate.referralBalances, true)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_card, { class: "q-mx-md card q-mt-lg" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => _cache[35] || (_cache[35] = [
                      _createElementVNode("div", { class: "text-h6" }, "Платежные данные", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.affiliate.payoutAccounts, (acc, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "flex row q-mb-md q-pa-md",
                          style: {"border-radius":"6px","border":"1px solid #ccc"}
                        }, [
                          _createElementVNode("div", null, [
                            _cache[36] || (_cache[36] = _createElementVNode("b", null, "Платежная система:", -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.paymentSystem[acc.paymentSystem]) + " " + _toDisplayString(acc.paymentSystemNetwork !== null
                  ? `(${_ctx.paymentSystemNetwork[acc.paymentSystemNetwork]})`
                  : ''), 1),
                            _cache[37] || (_cache[37] = _createElementVNode("br", null, null, -1)),
                            _cache[38] || (_cache[38] = _createElementVNode("b", null, "Счет:", -1)),
                            _createTextVNode(" " + _toDisplayString(acc.account) + " ", 1),
                            (acc.removedAt)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_17, "(Удален)"))
                              : _createCommentVNode("", true),
                            _cache[39] || (_cache[39] = _createElementVNode("br", null, null, -1))
                          ]),
                          _cache[40] || (_cache[40] = _createElementVNode("br", null, null, -1)),
                          _cache[41] || (_cache[41] = _createElementVNode("br", null, null, -1))
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_card, { class: "q-mx-md card q-mt-lg" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, { class: "q-pt-none q-mt-lg" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_18, [
                        _cache[42] || (_cache[42] = _createElementVNode("b", { class: "text-h6 q-mr-md" }, "Комментарий", -1)),
                        _createVNode(_component_q_btn, {
                          size: "sm",
                          "no-caps": "",
                          rounded: "",
                          color: "primary",
                          label: "Добавить",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCommentDialog = !_ctx.showCommentDialog))
                        })
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.affiliate.comments, (comment, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "flex row q-mb-md q-pa-md",
                          style: {"border-radius":"6px","border":"1px solid #ccc"}
                        }, [
                          _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.format(comment.createdAt)), 1),
                            _createVNode(_component_IconWithName, {
                              id: comment.createdBy?.id,
                              name: comment.createdBy?.credentials?.email,
                              size: "20px",
                              color: "primary",
                              icon: "manage_accounts",
                              entity: "manager"
                            }, null, 8, ["id", "name"])
                          ]),
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("span", {
                              innerHTML: comment.comment
                            }, null, 8, _hoisted_22)
                          ]),
                          _createElementVNode("div", _hoisted_23, [
                            _createVNode(_component_q_btn, {
                              size: "xs",
                              round: "",
                              icon: "delete",
                              color: "negative",
                              onClick: ($event: any) => (_ctx.deleteComment(comment.id))
                            }, null, 8, ["onClick"])
                          ])
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_q_btn, {
                  dense: "",
                  round: "",
                  flat: "",
                  class: "row",
                  color: "primary",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/affiliates/edit/' + _ctx.$route.params.id))),
                  icon: "edit"
                }),
                _createVNode(_component_q_btn, {
                  dense: "",
                  round: "",
                  flat: "",
                  class: "row",
                  color: "primary",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (
            _ctx.$router.push(
              `/statistics?affiliateId=${_ctx.$route.params.id}&fromPage=1`,
            )
          )),
                  icon: "bar_chart"
                }),
                _createVNode(_component_q_btn, {
                  dense: "",
                  round: "",
                  flat: "",
                  class: "row",
                  color: "primary",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loginAffiliate(_ctx.affiliate.id))),
                  icon: "play_circle_outline"
                })
              ])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_q_tabs, {
              key: 2,
              modelValue: _ctx.affiliateTab,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.affiliateTab) = $event)),
              "inline-label": "",
              breakpoint: 0,
              align: "justify",
              class: "shadow-2 q-mx-xl q-my-lg"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tab, {
                  "no-caps": "",
                  name: "offers",
                  label: "Офферы"
                }),
                _createVNode(_component_q_tab, {
                  "no-caps": "",
                  name: "campaigns",
                  label: "Потоки"
                }),
                _createVNode(_component_q_tab, {
                  "no-caps": "",
                  name: "payouts",
                  label: "Выплаты"
                }),
                _createVNode(_component_q_tab, {
                  "no-caps": "",
                  name: "referrals",
                  label: "Реф. программа"
                }),
                _createVNode(_component_q_tab, {
                  "no-caps": "",
                  name: "logs",
                  label: "Логи входа"
                }),
                _createVNode(_component_q_tab, {
                  "no-caps": "",
                  name: "managers",
                  label: "Лог смены менеджеров"
                }),
                _createVNode(_component_q_tab, {
                  "no-caps": "",
                  name: "balances",
                  label: "Лог изменений баланса"
                })
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_q_tab_panels, {
              key: 3,
              class: "q-mt-lg",
              modelValue: _ctx.affiliateTab,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.affiliateTab) = $event)),
              animated: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tab_panel, { name: "offers" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Offers)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_tab_panel, { name: "campaigns" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CampaignsPage, {
                      affiliateId: _ctx.affiliate.id
                    }, null, 8, ["affiliateId"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_tab_panel, { name: "payouts" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PayoutPage, {
                      affiliateId: _ctx.affiliate.id
                    }, null, 8, ["affiliateId"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_tab_panel, { name: "referrals" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Referrals, {
                      affiliateId: _ctx.affiliate.id,
                      individual: _ctx.affiliate.individualReferralPercent,
                      onOff: _ctx.affiliate.referralProgramOn
                    }, null, 8, ["affiliateId", "individual", "onOff"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_tab_panel, { name: "logs" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_LoginLogs, {
                      affiliateId: _ctx.affiliate.id
                    }, null, 8, ["affiliateId"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_tab_panel, { name: "managers" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ManagerChangelog, {
                      affiliateId: _ctx.affiliate.id
                    }, null, 8, ["affiliateId"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_tab_panel, { name: "balances" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BalanceChangelog, {
                      affiliateId: _ctx.affiliate.id
                    }, null, 8, ["affiliateId"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_AddAffiliateTagsDialog, {
      open: _ctx.showAddTagsDialog,
      affiliateId: _ctx.$route.params.id,
      markerId: _ctx.selectedMarkerId,
      onClose: _ctx.onCloseDialog,
      onRefresh: _ctx.onRequest
    }, null, 8, ["open", "affiliateId", "markerId", "onClose", "onRefresh"]),
    _createVNode(_component_AffiliateCommentDialog, {
      open: _ctx.showCommentDialog,
      onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showCommentDialog = false)),
      onRefresh: _ctx.onRequest,
      affiliateId: _ctx.affiliate.id
    }, null, 8, ["open", "onRefresh", "affiliateId"])
  ], 64))
}
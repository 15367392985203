import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "flex row justify-around mobile-display-block" }
const _hoisted_3 = { class: "col-sm-12 col-lg-4" }
const _hoisted_4 = { class: "col-sm-12 col-lg-4" }
const _hoisted_5 = { class: "flex row justify-around mobile-display-block" }
const _hoisted_6 = { class: "col-sm-12 col-lg-4" }
const _hoisted_7 = { class: "col-sm-12 col-lg-4" }
const _hoisted_8 = { class: "flex row justify-around mobile-display-block" }
const _hoisted_9 = { class: "col-sm-12 col-lg-4" }
const _hoisted_10 = { class: "col-sm-12 col-lg-4" }
const _hoisted_11 = { class: "flex row justify-around mobile-display-block" }
const _hoisted_12 = { class: "col-sm-12 col-lg-4" }
const _hoisted_13 = { class: "col-sm-12 col-lg-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[8] || (_cache[8] = _createElementVNode("b", null, "One Signal API Key", -1)),
        _createVNode(_component_q_input, {
          outlined: "",
          placeholder: "xc786vxcvxcvxc87vcx8v9xcv8xc",
          "model-value": _ctx.mobileApp.oneSignalApiKey,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = oneSignalApiKey => _ctx.update({ oneSignalApiKey })),
          error: !!_ctx.errors?.oneSignalApiKey,
          "error-message": _ctx.errors?.oneSignalApiKey
        }, null, 8, ["model-value", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[9] || (_cache[9] = _createElementVNode("b", null, "Yandex Post API Key", -1)),
        _createVNode(_component_q_input, {
          outlined: "",
          placeholder: "a89d3395-454c-41ae-a344-f029feee42b8",
          "model-value": _ctx.mobileApp.yandexPostApiKey,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = yandexPostApiKey => _ctx.update({ yandexPostApiKey })),
          error: !!_ctx.errors?.yandexPostApiKey,
          "error-message": _ctx.errors?.yandexPostApiKey
        }, null, 8, ["model-value", "error", "error-message"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _cache[10] || (_cache[10] = _createElementVNode("b", null, "One Signal App ID", -1)),
        _createVNode(_component_q_input, {
          outlined: "",
          placeholder: "5eb5a37e-b458-11e3-ac11-000c2940e62c",
          "model-value": _ctx.mobileApp.oneSignalAppId,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = oneSignalAppId => _ctx.update({ oneSignalAppId })),
          error: !!_ctx.errors?.oneSignalAppId,
          "error-message": _ctx.errors?.oneSignalAppId
        }, null, 8, ["model-value", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[11] || (_cache[11] = _createElementVNode("b", null, "Yandex App Id", -1)),
        _createVNode(_component_q_input, {
          outlined: "",
          placeholder: "234247643",
          "model-value": _ctx.mobileApp.yandexAppId,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = yandexAppId => _ctx.update({ yandexAppId })),
          error: !!_ctx.errors?.yandexAppId,
          "error-message": _ctx.errors?.yandexAppId
        }, null, 8, ["model-value", "error", "error-message"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _cache[12] || (_cache[12] = _createElementVNode("b", null, "App Package", -1)),
        _createVNode(_component_q_input, {
          outlined: "",
          placeholder: "com.egypt.legends",
          "model-value": _ctx.mobileApp.oneSignalAppPackage,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = 
            oneSignalAppPackage => _ctx.update({ oneSignalAppPackage })
          ),
          error: !!_ctx.errors?.oneSignalAppPackage,
          "error-message": _ctx.errors?.oneSignalAppPackage
        }, null, 8, ["model-value", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[13] || (_cache[13] = _createElementVNode("b", null, "Facebook App Id", -1)),
        _createVNode(_component_q_input, {
          outlined: "",
          placeholder: "234247643",
          "model-value": _ctx.mobileApp.facebookAppId,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = facebookAppId => _ctx.update({ facebookAppId })),
          error: !!_ctx.errors?.facebookAppId,
          "error-message": _ctx.errors?.facebookAppId
        }, null, 8, ["model-value", "error", "error-message"])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _cache[14] || (_cache[14] = _createElementVNode("b", null, "Developer ID", -1)),
        _createVNode(_component_q_input, {
          outlined: "",
          placeholder: "324235",
          "model-value": _ctx.mobileApp.developerId,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = developerId => _ctx.update({ developerId })),
          error: !!_ctx.errors?.developerId,
          "error-message": _ctx.errors?.developerId
        }, null, 8, ["model-value", "error", "error-message"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[15] || (_cache[15] = _createElementVNode("b", null, "AppsFlyer Key", -1)),
        _createVNode(_component_q_input, {
          outlined: "",
          placeholder: "EPYZ8JCL28gA343zKiRkHa",
          "model-value": _ctx.mobileApp.appsflyerKey,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = appsflyerKey => _ctx.update({ appsflyerKey })),
          error: !!_ctx.errors?.appsflyerKey,
          "error-message": _ctx.errors?.appsflyerKey
        }, null, 8, ["model-value", "error", "error-message"])
      ])
    ])
  ]))
}
import { MobileAppState } from '@/store/types'
import { Currency } from '@/enums/Currency'
import { MobileAppConfig } from '@/enums/MobileAppConfig'

function getDefaultData(): MobileAppState {
  return {
    mobileApp: {
      name: null,
      vertical: null,
      description: null,
      status: null,
      configStatus: MobileAppConfig.NOT_DEFINED,
      image: null,
      playMarketImage: null,
      playMarketUrl: null,
      rules: [],
      vpnCheckerOn: true,
      customTdsUrl: null,
      isDisplayOffer: null,
      sendInstallToCloak: false,
      openGeoIds: [],
      blockGeoIds: [
        3,
        6,
        9,
        50,
        234,
        74,
        103,
        105,
        106,
        107,
        114,
        127,
        134,
        166,
        169,
        174,
        179,
        200,
        205,
        119,
        216,
        225,
        226,
        233,
        236,
        246,
      ],
      freeInstalls: false,
      unknownTrafficRedirectUrl: null,
      oneSignalApiKey: null,
      oneSignalAppId: null,
      oneSignalAppPackage: null,
      yandexApiKey: null,
      yandexAppId: null,
      facebookAppId: null,
      developerId: null,
      appsflyerKey: null,
      trafficSources: [],
      affiliateRewards: [],
      defaultInstallValue: { currency: Currency.USD },
    },
    errors: {},
  }
}

export default getDefaultData

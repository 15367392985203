import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "q-pa-md"
}
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { class: "col-4" }
const _hoisted_7 = { class: "col-4" }
const _hoisted_8 = { class: "col-4" }
const _hoisted_9 = { class: "col-4" }
const _hoisted_10 = { class: "col-4" }
const _hoisted_11 = { class: "col-4" }
const _hoisted_12 = { class: "col-4" }
const _hoisted_13 = { class: "col-4" }
const _hoisted_14 = { class: "col-4" }
const _hoisted_15 = { class: "col-4" }
const _hoisted_16 = { class: "col-4" }
const _hoisted_17 = { class: "col-4" }
const _hoisted_18 = { class: "col-4" }
const _hoisted_19 = { class: "q-mt-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-px-xl q-layout-container" }, {
    default: _withCtx(() => [
      _cache[28] || (_cache[28] = _createElementVNode("h6", { class: "text-h6 flex flex-center" }, " Добавление/Редактирование админа ", -1)),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_q_spinner, {
            key: 0,
            color: "primary",
            size: "3em",
            thickness: 2
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_form, {
              onSubmit: _ctx.onSubmit,
              onReset: _ctx.goBack,
              class: "q-gutter-md"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _cache[18] || (_cache[18] = _createElementVNode("b", null, "Email", -1)),
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    "model-value": _ctx.admin.credentials?.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = email => _ctx.update({ credentials: { email } }))
                  }, null, 8, ["model-value"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _cache[19] || (_cache[19] = _createElementVNode("b", null, "Имя", -1)),
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    "model-value": _ctx.admin.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = name => _ctx.update({ name }))
                  }, null, 8, ["model-value"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _cache[21] || (_cache[21] = _createElementVNode("b", null, "Телеграм", -1)),
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    "model-value": _ctx.admin.contacts?.telegram,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = telegram => _ctx.update({ contacts: { telegram } }))
                  }, {
                    prepend: _withCtx(() => _cache[20] || (_cache[20] = [
                      _createTextVNode("@")
                    ])),
                    _: 1
                  }, 8, ["model-value"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _cache[22] || (_cache[22] = _createElementVNode("b", null, "Skype", -1)),
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    "model-value": _ctx.admin.contacts?.skype,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = skype => _ctx.update({ contacts: { skype } }))
                  }, null, 8, ["model-value"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _cache[23] || (_cache[23] = _createElementVNode("b", null, "Базовая валюта аккаунта", -1)),
                  _createVNode(_component_q_select, {
                    modelValue: _ctx.admin.baseCurrency,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.admin.baseCurrency) = $event)),
                    outlined: "",
                    dense: "",
                    class: "col-12 q-mr-lg",
                    options: _ctx.currencies,
                    "option-value": "id",
                    "option-label": "name",
                    "emit-value": "",
                    "map-options": ""
                  }, null, 8, ["modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _cache[25] || (_cache[25] = _createElementVNode("b", null, "Отображаемые аффилейты:", -1)),
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => _cache[24] || (_cache[24] = [
                      _createTextVNode("По дефолту значение ВСЕ, если не указано. Админ видит статистику и остальной функционал только по этим вебам. Офферы и ставки видны по всем вебам. ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_q_select, {
                    dense: "",
                    outlined: "",
                    multiple: "",
                    "use-chips": "",
                    ref: "admins",
                    onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.clearFilter('admins'))),
                    modelValue: _ctx.admin.displayedAffiliateIds,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.admin.displayedAffiliateIds) = $event)),
                    onFilter: _ctx.getAffiliates,
                    options: _ctx.affiliates,
                    "option-value": "id",
                    "option-label": "email",
                    "emit-value": "",
                    "map-options": "",
                    "use-input": "",
                    error: !!_ctx.errors?.displayedAffiliateIds,
                    "error-message": _ctx.errors?.displayedAffiliateIds
                  }, null, 8, ["modelValue", "onFilter", "options", "error", "error-message"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _cache[26] || (_cache[26] = _createElementVNode("b", null, "Новый пароль", -1)),
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    type: "password",
                    "model-value": _ctx.admin.newPassword,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = newPassword => _ctx.update({ newPassword }))
                  }, null, 8, ["model-value"])
                ]),
                _cache[27] || (_cache[27] = _createElementVNode("b", null, "Роли", -1)),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_q_checkbox, {
                    label: "Ограниченный доступ. Уровень Junior Aff (видит только своих вебов, не видит фантомов, не видит страницы админов и приложений)",
                    modelValue: _ctx.admin.isLimitedAccess,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.admin.isLimitedAccess) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_q_checkbox, {
                    label: "Ограниченный доступ. Уровень Middle Aff (видит финансы по своим вебам, видит всю стату, видит все конверсии, видит всех вебов для потоков и фантомов, но в аффилейтах видит только своих)",
                    modelValue: _ctx.admin.isLightLimitedAccess,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.admin.isLightLimitedAccess) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_q_checkbox, {
                    label: "Ограниченный доступ. Уровень Read Only No FIN (read only, видит все кроме цифр в статистике и финансах)",
                    modelValue: _ctx.admin.hideFinances,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.admin.hideFinances) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_q_checkbox, {
                    class: "col-1",
                    label: "Админ TDS",
                    modelValue: _ctx.admin.isTdsAdmin,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.admin.isTdsAdmin) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_q_checkbox, {
                    class: "col-1",
                    label: "Аффилейт менеджер",
                    modelValue: _ctx.admin.isAffiliateManager,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.admin.isAffiliateManager) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_q_checkbox, {
                    class: "col-1",
                    label: "Advertiser менеджер",
                    modelValue: _ctx.admin.isAdvertiserManager,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.admin.isAdvertiserManager) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_q_checkbox, {
                    class: "col-1",
                    label: "Админ по прилам (видит только раздел Приложения)",
                    modelValue: _ctx.admin.isAppsAdmin,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.admin.isAppsAdmin) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_q_checkbox, {
                    class: "col-1",
                    label: "Супер админ",
                    modelValue: _ctx.admin.isSuperAdmin,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.admin.isSuperAdmin) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_q_checkbox, {
                    class: "col-1",
                    label: "Финансовый менеджер",
                    modelValue: _ctx.admin.isFinanceManager,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.admin.isFinanceManager) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_q_checkbox, {
                    class: "col-1",
                    label: "Отключить E-mail фин. оповещения",
                    modelValue: _ctx.admin.disableEmails,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.admin.disableEmails) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_q_btn, {
                    label: "Сохранить",
                    "no-caps": "",
                    type: "submit",
                    color: "primary"
                  }),
                  _createVNode(_component_q_btn, {
                    label: "Отменить",
                    type: "reset",
                    color: "primary",
                    flat: "",
                    "no-caps": "",
                    class: "q-ml-sm"
                  })
                ])
              ]),
              _: 1
            }, 8, ["onSubmit", "onReset"])
          ]))
    ]),
    _: 1
  }))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDialog) = $event)),
    onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("div", { class: "text-h6" }, "Справка", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Информация по настройке Postback ")
            ])),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("p", null, "Параметры:", -1),
              _createElementVNode("p", null, " {event} - Тип события (reg - при регистрации, first_dep - при первом депозите, revshare - при доходе по ревшаре) ", -1),
              _createElementVNode("p", null, "{campaign_id} - ID потока", -1),
              _createElementVNode("p", null, "{conversion_id} - ID конверсии", -1),
              _createElementVNode("p", null, "{offer_id} - ID оффера", -1),
              _createElementVNode("p", null, "{offer_name} - Название оффера", -1),
              _createElementVNode("p", null, "{status} - Статус (approved, pending, hold, rejected, trash)", -1),
              _createElementVNode("p", null, " {payout} - Сумма поступления (CPA - сумма дохода, RevShare доход за каждый час) ", -1),
              _createElementVNode("p", null, "{revshare_payout}- Общий доход по RevShare", -1),
              _createElementVNode("p", null, " {deposit_id}- уникальный ID депозита либо другого целевого события. Используется при доп. продажах. ", -1),
              _createElementVNode("p", null, "{currency} - Валюта поступления (usd, eur, rub, uah)", -1),
              _createElementVNode("p", null, "{date} - unix timestamp генерации действия", -1),
              _createElementVNode("p", null, "{sub1} - Sub ID 1", -1),
              _createElementVNode("p", null, "{sub2} - Sub ID 2", -1),
              _createElementVNode("p", null, "{sub3} - Sub ID 3", -1),
              _createElementVNode("p", null, "{sub4} - Sub ID 4", -1),
              _createElementVNode("p", null, "{sub5} - Sub ID 5", -1),
              _createElementVNode("p", null, "{sub6} - Sub ID 6", -1),
              _createElementVNode("p", null, "{sub7} - Sub ID 7", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                flat: "",
                label: "OK",
                color: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
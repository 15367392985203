import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_AffAdvReports = _resolveComponent("AffAdvReports")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_AffReports = _resolveComponent("AffReports")!
  const _component_AdvReports = _resolveComponent("AdvReports")!
  const _component_CashFlow = _resolveComponent("CashFlow")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl" }, {
    default: _withCtx(() => [
      _createVNode(_component_center, null, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("h6", null, "FIN REPORTS", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_q_tabs, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
        "no-caps": "",
        class: "bg-grey-1 q-ma-lg offer-tabs",
        dense: "",
        align: "justify"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab, {
            name: "aff-adv-reports",
            label: "Aff/Adver Reports"
          }),
          _createVNode(_component_q_tab, {
            name: "aff-reports",
            label: "Affiliate Reports"
          }),
          _createVNode(_component_q_tab, {
            name: "adv-reports",
            label: "Advertiser Reports"
          }),
          _createVNode(_component_q_tab, {
            name: "cashflow",
            label: "Cashflow"
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_q_tab_panels, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
        animated: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab_panel, { name: "aff-adv-reports" }, {
            default: _withCtx(() => [
              (_ctx.tab === 'aff-adv-reports')
                ? (_openBlock(), _createBlock(_component_AffAdvReports, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "aff-reports" }, {
            default: _withCtx(() => [
              (_ctx.tab === 'aff-reports')
                ? (_openBlock(), _createBlock(_component_AffReports, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "adv-reports" }, {
            default: _withCtx(() => [
              (_ctx.tab === 'adv-reports')
                ? (_openBlock(), _createBlock(_component_AdvReports, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "cashflow" }, {
            default: _withCtx(() => [
              _createVNode(_component_CashFlow)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
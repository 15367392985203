import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mobile-display-block" }
const _hoisted_2 = { class: "col-sm-10 col-lg-3 q-mr-md" }
const _hoisted_3 = { class: "col-sm-10 col-lg-3 q-mr-md" }
const _hoisted_4 = { class: "col-sm-10 col-lg-2 q-mr-md" }
const _hoisted_5 = { class: "self-center" }
const _hoisted_6 = { class: "row justify-center" }
const _hoisted_7 = { class: "self-center q-mr-md" }
const _hoisted_8 = { class: "self-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-ml-xl" }, {
    default: _withCtx(() => [
      _createVNode(_component_center, null, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createElementVNode("h6", null, "Доступы к приложениям", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[6] || (_cache[6] = _createElementVNode("b", null, "Приложение", -1)),
          _createVNode(_component_q_select, {
            modelValue: _ctx.access.mobileAppId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.access.mobileAppId) = $event)),
            outlined: "",
            class: "col-5",
            options: _ctx.apps,
            onFilter: _ctx.getMobileApps,
            "option-value": "id",
            "option-label": "name",
            "emit-value": "",
            "map-options": "",
            "use-input": "",
            error: !!_ctx.errors?.mobileAppId,
            "error-message": _ctx.errors?.mobileAppId
          }, null, 8, ["modelValue", "options", "onFilter", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[7] || (_cache[7] = _createElementVNode("b", null, "Аффилейт:", -1)),
          _createVNode(_component_q_select, {
            modelValue: _ctx.access.affiliateId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.access.affiliateId) = $event)),
            onFilter: _ctx.getAffiliates,
            outlined: "",
            "use-input": "",
            options: _ctx.affiliates,
            "option-value": "id",
            "option-label": "email",
            "emit-value": "",
            "map-options": "",
            error: !!_ctx.errors?.affiliateId,
            "error-message": _ctx.errors?.affiliateId
          }, null, 8, ["modelValue", "onFilter", "options", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[8] || (_cache[8] = _createElementVNode("b", null, "Статус", -1)),
          _createVNode(_component_q_select, {
            modelValue: _ctx.access.status,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.access.status) = $event)),
            disable: !_ctx.access.mobileAppId || !_ctx.access.affiliateId,
            outlined: "",
            options: _ctx.statuses,
            "option-value": "id",
            "option-label": "name",
            "emit-value": "",
            "map-options": "",
            error: !!_ctx.errors?.status,
            "error-message": _ctx.errors?.status
          }, null, 8, ["modelValue", "disable", "options", "error", "error-message"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_q_btn, {
            label: "Сохранить",
            color: "primary",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeStatus(_ctx.access)))
          })
        ])
      ]),
      _cache[9] || (_cache[9] = _createElementVNode("h6", null, "Активные запросы на доступ:", -1)),
      _createVNode(_component_q_table, {
        dense: "",
        rows: _ctx.rows,
        columns: _ctx.columns,
        "row-key": "id",
        separator: "cell",
        pagination: _ctx.pagination,
        "onUpdate:pagination": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pagination) = $event)),
        loading: _ctx.loading,
        onRequest: _ctx.onRequest,
        "binary-state-sort": ""
      }, {
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_q_btn, {
                    label: "Дать доступ",
                    color: "primary",
                    onClick: ($event: any) => (_ctx.changeStatus({ ...props.row, status: _ctx.status.Opened }))
                  }, null, 8, ["onClick"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_q_btn, {
                    outline: "",
                    color: "primary",
                    label: "Отклонить",
                    onClick: ($event: any) => (_ctx.changeStatus({ ...props.row, status: _ctx.status.Closed }))
                  }, null, 8, ["onClick"])
                ])
              ])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
    ]),
    _: 1
  }))
}